import React from "react";
import PropTypes from "prop-types";
import Button from "./button";
import ReactTooltip from "react-tooltip";

const SelectImage = (props) => {
  const { id, img, name: description, subtitle = "", role = "" } = props.data;
  const { isSelected } = props;
  const { withButton } = props;

  // const addLineBreaks = () =>
  //   description.split("\n").map((text, index) => (
  //     <React.Fragment key={`${text}-${index}`}>
  //       {text}
  //       <br />
  //     </React.Fragment>
  //   ));

  let imageToShow = "";

  if (String(img).includes("http") || String(img).includes("https")) {
    imageToShow = img;
  } else {
    imageToShow = "/" + img;
  }

  let itemClass = "item";

  if (props.className) {
    itemClass = itemClass.concat("-" + props.className);
  }

  return withButton ? (
    <div className={`${itemClass} ${isSelected ? "selected" : ""}`}>
      <img alt="" var="image" src={imageToShow} />
      <Button
        settings={{
          text: `${description.replace("\n", "-")}`,
          type: "outline",
          onClick: props.handleClick,
        }}
      />
    </div>
  ) : (
    <div
      className={`${itemClass} ${isSelected ? "selected" : ""}`}
      onClick={() => props.handleClick(id)}
    >
      {props.icon ? (
        <div className="avatar-container">
          <img
            alt=""
            var="image"
            src={imageToShow}
            onError={(e) => {
              console.log(e.error);
              e.target.src = "/images/factory-bckgrnd.png";
            }}
          />
        </div>
      ) : (
        <img
          alt=""
          var="image"
          src={imageToShow}
          onError={(e) => {
            console.log(e.error);
            e.target.src = "/images/factory-bckgrnd.png";
          }}
        />
      )}

      <b className="companyName" var="name">
        <span
          data-tip={description.replace("\n", "-")}
          data-for={"name-tooltip-".concat(String(id))}
        >
          {!description.includes("\n")
            ? truncateText(description)
            : description.split("\n").map((text, index) => (
                <React.Fragment key={`${text}-${index}`}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
        </span>
        {!description.includes("\n") &&
        description.replace("\n", "-").length > 14 ? (
          <ReactTooltip
            id={"name-tooltip-".concat(String(id))}
            type="light"
            effect="solid"
          />
        ) : (
          ""
        )}
      </b>

      {subtitle !== "" && (
        <div style={{ color: "#D5DEFF", marginTop: "5px" }}>{subtitle}</div>
      )}
      {role !== "" && (
        <div style={{ color: "#D5DEFF", marginTop: "5px" }}>{role}</div>
      )}
    </div>
  );
};

const truncateText = (text) => {
  return text.length > 14 ? `${text.substr(0, 14)}...` : text;
};

SelectImage.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.bool,
};

SelectImage.defaultProps = {
  icon: false,
};

export default SelectImage;
