import React from 'react'
import Button from './button';
import { PropTypes } from 'prop-types';

class InputProfileImage extends React.Component {

  handlePicture = () => {
    this.props.handlePicture()
  }

  render() {

    const { profileImage, defaultImage = '' } = this.props;

    var backgroundImage = '';

    if (defaultImage !== '') {
      backgroundImage = defaultImage;
    } else {
      backgroundImage = this.props.isFactory ? '/images/upload-logo-factory.jpg' : '/images/user_large.svg';
    }

    if (profileImage) {
      backgroundImage = ''
    }

    return (
      <div className="picture" style={ this.props.marginStyle && this.props.marginStyle }>

        <div className='send_photo'>Sube tu foto <div className='optional'>(opcional)</div></div>
        <h2 className="caption">{this.props.caption}</h2>
        <h2 className="caption">*Recuerda que tu imagen debe ser formato <i>.jpg</i>, <i>.png</i> o <i>.gif</i>.</h2>

        <div className="pictureImage">
          {
            profileImage ? <img alt="" className={"pictureFrame"} src={String(profileImage).includes("http") ? profileImage : URL.createObjectURL(profileImage)} /> :

              <div 
                style={{backgroundImage: `url(${backgroundImage})` }}
                className={this.props.isFactory ? "pictureFrameFac" : "pictureFrame"}
                onClick={this.handlePicture}
                onDragEnter={this.props.dragAndDrop}
                onDragLeave={this.props.dragAndDrop}
                onDragOver={this.props.dragAndDrop}
                onDrop={this.props.dragAndDrop} >

                <img alt="" src="/images/add_large.svg" />
              </div>
          }
        </div>

        <div className="pictureImageCont">
          <div className="pictureButton">
            <Button settings={{
              text: 'Selecciona tu foto',
              type: 'button outline btnPicture',
              onClick: this.handlePicture
            }}
              onDragEnter={this.props.dragAndDrop}
              onDragLeave={this.props.dragAndDrop}
              onDragOver={this.props.dragAndDrop}
              onDrop={this.props.dragAndDrop}
            />
          </div>
        </div>

      </div>
    )
  }
}

InputProfileImage.propTypes = {
  handlePicture: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
}

InputProfileImage.defaultProps = {
  className: '',
  title: ''
}

export default InputProfileImage