import Axios from "axios";
import React from "react";
import { connect } from 'react-redux';
// import { getEntityItems, readEntities } from "../redux/api";
import { SERVER } from "../redux/api/api-urls";
// import { FILTER_LIST_TYPES } from "../shared/component/filter/filter-bar-content";
import { View, ViewTitle } from "../shared/styled/view";
// import { groupFacilities } from "../shared/utils";
import TableSortable from '../shared/component/table-sortable/table-sortable';
import Button from '../shared/controls/button';
import ReactTooltip from "react-tooltip";
import Loader from "../shared/icons/tat/loader";
import AvailabilityColumnsSettingsModal from "../shared/dialog/availability-columns-settings-modal";
import AvailabilityReportMapModal from '../shared/dialog/availability-report-map-modal';
import { getEntityArgs, getEntityItems, partialEntity, readCatalog, readEntities } from "../redux/api";
import AvailabilityModal from "../control/traffic/availability-modal";
import { getAssembles } from "../control/assemble/assemble-utils";
import HelperFilesModal from '../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../redux/reducers/navbar-reducer";
import { getTimeZone, isPremiumSubscriptionCo } from "../shared/utils";
import { columnsDataCO, columnsDataTR } from "./availability/availability-data";
import styled from "styled-components";

const ORBI_SESSION = "orbinetwork_session";

class AvailabilityReportView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      // filterDate: [],
      // filterDateType: [],
      // filterFacilities: []
      downloadingFile: false,
      cancelTokenSource: null,

      columnsHidden: [],
      isShowSettingsModal: false,
      dataToShowInMap: null,
      availabilityData: null,
      dateToChange: '',
      companyData: JSON.parse(sessionStorage.getItem(ORBI_SESSION)).company,
      trailerLocation: null,
      sendEmailConfirmation: true,
      isFreeSubscription: false
    }
  }

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.props.loadReport({company_id: this.props.companyId});
    this.props.setNavbarAction("availability-report", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    // Cancel request
    this.props.removeNavbarAction("availability-report");

    if(this.state.cancelTokenSource !== null){
      this.state.cancelTokenSource.cancel();
    }
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  getUrl = () => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/availability/`;
    // if(this.props.companyType === 1){
    //   return `${SERVER}/api/v2/companies/${this.props.companyId}/trips/?download=true&type=container&timezone=${getTimeZone() || ""}&view=controls&appointment=${this.state.filterDate[0] ? "".concat(this.state.filterDate[0].toISOString(), ",", this.state.filterDate[1] ? this.state.filterDate[1].toISOString() : this.state.filterDate[0].toISOString()) : ""}&appointment_type=${(this.state.filterDateType || []).join(",") || "1,2,3"}`;
    // }
    // return `${SERVER}/api/v2/companies/${this.props.companyId}/facilities/${this.props.firstFacility}/trips_control/?download=true&type=container&timezone=${getTimeZone()}&view=all&appointment=${this.state.filterDate[0] ? "".concat(this.state.filterDate[0].toISOString(), ",", this.state.filterDate[1] ? this.state.filterDate[1].toISOString() : this.state.filterDate[0].toISOString()) : ""}&appointment_type=${(this.state.filterDateType  || []).join(",") || "1,2,3"}&multifacilities=${(this.state.filterFacilities || []).join(",")}`;
    
  };

  handleFilter = () => {
    let url = this.getUrl();
    this.setState({
      loading: true,
      downloadingFile: true, 
      cancelTokenSource: Axios.CancelToken.source() 
    }, () => {
      Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        cancelToken: this.state.cancelTokenSource.token,
        headers: this.getToken(),
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "Availability.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({
          loading: false,
          downloadingFile: false
        });
      })
    });
  }

  filterContents = () => {
    return [
      // {
      //   title: "Instalación",
      //   items: this.props.facilitiesByTag,
      //   name: "filterFacilities",
      //   listType: 5,
      // },
      // {
      //   title: "Fecha",
      //   name: "filterDate",
      //   listType: FILTER_LIST_TYPES.dateRangeFixed,
      // },
      // {
      //   title: "Tipo fecha",
      //   items: [
      //     {
      //       id: "1",
      //       description: "Carga"
      //     },
      //     {
      //       id: "2",
      //       description: "Descarga"
      //     },
      //     {
      //       id: "3",
      //       description: "Plan de carga"
      //     },
      //   ],
      //   name: "filterDateType",
      //   listType: 5,
      // }
    ]
  }

  manageAvailabilityModal = (e) => {
    const { name, value } = e.target;

    if (name === "sendEmailConfirmation") {
      this.setState({
        sendEmailConfirmation: value
      });
    } else if (name === 'currentAvailabilityDate') {

      this.setState({
        dateToChange: value
      });

    } else if (name === 'saveAvailability') {

      let date = new Date(this.state.dateToChange);
      date = date.toISOString();

      console.log({
        company_id: this.props.companyId,
        fleet_id: (this.props.companyFleets[0] || {}).id || 0,
        assembly_id: this.state.currentAssemble,
        availability_date: date,
        availability_facility: this.getFacilityId()
      });

      // this.props.editAvailability({
      //   company_id: this.props.companyId,
      //   fleet_id: (this.props.companyFleets[0] || {}).id || 0,
      //   assembly_id: this.state.currentAssemble,
      //   availability_date: date,
      //   availability_facility: this.state.selectedFacility
      // }, {
      //   onSuccess: () => {
      //     this.setState({
      //       availabilityData: null,
      //       currentAssemble: 0,
      //       currentResources: [],
      //       selectedFacility: 0,
      //       currentAvailabilityDate: null,
      //       currentAvailabilityInserted: null,
      //     }, () => { this.loadAssemblies(); });
      //   }
      // });

    } else {
      this.setState({
        availabilityData: null,
        dataToShowInMap: null
      });
    }

  }

  getAvailabilityDate = () => {
    if (this.state.availabilityData !== null) { 
      return this.state.availabilityData['Fecha de la descarga de la disponibilidad'];
    } else {
      return '';
    }
  }

  getFacilityId = () => {
    var facility = null;

    if (this.state.availabilityData !== null) {
      var facilityName = this.state.availabilityData['Instalación'];
      var facilityCode = this.state.availabilityData['Código de instalación'];
      var facilityAlias = this.state.availabilityData['Alias de instalación'];

      for (var i = 0; i < this.props.facilities.length; i++) {
        if (this.props.facilities[i].label.includes(facilityName) && this.props.facilities[i].label.includes(facilityCode) && this.props.facilities[i].label.includes(facilityAlias)) {
          facility = this.props.facilities[i].value;
        }
      }
    }

    return facility || '';
  }

  buildResources = () => {
    if (this.state.availabilityData !== null) {
      var operatorName = this.state.availabilityData['Operador*'];

      var vehicle = this.state.availabilityData['Vehículo*'];
      var vehiclePlates = this.state.availabilityData['Placas vehículo*'];

      var dolly = this.state.availabilityData['Dolly***'] || '';
      var dollyPlates = this.state.availabilityData['Placas dolly***'] || '';

      var r1 = this.state.availabilityData['Remolque 1**'] || '';
      var r1Plates = this.state.availabilityData['Placas remolque 1**'] || '';

      var r2 = this.state.availabilityData['Remolque 2***'] || '';
      var r2Plates = this.state.availabilityData['Placas remolque 2***'] || '';

      var dataToReturn = [{type: 3, longDescription: operatorName}, {type: 1, longDescription: `${vehicle}/${vehiclePlates}`}];

      if (dolly !== '' || dollyPlates !== '') {
        dataToReturn = dataToReturn.concat({
          type: 2,
          longDescription: `${dolly}/${dollyPlates}`
        });
      }

      if (r1 !== '' || r1Plates !== '') {
        dataToReturn = dataToReturn.concat({
          type: 2,
          longDescription: `${r1}/${r1Plates}`
        });
      }

      if (r2 !== '' || r2Plates !== '') {
        dataToReturn = dataToReturn.concat({
          type: 2,
          longDescription: `${r2}/${r2Plates}`
        });
      }

      return dataToReturn;
    } else {
      return [];
    }
  }

  isCargoOwner = () => { return `${this.state.companyData.type.id}` === '2'; }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {

    let columnsData = []

    if (this.isCargoOwner()) {
      columnsData = columnsDataCO;
    } else {
      columnsData = columnsDataTR;
    }

    return (
      <View className='availability-view'>
        <ViewTitle>
          Reporte de disponibilidad
        </ViewTitle>

        <div className='top-bar'>

          <div className='settings-container'>

            <div onClick={() => {
              this.setState({
                isShowSettingsModal: true
              });
            }}>
              <img alt="" src={'/images/settings_icon.png'} style={{ width: 30, height: 30}} data-tip data-for='tooltip-settting'/>
              <ReactTooltip
                id={'tooltip-settting'}
                getContent={() =>
                  'Configurar columnas de tabla'
                }
                delayHide={200}
                place={"left"}
                border={true}
                type={"light"}
              />
            </div>

          </div>

          <div className='download-container'>
          {
            this.state.downloadingFile ? 
              <Loader width="40px" height="40px" /> :
              <Button
                settings={{
                  type: "btn primary small",
                  text: "Descargar",
                  onClick: () => {
                    this.handleFilter();
                  },
                }}
              />
          }
          </div>

        </div>

        <TableSortable
          isLoading={this.props.isLoadingReport}
          itemsToHide={this.state.columnsHidden}
          didSelectRow={(data) => {
            this.setState({
              dataToShowInMap: data,
              availabilityData: null,
              dateToChange: data['Fecha de la descarga de la disponibilidad'] || ''
            }, () => {
              let idToRequest = `${data['id'] || ''}`;

              if (idToRequest !== '') {
                this.props.getLocation(
                  {
                    company_id: this.props.companyId, 
                    id: idToRequest
                  }, 
                  {
                    onSuccess: (value) => {
                      let coordinates = value?.location || [];

                      if (coordinates.length > 1) {

                        this.setState({
                          trailerLocation: {
                            lat: coordinates[0],
                            lng: coordinates[1]
                          }
                        });

                      }
                    }
                  }
                );
              }

            });
          }}
          columns={columnsData}
          items={this.props.items}
        />

        <AvailabilityReportMapModal
          isCargoOwner={this.isCargoOwner()}
          data={this.state.dataToShowInMap}
          onClose={() => {
            this.setState({
              dataToShowInMap: null,
              availabilityData: null,
              trailerLocation: null
            })
          }}
          openEdit={(value) => {
            this.setState({
              dataToShowInMap: null,
              availabilityData: value,
              trailerLocation: null,
              sendEmailConfirmation: true
            });
          }}
          trailerLocation={this.state.trailerLocation}
        />

        <AvailabilityColumnsSettingsModal
          open={this.state.isShowSettingsModal}
          itemsToHide={this.state.columnsHidden}
          updateItems={(value) => {
            this.setState({
              columnsHidden: value
            });
          }}
          columns={columnsData}
          onClose={() => {
            this.setState({
              isShowSettingsModal: false
            })
          }}
        />

        <AvailabilityModal
          title={"Editar disponibilidad"}
          show={this.state.availabilityData !== null}
          onChange={this.manageAvailabilityModal}
          data={{
            resources: this.buildResources(),
            sendEmailConfirmation: this.state.sendEmailConfirmation
            //inserted: this.state.currentAvailabilityInserted
          }}
          facilities={[
            { label: "Selecciona una opción", value: 0, type: 2 },
          ].concat(this.props.facilities || [])}
          date={this.state.dateToChange}
          facility={this.getFacilityId()}
        />

      </View>
    )
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {
  let companyId = state.globalCatalog.session.company.id;
  let isLoadingReport = state?.api["COMPANIES.AVAILABILITY.REPORT"]?.status?.isFetching || false;
  let items = state?.api["COMPANIES.AVAILABILITY.REPORT"]?.items || [];

  let facilities = ((getEntityItems(state, "COMPANIES.ORDERS.FACILITIES") || [])["facilities"] || [])
      .map(f => ({
        label: `${f.name} - ${f.code} - ${f.alias}`,
        value: f.id
      }))
  let companyFleets = getEntityItems(state, "COMPANIES.FLEETS");

  return {
    facilities,
    companyId,
    isLoadingReport,
    items,
    companyFleets,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
}

const mapDispatchToProps = (dispatch) => {
  
  return {
    loadReport: (params) => dispatch(readEntities("COMPANIES.AVAILABILITY.REPORT", params)),
    getLocation: (params, opts) => dispatch(readEntities("COMPANIES.AVAILABILITY.LOCATION", params, opts)),

    loadAssemblies: (params, opts) => dispatch(readCatalog("COMPANIES.FLEETS.ASSEMBLIES", params, opts)),
    loadFacilities: (params, opt) => dispatch(readEntities("COMPANIES.ORDERS.FACILITIES", params, opt)),
    listFleets: (params, opts) => dispatch(readEntities("COMPANIES.FLEETS", params, opts)),
    editAvailability: (params, opt) => dispatch(partialEntity("COMPANIES.TRAFFIC.AVAILABILITY", params, opt)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  }

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityReportView);

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;