import {
  getApiUrl,
  BACKEND,
  postApiUrl,
  deleteApiUrl,
  patchApiUrl,
  putApiUrl,
} from "../../shared/backend-api";
import store from "../store";

export const ACTIONS = {
  COMPANY: {
    LIST: {
      REQUEST: {
        TYPE: "ACTIONS_COMPANY_LIST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.COMPANY.LIST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ACTIONS_COMPANY_LIST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.COMPANY.LIST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ACTIONS_COMPANY_LIST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.COMPANY.LIST.ERROR.TYPE,
          error,
        }),
      },
    },
    GET: {
      REQUEST: {
        TYPE: "ACTIONS_COMPANY_GET_REQUEST",
        ACTION: () => ({
          type: ACTIONS.COMPANY.GET.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ACTIONS_COMPANY_GET_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.COMPANY.GET.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ACTIONS_COMPANY_GET_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.COMPANY.GET.ERROR.TYPE,
          error,
        }),
      },
    },

    INVITATION: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_INVITATION_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.INVITATION.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_INVITATION_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.INVITATION.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_INVITATION_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.INVITATION.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_INVITATION_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.INVITATION.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_INVITATION_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.INVITATION.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_INVITATION_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.INVITATION.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      GET: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_INVITATION_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.INVITATION.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_INVITATION_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.INVITATION.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_INVITATION_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.INVITATION.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      FACILITY: {
        LIST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.LIST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.LIST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.LIST.ERROR.TYPE,
              error,
            }),
          },
        },
        POST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_POST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.POST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_POST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.POST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_POST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.POST.ERROR.TYPE,
              error,
            }),
          },
        },
        DELETE: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_DELETE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_DELETE_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_INVITATION_FACILITY_DELETE_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.ERROR.TYPE,
              error,
            }),
          },
        },
      },
    },

    NETWORK: {
      INVITATIONS: {
        APPROVED: {
          LIST: {
            REQUEST: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_APPROVED_LIST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.REQUEST
                    .TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_APPROVED_LIST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.SUCCESS
                    .TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_APPROVED_LIST_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.ERROR.TYPE,
                error,
              }),
            },
          },
        },
        SENT: {
          LIST: {
            REQUEST: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_SENT_LIST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.REQUEST.TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_SENT_LIST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.SUCCESS.TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_SENT_LIST_ERROR",
              ACTION: (error) => ({
                type: ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.ERROR.TYPE,
                error,
              }),
            },
          },
        },
        RECEIVED: {
          LIST: {
            REQUEST: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_RECEIVED_LIST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.REQUEST
                    .TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_RECEIVED_LIST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.SUCCESS
                    .TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_RECEIVED_LIST_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.ERROR.TYPE,
                error,
              }),
            },
          },
        },
        DELETE: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_NETWORK_INVITATIONS_DELETE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.NETWORK.INVITATIONS.DELETE.REQUEST.TYPE,
            }),
          },
        },
      },
      COMPANIES: {
        LIST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_NETWORK_COMPANIES_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_NETWORK_COMPANIES_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_NETWORK_COMPANIES_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.ERROR.TYPE,
              error,
            }),
          },
        },
      },
      CONTACTS: {
        PATCH: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_NETWORK_CONTACTS_PATCH_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.NETWORK.CONTACTS.PATCH.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_NETWORK_CONTACTS_PATCH_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.NETWORK.CONTACTS.PATCH.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_NETWORK_CONTACTS_PATCH_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.NETWORK.CONTACTS.PATCH.ERROR.TYPE,
              error,
            }),
          },
        },
      },
    },

    INVITATION_TYPE: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_INVITATION_TYPE_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.INVITATION_TYPE.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_INVITATION_TYPE_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.INVITATION_TYPE.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_INVITATION_TYPE_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.INVITATION_TYPE.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    CATALOG: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_CATALOG_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.CATALOG.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_CATALOG_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.CATALOG.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_CATALOG_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.CATALOG.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      FACILITIES: {
        LIST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_CATALOG_FACILITIES_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_CATALOG_FACILITIES_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_CATALOG_FACILITIES_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.ERROR.TYPE,
              error,
            }),
          },
        },
        POST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_CATALOG_FACILITIES_POST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.CATALOG.FACILITIES.POST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_CATALOG_FACILITIES_POST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.CATALOG.FACILITIES.POST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_CATALOG_FACILITIES_POST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.CATALOG.FACILITIES.POST.ERROR.TYPE,
              error,
            }),
          },
        },
      },
      USERS: {
        LIST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_CATALOG_USERS_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.CATALOG.USERS.LIST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_CATALOG_USERS_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.CATALOG.USERS.LIST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_CATALOG_USERS_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.CATALOG.USERS.LIST.ERROR.TYPE,
              error,
            }),
          },
        },
      },
    },

    COMPANY_USERS: {
      // POST: {
      //   REQUEST: {
      //     TYPE: 'ACTIONS_COMPANY_COMPANY_USERS_POST_REQUEST',
      //     ACTION: () => ({
      //       type: ACTIONS.COMPANY.COMPANY_USERS.POST.REQUEST.TYPE,
      //     })
      //   },
      //   SUCCESS: {
      //     TYPE: 'ACTIONS_COMPANY_COMPANY_USERS_POST_SUCCESS',
      //     ACTION: response => ({
      //       type: ACTIONS.COMPANY.COMPANY_USERS.POST.SUCCESS.TYPE,
      //       response
      //     })
      //   },
      //   ERROR: {
      //     TYPE: 'ACTIONS_COMPANY_COMPANY_USERS_POST_ERROR',
      //     ACTION: error => ({
      //       type: ACTIONS.COMPANY.COMPANY_USERS.POST.ERROR.TYPE,
      //       error
      //     })
      //   },
      // },
      // GET: {
      //   REQUEST: {
      //     TYPE: 'ACTIONS_COMPANY_COMPANY_USERS_GET_REQUEST',
      //     ACTION: () => ({
      //       type: ACTIONS.COMPANY.COMPANY_USERS.GET.REQUEST.TYPE,
      //     })
      //   },
      //   SUCCESS: {
      //     TYPE: 'ACTIONS_COMPANY_COMPANY_USERS_GET_SUCCESS',
      //     ACTION: response => ({
      //       type: ACTIONS.COMPANY.COMPANY_USERS.GET.SUCCESS.TYPE,
      //       response
      //     })
      //   },
      //   ERROR: {
      //     TYPE: 'ACTIONS_COMPANY_COMPANY_USERS_GET_ERROR',
      //     ACTION: error => ({
      //       type: ACTIONS.COMPANY.COMPANY_USERS.GET.ERROR.TYPE,
      //       error
      //     })
      //   },
      // },
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_COMPANY_USERS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.COMPANY_USERS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_COMPANY_USERS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.COMPANY_USERS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_COMPANY_USERS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.COMPANY_USERS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    USERS: {
      FACILITY_USERS: {
        POST: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_USERS_FACILITY_USERS_POST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_USERS_FACILITY_USERS_POST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_USERS_FACILITY_USERS_POST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.ERROR.TYPE,
              error,
            }),
          },
        },
        DELETE: {
          REQUEST: {
            TYPE: "ACTIONS_COMPANY_USERS_FACILITY_USERS_DELETE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "ACTIONS_COMPANY_USERS_FACILITY_USERS_DELETE_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "ACTIONS_COMPANY_USERS_FACILITY_USERS_DELETE_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.ERROR.TYPE,
              error,
            }),
          },
        },
      },
      FACILITIES: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_USERS_FACILITIES_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.USERS.FACILITIES.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_USERS_FACILITIES_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.USERS.FACILITIES.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_USERS_FACILITIES_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.USERS.FACILITIES.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    ROLES: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_ROLES_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.ROLES.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_ROLES_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.ROLES.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_ROLES_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.ROLES.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    EXPEDITION_CLASSES: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_EXPEDITION_CLASSES_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_EXPEDITION_CLASSES_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_EXPEDITION_CLASSES_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      DETAIL: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_EXPEDITION_CLASSES_DETAIL_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_EXPEDITION_CLASSES_DETAIL_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_EXPEDITION_CLASSES_DETAIL_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    CONTRACTS: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_CONTRACTS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.CONTRACTS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_CONTRACTS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.CONTRACTS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_CONTRACTS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.CONTRACTS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_CONTRACTS_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.CONTRACTS.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_CONTRACTS_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.CONTRACTS.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_CONTRACTS_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.CONTRACTS.POST.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    ALERTS: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_COMPANY_ALERTS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.ALERTS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_ALERTS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.COMPANY.ALERTS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_COMPANY_ALERTS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.COMPANY.ALERTS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
  },
};

const initialState = {
  companies: {
    isFetching: false,
    data: [],
    error: {},
  },
  company: {
    isFetching: false,
    data: {},
    error: {},
  },
  userTypes: {
    admin: 1,
    warehouser: 2,
  },
  invitationTypes: {
    isFetching: false,
    data: {},
    error: {},
  },
  invitations: {
    isFetching: false,
    data: {},
    error: {},
  },
  networkCompanies: {
    isFetching: false,
    data: [],
    error: {},
  },
  networkSentInvitations: {
    isFetching: false,
    data: [],
    error: {},
  },
  networkReceivedInvitations: {
    isFetching: false,
    data: [],
    error: {},
  },
  networkApprovedInvitations: {
    isFetching: false,
    data: [],
    error: {},
  },
  invitationFacilities: {
    isFetching: false,
    data: {},
    error: {},
  },
  users: {
    isFetching: false,
    data: {},
    error: {},
  },
  userFacilities: {
    isFetching: false,
    data: {},
    error: {},
  },
  companyUsers: {
    isFetching: false,
    data: {},
    error: {},
  },
  facilityUsers: {
    isFetching: false,
    data: {},
    error: {},
  },
  catalogUsers: {
    isFetching: false,
    data: {},
    error: {},
  },
  companyRoles: {
    isFetching: false,
    data: [],
    error: {},
  },
  companyExpeditionClasses: {
    isFetching: false,
    data: [],
    error: {},
  },
  companyExpeditionClassesDetail: {
    isFetching: false,
    data: [],
    error: {},
  },
  companyContracts: {
    isFetching: false,
    data: {},
    error: {},
  },
  companyAlerts: {
    isFetching: false,
    data: [],
    error: {},
  },
  companyUsersFacilities: {
    isFetching: false,
    data: [],
    error: {},
  },
};

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.COMPANY.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companies: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companies: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companies: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        company: {
          isFetching: true,
          data: {},
        },
      });
    case ACTIONS.COMPANY.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        company: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        company: {
          isFetching: false,
          data: {},
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.INVITATION.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.COMPANY.INVITATION.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.COMPANY.INVITATION.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.INVITATION_TYPE.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION_TYPE.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitationTypes: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION_TYPE.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        invitationTypes: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        networkCompanies: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        networkCompanies: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        networkCompanies: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        networkReceivedInvitations: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        networkReceivedInvitations: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        networkReceivedInvitations: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        networkSentInvitations: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        networkSentInvitations: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        networkSentInvitations: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        networkApprovedInvitations: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        networkApprovedInvitations: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        networkApprovedInvitations: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        userFacilities: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        userFacilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        userFacilities: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.CATALOG.FACILITIES.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        userFacilities: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.CATALOG.FACILITIES.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        userFacilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.CATALOG.FACILITIES.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        userFacilities: {
          isFetching: false,
          error: action.error,
        },
      });

    // case ACTIONS.COMPANY.COMPANY_USERS.POST.REQUEST.TYPE:
    //   return (Object.assign({}, state, {
    //     companyUsers: {
    //       isFetching: true
    //     }
    //   }))
    // case ACTIONS.COMPANY.COMPANY_USERS.POST.SUCCESS.TYPE:
    //   return (Object.assign({}, state, {
    //     companyUsers: {
    //       isFetching: false,
    //       data: action.response
    //     }
    //   }))
    // case ACTIONS.COMPANY.COMPANY_USERS.POST.ERROR.TYPE:
    //   return (Object.assign({}, state, {
    //     companyUsers: {
    //       isFetching: false,
    //       error: action.error
    //     }
    //   }))

    // case ACTIONS.COMPANY.COMPANY_USERS.GET.REQUEST.TYPE:
    //   return (Object.assign({}, state, {
    //     companyUsers: {
    //       isFetching: true
    //     }
    //   }))
    // case ACTIONS.COMPANY.COMPANY_USERS.GET.SUCCESS.TYPE:
    //   return (Object.assign({}, state, {
    //     companyUsers: {
    //       isFetching: false,
    //       data: action.response
    //     }
    //   }))
    // case ACTIONS.COMPANY.COMPANY_USERS.GET.ERROR.TYPE:
    //   return (Object.assign({}, state, {
    //     companyUsers: {
    //       isFetching: false,
    //       error: action.error
    //     }
    //   }))

    case ACTIONS.COMPANY.CATALOG.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        users: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.CATALOG.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        users: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.CATALOG.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        users: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.COMPANY_USERS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyUsers: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.COMPANY_USERS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyUsers: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.COMPANY_USERS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companyUsers: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.INVITATION.FACILITY.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION.FACILITY.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION.FACILITY.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.INVITATION.FACILITY.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION.FACILITY.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION.FACILITY.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        invitationFacilities: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.CATALOG.USERS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        catalogUsers: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.CATALOG.USERS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        catalogUsers: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.CATALOG.USERS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        catalogUsers: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.ROLES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyRoles: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.COMPANY.ROLES.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyRoles: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.ROLES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companyRoles: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyExpeditionClasses: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyExpeditionClasses: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companyExpeditionClasses: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      }); //companyExpeditionClassesDetail

    case ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyExpeditionClassesDetail: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyExpeditionClassesDetail: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.ERROR.TYPE:
      return Object.assign({}, state, {
        companyExpeditionClassesDetail: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.CONTRACTS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyContracts: {
          isFetching: true,
          data: {},
        },
      });
    case ACTIONS.COMPANY.CONTRACTS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyContracts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.CONTRACTS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companyContracts: {
          isFetching: false,
          data: {},
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.ALERTS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.ALERTS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.ALERTS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companyAlerts: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.COMPANY.USERS.FACILITIES.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyUsersFacilities: {
          isFetching: true,
        },
      });
    case ACTIONS.COMPANY.USERS.FACILITIES.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyUsersFacilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.COMPANY.USERS.FACILITIES.ERROR.TYPE:
      return Object.assign({}, state, {
        companyUsersFacilities: {
          isFetching: false,
          error: action.error,
        },
      });
    default:
      return state;
  }
}

export const listCompanies = () => {
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.LIST, {})
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.COMPANY.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.COMPANY.LIST.SUCCESS.ACTION(response))
      );
  };
};
export const loadCompanyByOrbiId = (id_orbi) => {
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.PUBLIC.GET_COMPANY, { id_orbi })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.COMPANY.GET.ERROR.ACTION(error))
      )
      .then((data) => dispatch(ACTIONS.COMPANY.GET.SUCCESS.ACTION(data)));
  };
};
export const getSessionCompanyData = () => {
  return function(dispatch) {
    // let storeItems = store.getState().companyReducer.company;
    // if (!storeItems.isFetching && Object.keys(storeItems.data).length) {
    //   return new Promise(resolve => resolve(storeItems))
    // } else {
    let company_id = store.getState().globalCatalog.session.company.id;
    dispatch(ACTIONS.COMPANY.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.GET, { company_id })
      .then((response) => response.json())
      .then((data) => dispatch(ACTIONS.COMPANY.GET.SUCCESS.ACTION(data)))
      .catch((error) => dispatch(ACTIONS.COMPANY.GET.ERROR.ACTION(error)));
    // }
  };
};

export const createInvitation = (
  invitation_type,
  user_type,
  user,
  email,
  invitations_facilities = {},
  invitations_permissions = {},
  invitations_fleets = {},
  from_facility = false
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let params = {
    company_id,
    type: invitation_type,
    email,
    user,
    user_type,
    invitations_facilities,
    invitations_permissions,
    invitations_fleets,
  };
  if (from_facility) {
    params.from = "facilities";
  }
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.INVITATION.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY.INVITATION.POST, params)
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.INVITATION.POST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.INVITATION.POST.ERROR.ACTION(error))
      );
  };
};
export const loadInvitationTypes = () => {
  return function(dispatch) {
    let storeItems = store.getState().companyReducer.invitationTypes;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.COMPANY.INVITATION_TYPE.LIST.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY.INVITATION.TYPES.LIST, {})
        .then((response) => response.json())
        .then((data) =>
          dispatch(ACTIONS.COMPANY.INVITATION_TYPE.LIST.SUCCESS.ACTION(data))
        )
        .catch((error) =>
          dispatch(ACTIONS.COMPANY.INVITATION_TYPE.LIST.ERROR.ACTION(error))
        );
    }
  };
};
export const loadUserFacilities = (user_id, facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.USERS.FACILITY.LIST, {
      company_id,
      user_id,
      facility_id,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.CATALOG.FACILITIES.LIST.ERROR.ACTION(error))
      );
  };
};
export const loadCompanyUsers = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.COMPANY_USERS.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.COMPANY_USERS.LIST, { company_id })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.COMPANY_USERS.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.COMPANY_USERS.LIST.ERROR.ACTION(error))
      );
  };
};
export const deleteCompanyUsers = (user_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return deleteApiUrl(BACKEND.COMPANY.COMPANY_USERS.DELETE, {
      company_id,
      user_id,
    })
      .then((response) => response.json())
      .then((response) => true)
      .catch((error) => false);
  };
};
export const loadCatalogUsers = (
  type = "",
  cards = "",
  facilities = "",
  role = "",
  account_status = ""
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.CATALOG.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.USERS.LIST, {
      company_id,
      type,
      cards,
      facilities,
      role,
      account_status,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.CATALOG.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.CATALOG.LIST.ERROR.ACTION(error))
      );
  };
};
export const addUserFacility = (user_id, facilityId) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.USERS.FACILITIES.POST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.USERS.FACILITY.POST, {
      company_id,
      user_id,
      users: user_id,
      facilities: facilityId,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.USERS.FACILITIES.POST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.USERS.FACILITIES.POST.ERROR.ACTION(error))
      );
  };
};

export const listCompanyInvitations = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.INVITATION.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.INVITATION.LIST, { company_id })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.INVITATION.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.INVITATION.LIST.ERROR.ACTION(error))
      );
  };
};

export const listNetworkCompanies = (searchStr = "") => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.NETWORK.SEARCH, { company_id, searchStr })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.NETWORK.COMPANIES.LIST.ERROR.ACTION(error))
      );
  };
};

export const listNetworkReceivedInvitations = (
  limit = 10,
  offset = 1,
  searchStr = ""
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(
      ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.REQUEST.ACTION()
    );
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.LIST.RECEIVED, {
      company_id,
      limit,
      offset,
      searchStr,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(
          ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.SUCCESS.ACTION(data)
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.COMPANY.NETWORK.INVITATIONS.RECEIVED.LIST.ERROR.ACTION(error)
        )
      );
  };
};

export const listNetworkSentInvitations = (
  limit = 10,
  offset = 1,
  searchStr = ""
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.LIST.SENT, {
      company_id,
      limit,
      offset,
      searchStr,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(
          ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.SUCCESS.ACTION(data)
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.COMPANY.NETWORK.INVITATIONS.SENT.LIST.ERROR.ACTION(error)
        )
      );
  };
};

export const listNetworkApprovedInvitations = (
  limit,
  offset,
  searchStr = ""
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(
      ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.REQUEST.ACTION()
    );
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.LIST.APPROVED, {
      company_id,
      limit,
      offset,
      searchStr,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(
          ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.SUCCESS.ACTION(data)
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.COMPANY.NETWORK.INVITATIONS.APPROVED.LIST.ERROR.ACTION(error)
        )
      );
  };
};

export const rejectNetworkRequest = (request_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.REJECT, {
      company_id,
      request_id,
    })
      .then((response) => response.json())
      .then(() => true)
      .catch(() => false);
  };
};

export const acceptNetworkRequest = (request_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.ACCEPT, {
      company_id,
      request_id,
    })
      .then((response) => response.json())
      .then(() => true)
      .catch(() => false);
  };
};

export const resendNetworkRequest = (request_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.RESEND, {
      company_id,
      request_id,
    })
      .then((response) => response.json())
      .then(() => true)
      .catch(() => false);
  };
};

export const deleteNetworkRequest = (request_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return deleteApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.DELETE, {
      company_id,
      request_id,
    })
      .then((response) => true)
      .catch(() => false);
  };
};

export const sendNetworkRequest = (to_company_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return postApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.POST, {
      company_id,
      to_companies: to_company_id,
    })
      .then((response) => response.json())
      .then(() => true)
      .catch(() => false);
  };
};

export const updateNetworkContact = (params) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  params.company_id = company_id;
  return function() {
    return patchApiUrl(BACKEND.COMPANY.NETWORK.CONTACTS.PATCH, params)
      .then((response) => response.json())
      //.then(() => true)
      .catch((error) => error);
  };
};

export const inviteNewCompanyByEmail = (email) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let user_id = store.getState().globalCatalog.session.user.id;
  return function() {
    return getApiUrl(BACKEND.COMPANY.NETWORK.INVITATIONS.INVITE_NON_USER, {
      company_id,
      user_id,
      email,
    })
      .then((response) => response.json())
      .then(() => true)
      .catch(() => false);
  };
};

export const listInvitationFacilities = (invitation_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.INVITATION.FACILITY.LIST, {
      company_id,
      invitation_id,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.LIST.ERROR.ACTION(error))
      );
  };
};

export const addFacilityToInvitation = (invitation_id, facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY.INVITATION.FACILITY.POST, {
      company_id,
      invitation_id,
      invitations: invitation_id,
      facilities: facility_id,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.POST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.POST.ERROR.ACTION(error))
      );
  };
};

export const addFacilityToUser = (user_id, facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY.USERS.FACILITY.POST, {
      company_id,
      user_id,
      facilities: facility_id,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.USERS.FACILITY_USERS.POST.ERROR.ACTION(error))
      );
  };
};
export const acceptInvitation = (invitation_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return getApiUrl(BACKEND.COMPANY.INVITATIONS.ACCEPT_USER_INVITATION, {
      company_id,
      invitation_id,
    })
      .then((response) => response.json())
      .then((response) => true)
      .catch((error) => error.then((e) => false));
  };
};

export const cancelInvitation = (invitation_id, from_facility = false) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return getApiUrl(
      BACKEND.COMPANY.INVITATIONS[
        from_facility
          ? "CANCEL_USER_INVITATION_FROM_FACILITY"
          : "CANCEL_USER_INVITATION"
      ],
      { company_id, invitation_id }
    )
      .then((response) => response.json())
      .then((response) => true)
      .catch((error) => error.then((e) => false));
  };
};

export const resendInvitation = (invitation_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return getApiUrl(BACKEND.COMPANY.INVITATIONS.RESEND_USER_INVITATION, {
      company_id,
      invitation_id,
    })
      .then((response) => response.json())
      .then((response) => true)
      .catch((error) => error.then((e) => false));
  };
};

export const removeFacilityFromUser = (user_id, user_facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.COMPANY.USERS.FACILITY.DELETE, {
      company_id,
      user_id,
      id: user_facility_id,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(
          ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.SUCCESS.ACTION(data)
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.COMPANY.USERS.FACILITY_USERS.DELETE.ERROR.ACTION(error)
        )
      );
  };
};

export const removeFacilityFromInvitation = (
  invitation_id,
  facility_invitation_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.COMPANY.INVITATION.FACILITY.DELETE, {
      company_id,
      invitation_id,
      id: facility_invitation_id,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(
          ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.SUCCESS.ACTION(data)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.INVITATION.FACILITY.DELETE.ERROR.ACTION(error))
      );
  };
};

export const listCatalogUsers = (facility_id, limit, offset) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.CATALOG.USERS.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.CATALOG.USERS, {
      company_id,
      facility_id,
      limit,
      offset,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.CATALOG.USERS.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.CATALOG.USERS.LIST.ERROR.ACTION(error))
      );
  };
};
//ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.REQUEST
export const listExpeditionClasses = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.LIST, { company_id })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.EXPEDITION_CLASSES.LIST.ERROR.ACTION(error))
      );
  };
};

export const listExpeditionClassesDetail = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.DETAIL, { company_id })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.EXPEDITION_CLASSES.DETAIL.ERROR.ACTION(error))
      );
  };
};

export const createExpeditionClass = (expeditionParams) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  expeditionParams.company_id = company_id;
  return function(dispatch) {
    return postApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.POST, expeditionParams)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error);
  };
};

export const updateExpeditionClass = (expeditionParams) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  expeditionParams.company_id = company_id;
  return function(dispatch) {
    return patchApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.PATCH, expeditionParams)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const deleteExpeditionClass = (class_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return (
      deleteApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.DELETE, {
        company_id,
        class_id,
      })
        .then((response) => response.json())
        // .then(data => true)
        .catch(() => false)
    );
  };
};

export const createExpeditionClassTags = (class_id, tag_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return postApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.TAGS.POST, {
      company_id,
      class_id,
      tags: tag_id,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const deleteExpeditionClassTags = (class_id, class_tag_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return deleteApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.TAGS.DELETE, {
      company_id,
      class_id,
      class_tag_id,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const listExpeditionClassTags = (class_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return getApiUrl(BACKEND.COMPANY.EXPEDITION_CLASS.TAGS.POST, {
      company_id,
      class_id,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const listCompanyTags = (type) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return getApiUrl(BACKEND.COMPANY.TAGS.LIST, {
      company_id,
      type,
    })
      .then((response) => response.json())
      // .then((data) => data)
      .catch((error) => false);
  };
};

// export const loadSanctions = () => {
//   return function (dispatch) {
//     let storeItems = store.getState().fleetReducer.sanctionsCatalog;
//     if (!storeItems.isFetching && storeItems.data.length) {
//       return new Promise((resolve) => resolve(storeItems));
//     } else {
//       dispatch(ACTIONS.FLEETS.SANCTIONS.GET.REQUEST.ACTION());
//       return getApiUrl(BACKEND.COMPANY_CATALOG.GET_SANCTIONS, {})
//         .then(
//           (response) => response.json(),
//           (error) =>
//             dispatch(ACTIONS.FLEETS.SANCTIONS.GET.ERROR.ACTION(error))
//         )
//         .then((response) =>
//           dispatch(ACTIONS.FLEETS.SANCTIONS.GET.SUCCESS.ACTION(response))
//         );
//     }
//   };
// };

export const createCompanyRol = (rol) => {
  return function() {
    return postApiUrl(BACKEND.COMPANY.ROLES.CREATE, rol)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

// export const listCompanyRoles = () => {
//   let company_id = store.getState().globalCatalog.session.company.id
//   return function (dispatch) {
//     dispatch(ACTIONS.COMPANY.ROLES.LIST.REQUEST.ACTION())
//     return getApiUrl(BACKEND.COMPANY.ROLES.LIST, { company_id })
//       .then(response => response.json())
//       .then(data => {
//         getPermissionsData(data, company_id)
//           .then(permissions => {
//             let newData = data.map(rol => ({...rol, permissions: (permissions.find(p => p[0].role === rol.id) || [])}))
//             dispatch(ACTIONS.COMPANY.ROLES.LIST.SUCCESS.ACTION(newData))
//           })
//           .catch(error => dispatch(ACTIONS.COMPANY.ROLES.LIST.ERROR.ACTION(error)))
//       })
//       .catch(error => dispatch(ACTIONS.COMPANY.ROLES.LIST.ERROR.ACTION(error)))
//   }
// }

// const anAsyncFunction = async (item, company_id) => {
//   return getApiUrl(BACKEND.COMPANY.ROLES.PERMISSIONS.LIST, { company_id, rol_id: item.id }).then(response => response.json())
// }

// const getPermissionsData = async (roles, company) => {
//   return Promise.all(roles.map(rol => anAsyncFunction(rol, company)))
// }

export const listCompanyRoles = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.ROLES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.ROLES.LIST, { company_id })
      .then((response) => response.json())
      .then((data) => dispatch(ACTIONS.COMPANY.ROLES.LIST.SUCCESS.ACTION(data)))
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.ROLES.LIST.ERROR.ACTION(error))
      );
  };
};

export const listCompanyRolesPermissions = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.ROLES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.ROLES.LIST_WITH_PERMISSIONS, {
      company_id,
    })
      .then((response) => response.json())
      .then((data) => dispatch(ACTIONS.COMPANY.ROLES.LIST.SUCCESS.ACTION(data)))
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.ROLES.LIST.ERROR.ACTION(error))
      );
  };
};

export const listCompanyRolPermissions = (rol_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return getApiUrl(BACKEND.COMPANY.ROLES.PERMISSIONS.LIST, {
      company_id,
      rol_id,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const editCompanyRoles = (newParams) => {
  return function() {
    return patchApiUrl(BACKEND.COMPANY.ROLES.PATCH, newParams)
      .then((response) => response.json())
      .then(() => true)
      .catch((error) => false);
  };
};

export const editCompanyRolesPermissions = (newParams) => {
  return function() {
    return patchApiUrl(BACKEND.COMPANY.ROLES.PERMISSIONS.PATCH, newParams)
      .then((response) => response.json())
      .then(() => true)
      .catch((error) => false);
  };
};

export const deleteCompanyRol = (rol_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return deleteApiUrl(BACKEND.COMPANY.ROLES.DELETE, { company_id, rol_id })
      .then((response) => response.json())
      .then(() => true)
      .catch((error) => false);
  };
};

export const changeCompanyUserRole = (company_user_id, role) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return patchApiUrl(BACKEND.COMPANY.USERS.ROLES.PATCH, {
      company_id,
      company_user_id,
      role,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const removeCompanyUserRole = (user_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return getApiUrl(BACKEND.COMPANY.USERS.ROLES.DELETE, {
      company_id,
      user_id,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const listCompanyContracts = (
  limit = "",
  offset = "",
  facilities = "",
  transportClass = "",
  network = ""
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.CONTRACTS.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.CONTRACTS.LIST, {
      company_id,
      limit,
      offset,
      facilities,
      transportClass,
      network,
    })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.CONTRACTS.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.CONTRACTS.LIST.ERROR.ACTION(error))
      );
  };
};

export const createCompanyContract = (
  facilities,
  network,
  transport_class,
  tag,
  quantity,
  date_from,
  date_to
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return postApiUrl(BACKEND.COMPANY.CONTRACTS.POST, {
      company_id,
      facilities,
      network,
      transport_class,
      tag,
      quantity,
      date_from,
      date_to,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const deleteCompanyContract = (contract_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return deleteApiUrl(BACKEND.COMPANY.CONTRACTS.DELETE, {
      company_id,
      contract_id,
    })
      .then((response) => response.json())
      .then((data) => true)
      .catch((error) => false);
  };
};

export const createCompanyContractClass = (
  contract_id,
  transport_class,
  date_from,
  date_to
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return postApiUrl(BACKEND.COMPANY.CONTRACTS.CLASSES.POST, {
      company_id,
      contract_id,
      transport_class,
      date_from,
      date_to,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const setCompanyContractBase = (contract_id, base) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return putApiUrl(BACKEND.COMPANY.CONTRACTS.BASE.PUT, {
      company_id,
      contract_id,
      base,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const createCompanyContractAddendum = (
  contract,
  quantity,
  date_from,
  date_to
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return postApiUrl(BACKEND.COMPANY.CONTRACTS.ADDENDUM.POST, {
      company_id,
      contract,
      quantity,
      date_from,
      date_to,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => false);
  };
};

export const listCompanyAlerts = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.ALERTS.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.ALERTS.LIST, { company_id })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.ALERTS.LIST.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.ALERTS.LIST.ERROR.ACTION(error))
      );
  };
};

export const listCompanyUsersFacilities = (user_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.USERS.FACILITIES.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.USERS.FACILITIES, { company_id, user_id })
      .then((response) => response.json())
      .then((data) =>
        dispatch(ACTIONS.COMPANY.USERS.FACILITIES.SUCCESS.ACTION(data))
      )
      .catch((error) =>
        dispatch(ACTIONS.COMPANY.USERS.FACILITIES.ERROR.ACTION(error))
      );
  };
};
