import React from "react";
import {
  Container,
  MainHeader,
  MainContainer,
  LeftColumns,
  RightColumns,
} from "./style";
import Button from "../../shared/controls/button";
import PaymentsPurchase from "./dashboard/purchase";
import PaymentsAccount from "./dashboard/account";
import PaymentsHistory from "./dashboard/history";
import { connect } from "react-redux";
import { getEntityItems, readEntities } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { SERVER } from "../../redux/api/api-urls";
import Axios from "axios";

const FIRST_YEAR = 2021;
const TODAY = new Date();

class PaymentsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credits: 10560,
      selectedYear: FIRST_YEAR
    };
  }

  componentDidMount() {
    this.props.loadWalletInfo({
      company_id: this.props.companyId
    });
    this.props.loadPlansInfo({
      company_id: this.props.companyId
    });
    this.props.loadSubscriptionCatalog();
  }

  formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
      formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = (year, month, type) => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/subscriptions/?view=statement&month=${month}&year=${year}&action=download_${type}`;
  };

  getStatement = (year, month, type) => {
    Axios({
      url: this.getUrl(year, month, type),
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Edo_Cuenta_".concat(year, "_", month, ".", type === "xls" ? "xlsx" : type));
      // link.setAttribute("download", null);
      document.body.appendChild(link);
      link.click();
    });
  }

  onChange = (e) => {
    // debugger;
    this.setState({[e.target.name]: e.target.value})
  }

  getData = () => {
    let bills = [
      {
        month: "Enero",
        bill: () => {this.getStatement(this.state.selectedYear, '01', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '01', 'xls')},
      },
      {
        month: "Febrero",
        bill: () => {this.getStatement(this.state.selectedYear, '02', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '02', 'xls')},
      },
      {
        month: "Marzo",
        bill: () => {this.getStatement(this.state.selectedYear, '03', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '03', 'xls')},
      },
      {
        month: "Abril",
        bill: () => {this.getStatement(this.state.selectedYear, '04', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '04', 'xls')},
      },
      {
        month: "Mayo",
        bill: () => {this.getStatement(this.state.selectedYear, '05', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '05', 'xls')},
      },
      {
        month: "Junio",
        bill: () => {this.getStatement(this.state.selectedYear, '06', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '06', 'xls')},
      },
      {
        month: "Julio",
        bill: () => {this.getStatement(this.state.selectedYear, '07', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '07', 'xls')},
      },
      {
        month: "Agosto",
        bill: () => {this.getStatement(this.state.selectedYear, '08', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '08', 'xls')},
      },
      {
        month: "Septiembre",
        bill: () => {this.getStatement(this.state.selectedYear, '09', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '09', 'xls')},
      },
      {
        month: "Octubre",
        bill: () => {this.getStatement(this.state.selectedYear, '10', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '10', 'xls')},
      },
      {
        month: "Noviembre",
        bill: () => {this.getStatement(this.state.selectedYear, '11', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '11', 'xls')},
      },
      {
        month: "Diciembre",
        bill: () => {this.getStatement(this.state.selectedYear, '12', 'pdf')},
        preview: (e) => {e.stopPropagation(); this.getStatement(this.state.selectedYear, '12', 'xls')},
      },
    ];
    // debugger;
    if(this.state.selectedYear === TODAY.getFullYear()){
      bills = bills.slice(0, (TODAY.getMonth() + 1));
    }

    let years = [];
    let year = FIRST_YEAR;
    while(year <= TODAY.getFullYear()){
      years.push(year++);
    }
    return {
      purchase: {
        // credits: this.formatNumber(this.state.credits),
        credits: (this.props.walletInfo[0] || {balance: '-'}).balance
      },
      account: {
        years,
        selectedYear: this.state.selectedYear,
        onChange: this.onChange,
        bills,
      },
      history: {
        movements: this.formatNumber(0),
        total: this.formatNumber(0),
        details: [
          // {
          //   date: "23 Jul",
          //   title: "Titulo de la transacción",
          //   description: "Breve descripción de la transacción",
          //   qty: this.formatNumber(200),
          // },
          // {
          //   date: "22 Jul",
          //   title: "Titulo de la transacción",
          //   description: "Breve descripción de la transacción",
          //   qty: this.formatNumber(-10),
          // },
        ],
      },
    };
  };

  getSubscriptionPLan = () => {
    let plan = this.props.subscriptionCatalog.filter( item => {
      return (this.props.walletInfo && this.props.walletInfo.length > 0 && this.props.walletInfo[0].subscription === item.id)
    });
    return plan && plan.length > 0 ? plan[0].description : '';
  }

  render() {
    return (
      <Container>
        <MainHeader>
          <div className="left">
            <div className="title">
              <div>Suscripcion :</div>
              <span>{ this.getSubscriptionPLan() }</span> 
            </div>
          </div>
          <div className="right">
          
          </div>
        </MainHeader>
        <MainContainer>
          <div className="left">
            <LeftColumns>
              <div className="panel ">
                <PaymentsPurchase data={this.getData().purchase} />
              </div>
              <div className="panel">
                <PaymentsAccount data={this.getData().account} />
              </div>
            </LeftColumns>
          </div>
          <div className="right">
            <RightColumns>
              <div className="panel title-panel">
                <div className="info-title">
                  <div>{"PUBLICA / PPV"}</div>
                  <span>activa desde {"abril 2020"}</span>
                  <div>SUSCRIPCIÓN ACTUAL</div>
                  <Button
                    text="Ver suscripción"
                    type="primary"
                    onClick={() => {}}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="panel">
                <PaymentsHistory data={this.getData().history} />
              </div>
            </RightColumns>
          </div>
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET");
  const subscriptionCatalog = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION") || [];
  return {
    companyId,
    walletInfo,
    subscriptionCatalog
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    loadWalletInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt)),
    loadPlansInfo: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.PLANS", params, opt)),
    loadSubscriptionCatalog: (params, opt) =>
      dispatch(readEntities("COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION", params, opt)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsDashboard);

// export default PaymentsDashboard;
