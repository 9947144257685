import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 100%) no-repeat;
  background-size: auto 470px;
  min-height: 90%;
`;

export const MainHeader = styled.div`
  display: flex;
  & .left {
    width: 35%;
    color: white;
    font-size: 30px;
    display: flex;
    font-weight: bold;
    & .title {
      height: 60px;
      margin-left: 30px;
      align-items: center;
      display: flex;

      & span {
        margin-left: 10px;
        font-size: 24px;
      }
    }
  }
  & .right {
    width: 65%;
  }
  & .full {
    width: 100%;
    color: white;
    font-size: 30px;
    display: flex;
    font-weight: bold;
    & .title {
      height: 60px;
      margin-left: 30px;
      align-items: center;
      display: flex;

      & span {
        margin-left: 10px;
        font-size: 24px;
      }
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  margin-top: 40px;
  & .left {
    width: 35%;
    display: flex;
    justify-content: flex-end;
  }

  & .right {
    width: 65%;
    display: flex;
    justify-content: flex-end;
  }

  & .panel {
    background-color: #2f3b52;
    border-radius: 15px;
    min-height: 115px;
    color: white;
  }
`;

export const LeftColumns = styled.div`
  width: 90%;
`;

export const RightColumns = styled.div`
  width: 90%;
  margin-right: 60px;

  & .title-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    & .info-title {
      align-items: center;
      // margin-right: 60px;
      display: flex;
      width: 90%;
      height: 60px;
      & > div,
      > span,
      .btn {
        flex: auto;
        font-size: 19px;
      }

      & .btn {
        font-size: 17px;
      }

      & > span {
        font-size: 15px;
        opacity: 50%;
      }
    }
  }
`;

export const BuyContainer = styled.div`
  width: 70%;
  margin: 20px auto 0 auto;

  & > *:last-child {
    border-bottom: 1px solid #8a9cbe;
  }
`;
