import React from 'react';
import { getUrlsEnv } from '../../../redux/api/api-urls';
import Button from '../../../shared/controls/button';
import Chip from '../../../shared/controls/chip';
import FileSelector, { acceptTypes } from '../../../shared/controls/file-selector';
import RadioInput from '../../../shared/controls/radio-input';
import AttachIcon from '../../../shared/icons/orders/AttachIcon';
import FavMarker from '../../../shared/icons/orders/FavMarker';
import SendIcon from '../../../shared/icons/orders/SendIcon';
import SVGIcon, { icons } from '../../../shared/svg-icon';

class CommentInput extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  getDescription = (f) => {
    if (f.size) {
      let size = f.size;
      let magnitude = ["B", "KB", "MB", "GB"];
      let magCount = 0;
      while (size > 1024) {
        size /= 1024;
        magCount++;
      }
      return (f.name.length > 10
        ? f.name.substring(0, 6).concat("...")
        : f.name
      ).concat(" (", size.toFixed(2), magnitude[magCount], ")");
    } else {
      return String(f.name).length > 10
        ? String(f.name).substring(0, 10) + "... "
        : f.name;
    }
  };

  render() {
    return (
      <div className="comment comment-input">
        <div className="fav-comments" style={{display: this.props.showFavComments ? "" : "none"}}>
          <div className="panel-title">
            Mensajes favoritos
          </div>
          <div className="message-container">
            {
              this.props.favComments.map(fC => (
                <div className="option">
                  <RadioInput 
                    title={fC.comment}
                    onClick={() => this.props.handleChange({target: {name: "favMsg", value: fC.id}})}
                      // this.setState({
                      //   favMsg: fC.id,
                      // })}
                    selected={this.props.favMsg}
                    value={fC.id}
                    name="selectedFavComment"
                  />
                  <img 
                    alt="" 
                    src="/images/bin.png" 
                    onClick={() => this.props.deleteFavoriteComment(fC.id)}
                  />
                  {/* <img alt="" src="/images/edit-transparent.svg"/> */}
                </div>
              ))  
            }
          </div>
          <div className="new-container">
            <RadioInput 
              title={""}
              onClick={() => this.props.handleChange({target: {name: "favMsg", value: -1}})}
                // this.setState({
                //   favMsg: -1,
                // })}
              selected={this.props.favMsg}
              value={-1}
              name="selectedFavComment"
            />
            <textarea 
                name="newFav"
                value={this.props.newFav}
                onChange={this.props.handleChange}
                style={{resize: "none"}}
                placeholder="Nuevo mensaje favorito"
              />
          </div>
          <div className="actions">
            <Button 
              text={'Aplicar'}
              type={'primary'}
              onClick={this.props.sendFav}
            />
          </div>
        </div>
        <div className="avatar-container">
          <img
            className={this.props.userData.profile_image ? "user-avatar" : ""}
            src={this.props.userData.profile_image
              ? getUrlsEnv().files.concat(this.props.userData.profile_image)
              : "/images/menu/user-no-circle.svg"
            }
            alt=""
            width={this.props.userData.profile_image ? "35px" : "29px"}
          />
        </div>
        <textarea
          name="currentComment"
          value={this.props.currentComment}
          onChange={this.props.handleChange}
          style={{resize: "none"}}
          placeholder="Dejar un comentario"
        />
        {
          this.props.commentFiles.map((cF, i) => {
            return <Chip
              key={i}
              id={i}
              active={true}
              className="white small"
              name="files"
              description={this.getDescription(cF)}
              onClick={() => {}}
              prefix={{
                element: (
                  <SVGIcon
                    name={icons.imageFile}
                    fill="#657795"
                    viewBox="0 -1 16 16"
                  />
                ),
              }}
              suffix={{
                element: (
                  <div onClick={() => this.props.handleRemoveDocument()}>
                    {" "}
                    <SVGIcon
                      name={icons.cross}
                      fill="#A7B6D6"
                      viewBox="0 3 16 16"
                    />
                  </div>
                ),
              }}
            />
          })
        }
        <FileSelector
          name={"files"}
          acceptTypes={[acceptTypes.imagesAndPdf]}
          onChange={this.props.handleAddDocument}
          // allowMultiple={true}
        >
          <AttachIcon style={{display: ""}} className="attach-icon"/>
        </FileSelector>
        <SendIcon className="send-icon" onClick={this.props.sendComment}/>
        <FavMarker style={{display: ""}} className="fav-icon" onClick={() => this.props.handleChange({target:{name:"showFavComments", value: !this.props.showFavComments}})}/>
      </div>
    )
  }
}

export default CommentInput;