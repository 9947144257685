import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import InputTagger from "../controls/inputTagger";
import { connect } from "react-redux";
import { getUserByEmail } from "../../redux/reducers/user-reducer";
import { getUrlsEnv } from "../backend-api";
import Tag from "../controls/tag";
import CollapsableContainer from "../controls/collapsable-container";
import InputSearch from "../controls/input-search";
import Checkbox from "../controls/checkbox";
import { normalizeStr } from "../utils";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import ExceedPlus from "../controls/exceed-plus";

class InviteUsersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      userType: null,
      facilities: [],
      roles: [],
      fleetTags: [],
      stage: 1,
      searchStr: "",
      alreadyInvited: [],
      // displayFacilityBy: 'name',

      waiting: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      if (this.props.companyType === 1) {
        this.setState({
          stage: 2,
          userType: this.props.userTypes.find(
            (uT) => uT.description.toLowerCase() === "administrador"
          ).id,
        });
      }
    }
  }
  onClose = () => {
    this.setState({
      emails: [],
      userType: null,
      facilities: [],
      roles: [],
      fleetTags: [],
      stage: 1,
      waiting: false,
      alreadyInvited: [],
    });
    this.props.closeAction();
  };
  prevStage = () => {
    this.setState({ stage: this.state.stage - 1 });
  };
  nextStage = () => {
    this.setState({ stage: this.state.stage + 1 });
  };
  setUserType = (userType) => {
    this.setState({ userType: userType });
  };
  handleEmailInput = (e) => {
    if (e) {
      switch (e.target.name) {
        case "inviteTags":
          e.target.value
            .filter((it) => !it.processed)
            .forEach((i) => this.onEmail(i.email));
          break;
        case "deleteTags":
          this.setState({ emails: [].concat(e.target.value) });
          break;
        default:
      }
    }
  };
  validateInvitationResponse = (email, user, invitation) => {
    if (user.type === "USERS_GET_BY_EMAIL_SUCCESS") {
      let tag = {
        email: email,
        avatar: "/images/mail.svg",
        avatarType: "contained",
        userId: null,
        processed: true,
      };
      if (user.response.length > 0) {
        if (
          user.response[0].companies.find((c) => c === this.props.companyId) || (user.invitation_id !== null && user.invitation_id !== 0 && invitation)
        ) {
          this.setState({
            alreadyInvited: this.state.alreadyInvited
              .filter((e) => e !== email)
              .concat(email),
          });
          return;
        }
        tag.avatar = user.response[0].profile_image
          ? getUrlsEnv().files.concat(user.response[0].profile_image)
          : "/images/user-white.svg";
        tag.avatarType = user.response[0].profile_image
          ? "full"
          : "contained";
        tag.userId = user.response[0].id;
      } else if(invitation){
        this.props.findUserByEmail(email, "", "").then(users => this.validateInvitationResponse(email, users, false))
      }
      this.setState({ 
        emails: this.state.emails
          .filter((e) => e.email !== tag.email)
          .concat(tag),
      });
    }
  }
  onEmail = (email) => {
    this.props.findUserByEmail(email, "1", this.props.companyId)
      .then(users => this.validateInvitationResponse(email, users, true))
      .catch(() => this.props.findUserByEmail(email, "", "").then(users => this.validateInvitationResponse(email, users, false)))
  };
  // setDisplayFacilityBy = (value) => {
  //     this.setState({displayFacilityBy: value})
  // }
  selectAllFacilities = (e) => {
    // let facilitiesIds = [];
    // if(this.state.facilities.length < this.props.facilities.length)
    //     facilitiesIds = this.props.facilities.map(f => f.id);
    // this.setState({facilities: facilitiesIds});
    let st = [];
    if (e.target.checked) {
      this.props.facilities.forEach((fe) => {
        if ((fe.items || []).length > 0) {
          fe.items.forEach((ch) => {
            if (!st.includes(ch.id)) st.push(ch.id);
          });
        } else {
          st.push(fe.id);
        }
      });
    }
    this.setState({ facilities: st });
  };
  selectAllFleetTags = (e) => {
    // let facilitiesIds = [];
    // if(this.state.facilities.length < this.props.facilities.length)
    //     facilitiesIds = this.props.facilities.map(f => f.id);
    // this.setState({facilities: facilitiesIds});
    let st = [];
    if (e.target.checked) {
      this.props.fleetTags.forEach((fe) => {
        // if ((fe.items || []).length > 0) {
        //   fe.items.forEach((ch) => {
        //     if (!st.includes(ch.id)) st.push(ch.id);
        //   });
        // } else {
          st.push(fe.id);
        // }
      });
    }
    this.setState({ fleetTags: st });
  };
  // handleSelectFacility = (e) => {
  //     if(e.target.value)
  //         this.setState({facilities: this.state.facilities.filter(fId => fId !== e.target.id)})
  //     else
  //         this.setState({facilities: this.state.facilities.concat(e.target.id)})
  // }
  handleSelectFacility = (e, items) => {
    let st = this.state.facilities.map((m) => m.toString()) || [];
    if ((items || {}).length > 0) {
      items.forEach((item) => {
        let val = item.id.toString();
        st = st.filter((a) => a !== val);
        if (e.target.checked) {
          st = st.concat(val);
        }
      });
    } else {
      if (st.includes(e.target.value.toString())) {
        st = st.filter((a) => a.toString() !== e.target.value.toString());
      } else {
        st = st.concat(e.target.value.toString());
      }
    }
    this.setState({ facilities: st });
  };
  handleFacilitySearch = (name, value) => {
    this.setState({ searchStr: value });
  };
  handleFilterListSearch = (items, field, value = "") => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field]).includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    "description",
                    this.state.searchStr
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field]).includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };
  handleFleetTagClick = (e) => {
    if (e.target.value) {
      this.setState({
        fleetTags: this.state.fleetTags.filter((r) => r !== e.target.id),
      });
    } else {
      this.setState({ fleetTags: this.state.fleetTags.concat(e.target.id) });
    }
  };
  handleTagClick = (e) => {
    if (e.target.value) this.setState({ roles: [] });
    else this.setState({ roles: [e.target.id] });
  };
  sendInvitations = () => {
    this.setState({ waiting: true });
    this.props.handleInvitations(
      this.state.emails,
      this.state.userType,
      this.state.facilities,
      this.state.roles,
      this.state.fleetTags
    );
  };
  getContent = () => {
    if (this.props.invitationsComplete) {
      return (
        <div>
          <p>Se han enviado las siguientes invitaciones:</p>
          <div>
            <div className="review-counters">
              <div className="review-users">
                <div
                  className="image default"
                  style={{
                    backgroundImage: "url(/images/modal-user-icon.svg)",
                  }}
                ></div>
                {this.state.emails.length}{" "}
                {
                  (
                    this.props.userTypes.find(
                      (uT) => uT.id === this.state.userType
                    ) || {}
                  ).description
                }
              </div>
              {this.props.companyType === 2 ? (
                <div className="review-facilities">
                  <div
                    className="image default"
                    style={{
                      backgroundImage: "url(/images/modal-factory-icon.svg)",
                    }}
                  ></div>
                  {this.state.facilities.length} instalaciones
                </div>
              ) : (
                <div className="review-roles">
                  <span>{this.state.fleetTags.length} etiqueta(s) </span>
                </div>
              )}
              <div className="review-roles">
                <span>{this.state.roles.length} rol(es) </span>
              </div>
            </div>
            <div className="review-emails">
              {this.state.emails.map((mail) => {
                return <div>{mail.email}</div>;
              })}
            </div>
          </div>
        </div>
      );
    }
    switch (this.state.stage) {
      case 1:
        return (
          <div>
            <p>Para este grupo de usuarios, ¿qué tipo de acceso van a tener?</p>
            <div className="user-type-options-container">
              {this.props.userTypes
                .filter((uT) => uT.description.toLowerCase() !== "owner")
                .map((uT, index) => {
                  return (
                    <label
                      key={index}
                      onClick={() => this.setUserType(uT.id)}
                      className="radio-container"
                    >
                      {uT.description}
                      <input
                        readOnly
                        type="radio"
                        name="role"
                        value={uT.id}
                        checked={this.state.userType === uT.id}
                      />
                      <span className="checkmark check"></span>
                    </label>
                  );
                })}
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            {this.state.alreadyInvited.length > 0 && (
              <div>
                Invitados con anterioridad:{" "}
                <ExceedPlus>
                  {this.state.alreadyInvited.map((x) => (
                    <div>{x}</div>
                  ))}
                </ExceedPlus>
              </div>
            )}
            <p>
              Como{" "}
              <b>
                {
                  (
                    this.props.userTypes.find(
                      (uT) => uT.id === this.state.userType
                    ) || {}
                  ).description
                }
              </b>
              :
            </p>
            <div>
              <InputTagger
                placeholder='Ingresa el o los correos separados por "enter"'
                tags={this.state.emails}
                onChange={this.handleEmailInput}
                list_mails={true}
              />
            </div>
          </div>
        );
      case 3:
        let counter = -9999;
        return (
          <div>
            <p>
              Estás invitando a <b>{this.state.emails.length} persona(s)</b>{" "}
              como{" "}
              <b>
                {
                  (
                    this.props.userTypes.find(
                      (uT) => uT.id === this.state.userType
                    ) || {}
                  ).description
                }
              </b>
            </p>
            <CollapsableContainer startsCollapsed={true}>
              {this.state.emails.map((e) => {
                return <p>{e.email}</p>;
              })}
            </CollapsableContainer>
            {this.props.companyType === 2 ? (
              <>
                <p>Indica a qué instalaciones tendrán acceso estos usuarios:</p>
                <div className="facilities-options-container">
                  <div className="search-container">
                    <InputSearch
                      type="text"
                      label="Buscar instalaciones"
                      name="usersFacilitySearch"
                      className="full"
                      onChange={this.handleFacilitySearch}
                    />
                    <img src="/images/search.svg" alt="" className="icono" />
                  </div>
                  {/* <div className="facilities-display-by">
                          Ver como: 
                          <div className="facilities-radio-container">
                            <label onClick={() => this.setDisplayFacilityBy('name')} className="radio-container">
                              Nombre
                              <input readOnly type="radio" name="displayFacilityBy" value={'name'} checked={this.state.displayFacilityBy === 'name'}/>
                              <span className="checkmark check"></span>
                            </label>
                            <label onClick={() => this.setDisplayFacilityBy('alias')} className="radio-container">
                              Alias
                              <input readOnly type="radio" name="displayFacilityBy" value={'alias'} checked={this.state.displayFacilityBy === 'alias'}/>
                              <span className="checkmark"></span>
                            </label>
                            <label onClick={() => this.setDisplayFacilityBy('code')} className="radio-container">
                              Código
                              <input readOnly type="radio" name="displayFacilityBy" value={'code'} checked={this.state.displayFacilityBy === 'code'}/>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div> */}
                  <div className="facilities-list-container">
                    <Checkbox
                      onChange={(e) => this.selectAllFacilities(e)}
                      item={{
                        id: 0,
                        description: "Todos",
                        active:
                          this.state.facilities.length ===
                          this.props.maxFacilities,
                      }}
                      name="facilities"
                      className="bold"
                    />
                    <div className="separator" />
                    <div className="items checks">
                      {
                        // this.props.facilities.filter(f => (
                        //   f.name.toLowerCase().includes(this.state.searchStr.toLowerCase())
                        //   || f.alias.toLowerCase().includes(this.state.searchStr.toLowerCase())
                        //   || f.code.toLowerCase().includes(this.state.searchStr.toLowerCase())
                        // ))
                        this.handleFilterListSearch(
                          this.props.facilities || [],
                          "description",
                          this.state.searchStr
                        ).map((i) => {
                          let l = [];
                          //l.push(
                          //   <Checkbox
                          //     onChange={() => this.handleSelectFacility({target: {id: i.id, value: this.state.facilities.find(si => si.toString() === i.id.toString()) ? true : false}})}
                          //     item={{
                          //       id: i.id,
                          //       description: i.description,
                          //       active: this.state.facilities.find(si => si.toString() === i.id.toString()) ? true : false,
                          //     }}
                          //     name='facilities'
                          //     className=''
                          //   />
                          // )
                          l.push(
                            <Checkbox
                              key={counter++}
                              onChange={(e) =>
                                this.handleSelectFacility(e, i.items)
                              }
                              item={{
                                id: i.id,
                                description: i.description,
                                active: (this.state.facilities || []).find(
                                  (si) => si.toString() === i.id.toString()
                                )
                                  ? true
                                  : false,
                              }}
                              name="facilities"
                              className={(i.items || []).length ? "bold" : ""}
                            />
                          );
                          if ((i.items || []).length) {
                            i.items.forEach((ci) =>
                              l.push(
                                <Checkbox
                                  key={counter++}
                                  className={"sub-item"}
                                  onChange={(e) => this.handleSelectFacility(e)}
                                  item={{
                                    id: ci.id,
                                    description: ci.description,
                                    active: (this.state.facilities || []).find(
                                      (si) => si.toString() === ci.id.toString()
                                    )
                                      ? true
                                      : false,
                                  }}
                                  name="facilities"
                                />
                              )
                            );
                            l.push(
                              <div className="separator" key={counter++} />
                            );
                            let full = true;
                            i.items.forEach((fe) =>
                              (this.state.facilities || []).find(
                                (si) => si.toString() === fe.id.toString()
                              ) && full
                                ? (full = true)
                                : (full = false)
                            );
                            l[0].props.item.active = full;
                          }
                          return l;
                        })
                      }
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p style={{display:"flex", justifyContent: "space-between"}}>
                  Tendrán las siguientes etiquetas asignadas:
                  <Checkbox
                    onChange={(e) => this.selectAllFleetTags(e)}
                    item={{
                      id: 0,
                      description: "Todas",
                      active:
                        this.state.fleetTags.length ===
                        this.props.fleetTags.length,
                    }}
                    name="fleetTags"
                    className="bold"
                  />
                </p>
                {/* <div className="facilities-list-container"> */}
                  
                {/* </div> */}
                <div className="user-tags-options-container">
                  {this.props.fleetTags.map((tag) => {
                    return (
                      <Tag
                        key={tag.id}
                        id={tag.id}
                        color={tag.color}
                        title={tag.title}
                        type={tag.type}
                        isFull={this.state.fleetTags.includes(tag.id)}
                        onClick={this.handleFleetTagClick}
                        parentBackground="light"
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      case 4:
        return (
          <div>
            <p>
              Estás invitando a <b>{this.state.emails.length} persona(s)</b>{" "}
              como{" "}
              <b>
                {
                  (
                    this.props.userTypes.find(
                      (uT) => uT.id === this.state.userType
                    ) || {}
                  ).description
                }
              </b>
            </p>
            <CollapsableContainer startsCollapsed={true}>
              {this.state.emails.map((e) => {
                return <p>{e.email}</p>;
              })}
            </CollapsableContainer>
            {this.props.companyType === 2 ? (
              <>
                <p>
                  Con acceso a{" "}
                  <b>{this.state.facilities.length} instalaciones</b>
                </p>

                <CollapsableContainer startsCollapsed={true}>
                  {this.state.facilities.map((f) => (
                    <p>
                      {
                        this.props.facilities
                          .map((pf) => pf.items)
                          .flat()
                          .find((pf) => pf.id.toString() === f.toString())
                          ?.description
                      }
                    </p>
                  ))}
                </CollapsableContainer>
              </>
            ) : (
              <>
                <p>
                  Con <b>{this.state.fleetTags.length} etiqueta(s)</b>{" "}
                  asignada(s)
                </p>
                <CollapsableContainer startsCollapsed={true}>
                  {this.props.fleetTags
                    .filter((fT) => this.state.fleetTags.includes(fT.id))
                    .map((tag) => {
                      return (
                        <Tag
                          key={tag.id}
                          id={tag.id}
                          color={tag.color}
                          title={tag.title}
                          type={tag.type}
                          // isFull={this.state.fleetTags.includes(tag.id)}
                          // onClick={this.handleFleetTagClick}
                          parentBackground="light"
                        />
                      );
                    })}
                </CollapsableContainer>
              </>
            )}
            <p>Tendrán el siguiente rol asignado:</p>
            <div className="user-tags-options-container">
              {this.props.tags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    id={tag.id}
                    color={tag.color}
                    title={tag.title}
                    type={tag.type}
                    isFull={this.state.roles.includes(tag.id)}
                    onClick={this.handleTagClick}
                    parentBackground="light"
                  />
                );
              })}
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };
  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog emails-modal invite-users-modal"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">
          {this.props.invitationsComplete ? "¡Listo!" : "Invitar usuarios"}
        </div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.getContent()}</div>
        <div className="actions">
          {!this.props.invitationsComplete &&
          ((this.props.companyType !== 1 && this.state.stage === 2) ||
            this.state.stage === 3 ||
            this.state.stage === 4) ? (
            <Button
              text="Atras"
              type="primary outline"
              onClick={this.prevStage}
            />
          ) : (
            ""
          )}
          {this.state.stage === 1 ||
          this.state.stage === 2 ||
          this.state.stage === 3 ? (
            <Button
              text="Continuar"
              type="primary"
              onClick={this.nextStage}
              disabled={
                (this.state.stage === 1 && !this.state.userType) ||
                (this.state.stage === 2 && !this.state.emails.length)
              }
            />
          ) : (
            ""
          )}
          {!this.props.invitationsComplete && this.state.stage === 4 ? (
            <Button
              text={this.state.waiting ? "Enviando" : "Enviar"}
              type="primary"
              onClick={this.sendInvitations}
              disabled={this.state.waiting && !this.props.invitationsComplete}
            />
          ) : (
            ""
          )}
          {this.props.invitationsComplete ? (
            <Button text="Finalizar" type="primary" onClick={this.onClose} />
          ) : (
            ""
          )}
        </div>
      </Modal>
    );
  }
}

// export default InviteUsersModal;

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  return { companyId };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    findUserByEmail: (email, invitation, company_id) => dispatch(getUserByEmail(email, invitation, company_id)),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(InviteUsersModal);

InviteUsersModal.defaultProps = {
  tags: [],
  fleetTags: [],
  companyType: 2, // 1-Trucking Company, 2-Cargo Owner
  facilities: [],
  maxFacilities: 0,
  userTypes: [],
  onInvite: () => {},
  closeAction: () => {},
  invitationsComplete: false,
};
