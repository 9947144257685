import React from "react";
import { Container, MainHeader, BuyContainer } from "./style";
import PaymentsBuyRow from "./buy/buy-row";

class PaymentsBuy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: 1000,
    };
  }

  getRows = () => {
    return [
      {
        title: "100",
        qty: 100,
        price: "1.45",
        editable: false,
      },
      {
        title: "200",
        qty: 200,
        price: "1.30",
        editable: false,
      },
      {
        title: "500",
        qty: 500,
        price: "1.15",
        editable: false,
      },
      {
        title: "+1000",
        qty: this.state.editable,
        price: "0.99",
        editable: true,
      },
    ];
  };

  changeEditable = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validEditable = (e) => {
    if (parseInt(e.target.value) < 1000) {
      this.setState({ [e.target.name]: 1000 });
    }
  };

  render() {
    return (
      <Container>
        <MainHeader>
          <div className="full">
            <div className="title">Comprar Orbibucks</div>
          </div>
        </MainHeader>
        <BuyContainer>
          <PaymentsBuyRow
            data={this.getRows()}
            changeEditable={this.changeEditable}
            validEditable={this.validEditable}
          />
        </BuyContainer>
      </Container>
    );
  }
}

export default PaymentsBuy;
