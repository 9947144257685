import React from "react";
import MenuAlertsDetails from "./details";
import {
  AlertsContainer,
  AlertsHeader,
  AlertsOptions,
  AlertsContent,
} from "./styled";

export default class MenuAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOpc: "alerts",
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.content && this.content.contains(e.target) || this.props.helpButton && this.props.helpButton.contains(e.target)) return;
    if (this.props.closeClick && this.props.show) {
      this.props.closeClick();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        currentOpc: "alerts",
      });
    }
  }

  changeOpc = (opc) => {
    this.setState({
      currentOpc: opc,
    });
  };

  render() {
    const { show, open, closeClick } = this.props;
    return show ? (
      <AlertsContainer 
        ref={node => (this.content = node)}
        className={`${open ? "open" : "closed"}`}
      >
        <AlertsHeader>
          <div
            style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: 'gray'}}
          >
            {/* <span style={{fontSize: 24,}} onClick={() => closeClick()}>x</span> */}
          </div>
        </AlertsHeader>
        <AlertsOptions>
          <div
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            className={`${this.state.currentOpc === "alerts" ? "active" : ""}`}
            onClick={() => this.changeOpc("alerts")}
          >
            <span>Alertas y notificaciones</span>
          </div>
          {/* <div
            className={`${
              this.state.currentOpc === "notifications" ? "active" : ""
            }`}
            onClick={() => this.changeOpc("notifications")}
          >
            <span>Notificaciones</span>
          </div> */}
        </AlertsOptions>
        <AlertsContent>
          <div style={{position: "absolute", top: "-15px", right: "0", cursor: "pointer", fontWeight: "bold"}} onClick={() => {closeClick(); this.props.history.push('/company/alerts')}}>Ver Todas</div>
          <MenuAlertsDetails history={this.props.history} currentOpc={this.state.currentOpc} /* alert_details={this.props.alert_details}  *//>
        </AlertsContent>
      </AlertsContainer>
    ) : (
      ""
    );
  }
}
