// import fetch from 'cross-fetch';
import {
  BACKEND,
  getApiUrl,
  getApiUrlWithToken,
  openSecureDoc,
  interpolate,
  postApiUrl,
} from "../../shared/backend-api";
import store from "../store";

export const REQUEST_COUNTRIES = "REQUEST_COUNTRIES";
export const RECEIVE_COUNTRIES = "RECEIVE_COUNTRIES";
export const REQUEST_COUNTRIES_ERROR = "REQUEST_COUNTRIES_ERROR";

export const REQUEST_STATES = "REQUEST_STATES";
export const RECEIVE_STATES = "RECEIVE_STATES";
export const REQUEST_STATES_ERROR = "REQUEST_STATES_ERROR";

export const REQUEST_CITIES = "REQUEST_CITIES";
export const RECEIVE_CITIES = "RECEIVE_CITIES";
export const REQUEST_CITIES_ERROR = "REQUEST_CITIES_ERROR";

export const REQUEST_TIME_ZONES = "REQUEST_TIME_ZONES";
export const RECEIVE_TIME_ZONES = "RECEIVE_TIME_ZONES";
export const REQUEST_TIME_ZONES_ERROR = "REQUEST_TIME_ZONES_ERROR";

export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const FLEET_CATALOG_TAB_SELECTED = "FLEET_CATALOG_TAB_SELECTED";
export const REMOVE_FLEET_CATALOG_TAB_SELECTED =
  "REMOVE_FLEET_CATALOG_TAB_SELECTED";

export const ORDER_DETAIL_SELECTED = "ORDER_DETAIL_SELECTED";
export const REMOVE_ORDER_DETAIL_SELECTED =
  "REMOVE_ORDER_DETAIL_SELECTED";

export const GET_REQUEST_COMPANY = "GET_REQUEST_COMPANY";
export const GET_REQUEST_COMPANY_ERROR = "GET_REQUEST_COMPANY_ERROR";
export const GET_REQUEST_COMPANY_RESPONSE = "GET_REQUEST_COMPANY_RESPONSE";

// export const SET_PERMISSION_USER = "SET_PERMISSION_USER";

const getRequestCompany = () => ({
  type: REQUEST_COUNTRIES,
});
const getRequestCompanySuccess = (response) => ({
  type: GET_REQUEST_COMPANY_RESPONSE,
  response,
});
const getRequestCompanyError = (error) => ({
  type: GET_REQUEST_COMPANY_ERROR,
  error,
});

const requestCountries = () => ({
  type: REQUEST_COUNTRIES,
});
const receiveCountries = (response) => ({
  type: RECEIVE_COUNTRIES,
  response,
});
const requestCountriesError = (error) => ({
  type: REQUEST_COUNTRIES_ERROR,
  error,
});

const requestStates = () => ({
  type: REQUEST_STATES,
});
const receiveStates = (response) => ({
  type: RECEIVE_STATES,
  response,
});
const requestStatesError = (error) => ({
  type: REQUEST_STATES_ERROR,
  error,
});

const requestCities = () => ({
  type: REQUEST_CITIES,
});
const receiveCities = (response) => ({
  type: RECEIVE_CITIES,
  response,
});
const requestCitiesError = (error) => ({
  type: REQUEST_CITIES_ERROR,
  error,
});

const requestTimeZones = () => ({
  type: REQUEST_TIME_ZONES,
});
const receiveTimeZones = (response) => ({
  type: RECEIVE_TIME_ZONES,
  response,
});
const requestTimeZonesError = (error) => ({
  type: REQUEST_TIME_ZONES_ERROR,
  error,
});

export function addToast(payload) {
  return {
    payload,
    type: ADD_TOAST,
  };
}

export function removeToast() {
  return {
    type: REMOVE_TOAST,
  };
}

export function setFleetCatalogTabSeleted(payload) {
  return {
    payload,
    type: FLEET_CATALOG_TAB_SELECTED,
  };
}

export function removeFleetCatalogTabSeleted() {
  return {
    type: REMOVE_FLEET_CATALOG_TAB_SELECTED,
  };
}

export function setOrderSeleted(payload) {
  return {
    payload,
    type: ORDER_DETAIL_SELECTED,
  };
}

export function removeOrderSeleted() {
  return {
    type: REMOVE_ORDER_DETAIL_SELECTED,
  };
}

export function getRequest(session) {
  return function (dispatch) {
    dispatch(getRequestCompany());
    return getApiUrlWithToken(BACKEND.USERS.REQUESTS, { session })
      .then(
        (response) => response.json(),
        (error) => dispatch(getRequestCompanyError(error))
      )
      .then((data) => dispatch(getRequestCompanySuccess(data)));
  };
}

// export function setPermissionUsers() {
//   return {
//     type: SET_PERMISSION_USER
//   };
// }

const ACTIONS = {
  SESSION: {
    KEY: "orbinetwork_session",
    LOAD: {
      TYPE: "ACTIONS_SESSION_LOAD",
      ACTION: () => ({
        type: ACTIONS.SESSION.LOAD.TYPE,
      }),
    },

    SET_USER: {
      TYPE: "ACTIONS_SESSION_SET_USER",
      ACTION: (user) => ({
        type: ACTIONS.SESSION.SET_USER.TYPE,
        user,
      }),
    },

    SET_COMPANY: {
      TYPE: "ACTIONS_SESSION_SET_COMPANY",
      ACTION: (company) => ({
        type: ACTIONS.SESSION.SET_COMPANY.TYPE,
        company,
      }),
    },

    SET_COMPANIES_LIST: {
      TYPE: "ACTIONS_SESSION_SET_COMPANIES_LIST",
      ACTION: (listCompanies) => ({
        type: ACTIONS.SESSION.SET_COMPANIES_LIST.TYPE,
        listCompanies,
      }),
    },

    SET_FACILITY: {
      TYPE: "ACTIONS_SESSION_SET_FACILITY",
      ACTION: (facility) => ({
        type: ACTIONS.SESSION.SET_FACILITY.TYPE,
        facility,
      }),
    },

    CLEAR_SESSION: {
      TYPE: "ACTIONS_SESSION_CLEAR_SESSION",
      ACTION: () => ({
        type: ACTIONS.SESSION.CLEAR_SESSION.TYPE,
      }),
    },
  },

  CATALOG: {
    ACCOUNT_STATUS: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_CATALOG_ACCOUNT_STATUS",
          ACTION: () => ({
            type: ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_CATALOG_ACCOUNT_STATUS_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_CATALOG_ACCOUNT_STATUS_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    NETWORK_STATUS: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_CATALOG_NETWORK_STATUS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.CATALOG.NETWORK_STATUS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_CATALOG_NETWORK_STATUS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.CATALOG.NETWORK_STATUS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_CATALOG_NETWORK_STATUS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.CATALOG.NETWORK_STATUS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    COMPANY_TYPE: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_CATALOG_COMPANY_TYPE_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.CATALOG.COMPANY_TYPE.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_CATALOG_COMPANY_TYPE_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.CATALOG.COMPANY_TYPE.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_CATALOG_COMPANY_TYPE_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.CATALOG.COMPANY_TYPE.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    PERMISSIONS: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_CATALOG_PERMISSIONS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.CATALOG.PERMISSIONS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_CATALOG_PERMISSIONS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.CATALOG.PERMISSIONS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_CATALOG_PERMISSIONS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.CATALOG.PERMISSIONS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    NORMATIVITY_STATUS_DESCRIPTION: {
      LIST: {
        REQUEST: {
          TYPE: "ACTIONS_CATALOG_NORMATIVITY_STATUS_DESCRIPTION_LIST_REQUEST",
          ACTION: () => ({
            type:
              ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_CATALOG_NORMATIVITY_STATUS_DESCRIPTION_LIST_SUCCESS",
          ACTION: (response) => ({
            type:
              ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_CATALOG_NORMATIVITY_STATUS_DESCRIPTION_LIST_ERROR",
          ACTION: (error) => ({
            type:
              ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
  },
  PERMISSION_FILE: {
    DOWNLOAD: {
      REQUEST: {
        TYPE: "ACTIONS_PERMISSION_FILE_DOWNLOAD_REQUEST",
        ACTION: () => ({
          type: ACTIONS.PERMISSION_FILE.DOWNLOAD.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ACTIONS_PERMISSION_FILE_DOWNLOAD_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.PERMISSION_FILE.DOWNLOAD.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ACTIONS_PERMISSION_FILE_DOWNLOAD_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.PERMISSION_FILE.DOWNLOAD.ERROR.TYPE,
          error,
        }),
      },
    },
    LOAD_PERMISSION: {
      TYPE: "ACTIONS_PERMISSION_FILE_LOAD_PERMISSION",
      ACTION: (permission) => ({
        type: ACTIONS.PERMISSION_FILE.LOAD_PERMISSION.TYPE,
        permission,
      }),
    },
  },
};

const initialState = {
  countries: {
    isFetching: false,
    items: [],
    error: "",
  },
  cities: {
    isFetching: false,
    items: [],
    error: "",
  },
  states: {
    isFetching: false,
    items: [],
    error: "",
  },
  timeZones: {
    isFetching: false,
    items: [],
    error: "",
  },
  weekDays: [
    { id: 1, description: "Domingo", shortName: "Dom", abbreviation: "D" },
    { id: 2, description: "Lunes", shortName: "Lun", abbreviation: "L" },
    { id: 3, description: "Martes", shortName: "Mar", abbreviation: "M" },
    { id: 4, description: "Miércoles", shortName: "Mie", abbreviation: "X" },
    { id: 5, description: "Jueves", shortName: "Jue", abbreviation: "J" },
    { id: 6, description: "Viernes", shortName: "Vie", abbreviation: "V" },
    { id: 7, description: "Sábado", shortName: "Sab", abbreviation: "S" },
  ],
  colorCatalog: [
    { id: 1, description: "blue", value: "3D77F7" },
    { id: 2, description: "green", value: "05C985" },
    { id: 3, description: "yellow", value: "FFA517" },
    { id: 4, description: "thistle", value: "EDB8FF" },
    { id: 5, description: "pink", value: "EB496B" },
    { id: 6, description: "orange", value: "EF7019" },
    { id: 7, description: "skyblue", value: "79A3FF" },
    { id: 8, description: "purple", value: "B84CBE" },
    { id: 9, description: "mediumaquamarine", value: "22B6A9" },
    { id: 10, description: "silver", value: "D5DEEC" },
    { id: 11, description: "red", value: "f51505" },
  ],
  session: {
    user: {},
    company: {},
    listCompanies: [],
  },

  facility: {},

  toast: null,
  accountStatus: {
    isFetching: false,
    data: [],
    error: {},
  },
  networkStatus: {
    isFetching: false,
    error: {},
    data: [],
  },
  permissionsCatalog: {
    isFetching: false,
    error: {},
    data: [],
  },
  companyTypes: {
    isFetching: false,
    error: {},
    data: [],
  },
  statusDescription: {
    isFetching: false,
    error: {},
    data: [],
  },
  requestCompany: [],
  tabSelected: null,
  orderSelected: null,
  permissionUser: {
    isFetching: false,
    error: {},
    data: {},
  },
  socket: {
    client: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ORDER_DETAIL_SELECTED:
      return {
        ...state,
        orderSelected: action.payload,
      };
    case REMOVE_ORDER_DETAIL_SELECTED:
      return {
        ...state,
        orderSelected: null,
      };
    case FLEET_CATALOG_TAB_SELECTED:
      return {
        ...state,
        tabSelected: action.payload,
      };
    case REMOVE_FLEET_CATALOG_TAB_SELECTED:
      return {
        ...state,
        tabSelected: null,
      };
    case GET_REQUEST_COMPANY_RESPONSE:
      return {
        ...state,
        requestCompany: action.response,
      };
    case REMOVE_TOAST:
      return {
        ...state,
        toast: null,
      };
    case ADD_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case REQUEST_COUNTRIES:
      return Object.assign({}, state, {
        countries: {
          isFetching: true,
        },
      });
    case RECEIVE_COUNTRIES:
      return Object.assign({}, state, {
        countries: {
          isFetching: false,
          items: action.response.results,
        },
      });
    case REQUEST_COUNTRIES_ERROR:
      return Object.assign({}, state, {
        countries: {
          isFetching: false,
          error: action.error,
        },
      });

    case REQUEST_STATES:
      return Object.assign({}, state, {
        states: {
          isFetching: true,
        },
      });
    case RECEIVE_STATES:
      return Object.assign({}, state, {
        states: {
          isFetching: false,
          items: action.response.results,
        },
      });
    case REQUEST_STATES_ERROR:
      return Object.assign({}, state, {
        states: {
          isFetching: false,
          error: action.error,
        },
      });

    case REQUEST_CITIES:
      return Object.assign({}, state, {
        cities: {
          isFetching: true,
        },
      });
    case RECEIVE_CITIES:
      return Object.assign({}, state, {
        cities: {
          isFetching: false,
          items: action.response.results,
        },
      });
    case REQUEST_CITIES_ERROR:
      return Object.assign({}, state, {
        cities: {
          isFetching: false,
          error: action.error,
        },
      });

    case REQUEST_TIME_ZONES:
      return Object.assign({}, state, {
        timeZones: {
          isFetching: true,
        },
      });
    case RECEIVE_TIME_ZONES:
      return Object.assign({}, state, {
        timeZones: {
          isFetching: false,
          items: action.response,
        },
      });
    case REQUEST_TIME_ZONES_ERROR:
      return Object.assign({}, state, {
        timeZones: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SESSION.LOAD.TYPE:
      let tempSession = JSON.parse(sessionStorage.getItem(ACTIONS.SESSION.KEY));
      return Object.assign({}, state, {
        session: tempSession ? tempSession : { company: {}, user: {} }
      });

    case ACTIONS.SESSION.SET_USER.TYPE:
      let session = JSON.parse(sessionStorage.getItem(ACTIONS.SESSION.KEY));
      let user = Object.assign(session.user, action.user);
      session.user = user;
      sessionStorage.setItem(ACTIONS.SESSION.KEY, JSON.stringify(session));
      return Object.assign({}, state, {
        session: {
          user,
          listCompanies: state.session.listCompanies,
          company: state.session.company,
        },
      });

    case ACTIONS.SESSION.SET_COMPANY.TYPE:
      let sessioncmp = JSON.parse(sessionStorage.getItem(ACTIONS.SESSION.KEY));
      let company = Object.assign(sessioncmp.company, action.company);
      sessioncmp.company = company;
      sessionStorage.setItem(ACTIONS.SESSION.KEY, JSON.stringify(sessioncmp));
      return Object.assign({}, state, {
        session: {
          user: state.session.user,
          listCompanies: state.session.listCompanies,
          company: company,
        },
      });

    case ACTIONS.SESSION.SET_COMPANIES_LIST.TYPE:
      let sessioncmplst = JSON.parse(
        sessionStorage.getItem(ACTIONS.SESSION.KEY)
      );
      let listCompanies = Object.assign(
        sessioncmplst.listCompanies || {},
        action.listCompanies
      );
      sessioncmplst.listCompanies = listCompanies;
      sessionStorage.setItem(
        ACTIONS.SESSION.KEY,
        JSON.stringify(sessioncmplst)
      );
      return Object.assign({}, state, {
        session: {
          user: state.session.user,
          listCompanies: listCompanies,
        },
      });

    case ACTIONS.SESSION.SET_FACILITY.TYPE:
      let facility = action.facility;
      let sessioncmplstfacility = JSON.parse(
        sessionStorage.getItem(ACTIONS.SESSION.KEY)
      );
      sessioncmplstfacility.facility = facility;
      sessionStorage.setItem(
        ACTIONS.SESSION.KEY,
        JSON.stringify(sessioncmplstfacility)
      );
      return Object.assign(
        {},
        state,
        Object.assign({}, state.session, {
          facility: facility,
        })
      );

    case ACTIONS.SESSION.CLEAR_SESSION.TYPE:
      return {
        session: {
          user: {},
          company: {},
        },
      };

    case "ACTIONS_SOCKET_SET":
      return Object.assign({}, state, {
        socket: {
          client: action.client,
        },
      });
    // let sessioncmp = JSON.parse(sessionStorage.getItem(ACTIONS.SESSION.KEY));
    // let company = Object.assign(sessioncmp.company, action.company);
    // sessioncmp.company = company;
    // sessionStorage.setItem(ACTIONS.SESSION.KEY, JSON.stringify(sessioncmp));
    // return Object.assign({}, state, {
    //   session: {
    //     user: state.session.user,
    //     company: company
    //   }
    // });

    case ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        accountStatus: {
          isFetching: true,
        },
      });
    case ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        accountStatus: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        accountStatus: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.CATALOG.NETWORK_STATUS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        networkStatus: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.CATALOG.NETWORK_STATUS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        networkStatus: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.CATALOG.NETWORK_STATUS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        networkStatus: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.CATALOG.PERMISSIONS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        permissionsCatalog: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.CATALOG.PERMISSIONS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        permissionsCatalog: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.CATALOG.PERMISSIONS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        permissionsCatalog: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.CATALOG.COMPANY_TYPE.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyTypes: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.CATALOG.COMPANY_TYPE.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyTypes: {
          isFetching: false,
          data: action.response,
        },
      });

    case ACTIONS.CATALOG.COMPANY_TYPE.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        companyTypes: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    case ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        statusDescription: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        statusDescription: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        statusDescription: {
          isFetching: false,
          error: action.error,
          data: [],
        },
      });

    // case SET_PERMISSION_USER:
    //   return Object.assign({}, state, {
    //     permissionUser: JSON.parse(localStorage.getItem("USER_PERMISSION"))
    //   });

    case ACTIONS.PERMISSION_FILE.DOWNLOAD.REQUEST.TYPE:
      return Object.assign({}, state, {
        permissionUser: {
          isFetching: true,
          data: [],
        },
      });

    case ACTIONS.PERMISSION_FILE.DOWNLOAD.SUCCESS.TYPE:
      sessionStorage.setItem(
        "USER_PERMISSION",
        JSON.stringify(action.response.content)
      );
      return state;
    // return Object.assign({}, state, {
    //   permissionUser: {
    //     isFetching: false
    //     // data: JSON.parse(action.response)
    //   }
    // });

    case ACTIONS.PERMISSION_FILE.DOWNLOAD.ERROR.TYPE:
      return Object.assign({}, state, {
        permissionUser: {
          isFetching: false,
          error: action.error,
          data: [],
        },
      });

    case ACTIONS.PERMISSION_FILE.LOAD_PERMISSION.TYPE:
      return Object.assign({}, state, {
        permissionUser: {
          isFetching: false,
          data: action.permission,
        },
      });

    default: {
      return state;
    }
  }
}

export function refreshSession(token) {
  return function () {
    return postApiUrl(BACKEND.SESSION.REFRESH, { refresh: token })
      .then((response) => response.json())
      .then((result) => result)
      .catch(() => false);
  };
}

export function loadCountries() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.countries;
    if (!storeItems.isFetching && storeItems.items.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(requestCountries());
      return getApiUrl(BACKEND.PUBLIC.COUNTRY)
        .then(
          (response) => response.json(),
          (error) => dispatch(requestCountriesError(error))
        )
        .then((data) => dispatch(receiveCountries(data)));
    }
  };
}

export function loadStates(country) {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.states;
    // if (!storeItems.isFetching && storeItems.items.length) {
    //   return new Promise((resolve) => resolve(storeItems));
    // } else {
      dispatch(requestStates());
      return getApiUrl(BACKEND.PUBLIC.STATE + `?country=${country}`)
        .then(
          (response) => response.json(),
          (error) => dispatch(requestStatesError(error))
        )
        .then((data) => dispatch(receiveStates(data)));
    //}
  };
}

export function loadCities(state) {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.cities;
    // if (!storeItems.isFetching && storeItems.items.length) {
    //   return new Promise((resolve) => resolve(storeItems));
    // } else {
      dispatch(requestCities());
      return getApiUrl(BACKEND.PUBLIC.CITY + `?state=${state}`)
        .then(
          (response) => response.json(),
          (error) => dispatch(requestCitiesError(error))
        )
        .then((data) => dispatch(receiveCities(data)));
    //}
  };
}

export function loadTimeZones() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.timeZones;
    if (!storeItems.isFetching && storeItems.items.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(requestTimeZones());
      return getApiUrl(BACKEND.PUBLIC.TIME_ZONES)
        .then(
          (response) => response.json(),
          (error) => dispatch(requestTimeZonesError(error))
        )
        .then((data) => dispatch(receiveTimeZones(data)));
    }
  };
}

export function loadAccountStatusCatalog() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.accountStatus;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.ACCOUNT_STATUS)
        .then((response) => response.json())
        .then((data) =>
          dispatch(ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.SUCCESS.ACTION(data))
        )
        .catch((error) =>
          dispatch(ACTIONS.CATALOG.ACCOUNT_STATUS.LIST.ERROR.ACTION(error))
        );
    }
  };
}

export function loadNetworkStatusCatalog() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.networkStatus;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.CATALOG.NETWORK_STATUS.LIST.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY.CATALOG.NETWORK_STATUS)
        .then((response) => response.json())
        .then((data) =>
          dispatch(ACTIONS.CATALOG.NETWORK_STATUS.LIST.SUCCESS.ACTION(data))
        )
        .catch((error) =>
          dispatch(ACTIONS.CATALOG.NETWORK_STATUS.LIST.ERROR.ACTION(error))
        );
    }
  };
}

export function loadPermissionsCatalog() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.permissionsCatalog;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.CATALOG.PERMISSIONS.LIST.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.PERMISSIONS)
        .then((response) => response.json())
        .then((data) =>
          dispatch(ACTIONS.CATALOG.PERMISSIONS.LIST.SUCCESS.ACTION(data))
        )
        .catch((error) =>
          dispatch(ACTIONS.CATALOG.PERMISSIONS.LIST.ERROR.ACTION(error))
        );
    }
  };
}

export function loadCompanyTypesCatalog() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.companyTypes;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.CATALOG.COMPANY_TYPE.LIST.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY.CATALOG.COMPANY_TYPE)
        .then((response) => response.json())
        .then((data) =>
          dispatch(ACTIONS.CATALOG.COMPANY_TYPE.LIST.SUCCESS.ACTION(data))
        )
        .catch((error) =>
          dispatch(ACTIONS.CATALOG.COMPANY_TYPE.LIST.ERROR.ACTION(error))
        );
    }
  };
}

export function loadStatusDescriptions() {
  return function (dispatch) {
    let storeItems = store.getState().globalCatalog.statusDescription;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(
        ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.REQUEST.ACTION()
      );
      return getApiUrl(BACKEND.COMPANY_CATALOG.NORMATIVITY_STATUS_DESCRIPTION)
        .then((response) => response.json())
        .then((data) =>
          dispatch(
            ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.SUCCESS.ACTION(
              data
            )
          )
        )
        .catch((error) =>
          dispatch(
            ACTIONS.CATALOG.NORMATIVITY_STATUS_DESCRIPTION.LIST.ERROR.ACTION(
              error
            )
          )
        );
    }
  };
}

export function loadSession() {
  return function (dispatch) {
    dispatch(ACTIONS.SESSION.LOAD.ACTION());
  };
}

export function setSessionUser(user) {
  return function (dispatch) {
    dispatch(ACTIONS.SESSION.SET_USER.ACTION(user));
  };
}
export function setSessionCompany(company) {
  return function (dispatch) {
    dispatch(ACTIONS.SESSION.SET_COMPANY.ACTION(company));
  };
}

export function setSessionFacility(facility) {
  return function (dispatch) {
    dispatch(ACTIONS.SESSION.SET_FACILITY.ACTION(facility));
  };
}

export function setSessionCompanyList(company) {
  return function (dispatch) {
    dispatch(ACTIONS.SESSION.SET_COMPANIES_LIST.ACTION(company));
  };
}

export function clearSession() {
  return function (dispatch) {
    dispatch(ACTIONS.SESSION.CLEAR_SESSION.ACTION());
    sessionStorage.clear();
  };
}

export function downloadPermissionFile() {
  let params = {
    company_id: (store.getState().globalCatalog.session) ? store.getState().globalCatalog.session.company.id : '',
    user_id: (store.getState().globalCatalog.session) ? store.getState().globalCatalog.session.user.id : '',
  };

  return function (dispatch) {
    dispatch(ACTIONS.PERMISSION_FILE.DOWNLOAD.REQUEST.ACTION);

    openSecureDoc(interpolate(BACKEND.PERMISSIONS.GET_PERMISSION_FILE, params))
      .then((doc) => doc.json())
      .then((response) => {
        dispatch(ACTIONS.PERMISSION_FILE.DOWNLOAD.SUCCESS.ACTION(response));
      })
      .then((rest) => {
        dispatch(loadPermissionsUser());
      })
      .catch((error) =>
        dispatch(ACTIONS.PERMISSION_FILE.DOWNLOAD.ERROR.ACTION(error))
      );
  };
}

export function loadPermissionsUser() {
  let permission = JSON.parse(sessionStorage.getItem("USER_PERMISSION"));
  return function (dispatch) {
    dispatch(ACTIONS.PERMISSION_FILE.LOAD_PERMISSION.ACTION(permission));
  };
}

export function validateObjPerms(perms, obj, evalPerm = "") {
  let permission = perms;
  let valid = false;
  let permissionList = store.getState().globalCatalog.permissionUser?.data ?? [];
  if (
    permission !== undefined &&
    permissionList !== undefined &&
    Object.values(permissionList).length
  ) {
    valid = true;
    permission.forEach((item) => {
      if (item.length || 0) {
        if (evalPerm === "or") {
          valid = false;
          item.forEach((subItem) => {
            if (
              ((permissionList[subItem.id] || {})[subItem.perm] || false) ===
              true
            ) {
              valid = true;
            }
          });
        }
      } else {
        if (((permissionList[item.id] || {})[item.perm] || false) === false) {
          valid = false;
        }
      }
    });
  }

  if (valid) {
    return obj;
  } else {
    return {};
  }
}

export const SOCKET = {
  SET: {
    ACTION: (client) => ({
      type: "ACTIONS_SOCKET_SET",
      client,
    }),
  },
};

// export function loadPermissionsUser() {
//   return function(dispatch) {
//     let storeItems = store.getState().globalCatalog.permissionUser;
//     if (storeItems.length) {
//       return new Promise(resolve => resolve(storeItems));
//     } else {
//       let permission = sessionStorage.getItem("USER_PERMISSION");
//       if (permission !== null && permission.length) {
//         dispatch(setPermissionUsers);
//       } else {
//       }
//     }
//   };
// }

// export function loadPermissionsUser() {
//   console.log("yxed")
//   return "yxed";
//   // return function(dispatch) {
//   //   let storeItems = store.getState().globalCatalog.permissionUser;
//   //   if (storeItems.data.length) {
//   //     console.log("existe");
//   //     return new Promise(resolve => resolve(storeItems));
//   //   } else {
//   //     console.log("no existe");
//   //     let storage = sessionStorage.getItem("orbinetwork_session");

//   //     // if (!permission.length) {
//   //     //   let storage = sessionStorage.getItem("orbinetwork_session");
//   //     //   let params = {
//   //     //     // company_id: this.props.session.company.id,
//   //     //     company_id: "8",
//   //     //     user_id: storage.user.id
//   //     //   };

//   //     //   openSecureDoc(
//   //     //     interpolate(BACKEND.PERMISSIONS.GET_PERMISSION_FILE, params)
//   //     //   )
//   //     //     .then(doc => doc.json())
//   //     //     .then(response => {
//   //     //       sessionStorage.setItem(
//   //     //         "USER_PERMISSION",
//   //     //         JSON.stringify(response.content)
//   //     //       );
//   //     //     });
//   //     // }
//   //   }
//   // };
// }

// export function setPermissionsUser() {
//   let storage = sessionStorage.getItem("orbinetwork_session");
//   let params = {
//     // company_id: this.props.session.company.id,
//     company_id: "8",
//     user_id: storage.user.id
//   };

//   openSecureDoc(interpolate(BACKEND.PERMISSIONS.GET_PERMISSION_FILE, params))
//     .then(doc => doc.json())
//     .then(response => {
//       sessionStorage.setItem(
//         "USER_PERMISSION",
//         JSON.stringify(response.content)
//       );
//     });
//   // return function(dispatch) {
//   //   dispatch(ACTIONS.SESSION.CLEAR_SESSION.ACTION());
//   //   sessionStorage.clear();
//   // };
// }
