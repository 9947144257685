import React from 'react';
import { connect } from 'react-redux';
import Panel, { SubPanel } from '../shared/component/panel';
import SelectImage from '../shared/controls/select-image';
import InputProfileImage from '../shared/controls/input-profile-image';
import TextInput from '../shared/controls/text-input';
import SelectInput from '../shared/controls/select-input';
import {
  loadData,
  RECEIVE_COUNTRIES_,
  RECEIVE_STATES_,
  RECEIVE_CITIES_,
  getCompanyData,
  putCompany,
  RECEIVE_TYPES,
  EDIT_COMPANY_RESPONSE,
  EDIT_COMPANY_ERROR,
  postValidateDomain
} from '../redux/reducers/register-company.reducer';
import Button from '../shared/controls/button';
import ErrorDialog from '../shared/dialog/error-dialog';
import ConfirmDialog from '../shared/dialog/confirm-dialog';
import ReactTooltip from 'react-tooltip';
import { getUrlsEnv } from '../shared/backend-api';
import Checkbox from '../shared/controls/checkbox';
import { withRouter } from 'react-router-dom';
import { validators, validateWebPage } from '../shared/validate';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import InputCompany from '../shared/controls/input-company';
import UserChip from '../shared/controls/usermail-chip';
import { getEntityItems, partialEntity, readEntities, readEntity } from '../redux/api';
import Loader from '../shared/icons/tat/loader';

let profileImage;
let typingTimer;

// TODO: Sacar constantes a un archivo común
// TODO: Utilizar los catalogos que ya existen en el global reducer
// TODO: para los metodos que reciben mas de 4 parametros, utilizar objetos, evitar listas de parametros
// TODO: para elementos que se repiten en varias vistas, crear componentes (drag&drop)

const { files } = getUrlsEnv();

class CompanyData extends React.Component {

  constructor(props) {
    super(props);

    // profileImage = `${files}images/company/pictures/${this.props.match.params.company}.jpg?${Date.now()}`

    this.state = {
      typeAccount: props.typeAccount || null,
      companyName: props.companyName || '',
      idOrbi: props.idOrbi || this.props.match.params.company || '',
      webPage: '',
      postalCode: '',
      postalCodeId: 0,
      phone: '',
      address: '',
      country: 0,
      state: 0,
      city: 0,
      locality: 0,
      extNumber: '',
      intNumber: '',
      neighborhood: 0,
      profileImage: null,

      showError: false,
      emails: [],
      subscribe: false,
      onFocused: false,

      crop: {
        unit: '%',
        width: 300,
        aspect: 1 / 1,
      },
      editImage: false,
      imageToCrop: null,
      
      countryOptions: [],
      neighborhoodOptions: [],
      stateOptions: [],
      cityOptions: [],
      localityOptions: [],

      loading: false,
    }

  }

  isValid(url) {
    fetch(url).then(response => this.setState({ profileImage: response.status === 200 ? profileImage : null }))
  }

  componentDidMount() {
    // this.props.getCountries()
    this.props.loadCountries();
    this.props.getTypes();
    this.props.getCompanyData(this.state.idOrbi)
      .then(response => {
        const json = response.params;
        this.props.getCompany({
          company_id: json.id
        }, {
          onSuccess: (r) => {
            // let countryOptions = [{id: r.data?.country?.id, description: r.data?.country?.descripcion}];
            let neighborhoodOptions = [{id: r.data?.suburb?.id, description: r.data?.suburb?.nombre}];
            let stateOptions = [{id: r.data?.state?.id, description: r.data?.state?.descripcion}];
            let cityOptions = [{id: r.data?.city?.id, description: r.data?.city?.descripcion}];
            let localityOptions = [{id: r.data?.locality?.id, description: r.data?.locality?.descripcion}];

            this.setState({
              // countryOptions,
              neighborhoodOptions,
              stateOptions,
              cityOptions, 
              localityOptions,

              typeAccount: r.type || '',
              companyName: r.corporation_name || '',
              idOrbi: r.orbi_id || '',
              webPage: r.web_page || '',
              postalCode: r.data?.zip_code?.cp || '',
              postalCodeId: r.data?.zip_code?.id || 0,
              phone: r.phone || '',
              address: r.data?.street || '',
              country: r.data?.country?.id || 0,
              state: r.data?.state.id || 0,
              city: r.data?.city?.id || 0,
              locality: r.data?.locality?.id || 0,
              extNumber: r.data?.exterior_number || '',
              intNumber: r.data?.interior_number || '',
              neighborhood: r.data?.suburb?.id || 0,
            })
            profileImage = `${files}${r.logo}`
            this.isValid(profileImage)
          }
        });
        // this.setState({
        //   companyName: json.corporation_name || '',
        //   webPage: json.web_page || '',
        //   country: json.country || '',
        //   postalCode: json.postalcode || '',
        //   phone: json.phone || '',
        //   state: json.state || '',
        //   city: json.city || '',
        //   address: json.address || '',
        //   country: json.country || '',
        //   // subscribe: json.allow_request || '',
        //   // emails: (json.email && String(json.email).split(',').map(e => {
        //   //   return {
        //   //     email: e,
        //   //     avatar: '/images/mail.svg',
        //   //     avatarType: 'contained',
        //   //     userId: null,
        //   //     processed: true
        //   //   }
        //   // })) || [],
        //   emails: [],
        //   typeAccount: json.type || ''
        // }, () => {
        //   this.props.getStates(this.state.country);
        //   this.props.getCities(this.state.state);
        // });
        // this.isValid(`${files}${json.logo}`)
      })
  }

  isTypeSelected = typeId => {
    const { typeAccount } = this.state;
    return typeAccount && typeAccount === typeId;
  }

  handleClickAccountType = typeAccount => this.setState({ typeAccount });

  handlePicture = () => {
    this.refs.fileUploader.value = '';
    this.refs.fileUploader.click();
  }
  
  handleInput = (mails) => {
    this.setState({ emails: mails });
  }
  // handleEmailInput = (e) => {
  //   if(e){
  //     console.log(e.target.name);
      
  //       switch (e.target.name) {
  //           case 'inviteTags':
  //               e.target.value.filter(it => !it.processed).forEach(i => this.onEmail(i.email));
  //               break;
  //           case 'deleteTags':
  //               this.setState({ emails: [].concat(e.target.value) });
  //               break;
  //           default:
  //       }
  //   }     
  // }
  // onEmail = (email) => {
  //   this.props.postValidateDomain(String(email).toLowerCase()).then(user => {
  //       if (user.type === 'VALIDATE_DOMAIN_RESPONSE') {
  //           let tag = {
  //               email: email,
  //               avatar: '/images/mail.svg',
  //               avatarType: 'contained',
  //               userId: null,
  //               processed: true
  //           };
  //           // if (user.response.length > 0) {
  //             let user = {
  //               response: [
  //                 {id: "12"}
  //               ]
  //             }
  //               tag.avatar = user.response[0].profile_image ? getUrlsEnv().files.concat(user.response[0].profile_image) : '/images/mail.svg';
  //               tag.avatarType = user.response[0].profile_image ? 'full' : 'contained';
  //               tag.userId = user.response[0].id;
  //           // }
  //           this.setState({ emails: this.state.emails.concat(tag) });
  //       }
  //   })
  // }
  // deleteTag = (email) => {
  //   this.handleEmailInput({ target: { name: 'deleteTags', value: this.state.emails.filter(f => f.email !== email) } });
  // }

  handleAddress = (event) => {
    let property = event.target.name;
    let value = event.target.value;
    if (property === "idOrbi") {
      value = String(value).replace(/\s/g, '_')
      this.setState({ [property]: value })
    }else if (property === "postalCode") {
      this.setState({[property]: value}, () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(this.getZipCodeInfo, 1000);
      })
    } else if(property === "country"){
      this.setState({[property]: value}, () => {
        if(this.state.postalCode) this.getZipCodeInfo();
      })
    } else {
      this.setState({ [property]: value })
    }
  }

  getZipCodeInfo = () => {
    this.props.checkZipCode({
      country: this.state.country,
      cp: this.state.postalCode,
    }, {
      prefix: 'orbisat.',
      onSuccess: (result) => {
        if(result.length){
          // let countryOptions = [{id: result[0].pais_id, description: result[0].pais}];
          let neighborhoodOptions = result.map(r => ({id: r.colonia_id, description: r.colonia}))
          let stateOptions = [{id: result[0].estado_id, description: result[0].estado}];
          let cityOptions = [{id: result[0].municipio_id, description: result[0].municipio}];
          let localityOptions = [{id: result[0].localidad_id, description: result[0].localidad}];
          this.setState({
            // countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions, 

            // country: result[0].pais_id,
            neighborhood: result.length > 1 ? 0 : result[0].colonia_id,
            state: result[0].estado_id,
            city: result[0].municipio_id,
            locality: result[0].localidad_id,
            postalCodeId: result[0].cp_id,
          })
        } else {
          this.setState({
            // countryOptions: [],
            neighborhoodOptions: [],
            stateOptions: [],
            cityOptions: [],
            localityOptions: [],
            // country: 0,
            neighborhood: 0,
            state: 0,
            city: 0,
            locality: 0,
            postalCodeId: 0
          })
        }
      }
    })
  }

  // updateCity = (property, value) => {
  //   if (property === "country") {
  //     if (this.props.states.find(a => a.country === Number(value)) !== undefined) {
  //       const firstState = this.props.states.find(a => a.country === Number(value)).id
  //       this.setState({ state: firstState })

  //       if (this.props.cities.find(a => a.state === Number(firstState)) !== undefined) {
  //         const firstCity = this.props.cities.find(a => a.state === Number(firstState)).id
  //         this.setState({ city: firstCity })
  //       } else {
  //         this.setState({ city: -1 })
  //       }
  //     } else {
  //       this.setState({ state: -1 })
  //       this.setState({ city: -1 })
  //     }
  //   } else if (property === "state") {
  //     if (this.props.cities.find(a => a.state === Number(this.state.state)) !== undefined) {
  //       const firstCity = this.props.cities.find(a => a.state === Number(this.state.state)).id
  //       this.setState({ city: firstCity })
  //     } else {
  //       this.setState({ city: -1 })
  //     }
  //   }
  // }

  handleSave = () => {
    const { country, typeAccount, subscribe, city, locality, postalCode, webPage, phone, companyName, address, idOrbi, state, profileImage, emails, postalCodeId, neighborhood, extNumber, intNumber } = this.state;
    this.setState({loading: true})
    this.props.updateCompany({
      company_id: this.props.companyData.id,
      orbi_id: idOrbi,
      corporation_name: companyName,
      type: typeAccount,
      data: {
        street: address,
        suburb: neighborhood,
        state: state,
        country: country,
        exterior_number: extNumber,
        interior_number: intNumber || null,
        city: city,
        locality: locality || null,
        zip_code: postalCodeId 
      },
      phone: phone || null,
      web_page: (webPage && webPage !== "") ? (webPage.includes("https://") || webPage.includes("http://")) ? webPage : "https://" + webPage : ""
    }, {
      onSuccess: () => {
        if(profileImage && !String(profileImage).includes("http")){
          this.props.updateCompany({
            company_id: this.props.companyData.id,
            logo: profileImage
          }, {
            isForm: true,
            onSuccess: () => {
              this.setState({loading: false})
              this.props.history.goBack();
            },
            onError: () => {
              this.setState({ showError: true, msgError: "Comprueba los datos" });
            }
          })
        } else {
          this.setState({loading: false})
          this.props.history.goBack();
        }
      },
      onError: () => {
        this.setState({ showError: true, msgError: "Comprueba los datos" });
      }
    })
    // this.props.putCompany(country, typeAccount, subscribe, city, postalCode, (webPage && webPage !== "") ? webPage.includes("https://") ? webPage : "https://" + webPage : "", true, phone, companyName, address, idOrbi, state, profileImage, String(emails.map(e => e.email)))
    //   .then(
    //     response => {
    //       switch (response.type) {
    //         case EDIT_COMPANY_RESPONSE:
    //           this.props.history.goBack();
    //           break
    //         case EDIT_COMPANY_ERROR:
    //           if (Array.isArray(response.error.orbi_id) && response.error.orbi_id.length > 0) {
    //             this.setState({ showError: true, msgError: response.error.orbi_id[0] })
    //           } else if (Array.isArray(response.error.email) && response.error.email.length > 0) {
    //             this.setState({ showError: true, msgError: response.error.email[0] })
    //           } else {
    //             this.setState({ showError: true, msgError: "Comprueba los datos" });
    //           }
    //           break
    //         default:
    //           this.setState({ showError: true, msgError: "Falló la conexión" })
    //           break
    //       }
    //     }
    //   )
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  // handleSubscribe = e => {
  //   this.setState({ subscribe: !this.state.subscribe });
  // }

  actionCloseDialog = () => {
    this.setState({
      openDialog: false,
      showError: false
    });
  };

  dragAndDrop = e => {

    e.preventDefault()
    e.stopPropagation()
    let dt = e.dataTransfer
    let files = dt.files

    let pictureFrame = document.getElementsByClassName("pictureFrameFac")
    let pictureButton = document.getElementsByClassName("btnPicture")

    switch (e.type) {
      case "dragenter":
      case "dragover":
        pictureFrame[0].style.transform = "scale(1.1, 1.1)"

        pictureButton[0].style.color = "#3D77F7"
        pictureButton[0].style.border = "2px solid #ffffff"
        pictureButton[0].style.backgroundColor = "#ffffff"
        break
      default:
        pictureFrame[0].style.transform = "scale(1.0, 1.0)"

        pictureButton[0].style.color = "rgb(232, 240, 255)"
        pictureButton[0].style.border = "2px solid white"
        pictureButton[0].style.background = "none"

        // const profileImage = files[0];
        var reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({
            imageToCrop: reader.result,
            editImage: true
          });
        })
        reader.readAsDataURL(files[0]);

        // this.setState({
        //   profileImage,
        //   editImage: true
        // });
        break;
    }
  }

  isValidForm = () => {
  const { companyName, idOrbi, webPage, postalCode, address, country, state, city, typeAccount/*, emails*/ } = this.state;

    // if (webPage === null || webPage === '') {
      return (
        companyName !== '' 
        && companyName.length <= 45 
        && typeAccount !== null 
        && idOrbi !== '' 
        && postalCode !== '' 
        && address !== '' 
        && country !== 0 
        && state !== 0 
        && city !== 0
        && this.state.postalCodeId
        && this.state.extNumber
        && this.state.neighborhood
        && (!webPage || validateWebPage(webPage))
      ) /*&& emails !== null && emails !== ''*/;
    // } else {
    //   return companyName !== '' && companyName.length <= 45 && idOrbi !== '' && validateWebPage(webPage) && postalCode !== '' && address !== '' && country !== '' && state !== -1 && city !== -1 /*&& emails !== null && emails !== ''*/;
    // }

  }

  handleLeave = () => {
    this.setState({ onFocused: true })
  }

  getErrors = () => {
    let errors = ''

    if (this.state.companyName === '' ||
      this.state.idOrbi === '' ||
      this.state.postalCode === '' ||
      this.state.country === 0 ||
      this.state.address === '' ||
      this.state.extNumber === '' ||
      this.state.neighborhood === 0 ||
      this.state.typeAccount === null ||
      // this.state.emails === null ||
      // this.state.emails === '' || 
      (this.state.webPage !== null && this.state.webPage !== '' && !validateWebPage(this.state.webPage))) {
      errors = 'Por favor completa los siguientes campos:<br/>'
    }

    errors += this.state.companyName === '' ? `<b>Nombre de la empresa<b/><br/>` : ''
    errors += this.state.idOrbi === '' ? `<b>ID Orbinetwork<b/><br/>` : ''
    errors += this.state.country === 0 ? `<b>País<b/><br/>` : ''
    errors += this.state.postalCode === '' ? `<b>Codigo Postal<b/><br/>` : ''
    errors += this.state.address === '' ? `<b>Domicilio<b/><br/>` : ''
    errors += this.state.extNumber === '' ? `<b>Num Ext<b/><br/>` : ''
    errors += this.state.neighborhood === 0 ? `<b>Colonia<b/><br/>` : ''
    // errors += this.state.emails === null || this.state.emails === '' ? `<b>Dominio de correos<b/><br/>` : ''
    errors += this.state.typeAccount === null ? `<b>Tipo de empresa/tipo de cuenta<b/><br/>` : ''

    if(this.state.webPage !== null && this.state.webPage !== '' && !validateWebPage(this.state.webPage)){
      errors += `<b>"${this.state.webPage}"<b/> no es una página web válida<br/>`
    }

    if(this.state.companyName.length > 45){
      errors += `<b>El nombre de tu empresa es mayor a 45 caracteres<br/>`
    }

    return errors
  }

  validateCompanyName = (e) => {
    if(e.target.value.toLowerCase() !== (this.props.companyData?.corporation_name || '').toLowerCase()){
      this.props.validateCompanyName({
        corporation_name: e.target.value
      }, {
        onSuccess: () => {
        }, 
        onError: () => {
          this.setState({ showError: true, msgError: `${this.state.companyName} ya existe` })
        }
      })
    }
  }

  render() {
    // const stateList = this.props.states.filter(a => a.country === Number(this.state.country))
    // const cityList = this.props.cities.filter(a => a.state === Number(this.state.state))
    return (

      <div className='register'>

        <input accept="image/*" type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={event => {

          event.stopPropagation();
          event.preventDefault();
          // var profileImage = event.target.files[0];
          // this.setState({ profileImage });
          var reader = new FileReader();
          reader.addEventListener('load', () => {
            this.setState({
              imageToCrop: reader.result,
              editImage: true
            });
          })
          reader.readAsDataURL(event.target.files[0]);

        }} />

        <h1 className="title">Información de empresa</h1>

        <Panel title='Datos de tu empresa' number='01' className='panel'>

          <div className='form'>
            <div className='data'>

              <TextInput
                type='text'
                label='*Nombre de la empresa (Razón social)'
                name='companyName'
                dataTip="<ul><li>El nombre de tu empresa no debe exceder de <b>45 caracteres</b></li></ul>"
                dataFor="orbi-tooltip-company-name"
                className='full'
                value={this.state.companyName}
                onChange={this.handleAddress}
                validate={[validators.required, 'maxLength']}
                validationParam={45}
                onFocused={this.state.onFocused}
                handleBlur={this.validateCompanyName}
              />

              <ReactTooltip
                id={"orbi-tooltip-company-name"}
                place="right"
                type="light"
                effect="solid"
                html={true}
                event="focusin"
                eventOff="focusout"
              />

              <TextInput
                type='text'
                label='*ID Orbinetwork'
                name='idOrbi'
                dataTip="<ul><li>Tu Id Orbi debe ser una sola palabra, Si usas más de una palabra, los espacios se mostrarán como un guion bajo (ej. Mi_Empresa)</li><li>Asegúrate de no dejar espacios al final (ej. Mi_Empresa_)</li></ul>"
                dataFor="orbi-tooltip"
                disabled={true}
                value={this.state.idOrbi}
                onChange={this.handleAddress}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />

              <ReactTooltip
                id={"orbi-tooltip"}
                place="right"
                type="light"
                effect="solid"
                // style={{maxWidth: "380px", fontSize: "16px"}}
                html={true}
                event="focusin"
                eventOff="focusout"
              />

              <TextInput
                type='text'
                label='Página web de tu empresa (opcional)'
                name='webPage'
                className='full'
                value={this.state.webPage}
                onChange={this.handleAddress}
              />

              <SelectInput
                label='*País de origen (selecciona una opción)'
                className="full"
                name="country"
                // items={this.state.countryOptions}
                items={this.props.countries || []}
                value={this.state.country}
                onChange={this.handleAddress}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
                disabled={true}
              />

              <div className='formDivided'>
                <TextInput
                  type='number'
                  label='*Código Postal'
                  name='postalCode'
                  className='full'
                  value={this.state.postalCode}
                  onChange={this.handleAddress}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                  disabled={!this.state.country}
                />
                <TextInput
                  type='number'
                  label='Teléfono (opcional)'
                  name='phone'
                  className='full'
                  value={this.state.phone}
                  onChange={this.handleAddress}
                />

              </div>

              <div className='formDivided'>

                <SelectInput
                  label='*Selecciona estado'
                  className="full"
                  name="state"
                  // items={stateList}
                  items={this.state.stateOptions}
                  value={this.state.state}
                  onChange={this.handleAddress}
                  disabled={!this.state.postalCode}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />

                <SelectInput
                  label='*Selecciona Ciudad'
                  className="full"
                  name="city"
                  // items={cityList}
                  items={this.state.cityOptions}
                  value={this.state.city}
                  onChange={this.handleAddress}
                  disabled={!this.state.postalCode}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />

              </div>

              <div className='formDivided'>  
                {
                  this.state.locality 
                  ? <SelectInput
                    label='Localidad:'
                    // className="full"
                    value={this.state.locality}
                    name='locality'
                    items={this.state.localityOptions}
                    onChange={this.handleAddress}
                    disabled={!this.state.postalCode}
                  />
                  : ''
                }
                <TextInput
                  type='text'
                  label='*Domicilio'
                  name='address'
                  className='full'
                  value={this.state.address}
                  onChange={this.handleAddress}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>

              <div className='formDivided'>   
                <TextInput 
                  label='*Num Ext:'
                  type='text'
                  value={this.state.extNumber}
                  name='extNumber'
                  onChange={this.handleAddress}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
                <TextInput 
                  label='Num Int:'
                  type='text'
                  value={this.state.intNumber}
                  name='intNumber'
                  onChange={this.handleAddress}
                />   
              </div>

              <SelectInput
                label='Colonia:'
                // className="full"
                value={this.state.neighborhood}
                name='neighborhood'
                items={this.state.neighborhoodOptions}
                onChange={this.handleAddress}
                disabled={!this.state.postalCode}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />

              {/* <div className="text-input">
                <InputCompany
                  placeholder='Ingresa el o los dominios iniciando con "@" separados por "enter"'
                  className='field'
                  domain={true}
                  tags={this.state.emails}
                  onChange={this.handleEmailInput}
                />
              </div>
              <div className="tags-container">
                {this.state.emails.map((tag) => {
                  return (
                    <UserChip
                      mail={tag.email}
                      avatar={tag.avatar}
                      avatarType={tag.avatarType}
                      key={tag.email}
                      deleteTag={this.deleteTag}
                    />
                  )
                })}
              </div> */}

              <div style={{ margin: "35px 0px 0px 5px" }} />

              {/* <Checkbox
                name="subscribe"
                onChange={this.handleSubscribe}
                item={{
                  id: 1,
                  description: "Deseo recibir solicitudes de mis colaboradores",
                  active: this.state.subscribe,
                  style: true
                }}
              /> */}

              <div className='extraPadding' />

              <SubPanel title='Tipo de empresa' number='02' className="margin" ></SubPanel>

              <div className='companies'>
                {
                  this.props.types && this.props.types.length > 0 && this.props.types.filter(c => c.id === this.state.typeAccount).map((c, index) => (

                    <SelectImage
                      data={{
                        img: c.avatar_image,
                        name: c.description,
                        id: c.id
                      }}
                      key={index}
                      handleClick={() => { }}
                    />

                  ))
                }

              </div>

            </div>



            <InputProfileImage    
              dragAndDrop={this.dragAndDrop}
              handlePicture={this.handlePicture}
              handleSave={this.handleSave}
              profileImage={this.state.profileImage}
              caption="Dale clic al cuadro para agregar el logo de tu empresa, o bien, arrástralo desde tu computadora:"
              isFactory={true}
            />

          </div>

        </Panel>



        <div className="contentButton">
          <Button
            text={'Cancelar'}
            type={'secondary'}
            onClick={this.handleCancel}
          />
          <div className="separator" />
          <Button
            text={this.state.loading ? <Loader /> : "Guardar"}
            type={'primary'}
            disabled={!this.isValidForm() || this.state.loading}
            onClick={this.handleSave}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="left"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        <ConfirmDialog
          closeAction={this.closeImageEditModal}
          open={this.state.editImage}
          title={'Edita tu imagen'}
          message={'Edita tu imagen'}
          acceptText={'Guardar'}
          acceptAction={this.AcceptImageEdit}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          contentObject={
            <ReactCrop
              src={this.state.imageToCrop}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crossorigin='anonymous'
              style={{ maxHeight: '340px', overflow: 'auto', margin: '10px' }}
            />
          }
        />

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />
      </div>
    );

  }


  closeImageEditModal = () => {
    this.setState({ editImage: false })
  }
  AcceptImageEdit = () => {
    this.setState({
      profileImage: this.state.croppedImageUrl,
      editImage: false
    });
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  }
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  }
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        var f = new File([blob], 'image.jpeg');
        resolve(f);
      }, 'image/jpeg');
    });
  }

}

const mapStateToProps = state => {
  const { registerCompany: { /* countries = [], states = [],  cities = [],*/ types = [], isFetching, error } } = state;
  const companyData = getEntityItems(state, "COMPANIES.RETRIEVE");
  const countries = getEntityItems(state, "COMPANIES.SAT.ADDRESS.CATALOGS.COUNTRY")
    .map(c => ({id: c.id, description: c.descripcion}))
    .sort((a, b) => {if(a.description > b.description) return 1; if(a.description < b.description) return -1; return 0});
  return { types, countries, /* states, cities, */ isFetching, error, companyData };
}

const mapDispatchToProps = dispatch => ({
  loadCountries: () =>{
    dispatch(
      readEntities("COMPANIES.SAT.ADDRESS.CATALOGS.COUNTRY", {}, {prefix: 'orbisat.'}) 
    );
  },
  checkZipCode: (params, opt) =>{
    dispatch(
      readEntities("COMPANIES.SAT.ADDRESS.ZIPCODECHECKBYCOUNTRY", params, opt) 
    );
  },
  getCompany: (params, opt) =>{
    dispatch(
      readEntity("COMPANIES.RETRIEVE", params, opt) 
    );
  },
  updateCompany: (params, opt) =>{
    dispatch(
      partialEntity("COMPANIES.UPDATE", params, opt) 
    );
  },
  validateCompanyName: (params, opt) =>{
    dispatch(
      readEntity("COMPANIES.CREATE.VALIDATIONS.COMPANYNAME", params, opt) 
    );
  },
  getCountries: () => dispatch(loadData(RECEIVE_COUNTRIES_)),
  getStates: (country) => dispatch(loadData(RECEIVE_STATES_, country)),
  getCities: (state) => dispatch(loadData(RECEIVE_CITIES_, state)), 
  getTypes: () => dispatch(loadData(RECEIVE_TYPES)),
  getCompanyData: (orbi_id) => dispatch(getCompanyData({ orbi_id })),
  postValidateDomain: (email) => dispatch(postValidateDomain({ email })),
  putCompany: (country, type, allow_request, city, postalcode, web_page, visible, phone, corporation_name, address, orbi_id, state, logo, email) => dispatch(putCompany({ country, type, allow_request, city, postalcode, web_page, visible, phone, corporation_name, address, orbi_id, state, logo, email }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyData))