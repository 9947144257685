import React from "react";
import FleetNormativityRow from "../../shared/component/fleet/fleet-normativity-row";
import { getUrlsEnv } from "../../shared/backend-api";
import Button from "../../shared/controls/button";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  loadSession,
  removeToast,
  loadStatusDescriptions,
  setFleetCatalogTabSeleted,
} from "../../redux/reducers/global-catalog.reducer";
import SVGIcon, { icons } from "../../shared/svg-icon";
import {
  loadFleets,
  loadOperatorData,
  loadOperatorNormativities,
  loadImageOperatorNormativities,
  loadOperatorTypes,
  loadNormativityTypes,
  DATA_TYPES,
  patchNormativityOperatorData,
  loadOperatorTags,
  getNormativitiesChanges,
  loadShareOperatorData,
  loadShareOperatorNormativities,
  loadNormativitiesStatus,
  patchShareNormativityData,
} from "../../redux/reducers/fleet-reducer";
import { loadMobileUser } from "../../redux/reducers/register.reducer";
import { getLastSession, DISPLAY_FORMAT, LOG_FORMAT, isPremiumSubscriptionCo } from "../../shared/utils";
import { ToastContainer, toast } from "react-toastify";
import SelectOptionDialog from "../../shared/dialog/select-option-dialog";
import SelectInput from "../../shared/controls/select-input";
import Tag from "../../shared/controls/tag";
import ExceedPlus from "../../shared/controls/exceed-plus";
import TruckIcon from "../../shared/component/truck-icon";
import TruckIconStatus from "../../shared/component/truck-icon-status";
import ReactTooltip from "react-tooltip";
import ValidatePerms from "../../shared/component/validate-perms";
import { updateOperatorNormativityStatus } from "../../redux/reducers/fleet-reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import SubStringText from "../../shared/component/substring-text";
import { readEntity } from '../../redux/api/actions';
import styled from "styled-components";

class FleetOperatorProfile extends React.Component {
  constructor() {
    super();

    this.fleetLoaded = false;
    this.fleetOperatorTagsLoaded = false;

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };

    this.state = {
      isFreeSubscription: false,
      saveExit: false,
      showModal: false,
      rejectReason: 0,
      mounted: false,
      fleetShares: [],
      name: "",
      lastName: "",
      birthDate: "",
      age: "",
      email: "",
      typeCategory: "",
      phoneNumber: "",
      ine: "",
      rControl: "",
      companyName: "",
      avatar: "",
      companyAvatar: "",
      validated: 0,
      fleetTags: [],
      normativityUpdated: false,
      validations: [
        {
          title: "Constancia de registro de IMSS",
          title_icon: "/images/medRecords.svg",
          details: [
            {
              name: "Documento:",
              value: "",
              images: [],
            },
            {
              name: "IMSS:",
              value: "",
            },
          ],
        },
        {
          title: "Constancia de aptitud psicofisica",
          title_icon: "/images/heart.svg",
          details: [
            {
              name: "Documento:",
              value: "",
              images: [],
            },
            // {
            //   name: "Apto para operar",
            //   value: "",
            // },
          ],
        },
      ],
      rfcInfo: {
        details: [
          {
            name: 'RFC:',
            value: ''
          },
          {
            name: 'Calle:',
            value: ''
          },
          {
            name: 'Num ext:',
            value: ''
          },
          {
            name: 'Num int:',
            value: ''
          },
          {
            name: 'Colonia:',
            value: ''
          },
          {
            name: 'C.P.:',
            value: ''
          },
          {
            name: 'Localidad:',
            value: ''
          },
          {
            name: 'Municipio:',
            value: ''
          },
          {
            name: 'Estado:',
            value: ''
          },
          {
            name: 'País:',
            value: ''
          }
        ]
      },
      circulationCard: {
        title_icon: icons.normativityCard,
        documentLabel: "",
        details: [
          {
            name: "Documento: ",
            value: "",
            images: [],
          },
          {
            name: "No. de licencia:",
            value: "",
          },
          {
            name: "Antigüedad:",
            value: "",
          },
          {
            name: "Expediente médico:",
            value: "",
          },
          {
            name: "Centro de expedición SCT:",
            value: "",
          },
          {
            name: "Categoría:",
            value: "",
          },
          {
            name: "Último refrendo:",
            value: "",
          },
          // {
          //   name: "Tipo de examen médico",
          //   value: "",
          // },
          {
            name: "Último examen médico:",
            value: "",
          },
        ],
      },
    };
  }

  isNotAllowedDate = (date_string) => {
    return date_string === '01 de enero de 1900';
  }

  componentDidUpdate() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.operatorId &&
      this.props.match.params.operatorId > 0 &&
      this.props.fleet &&
      !this.loaded
    ) {
      this.loaded = true;

      const fleetId = this.getShareFleetId();

      if (!this.fleetOperatorTagsLoaded && this.props.match.params.operatorId) {
        this.props
          .loadOperatorTags(fleetId, this.props.match.params.operatorId)
          .then((data) => {
            const { response = [] } = data;
            let newTags = [];

            response.forEach((item) => {
              newTags.push(item);
            });

            this.setState({
              fleetTags: newTags,
            });
          });
        this.fleetOperatorTagsLoaded = true;
      }
      this.props.loadNormativitiesStatus(
        fleetId,
        this.props.match.params.operatorId,
        this.isShared() ? 2 : 3
      );
      (this.isShared()
        ? this.props.loadShareOperatorData(
            this.props.match.params.operatorId,
            fleetId
          )
        : this.props.loadOperatorData(
            this.props.match.params.operatorId,
            fleetId
          )
      ).then((data) => {
        const { response = {} } = data;
        const { files } = getUrlsEnv();

        const birth = String(response.birth_date).split("-") || "";

        this.setState({
          fleetShares: Array.isArray(response.network) ? response.network : [],
          rControl: response.r_control || "",
          birthDate: getLastSession(
            `${birth[1]}/${birth[2]}/${birth[0]}`,
            DISPLAY_FORMAT
          ),
          age: this.calculateAge(response.birth_date),
          typeCategory: response.licence_type ? `${response.licence_type}` : "",
          ableTo: response.fit_to_operate
            ? this.getLicenseType(response.fit_to_operate)
            : "",
          phoneNumber: response.mobile_phone || "",
          ine: response.identification || "",
          admissionDate: response.hire_date || "",
          companyName:
            (response.company || { corporation_name: "" }).corporation_name ||
            "",
          companyAvatar:
            (response.company || { logo: null }).logo !== null
              ? `${files}${response.company.logo}`
              : "" || "",
          validated: (response.status || { id: 0 }).id || 4,
        });

        this.props.loadMobileUser(response.user).then((mobileUserData) => {
          const { data = [] } = mobileUserData;

          var taxInfo = data.tax_info ? `${data.tax_info}` : '';

          this.setState({
            name: data.first_name || "",
            lastName: data.last_name || "",
            email: data.username || "",
            avatar:
              data.profile_image !== null
                ? `${files}${data.profile_image}`
                : "" || "",
          });

          if (taxInfo !== '') {
            this.props.loadTaxInfo({
              company_id: this.props.fleet?.company || 0,
              fleet_id: fleetId,
              id: response.user,
              tax_info_id: taxInfo
            }, {
              onSuccess: (result) => {
                this.setState({
                  rfcInfo: {
                    details: [
                      {
                        name: 'RFC:',
                        value: result?.data?.fiscal_number ?? ''
                      },
                      {
                        name: 'Calle:',
                        value: result?.data?.street ?? ''
                      },
                      {
                        name: 'Num ext:',
                        value: result?.data?.exterior_number ?? ''
                      },
                      {
                        name: 'Num int:',
                        value: result?.data?.interior_number ?? ''
                      },
                      {
                        name: 'Colonia:',
                        value: result?.data?.suburb?.nombre ?? ''
                      },
                      {
                        name: 'C.P.:',
                        value: result?.data?.zip_code?.cp ?? ''
                      },
                      {
                        name: 'Localidad:',
                        value: result?.data?.locality?.descripcion ?? ''
                      },
                      {
                        name: 'Municipio:',
                        value: result?.data?.city?.descripcion ?? ''
                      },
                      {
                        name: 'Estado:',
                        value: result?.data?.state?.descripcion ?? ''
                      },
                      {
                        name: 'País:',
                        value: result?.data?.country?.descripcion ?? ''
                      },
                      {
                        name: 'Referencia:',
                        value: result?.data?.reference?? ''
                      }
                    ]
                  }
                });
              }
            });
          }
          (this.isShared()
            ? this.props.loadShareOperatorNormativities(
                fleetId,
                this.props.match.params.operatorId
              )
            : this.props.loadOperatorNormativities(
                fleetId,
                this.props.match.params.operatorId
              )
          ).then((data) => {
            const { response = [] } = data;

            let licenseNumber = "";
            let expeditionDate = "";
            let expedition_center = "";
            // let licenseType = "";
            let preventiveMedicine = "";
            // let lastMedicalTestType = "";
            let vigencyLastMedicalTest = "";
            let endVigencyLastMedicalTest = "";
            let vigencyLastEndorsement = "";
            let endVigencyLastEndorsement = "";
            let medicalNumber = "";
            let medicalNumberExpedition = "";
            let normativityStatus1 = "";
            let normativityStatus2 = "";
            let normativityStatus3 = "";
            let shareId1 = "";
            let shareId2 = "";
            let shareId3 = "";
            let normId1 = "";
            let normId2 = "";
            let normId3 = "";

            response.forEach((item) => {
              switch (item.type) {
                case 1:
                  // console.log("yxed");
                  // console.log(item.licence_type);
                  // console.log("yxed");
                  // const licenses = this.props.typeCategoryCatalog.find(
                  //   (t) => String(t.id) === String(this.state.typeCategory)
                  // );
                  // const licenseTyp =
                  //   licenses !== undefined ? licenses.description : "";

                  licenseNumber = item.code || "";
                  expeditionDate =
                    getLastSession(item.expedition_date, DISPLAY_FORMAT) || "";
                  expedition_center = item.expedition_center || "";
                  // licenseType = licenseTyp || "";
                  // licenseType = item.licence_type || "";
                  vigencyLastEndorsement =
                    getLastSession(item.expedition, DISPLAY_FORMAT) || "";
                  endVigencyLastEndorsement =
                    getLastSession(item.expiration, DISPLAY_FORMAT) || "";
                  normativityStatus1 = item.status?.id || item.status || "";
                  shareId1 = item.shared_normativity_id || "";
                  normId1 = item.id || "";

                  this.props
                    .loadImageOperatorNormativities(
                      fleetId,
                      this.props.match.params.operatorId,
                      item.id
                    )
                    .then((normImage) => {
                      const images = normImage.response || [];
                      let filesToUload = [];

                      images.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });

                      let circulationCard = this.state.circulationCard;
                      circulationCard.details[0].images = images;
                      this.setState({ circulationCard });
                    });

                  break;

                case 2:
                  preventiveMedicine = item.code || "";
                  // lastMedicalTestType = item.expedition_center || "";
                  vigencyLastMedicalTest =
                    getLastSession(item.expedition, DISPLAY_FORMAT) || "";
                  endVigencyLastMedicalTest =
                    getLastSession(item.expiration, DISPLAY_FORMAT) || "";
                  normativityStatus2 = item.status?.id || item.status || "";
                  shareId2 = item.shared_normativity_id || "";
                  normId2 = item.id || "";

                  this.props
                    .loadImageOperatorNormativities(
                      fleetId,
                      this.props.match.params.operatorId,
                      item.id
                    )
                    .then((normImage) => {
                      const images = normImage.response || [];
                      let filesToUload = [];

                      images.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });

                      let validations = this.state.validations;
                      if (validations[1].details.length > 0)
                        validations[1].details[0].images = images;
                      this.setState({ validations });
                    });

                  break;

                case 3:
                  medicalNumber = item.code || "";
                  normativityStatus3 = item.status?.id || item.status || "";
                  shareId3 = item.shared_normativity_id || "";
                  normId3 = item.id || "";
                  medicalNumberExpedition =
                    getLastSession(item.expedition, DISPLAY_FORMAT) || "";

                  this.props
                    .loadImageOperatorNormativities(
                      fleetId,
                      this.props.match.params.operatorId,
                      item.id
                    )
                    .then((normImage) => {
                      const images = normImage.response || [];
                      let filesToUload = [];

                      images.forEach((item) => {
                        filesToUload.push({
                          name:
                            item.original_name + " (" + item.file_size + ")",
                          idNormativityImage: item.id,
                        });
                      });

                      let validations = this.state.validations;
                      validations[0].details[0].images = images;
                      this.setState({ validations });
                    });

                  break;

                default:
                  break;
              }
            });

            // Se construye los datos a mostrar de licencia

            let license_data = [
              {
                name: "No. de licencia:",
                value: licenseNumber,
              }
            ];

            if (!this.isNotAllowedDate(expeditionDate)) {
              license_data.push({
                name: "Antigüedad:",
                value: expeditionDate,
              })
            }

            license_data = license_data.concat([
              {
                name: "Centro de expedición SCT:",
                value: expedition_center,
              },
              {
                name: "Categoría:",
                value: this.state.typeCategory,
              }
            ]);

            if (!this.isNotAllowedDate(vigencyLastEndorsement)) {
              license_data.push({
                name: "Vigencia desde:",
                value: vigencyLastEndorsement,
              })
            }

            if (!this.isNotAllowedDate(endVigencyLastEndorsement)) {
              license_data.push({
                name: "Vigencia hasta:",
                value: endVigencyLastEndorsement,
              })
            }

            license_data = license_data.concat([
              {
                name: "Apto para operar:",
                value: this.state.ableTo,
              },
              {
                name: "Expediente médico:",
                value: preventiveMedicine,
              }
            ]);

            // Se construye los datos a mostrar de constancia de aptitude

            let norm_2_details = [];

            if (!this.isNotAllowedDate(vigencyLastMedicalTest)) {
              norm_2_details.push({
                name: "Vigencia desde:",
                value: vigencyLastMedicalTest,
              })
            }

            if (!this.isNotAllowedDate(endVigencyLastMedicalTest)) {
              norm_2_details.push({
                name: "Vigencia hasta:",
                value: endVigencyLastMedicalTest,
              })
            }

            this.setState({
              circulationCard: {
                normId: normId1,
                normativityStatus: normativityStatus1,
                title_icon: icons.normativityCard,
                shareId: shareId1,
                details: license_data,
                documentLabel: "Licencia:",
              },
              validations: [
                {
                  normId: normId3,
                  shareId: shareId3,
                  normativityStatus: normativityStatus3,
                  title: "Constancia de registro de IMSS",
                  title_icon: "/images/medRecords.svg",
                  details: this.isNotAllowedDate(medicalNumberExpedition) ? [
                    {
                      name: "IMSS:",
                      value: medicalNumber,
                    }
                  ] : [
                    {
                      name: "IMSS:",
                      value: medicalNumber,
                    },
                    {
                      name: "Fecha de alta:",
                      value: medicalNumberExpedition,
                    },
                  ],
                  documentLabel: "Constancia de registro de IMSS:",
                },
                {
                  normId: normId2,
                  shareId: shareId2,
                  normativityStatus: normativityStatus2,
                  title: "Constancia de aptitud psicofisica",
                  title_icon: "/images/heart.svg",
                  details: norm_2_details,
                  documentLabel: "Constancia de aptitud psicofisica:",
                },
              ],
            });
          });
        });
      });
    }

    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }

  componentDidMount() {
    this.props.setFleetTab("3");
    this.setState({ mounted: true });

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.unblock = this.props.history.block((targetLocation) => {
      if (this.state.normativityUpdated) {
        this.setState({ saveExit: true });
        this.props
          .updateOperatorNormativityStatus(
            this.isShared() ? this.props.operatorData.company.id : undefined,
            this.getShareFleetId(),
            this.props.operatorData.id
          )
          .then((r) => {
            this.setState(
              { saveExit: false, normativityUpdated: false },
              this.props.history.goBack()
            );
          });
      }
      return !this.state.normativityUpdated;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  getLicenseType = (type) => {
    switch (type) {
      case 1:
        return "Solo Sencillo";
      case 2:
        return "Solo Full";
      case 3:
        return "Ambos";
      case 4:
        return "Torton";

      default:
        return "";
    }
  };

  calculateAge = (birthDate) => {
    let age = "";
    if (String(birthDate) !== "") {
      const ageDifMs = Date.now() - new Date(birthDate);
      if (ageDifMs > 0) {
        const ageDate = new Date(ageDifMs);
        age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 1) {
          age = "";
        }
      }
    }

    return age;
  };

  handleRejectReason = (e) => {
    this.setState({ rejectReason: e.target.value });
  };

  handleModalClose = (e) => {
    if (e && this.state.rejectReason) {
      this.setState({ normativityUpdated: true });
      if (this.isShared()) {
        this.props
          .updateShareNormativityStatus(
            this.getShareFleetId(),
            this.props.match.params.operatorId,
            this.state.normativityId,
            {
              status: 3,
              reason: this.state.rejectReason,
              description: this.state.rejectReason,
            },
            2
          )
          .then((res) => {
            this.updateGlobalStatus(res);
            this.changeShareDocumentStatus(this.state.newEvent);
            const fleetId = this.getShareFleetId();
            this.props.loadNormativitiesStatus(
              fleetId,
              this.props.match.params.operatorId,
              this.isShared() ? 2 : 3
            );
          });
      } else {
        this.props
          .updateNormativityStatus(
            this.getShareFleetId(),
            this.props.match.params.operatorId,
            this.state.normativityId,
            {
              status: 3,
              reason: this.state.rejectReason,
              description: this.state.rejectReason,
            }
          )
          .then((res) => {
            this.updateGlobalStatus(res);
            this.changeDocumentStatus(this.state.newEvent);
            const fleetId = this.getShareFleetId();
            this.props.loadNormativitiesStatus(
              fleetId,
              this.props.match.params.operatorId,
              this.isShared() ? 2 : 3
            );
          });
      }
    }
    this.setState({
      showModal: false,
      rejectReason: 0,
    });
  };

  updateGlobalStatus = (data) => {
    if (data.type && data.type === "FLEET_NORMATIVITY_PATCH_SUCCESS") {
      const { response = { global_status: {} } } = data;
      const { global_status } = response;

      if (global_status.id) {
        this.setState({ validated: parseInt(global_status.id) });
      }
    }
  };

  updateNormativityStatus = (e) => {
    let imageExist = false;
    const { validations = [] } = this.state;

    switch (e.target.name) {
      case "Constancia de registro de IMSS":
        if (validations.length > 0) {
          const { details = [] } = validations[0];

          if (details.length > 0) {
            const { images = [] } = details[0];
            imageExist = images.length > 0;
          }
        }

        break;

      case "Constancia de aptitud psicofisica":
        if (validations.length > 1) {
          const { details = [] } = validations[1];

          if (details.length > 0) {
            const { images = [] } = details[0];
            imageExist = images.length > 0;
          }
        }

        break;

      case "Licencia":
        const { details = [] } = this.state.circulationCard;

        if (details.length > 0) {
          const { images = [] } = details[0];
          imageExist = images.length > 0;
        }

        break;

      default:
        break;
    }

    if (
      imageExist &&
      (e.target.value.toString() === "2" || e.target.value.toString() === "3")
    ) {
      if (e.target.value.toString() === "3" && this.state.mounted) {
        this.setState({
          showModal: true,
          normativityId: e.target.id,
          newEvent: e,
        });
      } else {
        this.setState({ normativityUpdated: true });
        if (this.isShared()) {
          this.setState({saveExit: true});
          this.props
            .updateShareNormativityStatus(
              this.getShareFleetId(),
              this.props.match.params.operatorId,
              e.target.id,
              { status: parseInt(e.target.value) },
              2
            )
            .then((res) => {
              this.setState({saveExit: false});
              this.updateGlobalStatus(res);
              this.changeShareDocumentStatus(e);
              const fleetId = this.getShareFleetId();
              this.props.loadNormativitiesStatus(
                fleetId,
                this.props.match.params.operatorId,
                this.isShared() ? 2 : 3
              );
            });
        } else {
          this.setState({saveExit: true});
          this.props
            .updateNormativityStatus(
              this.props.fleet.id,
              this.props.match.params.operatorId,
              e.target.id,
              { status: parseInt(e.target.value) }
            )
            .then((res) => {
              this.setState({saveExit: false});
              this.updateGlobalStatus(res);
              this.changeDocumentStatus(e);
              const fleetId = this.props.fleet ? this.props.fleet.id : "";
              this.props.loadNormativitiesStatus(
                fleetId,
                this.props.match.params.operatorId,
                this.isShared() ? 2 : 3
              );
            });
        }
      }
    }
  };

  changeDocumentStatus(e) {
    if (String(this.state.validations[0].normId) === String(e.target.id)) {
      let validations = this.state.validations;
      validations[0].normativityStatus = parseInt(e.target.value);
      this.setState({ validations });
    } else if (
      String(this.state.validations[1].normId) === String(e.target.id)
    ) {
      let validations = this.state.validations;
      validations[1].normativityStatus = parseInt(e.target.value);
      this.setState({ validations });
    } else if (
      String(this.state.circulationCard.normId) === String(e.target.id)
    ) {
      let circulationCard = this.state.circulationCard;
      circulationCard.normativityStatus = parseInt(e.target.value);
      this.setState({ circulationCard });
    }
  }

  changeShareDocumentStatus(e) {
    if (String(this.state.validations[0].shareId) === String(e.target.id)) {
      let validations = this.state.validations;
      validations[0].normativityStatus = parseInt(e.target.value);
      this.setState({ validations });
    } else if (
      String(this.state.validations[1].shareId) === String(e.target.id)
    ) {
      let validations = this.state.validations;
      validations[1].normativityStatus = parseInt(e.target.value);
      this.setState({ validations });
    } else if (
      String(this.state.circulationCard.shareId) === String(e.target.id)
    ) {
      let circulationCard = this.state.circulationCard;
      circulationCard.normativityStatus = parseInt(e.target.value);
      this.setState({ circulationCard });
    }
  }

  isShared = () => {
    const { search = "" } = this.props.history.location;
    const variables = search === "" ? [] : search.substr(1).split("&");
    const result = variables.length > 0 ? variables[0].split("=") : [];

    if (Array.isArray(result) && result.length > 1) {
      return result[1] === "1";
    } else {
      return false;
    }
  };

  getShareFleetId() {
    if (this.isShared()) {
      const { search = "" } = this.props.history.location;
      const variables = search === "" ? [] : search.substr(1).split("&");
      const result = variables > 1 ? [] : variables[1].split("=");

      if (Array.isArray(result) && result.length > 1) {
        return String(result[1]);
      } else {
        return "0";
      }
    } else {
      return this.props.fleet ? this.props.fleet.id : "";
    }
  }

  getShareStatusLogContent = (data, key) => {
    const results = this.getNormativitiesStatus(data);

    if (Array.isArray(results) && results.length > 0) {
      const item = results[0];

      return (
        <React.Fragment>
          <ReactTooltip
            className={"truck-tooltip"}
            id={"status-log".concat(String(key))}
            getContent={() =>
              React.Children.toArray([
                <TruckIconStatus
                  title={item.name}
                  image={item.imageIcon}
                  date={item.date}
                  statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
                  statusIcon={VALIDATED_ICONS[item.status]}
                />,
              ])
            }
            delayHide={200}
            // clickable={true}
            // delayShow={200}
            // delayUpdate={500}
            place={"right"}
            border={true}
            type={"light"}
          />

          <div data-tip data-for={"status-log".concat(String(key))}>
            <TruckIconStatus
              title={item.name}
              image={item.imageIcon}
              date={item.date}
              statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
              statusIcon={VALIDATED_ICONS[item.status]}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  };

  getStatusLogContent = (data, keyNormativity) => {
    return (
      <ExceedPlus
        maxVisibleItems={2}
        name={"status-".concat(`${keyNormativity}`)}
        onTooltipClassname="truck-tooltip"
      >
        {this.getNormativitiesStatus(data).map((item, id) => {
          return (
            <div key={keyNormativity}>
              <ReactTooltip
                className={"truck-tooltip"}
                id={"status-log".concat(item.name).concat(`${keyNormativity}`)}
                getContent={() =>
                  React.Children.toArray([
                    <TruckIconStatus
                      title={item.name}
                      image={item.imageIcon}
                      date={item.date}
                      statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
                      statusIcon={VALIDATED_ICONS[item.status]}
                    />,
                  ])
                }
                delayHide={200}
                // clickable={true}
                // delayShow={200}
                // delayUpdate={500}
                place={"right"}
                border={true}
                type={"light"}
              />

              <div
                data-tip
                data-for={"status-log"
                  .concat(item.name)
                  .concat(`${keyNormativity}`)}
              >
                <TruckIconStatus
                  title={item.name}
                  image={item.imageIcon}
                  key={id}
                  date={item.date}
                  statusLabel={`${VALIDATED_MESSAGE[item.status]} ${item.comment}`}
                  statusIcon={VALIDATED_ICONS[item.status]}
                />
              </div>
            </div>
          );
        })}
      </ExceedPlus>
    );
  };

  getNormativitiesStatus = (data) => {
    let n =
      this.props.fleetsStatusLog.filter((n) => {
        // if(!n || !n.object_id) return false;
        // const { object_id: { id = "" } = { id: "" } } = n;
        const id = n?.object_id?.id ?? 0;
        return String(id) === String(data);
      }) || [];

    let statusResults = [];
    let companyAdded = [];

    n.forEach((n) => {
      const {
        user = {},
        data_after: { id = 1 },
        company = {},
        log_date = "",
        comment = "",
      } = n;

      if (this.isShared()) {
        statusResults.push({
          imageIcon: company.logo || "",
          name:
            String(user.first_name || "") +
            " " +
            String(user.last_name || "") +
            " - " +
            String(company.orbi_id || ""),
          date: getLastSession(log_date, LOG_FORMAT),
          status: id,
          comment: comment || "",
        });
      } else {
        if (!companyAdded.includes(company.orbi_id)) {
          companyAdded.push(company.orbi_id);
          statusResults.push({
            imageIcon: company.logo || "",
            name:
              String(user.first_name || "") +
              " " +
              String(user.last_name || "") +
              " - " +
              String(company.orbi_id || ""),
            date: getLastSession(log_date, LOG_FORMAT),
            status: id,
            comment: comment || "",
          });
        }
      }
    });

    return statusResults;
  };

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return (
      <div className="operator-profile view">
        <ToastContainer className="global-toast-container" />

        <div className="left-column">
          <div className="credentials panel">
            <div className="upper-row">
              <div className="profile-information">
                <div className="avatars">
                  <img
                    alt=""
                    src={`${
                      this.state.avatar || "/images/Artboard.svg"
                    }`}
                    style={{ backgroundColor: "#FFF" }}
                  />
                  <img
                    alt=""
                    src={`${
                      this.state.companyAvatar || "/images/factory-bckgrnd.png"
                    }`}
                    className="company-logo"
                  />
                </div>
                <div className="edit">
                  {!this.isShared() && (
                    <div
                      className="edit-info"
                      onClick={() =>
                        this.props.history.push(
                          `/company/fleet/operator/${
                            this.props.match.params.operatorId || 0
                          }`
                        )
                      }
                    >
                      <ValidatePerms
                        perms={[
                          {
                            id: "58",
                            perm: "u",
                          },
                        ]}
                      >
                        <img alt="" src="/images/edit-transparent.svg" />
                      </ValidatePerms>
                    </div>
                  )}
                </div>
              </div>
              <SubStringText
                label={`${this.state.name} ${this.state.lastName}`}
                labelTag={"p"}
                labelSize={30}
                labelClass={"id"}
                tooltipClass={"tooltip-class-name"}
              />
              {/* <p className="id">
                {this.state.name} {this.state.lastName}
              </p> */}
              <p className="age">
                {this.state.birthDate} / {this.state.age} años
              </p>
              <p className="description">
                {`Usuario: ${this.state.email} / Cel: ${this.state.phoneNumber}`}
              </p>
              <p
                className={"validated ".concat(
                  VALIDATED_MESSAGE[this.state.validated]
                )}
              >
                {VALIDATED_MESSAGE[this.state.validated]}
              </p>
            </div>
            <div className="lower-row">
              <div>
                <p className="submodule-title">INE ó DNI:</p>
                <p>{this.state.ine}</p>
              </div>
              {/* <div>
                <p className="submodule-title">R Control:</p>
                <p>{this.state.rControl}</p>
              </div> */}
              <div>
                {this.isShared() ? (
                  <React.Fragment>
                    <p className="submodule-title">Propietario:</p>
                    <p>
                      {(this.props.operatorData || { owner_company: "" })
                        .owner_company || ""}
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p className="submodule-title">Compartido con:</p>
                    <div
                      style={{
                        height: "50px",
                        marginTop: "-15px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ExceedPlus
                        maxVisibleItems={3}
                        name={""}
                        onTooltipClassname="truck-tooltip"
                      >
                        {this.state.fleetShares.map((item, id) => {
                          return (
                            <React.Fragment>
                              <div
                                data-tip
                                data-for={"tooltip-truck-icon".concat(
                                  item.name
                                )}
                              >
                                <TruckIcon
                                  title={item.name}
                                  image={item.logo}
                                  key={id}
                                />
                              </div>
                              <ReactTooltip
                                className="truck-tooltip"
                                id={"tooltip-truck-icon".concat(item.name)}
                                // getContent={() => el}
                                clickable={true}
                                delayHide={600}
                                delayShow={200}
                                delayUpdate={500}
                                place={"bottom"}
                                // isCapture={true}
                                border={true}
                                type={"light"}
                                effect="solid"
                              >
                                {item.name}
                              </ReactTooltip>
                            </React.Fragment>
                          );
                        })}
                      </ExceedPlus>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          {!this.isShared() && (
            <div className="tags panel">
              <span className="submodule-title">
                Etiquetas:&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              {/* eslint-disable-next-line */}
              {this.state.fleetTags.map((tag) => {
                if (tag) {
                  return (
                    <Tag
                      key={tag.tags.id}
                      id={tag.tags.id}
                      title={tag.tags.title}
                      color={tag.tags.color}
                    />
                  );
                }
              })}
            </div>
          )}
          <div className="normativity-panel">
            {this.state.validations.map((item, i) => {
              // console.log("1", item);
              return (
                <FleetNormativityRow
                  title={item.title}
                  key={i}
                  images={(item.details.length > 0) ? item.details[0].images : ''}
                  iconElement={
                    <img
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        alignSelf: "center",
                        marginLeft: "30px",
                        marginRight: "30px",
                      }}
                      src={item.title_icon}
                    />
                  }
                  details={item.details}
                  onChangeStatus={this.updateNormativityStatus}
                  documentStatus={item.normativityStatus}
                  documentStatusLocked={
                    item.normativityStatus === 4 || item.normativityStatus === 5
                  }
                  normId={item.normId}
                  shareID={item.shareId}
                  exceedPlusDiv={
                    <div
                      style={{
                        width: "110px",
                        height: "45px",
                        marginTop: "15px",
                      }}
                    >
                      {this.isShared()
                        ? this.getShareStatusLogContent(item.normId, i)
                        : this.getStatusLogContent(item.normId, i)}
                    </div>
                  }
                  documentLabel={item.documentLabel}
                />
              );
            })}
          </div>
        </div>
        <div className="right-column">
          <div className="normativity-panel">
            <FleetNormativityRow
              title="Licencia"
              title_icon="/images/credential.svg"
              iconElement={
                <SVGIcon
                  name={this.state.circulationCard.title_icon}
                  fill="#98BCDF"
                  className="icon"
                />
              }
              details={this.state.circulationCard.details}
              images={this.state.circulationCard.details[0].images}
              onChangeStatus={this.updateNormativityStatus}
              documentStatus={this.state.circulationCard.normativityStatus}
              documentStatusLocked={
                this.state.circulationCard.normativityStatus === 4 ||
                this.state.circulationCard.normativityStatus === 5
              }
              shareID={this.state.circulationCard.shareId}
              normId={this.state.circulationCard.normId}
              exceedPlusDiv={
                <div
                  style={{ width: "110px", height: "45px", marginTop: "15px" }}
                >
                  {this.isShared()
                    ? this.getShareStatusLogContent(
                        this.state.circulationCard.normId,
                        999
                      )
                    : this.getStatusLogContent(
                        this.state.circulationCard.normId,
                        9999
                      )}
                </div>
              }
              documentLabel={this.state.circulationCard.documentLabel}
            />
            <div style={{height: 16, width: 40}}></div>
            <FleetNormativityRow
              documentStatus={null}
              title="RFC"
              title_icon="/images/credential.svg"
              iconElement={
                <SVGIcon
                  name={this.state.circulationCard.title_icon}
                  fill="#98BCDF"
                  className="icon"
                />
              }
              details={this.state.rfcInfo.details}
              images={[]}
              documentStatusLocked={false}
              documentLabel={''}
              isConsidered={false}
            />
          </div>
          <div className="actions">
            <Button
              onClick={() => this.props.history.goBack()}
              type={"primary"}
              text={"Finalizar"}
              name={"finish"}
            />
          </div>
        </div>
        <SelectOptionDialog
          open={this.state.showModal} 
          title={"Razón de rechazo"}
          message={"Señala la razón por la cual se rechaza el documento."}
          options={[]}
          acceptText={"Aceptar"}
          acceptAction={this.handleModalClose}
          showCancel={true}
          cancelText={"Cancelar"}
          closeAction={() =>
            this.setState({ showModal: false, rejectReason: 0 })
          }
          class="footer-buttons-position"
          contentObject={
            <SelectInput
              label={"Razón rechazo"}
              name="reject"
              value={this.state.rejectReason}
              items={this.props.rejectReasons}
              onChange={this.handleRejectReason}
            />
          }
        />
        <ConfirmDialog
          open={this.state.saveExit || false}
          title="Actualizando"
          disabledAccept={true}
        />
      </div>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {
  const fleets = state.fleetReducer.fleets.data;

  const {
    response: { company } = { company: {} },
  } = state.fleetReducer.operatorData;

  // console.log(
  //   "asdfasdfas: ",
  //   ((state.fleetReducer.normativities || {}).data || []).map((n) => {
  //     delete n.created;
  //     delete n.modified;
  //     delete n.visible;
  //     return n;
  //   })
  // );
  return {
    company,
    fleetsStatusLog: state.fleetReducer.normativityStatusLog.data || [],
    toast: state.globalCatalog.toast,
    fleet: Array.isArray(fleets) && fleets.length > 0 ? fleets[0] : null,
    sessionCompany: state.globalCatalog.session.company || {},
    operatorData: state.fleetReducer.operatorData.response,
    typeCategoryCatalog: Array.isArray(state.fleetReducer.operatorType.data)
      ? state.fleetReducer.operatorType.data
      : [],

    normativityTypes: (state.fleetReducer.normativityType.data || []).filter(
      (n) => n.type === DATA_TYPES.OPERATOR
    ),
    normativities: ((state.fleetReducer.normativities || {}).data || []).map(
      (n) => {
        delete n.created;
        delete n.modified;
        delete n.visible;
        return n;
      }
    ),
    rejectReasons: state.globalCatalog.statusDescription.data || [],
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadFleets());
  dispatch(loadOperatorTypes());

  dispatch(loadNormativityTypes());
  dispatch(loadStatusDescriptions());
  return {
    loadTaxInfo: (params, opt) => dispatch(readEntity("COMPANIES.SAT.TAXINFO.OPERATOR", params, opt)),
    loadMobileUser: (userId) => dispatch(loadMobileUser(userId)),
    loadShareOperatorData: (fleetId, operatorId) =>
      dispatch(loadShareOperatorData(fleetId, operatorId)),
    loadOperatorData: (fleetId, operatorId) =>
      dispatch(loadOperatorData(fleetId, operatorId)),
    loadOperatorNormativities: (fleetId, operatorId) =>
      dispatch(loadOperatorNormativities(fleetId, operatorId)),
    loadShareOperatorNormativities: (fleetId, operatorId) =>
      dispatch(loadShareOperatorNormativities(fleetId, operatorId)),
    loadImageOperatorNormativities: (fleetId, operatorId, normId) =>
      dispatch(loadImageOperatorNormativities(fleetId, operatorId, normId)),
    loadOperatorTags: (fleetId, operatorId) =>
      dispatch(loadOperatorTags(fleetId, operatorId)),
    removeToast: () => dispatch(removeToast()),
    updateNormativityStatus: (fleetId, operator_id, id, normativityDataObj) =>
      dispatch(
        patchNormativityOperatorData(
          fleetId,
          operator_id,
          id,
          normativityDataObj
        )
      ),
    updateShareNormativityStatus: (
      fleetId,
      fleetDataId,
      id,
      normativityDataObj,
      type
    ) =>
      dispatch(
        patchShareNormativityData(
          fleetId,
          fleetDataId,
          id,
          normativityDataObj,
          type
        )
      ),
    setFleetTab: (payload) => dispatch(setFleetCatalogTabSeleted(payload)),
    getNormativitiesChanges: (id) => dispatch(getNormativitiesChanges(id)),
    loadNormativitiesStatus: (fleet_id, fleet_data_id, type) =>
      dispatch(loadNormativitiesStatus(fleet_id, fleet_data_id, type)),
    updateOperatorNormativityStatus: (companyId, fleetId, dataId) =>
      dispatch(updateOperatorNormativityStatus(companyId, fleetId, dataId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FleetOperatorProfile)
);

const VALIDATED_MESSAGE = [
  "",
  "Pendiente",
  "Validado",
  "Rechazado",
  "Incompleto",
  "Vencido",
];

const VALIDATED_ICONS = [
  "",
  "/images/info_pendiente.svg",
  "/images/oval green.svg",
  "/images/interrogacion_red.svg",
  "/images/interrogacion.svg",
  "/images/interrogacion.svg",
];

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;