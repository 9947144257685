import * as React from "react";
import styled from "styled-components";
import RowD from "../../../shared/component/row-d";
import Tag from "../../../shared/controls/tag";
import ExceedPlus from "../../../shared/controls/exceed-plus";
import * as moment from "moment";
import "moment/locale/es";
import { FILES_SERVER } from "../../../redux/api";
import ReactTooltip from 'react-tooltip';
import GroupContainer from '../../../company/company/group-container';
import { StyledTag } from '../../../company/company/styled-tag';
import { ActionsTricolon } from '../../../shared/controls/actions-tricolon';
import CopyIcon from '../../../shared/icons/copy-icon';

class UnassignedOrderRow extends React.Component<Props, State> { 

  timerId = null;
  refText = {};
  tooltipsActivations = {};

  componentDidMount() {
    document.addEventListener('mousedown', this.handlePageContentScroll, false);
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    let keys = Object.keys(this.refText);

    for (let i = 0; i < keys.length; i++) {

      let refTemp = this.refText[keys[i]];
      let clientWidth = refTemp?.clientWidth ?? 0;
      let scrollWidth = refTemp?.scrollWidth ?? 0;

      if (clientWidth !== 0 && scrollWidth !== 0) {
        this.tooltipsActivations[keys[i]] = clientWidth !== scrollWidth;
      }

    }
    
  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
    document.removeEventListener('mousedown', this.handlePageContentScroll, false);
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && e.target.id.includes('container-eta')) {
      return;
    }
    ReactTooltip.hide();
  }

  componentDidUpdate(prep) {

    if (prep.columnsUpdate !== this.props.columnsUpdate) {
      let keys = Object.keys(this.refText);

      for (let i = 0; i < keys.length; i++) {

        let refTemp = this.refText[keys[i]];
        let clientWidth = refTemp?.clientWidth ?? 0;
        let scrollWidth = refTemp?.scrollWidth ?? 0;

        if (clientWidth !== 0 && scrollWidth !== 0) {
          this.tooltipsActivations[keys[i]] = clientWidth !== scrollWidth;
        }

      }
      
    }

  }
  
  redirectOrderProfile = () => {
    if (this.props.setOrderSelected) this.props.setOrderSelected();
    this.props.history.push(
      `/company/controls/shipment/orderdetail/${this.props.trip_id}?facilityId=${this.props.facilityId}`
    )
  }

  getDateFormat = (date) => {
    return (
      moment(date).format("D") +
      " " +
      moment(date)
        .format("MMM")
        .charAt(0)
        .toUpperCase() +
      moment(date)
        .format("MMM")
        .slice(1)
        .replace(".", "") +
      " " +
      moment(date).format("H:mm")
    );
  };

  getNormalDateFormat = (date) => {
    return (
      moment(date).format("D") +
      " " +
      moment(date)
        .format("MMM")
        .charAt(0)
        .toUpperCase() +
      moment(date)
        .format("MMM")
        .slice(1)
        .replace(".", "") +
        " " +
      moment(date).format("YYYY") 
    );
  };

  getVehicle = (row_id, id) => {
    if(!this.props.assembly || !this.props.assembly.vehicles) return '-';

    let vehicle = Object.values(this.props.assembly.vehicles)
      .flat()
      .map(v => v.economic_number);

    let plates = Object.values(this.props.assembly.vehicles)
      .flat()
      .map(v => v.plates);

    return(
      <>
        <ReactTooltip
          id={`vehicle-vehicle-${row_id}`}
          disable={ this.tooltipsActivations[`${id}-1`] ? !this.tooltipsActivations[`${id}-1`] : true }
        >
          <div>
          {vehicle.join(' | ')}
          </div>
        </ReactTooltip>
        <div
          ref={(c) => {this.refText[`${id}-1`] = c}}
          data-place="left"
          data-tip
          data-for={`vehicle-vehicle-${row_id}`}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >{vehicle.join(' | ')}</div>

        <ReactTooltip
          id={`vehicle-plates-${row_id}`}
          disable={ this.tooltipsActivations[`${id}-2`] ? !this.tooltipsActivations[`${id}-2`] : true }
        >
          <div>
          {plates.join(' | ')}
          </div>
        </ReactTooltip>
        <div
          ref={(c) => {this.refText[`${id}-2`] = c}}
          data-place="left"
          data-tip
          data-for={`vehicle-plates-${row_id}`}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >{plates.join(' | ')}</div>
      </>
    );
  }

  getOperators = (id) => {
    if(!this.props.assembly || !this.props.assembly.operators) return '-';

    let operator = Object.values(this.props.assembly.operators)
      .flat()
      .map(v => `${v.first_name || ''} ${v.last_name || ''}`);

    let nameToShow = operator.join(' | ');

    if (nameToShow === '') {
      return(
        <NameLine>{'-'}</NameLine>
      );
    } else {
      return(
        <>
          <ReactTooltip
            id={`operators-${this.props.id}`}
            disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
          >
            <div>
              {nameToShow}
            </div>
          </ReactTooltip>
          <div
            ref={(c) => {this.refText[id] = c}}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            data-place="left"
            data-tip
            data-for={`operators-${this.props.id}`}
          >{nameToShow}</div>
        </>
      );
    }

  }

  singleEventList = (title, events) => {

    let eventsList = [ 
      {
        id: 2,
        description: 'Inicio de viaje'
      },
      {
        id: 3,
        description: 'Llegada origen'
      },
      {
        id: 4,
        description: 'Entrada origen'
      },
      {
        id: 7,
        description: 'Salida origen'
      },
      {
        id: 11,
        description: 'Llegada destino'
      },
      {
        id: 12,
        description: 'Entrada destino'
      },
      {
        id: 15,
        description: 'Salida destino'
      }
    ];

    return(
      <div id = {`${title}-events`}>
        <div style={{marginBottom: 5, fontSize: 16, width: 190, display: 'flex', justifyContent: 'center'}}>{title}</div>
        {
          eventsList.map( (eventItem, index) => {
            return(
              <div
                id={`event-detail-${index}`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div>{eventItem.description}</div>
                <div>{this.searchEvent(eventItem.id, events)}</div>
              </div>
            );
          })
        }
      </div>
    );
  }

  searchEvent = (id, events) => {

    let dateString = '';

    events.map( item => {
      if (`${item.container_status_id}` === `${id}`) {
        dateString = `${moment(item.event_date).format("DD MMM HH:mm")}`;
      }
    });

    return dateString;

  }

  doubleEventList = (titles, events) => {
    return(
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {this.singleEventList(titles[0], events[0])}
        <div
          style={{
            width: 2,
            height: 'auto',
            backgroundColor: 'white',
            color: 'white',
            marginLeft: 20,
            marginRight: 20
          }}
        />
        {this.singleEventList(titles[1], events[1])}
      </div>
    );
  }

  getEventsSeperated = () => {

    let eventsList = [2,3,4,7,11,12,15];

    let economicEvents = [];

    Object.keys(this.props.containers_status).map( (e) => {

      let eventsToShow = [];

      (this.props.containers_status[`${e}`] ?? []).map( item => {
        if (eventsList.includes(item.container_status_id)) {
          eventsToShow = eventsToShow.concat(item);
        }
      });

      economicEvents.push(eventsToShow);

    });

    if (economicEvents.length === 1) {
      return({valid: true, economicEvents});
    } else {

      let hasDifferent = false;

      for (var i = 1; i < economicEvents.length; i++) {
        let currentEvents = economicEvents[i - 1] ?? [];
        let nextEvents = economicEvents[i] ?? [];

        if (currentEvents.length === 0 || nextEvents.length === 0 || nextEvents.length !== currentEvents.length) {
          hasDifferent = true;
        } else{
          currentEvents.map( currentEvent => {
            nextEvents.map( nextEvent => {
              if (currentEvent.container_status_id === nextEvent.container_status_id) {
                let diff = moment(currentEvent.event_date).diff(moment(nextEvents.event_date));

                if (parseInt(diff) > 59 && parseInt(diff) < -59) {
                  hasDifferent = true;
                }
              }
            });
          });
        }

      }

      return({valid: !hasDifferent, economicEvents});

    }
  
  }

  getEquipmentTest = (id) => {
    if(!this.props.assembly || !this.props.assembly.equipments) return '-';

    let economicsNumber = Object.values(this.props.assembly.equipments).flat().map(v => { return v.economic_number });

    return(
      <>
        {
          economicsNumber.length === 0 ? '-' :
          economicsNumber.map( (item, index) => {
            return(
              <>
                <ReactTooltip
                  id={`economic-${index}-${this.props.id}`}
                  disable={ this.tooltipsActivations[`${id}-2`] ? !this.tooltipsActivations[`${id}-${index}`] : true }
                >
                  <div>
                    {item}
                  </div>
                </ReactTooltip>
                <div
                  ref={(c) => {this.refText[`${id}-${index}`] = c}}
                  id={`economic-${index}-${this.props.id}`}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  data-place="left"
                  data-tip
                  data-for={`economic-${index}-${this.props.id}`}
                >{item}</div>
              </>
            );
          })
        }
      </>
    );  
  }

  getEquipment = () => {
    if(!this.props.assembly || !this.props.assembly.equipments) return '-';

    let vehicle = Object.values(this.props.assembly.equipments)
      .flat()
      .map(v => { return {economic: v.economic_number, id: v.fleet_data_id}});

    return(
      <>
        {
          vehicle.map( (item, index) => {

            let eventsToShow = [];

            if (this.props.containers_status !== null) {
              Object.keys(this.props.containers_status).map( (e, i) => {
                if (`${item.id}` === `${e}`) {
                  eventsToShow = this.props.containers_status[`${e}`];
                }
              });
            }

            return(
              <>
                <ReactTooltip
                  id={`equipment-tooltip-${index}-${this.props.id}`}
                >
                  <div 
                    style={{
                      width: 300,
                      textAlign: 'center',
                    }}
                  >
                    {
                      (eventsToShow || []).length === 0 ? <div>{'No existe eventos registrados'}</div> :
                      (eventsToShow || []).map( item => {
                        return(
                          <div>{`${item.container_status__description}: ${moment(item.event_date).format("DD MMM HH:mm")}`}</div>
                        );
                      })
                    }
                  </div>
                </ReactTooltip>
                <div 
                  key={`equipment-${index}`}
                  data-place="left"
                  data-tip
                  data-for={`equipment-tooltip-${index}-${this.props.id}`}
                >
                  {item.economic}
                </div>
              </>
            );
          })
        }
      </>
    );
  }

  getContainerStatusDescription = (statusId) => {
    let status = this.props.containerStatusCatalog.find(s => s.id === statusId);
    if(!status) return '-';
    return status.event_description;
  }

  getStatusFromContainerStatus = (status, row_id, id) => {

    let statusToShow = [];

    if (status) {
      status.map( item => {
        if (!statusToShow.includes(item.status)) {
          statusToShow.push(item.status);
        }
      });
      // Object.entries(status).map( ([key, value]) => {
      //   if (value && value.length) {
      //     let statusId = value[value.length - 1].container_status_id;
      //     if (!statusToShow.includes(statusId)) {
      //       statusToShow.push(statusId);
      //     }
      //   }
      // });
    }

    if (statusToShow.length == 1) {

      return(
        <>
          <ReactTooltip
            id={`estatus-equipo-${row_id}-1`}
            disable={ this.tooltipsActivations[`${id}-1`] ? !this.tooltipsActivations[`${id}-1`] : true }
          >
            <div>
            {this.getContainerStatusDescription(statusToShow[0])}
            </div>
          </ReactTooltip>
          <div 
            ref={(c) => {this.refText[`${id}-1`] = c}}
            id={'status-1'}
            data-place="left"
            data-tip
            data-for={`estatus-equipo-${row_id}-1`}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {this.getContainerStatusDescription(statusToShow[0])}
          </div>
        </>
      );

    } else {

      return(
        <>
          {statusToShow.map(c => (
            <>
              <ReactTooltip
                id={`estatus-equipo-${row_id}-${c}`}
                disable={ this.tooltipsActivations[`${id}-${c}`] ? !this.tooltipsActivations[`${id}-${c}`] : true }
              >
                <div>
                {this.getContainerStatusDescription(c)}
                </div>
              </ReactTooltip>
              <div 
                ref={(r) => {this.refText[`${id}-${c}`] = r}}
                key={c}
                data-place="left"
                data-tip
                data-for={`estatus-equipo-${row_id}-${c}`}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {this.getContainerStatusDescription(c)}
              </div>
            </>
          ))}
        </>
      );

    }

  }

  getStatus = (status) => {
    
    if (status.length > 1) {

      let firstStatus = status[0].status;
      let isEqualStatus = true;

      status.map( item => {
        if (item.status !== firstStatus) {
          isEqualStatus = false;
        }
      });

      if (isEqualStatus) {
        return(
          <div id={'status-1'}>
            {this.getContainerStatusDescription(firstStatus)}
          </div>
        );
      }

    } 

    return(
      <>
        {status.map(c => (
          <div key={c.id}>
            {this.getContainerStatusDescription(c.status)}
          </div>
        ))}
      </>
    );

  }

  setTimeValue = (times) => {

    let timesToShow = [];

    times.map( totalSeconds => {
      let timeToShow = '';

      if (totalSeconds > 0) {

        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        timeToShow = `${hours < 10 ? `0${hours}` : `${hours}`}:${ minutes < 10 ? `0${minutes}` : `${minutes}`}:${ seconds < 10 ? `0${seconds}` : `${seconds}`}`;

      } else {
        timeToShow = '-';
      }

      timesToShow = timesToShow.concat(timeToShow);
    })

    return timesToShow;

  }

  getGroupdEvents = () => {
    
    return this.getEventsSeperated(); 
  }

  getInitialTrip = (eventsGroup, eventId, row_id, id) => {
    let itemsToShow = [];
  
    (eventsGroup.economicEvents || []).map( item => {
  
      (item || []).map( item2 => {
        if ((eventsGroup.valid && itemsToShow.length === 0) || !eventsGroup.valid) {
          if (`${item2.container_status_id}` === `${eventId}`) {
            if(itemsToShow.length){//Validacion para casos especiales, container_fleet_equipment = null
              let diff = moment(itemsToShow[0].event_date).diff(moment(item2.event_date));
              if (parseInt(diff) > 59 && parseInt(diff) < -59) {
                itemsToShow.push(item2);
              }
            } else {
              itemsToShow.push(item2);
            }
          }
        }
      });
  
    });
  
    if (itemsToShow.length === 0 ) {
      return '-';
    } else {
      return(
        <div>
          {
            itemsToShow.map( (item, i) => 
              <>
                <ReactTooltip
                  id={`new-event-${row_id}-${eventId}-${i}`}
                  disable={ this.tooltipsActivations[`${id}-${i}`] ? !this.tooltipsActivations[`${id}-${i}`] : true }
                >
                  <div>
                    {`${moment(item.event_date).format("DD MMM HH:mm")}`}
                  </div>
                </ReactTooltip>
                <div
                  ref={(c) => {this.refText[`${id}-${i}`] = c}}
                  data-place="left"
                  data-tip
                  data-for={`new-event-${row_id}-${eventId}-${i}`}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >{`${moment(item.event_date).format("DD MMM HH:mm")}`}</div>
              </>
            )
          }
        </div>
      );
    }
    
  }

  getTimeStatus = (row_id, id) => {

    let statusId = `${this.props.status?.id ?? ''}`;

    if (statusId !==  '7') {
      return('-');
    }

    let timesToShow = [];

    if (this.props.containerTimeStatus && this.props.containerTimeStatus[`${this.props.id}`]) {

      let timeInSeconds = [];

      Object.keys(this.props.containerTimeStatus[`${this.props.id}`]).map( (e, i) => {
        let totalSeconds = parseInt(this.props.containerTimeStatus[`${this.props.id}`][`${e}`]);
        timeInSeconds = timeInSeconds.concat(totalSeconds);
      });

      if (timeInSeconds.length > 1) {
        let difference = timeInSeconds[0] - timeInSeconds[1];

        if (difference > -60 && difference < 60) {
          timesToShow = this.setTimeValue([timeInSeconds[0]]);
        } else {
          timesToShow = this.setTimeValue(timeInSeconds);
        }

      } else {
        timesToShow = this.setTimeValue(timeInSeconds);
      }

    }

    return(
      <>
        {timesToShow.map( (item, i) => {
          return(
            <>
              <ReactTooltip
                id={`tiempo-estatus-${row_id}-${i}`}
                disable={ this.tooltipsActivations[`${id}-${i}`] ? !this.tooltipsActivations[`${id}-${i}`] : true }
              >
                <div>
                  {item}
                </div>
              </ReactTooltip>
              <div
                ref={(c) => {this.refText[`${id}-${i}`] = c}}
                data-place="left"
                data-tip
                data-for={`tiempo-estatus-${row_id}-${i}`}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {item}
              </div>
            </>
          );
        })}
      </>
    );

  }

  getEtaLoading = (id) => {

    let timesToShow = [];

    (this.props.order_containers || []).map( (container, i) => {
      let ataDate = container?.ata_loading_date ?? '';
      let etaDate = container?.eta_loading ?? '';

      let timeToValidate = ataDate === '' ? etaDate : ataDate;

      let isEta = ataDate === '';

      let appointment = this.props.appointment_loading_date;

      if (timeToValidate === '' || !isEta) {
        timesToShow.push(
          <div style={{marginLeft: 5, overflow: 'hidden', textOverflow: 'ellipsis'}}>{'-'}</div>
        );
      } else {

        let isRedColor = false;

        if (appointment !== '') {
          isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
        }

        let eta_loading_data = container.eta_loading_data ?? null;
        let data_to_show = null;

        if (eta_loading_data !== null && isEta) {
          data_to_show = <>
            <div id={'container-eta'}>{`Creación de ETA: ${`${moment(eta_loading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}`}</div>
            <div id={'container-eta'} style={{display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'}>{'Coordenada:'}</div>
              <div
                id={'container-eta'}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: 5
                }}
                onClick={() => {
                  let points = (eta_loading_data?.from ?? '').split(',');
                  if (points.length > 1) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                  }
                }}
              >{` ${eta_loading_data?.from ?? ''}`}</div>
            </div>
            <div id={'container-eta'}>{`Distancia: ${eta_loading_data?.distance ?? ''} kms`}</div>
            <div id={'container-eta'}>{`Placa ETA: ${eta_loading_data?.plates ?? ''}`}</div>
            <div style={{maxWidth: 350}} id={'container-eta'}>{`Placas Ensamble: ${ (eta_loading_data?.plates_history ?? []).join(', ')}`}</div>
            <div id={'container-eta'}>{`Dispositivo: ${eta_loading_data?.device ?? ''}`}</div>
          </>
        }

        let is_visible = this.tooltipsActivations[`${id}-${i}`] ? !this.tooltipsActivations[`${id}-${i}`] : true;

        timesToShow.push(
          <>
            <ReactTooltip
              id={`eta-origen-${container.container_id}-${i}`}
              disable={ eta_loading_data !== null ? false : is_visible }
              clickable={true}
              event="click"
            >
              {
                !is_visible && <div>{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
              }
              { data_to_show && data_to_show }
            </ReactTooltip>
            <div 
              ref={(c) => {this.refText[`${id}-${i}`] = c}}
              data-place="left"
              data-tip
              data-for={`eta-origen-${container.container_id}-${i}`}
              style={{marginLeft: 5, color: isRedColor ? 'red' : 'white', overflow: 'hidden', textOverflow: 'ellipsis'}}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
            >{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
          </>
        );

      }

    });

    return(timesToShow);

  }

  getEtaUnloading = (id) => {

    let timesToShow = [];

    (this.props.order_containers || []).map( (container, i) => {
      
      let ataDate = container?.ata_unload_date ?? '';
      let etaDate = container?.eta_unloading ?? '';

      let timeToValidate = ataDate === '' ? etaDate : ataDate;

      let isEta = ataDate === ''; 

      let appointment = this.props.appointment_unload_date;

      if (timeToValidate === '' || !isEta) {
        timesToShow.push(
          <div style={{marginLeft: 5, overflow: 'hidden', textOverflow: 'ellipsis'}}>{'-'}</div>
        );
      } else {

        let isRedColor = false;

        if (appointment !== '') {
          isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
        }

        let eta_unloading_data = container.eta_unloading_data ?? null;
        let data_to_show = null;

        if (eta_unloading_data !== null && isEta) {
          data_to_show = <>
            <div id={'container-eta'}>{`Creación de ETA: ${`${moment(eta_unloading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}`}</div>
            <div id={'container-eta'} style={{display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'}>{'Coordenada:'}</div>
              <div
                id={'container-eta'}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: 5
                }}
                onClick={() => {
                  let points = (eta_unloading_data?.from ?? '').split(',');
                  if (points.length > 1) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                  }
                }}
              >{` ${eta_unloading_data?.from ?? ''}`}</div>
            </div>
            <div id={'container-eta'}>{`Distancia: ${eta_unloading_data?.distance ?? ''} kms`}</div>
            <div id={'container-eta'}>{`Placa ETA: ${eta_unloading_data?.plates ?? ''}`}</div>
            <div id={'container-eta'} style={{maxWidth: 350}}>{`Placas Ensamble: ${ (eta_unloading_data?.plates_history ?? []).join(', ')}`}</div>
            <div id={'container-eta'}>{`Dispositivo: ${eta_unloading_data?.device ?? ''}`}</div>
          </>
        }

        let is_visible = this.tooltipsActivations[`${id}-${i}`] ? !this.tooltipsActivations[`${id}-${i}`] : true;

        timesToShow.push(
          <>
            <ReactTooltip
              id={`eta-destino-${container.container_id}-${i}`}
              disable={ eta_unloading_data !== null ? false : is_visible }
              clickable={true}
              event="click"
            >
              {
                !is_visible && <div>{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
              }
              { data_to_show && data_to_show }
            </ReactTooltip>
            <div 
              ref={(c) => {this.refText[`${id}-${i}`] = c}}
              data-place="left"
              data-tip
              data-for={`eta-destino-${container.container_id}-${i}`}
              style={{marginLeft: 5, color: isRedColor ? 'red' : 'white', overflow: 'hidden', textOverflow: 'ellipsis'}}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
            >{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
          </>
        );

      }

    });

    return(
      <div> 
        { timesToShow }
      </div>
    );

  }

  getEstimatedTime = (eta, ata, appointment) => {  
    let ataDate = ata?.ata ?? '';
    let etaDate = eta?.eta ?? '';

    let timeToValidate = ataDate === '' ? etaDate : ataDate;

    let isEta = ataDate === ''
    let trailerInfo = isEta ? eta : ata;
  
    if (timeToValidate === '' || !isEta) {
  
      return(
        <div style={{display: 'flex', flexDirection: 'row', fontSize: 11, color: 'white'}}>
          <div style={{marginLeft: 5}}>{'-'}</div>
        </div>
      );
  
    } else {

      let isRedColor = false;

      if (appointment !== '') {
        isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
      }
  
      let coords = trailerInfo.coords || [];

      return(
        <>
          <ReactTooltip
            id={`eta-tooltip-${this.props.id}`}
          >
            <div 
              style={{
                textAlign: 'left',
              }}
            >
              <div>{`Creación: ${`${moment(trailerInfo.created || '').format("DD MMMM HH:mm")} hr`}`}</div>
              <div>{`Placas: ${trailerInfo.plates || ''}`}</div>
              <div>{`Coordenada: (${coords.length > 1 ? coords[0] : ''}, ${coords.length > 1 ? coords[1] : ''})`}</div>
            </div>
          </ReactTooltip>
          <div
            data-place="left"
            data-tip
            data-for={`eta-tooltip-${this.props.id}`}
            style={{display: 'flex', flexDirection: 'row', fontSize: 11}}
          >
            {/* <div style={{color: 'white'}}>{isEta ? 'ETA: ' : 'ATA: '}</div> */}
            <div style={{marginLeft: 5, color: isRedColor ? 'red' : 'white'}}>{`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}</div>
          </div>
        </>
      );
  
    }
  
  }

  getLastConnection = (id) => {

    if (this.props.last_connection) {

      let dateToShow = `${moment(this.props.last_connection.date).format("DD MMM HH:mm")}`;

      return(
        <>
          <ReactTooltip
            id={`last_connection-${this.props.id}-${id}-1`}
            disable={ this.tooltipsActivations[`${this.props.id}-${id}-1`] ? !this.tooltipsActivations[`${this.props.id}-${id}-1`] : true }
          >
            <div>
              {dateToShow}
            </div>
          </ReactTooltip>
          <div
            ref={(c) => {this.refText[`${this.props.id}-${id}-1`] = c}}
            data-place="left"
            data-tip
            data-for={`last_connection-${this.props.id}-${id}-1`}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {dateToShow}
          </div>
          <ReactTooltip
            id={`last_connection-${this.props.id}-${id}-2`}
            disable={ this.tooltipsActivations[`${this.props.id}-${id}-2`] ? !this.tooltipsActivations[`${this.props.id}-${id}-2`] : true }
          >
            <div>
              {this.props.last_connection.device}
            </div>
          </ReactTooltip>
          <div
            ref={(c) => {this.refText[`${this.props.id}-${id}-2`] = c}}
            data-place="left"
            data-tip
            data-for={`last_connection-${this.props.id}-${id}-2`}
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {this.props.last_connection.device}
          </div>
        </>
      );
    } else {
      return '-';
    }
  }

  getItemUsingIdConfig = (id, args) => {

    const props = this.props;
    const assignAction = props.actions.find(a => a.type.includes("assign"));
    const changeAction = props.actions.find(a => a.type.includes("change"));

    const events = args.events || [];

    const aliasLT = this.props.secondary_company?.secondary_alias ?? '';

    switch(id) {
      case 26:
        return(
          <Text>{this.getLastConnection(id)}</Text>
        );
      case 25: 
        return(
          <Text>{this.getOperators(id)}</Text>
        );
      case 1: 
        return(
          <>
            <ReactTooltip
              id={`orden-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
                {this.props.code}
              </div>
            </ReactTooltip>
            <Text
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`orden-${this.props.id}`}
              style={{ cursor: "pointer", width: 'fit-content' }}
              onClick={this.redirectOrderProfile}
            >
              {this.props.code}
            </Text>
            {
              this.props.code !== "" ?
              <CopyIcon className="copy-icon-style" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(this.props.code);}}/> : ""
            }
          </>
        );

      case 2: 
        return(
          <>
            <ReactTooltip
              id={`viaje-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
                {this.props.signature}
              </div>
            </ReactTooltip>
            <Text
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`viaje-${this.props.id}`}
              style={{ cursor: "pointer", width: 'fit-content' }}
              onClick={this.redirectOrderProfile}
            >
              {this.props.signature}
            </Text>
            {
              this.props.signature !== "" ?
              <CopyIcon className="copy-icon-style" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(this.props.signature);}}/> : ""
            }
          </>
        );

      case 3:
        return(
          <>
            <ReactTooltip
              id={`client-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
              { this.props.fecha_programacion ? `${this.getNormalDateFormat(this.props.fecha_programacion)}` : '-'}
              </div>
            </ReactTooltip>
            <Text
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`client-${this.props.id}`}
            >
              { this.props.fecha_programacion ? `${this.getNormalDateFormat(this.props.fecha_programacion)}` : '-'}
            </Text>
          </>
        );
      case 4:
        return(
          <CompanyAvatar>
            <img
              src={
                this.props.companyType === 1 
                ? (this.props.from_company?.logo ? FILES_SERVER.concat(this.props.from_company?.logo) : "/images/interrogacion.svg")
                : (this.props.secondary_company?.secondary_logo ? FILES_SERVER.concat(this.props.secondary_company?.secondary_logo) : "/images/interrogacion.svg")
              }
              alt=""
            />
            <ReactTooltip
              id={`transportista-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
              {
                this.props.companyType === 1 
                ? (this.props.from_company?.orbi_id || "-")
                : aliasLT !== '' ? aliasLT : (this.props.secondary_company?.secondary_orbi_id || "-")
              }
              </div>
            </ReactTooltip>
            <Text 
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`transportista-${this.props.id}`}
              numberOfLine
            >{
              this.props.companyType === 1 
              ? (this.props.from_company?.orbi_id || "-")
              : aliasLT !== '' ? aliasLT : (this.props.secondary_company?.secondary_orbi_id || "-")
            }</Text>
          </CompanyAvatar>
        );
      
      case 5:
        return(
          <>
            <CompanyAvatar>
              <img src={this.props.from_company?.logo ? FILES_SERVER.concat(this.props.from_company?.logo) : "/images/interrogacion.svg"} alt="" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden'
                }}
              >
                <ReactTooltip
                  id={`from-facility-${this.props.id}`}
                  disable={ this.tooltipsActivations[`${id}-1`] ? !this.tooltipsActivations[`${id}-1`] : true }
                >
                  <div>
                    {this.props.from_facility?.name ?? ''}
                  </div>
                </ReactTooltip>
                <NameLine
                  ref={(c) => {this.refText[`${id}-1`] = c}}
                  data-place="left"
                  data-tip
                  data-for={`from-facility-${this.props.id}`}
                >{this.props.from_facility?.name ?? ''}</NameLine>
              

                <ReactTooltip
                  id={`from-facility-2-${this.props.id}`}
                  disable={ this.tooltipsActivations[`${id}-2`] ? !this.tooltipsActivations[`${id}-2`] : true }
                >
                  <div>
                  {this.props.appointment_loading_date ? this.getDateFormat(this.props.appointment_loading_date) : "Pendiente"}
                  </div>
                </ReactTooltip>

                <div
                  style={{
                    display: 'flex',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <NameLine
                    ref={(c) => {this.refText[`${id}-2`] = c}}
                    data-place="left"
                    data-tip
                    data-for={`from-facility-2-${this.props.id}`}
                  >
                    {this.props.appointment_loading_date ? this.getDateFormat(this.props.appointment_loading_date) : "Pendiente"}
                  </NameLine>
                  {props.detail.origin.editable &&
                    (props.detail.origin.date
                    ? (
                      changeAction &&
                      (
                        <ActionsTricolon
                          tooltipPlacement={"right"}
                          actions={props.actions
                            .filter((f) => f.type.includes("cancel") || f.type.includes("change"))
                            .map((a) => ({
                              ...a,
                              additional:{
                                ticket: props.detail.ticket,
                                maneuver: {
                                  ...props.detail.origin,
                                  maneuver: "Carga",
                                  ticket: props.detail.ticket,
                                  orderId: props.detail.id,
                                }
                              },
                            }))
                          }
                        />
                      )
                    )
                    : (
                      assignAction &&
                      <img 
                        alt="" 
                        src="/images/edit-transparent.svg" 
                        class="edit-image"
                        style={{width: 20, height: 20, cursor: 'pointer'}}
                        onClick={() => {
                            assignAction.action({
                              ticket: props.detail.ticket,
                              maneuver: {
                                ...props.detail.origin,
                                maneuver: "Carga",
                                ticket: props.detail.ticket,
                                orderId: props.detail.id,
                              }
                            })
                          }
                        }
                      ></img>
                    ))
                  }
                </div>
              </div>
            </CompanyAvatar> 
          </>
        );
      case 17:   
        return(
          <Text>
            { this.getEtaLoading(id) }
          </Text>
        );

      case 6: 
        return(
          <>
            <CompanyAvatar>
              <img src={this.props.to_company?.logo ? FILES_SERVER.concat(this.props.to_company?.logo) : "/images/interrogacion.svg"} alt="" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden'
                }}
              >
                <ReactTooltip
                  id={`to-facility-${this.props.id}`}
                  disable={ this.tooltipsActivations[`${id}-1`] ? !this.tooltipsActivations[`${id}-1`] : true }
                >
                  <div>
                    {this.props.to_facility?.name ?? ''}
                  </div>
                </ReactTooltip>
                <NameLine
                  ref={(c) => {this.refText[`${id}-1`] = c}}
                  data-place="left"
                  data-tip
                  data-for={`to-facility-${this.props.id}`}
                >{this.props.to_facility?.name ?? ''}</NameLine>
                <ReactTooltip
                  id={`to-facility-2-${this.props.id}`}
                  disable={ this.tooltipsActivations[`${id}-2`] ? !this.tooltipsActivations[`${id}-2`] : true }
                >
                  <div>
                    {this.props.appointment_unload_date ? this.getDateFormat(this.props.appointment_unload_date) : "Pendiente"}
                  </div>
                </ReactTooltip>
                
                <div
                  style={{
                    display: 'flex',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <NameLine
                    ref={(c) => {this.refText[`${id}-2`] = c}}
                    data-place="left"
                    data-tip
                    data-for={`to-facility-2-${this.props.id}`}
                  >
                    {this.props.appointment_unload_date ? this.getDateFormat(this.props.appointment_unload_date) : "Pendiente"}
                  </NameLine>
                  {props.detail.destination.editable &&
                    (props.detail.destination.date
                    ? (
                      changeAction &&
                      (
                        <ActionsTricolon
                          tooltipPlacement={"right"}
                          actions={props.actions
                            .filter((f) => f.type.includes("cancel") || f.type.includes("change"))
                            .map((a) => ({
                              ...a,
                              additional:{
                                ticket: props.detail.ticket,
                                maneuver: {
                                  ...props.detail.destination,
                                  maneuver: "Descarga",
                                  ticket: props.detail.ticket,
                                  orderId: props.detail.id,
                                }
                              },
                            }))
                          }
                        />
                      )
                    )
                    : (
                      assignAction &&
                      <img 
                        alt="" 
                        src="/images/edit-transparent.svg" 
                        class="edit-image"
                        style={{width: 20, height: 20, cursor: 'pointer'}}
                        onClick={() => {
                            assignAction.action({
                              ticket: props.detail.ticket,
                              maneuver: {
                                ...props.detail.destination,
                                maneuver: "Descarga",
                                ticket: props.detail.ticket,
                                orderId: props.detail.id,
                              }
                            })
                          }
                        }
                      ></img>
                    ))
                  }
                </div>
              </div>
            </CompanyAvatar>
            </>
        );

      case 21:
        return(
          <Text>
            {this.getEtaUnloading(id)}
          </Text>
        );

      case 7: 
        return(
          <div>
            <ExceedPlus maxVisibleItems={1} name="license">
              {(this.props.tags || []).map((f, i) => (
                <Tag {...f} key={i} />
              ))}
            </ExceedPlus>
          </div>
        );

      case 8:
        return(
          <Text>{this.getVehicle(this.props.id, id)}</Text>
        );

      case 9: 
        return(
          <Text>{this.getEquipmentTest(id)}</Text>
        );

      case 10:
        return(
          <Text>
            {/* { this.getStatusFromContainerStatus(this.props.containers_status ?? {}, this.props.id, id) } */}
            { this.getStatusFromContainerStatus(this.props.order_containers ?? {}, this.props.id, id) }
            {/* { this.getStatus(this.props.order_containers ?? []) } */}
          </Text>
        );
      
      case 11:
        return(
          <Text>
            {this.getTimeStatus(this.props.id, id)}
          </Text>
        );

      case 12: 
        return(
          <div style={{ lineHeight: "15px" }}>
            {
              this.props.transport_class ? 
              <GroupContainer
                title={'-'}
              >
                <StyledTag
                  id={this.props.transport_class.id}
                  title={this.props.transport_class.code}
                  onClick={() => { }}
                />
              </GroupContainer> : <div/>
            }
          </div>
        );

      case 13:
        return(
          <>
            <ReactTooltip
              id={`estatus-order-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
                {`${ this.props.status?.event_description ?? '-' }`} 
              </div>
            </ReactTooltip>
            <Text
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`estatus-order-${this.props.id}`}
            >{`${ this.props.status?.event_description ?? '-' }`}</Text>
          </>
        );

      case 14:
        return(
          <>
            <ReactTooltip
              id={`nivel-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
                {this.props.priority || '-'}
              </div>
            </ReactTooltip>
            <Text
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`nivel-${this.props.id}`}
            >{this.props.priority || '-'}</Text>
          </>
        );

      case 15:
        return(
          <>
            <ReactTooltip
              id={`tipo-${this.props.id}`}
              disable={ this.tooltipsActivations[id] ? !this.tooltipsActivations[id] : true }
            >
              <div>
                {this.props.transport_type?.description || '-'}
              </div>
            </ReactTooltip>
            <Text
              ref={(c) => {this.refText[id] = c}}
              data-place="left"
              data-tip
              data-for={`tipo-${this.props.id}`}
            >{this.props.transport_type?.description || '-'}</Text>
          </>
        );
      case 16:
        return(
          <Text>{this.getInitialTrip(events, 2, this.props.id, id)}</Text>
        );
      case 18:
        return(
          <Text>{this.getInitialTrip(events, 3, this.props.id, id)}</Text>
        );
      case 19:
        return(
          <Text>{this.getInitialTrip(events, 4, this.props.id, id)}</Text>
        );
      case 20:
        return(
          <Text>{this.getInitialTrip(events, 7, this.props.id, id)}</Text>
        );
      case 22:
        return(
          <Text>{this.getInitialTrip(events, 11, this.props.id, id)}</Text>
        );
      case 23:
        return(
          <Text>{this.getInitialTrip(events, 12, this.props.id, id)}</Text>
        );
      case 24:
        return(
          <Text>{this.getInitialTrip(events, 15, this.props.id, id)}</Text>
        );

      default: break;
    }

  }


  render() {

    const eventsToShow = this.getGroupdEvents();

    return(
      <RowD {...this.props}>
        {
          (this.props.tableConfig?.columns ?? []).filter( item => (item.idConfig || 1000) > 0 ).map( item => this.getItemUsingIdConfig(item.idConfig || 1000, {events: eventsToShow}))
        }

        {/* <div>
          {
            props.actions
            .filter((f) => f.type.includes("extra") || f.type.includes("xtra-".concat(props.id))).length 
            ? <ActionsTricolon
              tooltipPlacement={"left"}
              actions={props.actions
                .filter((f) => f.type.includes("extra"))
                .map((a) => ({
                  ...a,
                  additional: props.ticket,
                })).concat(props.actions
                  .filter((f) => f.type.includes("xtra-".concat(props.id)))
                  .map((a) => ({
                    ...a,
                    additional: props.ticket,
                  })))}
            />
            :
            ''
          }
        </div> */}
      </RowD>
    )
  }
}

export default UnassignedOrderRow;


const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 6px;
  }
`;

const Name = styled.div`
  align-self: center;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 135px;
`;

const NameLine = styled.div`
  align-self: center;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 135px;
  white-space: nowrap;
`;

const Text = styled.div`
  align-self: center;
  width: 100%;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
