
import * as React from "react";
import { ViewTitle, View } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import { connect } from "react-redux";
import RadioInput from "../../shared/controls/radio-input";
import UnassignedOrdersBoard from "../shipment/unassigned/unassigned-orders-board";
import { cancelRequest, getEntityItems, readCatalog, readEntities, getEntityCount, createEntity, readEntity } from "../../redux/api";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import Loader from '../../shared/icons/tat/loader';
import { SERVER } from "../../redux/api/api-urls";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import TripControlColumnsSettingsModal from '../../shared/dialog/trip-controls-availability-columns-settings-modal';
import { getDefaultOrder, transformIdArrays, reorderArray, initialOrder} from "../shipment/detail/trips-control-configuration";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import { getTimeZone } from "../../shared/utils";

let timer;

class AssembleShipmentOrdersList extends React.Component {

  state={
    isLoadingTrips: false,
    textSearch: "",
    searchType: "1",
    getTripsCalled: false,
    buttonFilterName: "Aplicar",
    offset: 1,
    tempOffset: 1,
    limit: 100,
    filterParams: {
      status: [],
      orderStatus: [],
      transportClass: [],
      fleet_providers: [],
      from_facilities: [],
      to_facilities: [],
      from_to_facilities: [],
      appointment: [],
      appointment_type: [],
      vehicle: [],
      baseTags: []
    },
    searchStr: "",
    emails: [],
    vehiclesToShow: [],
    downloadingFile: false,
    isShowSettingsModal: false,
    columnsHidden: [],
    columnsData: getDefaultOrder(true, []),
    categories: [],
    tripControlCategories: [],
    isLoadingCategories: true,
    refreshSelected: null,
    order: []
  }

  scrollPosition = {horizontal: 0, vertical: 0};

  componentDidMount() {
    document.addEventListener("keydown", this.cancelSearch)
    this.props.setNavbarAction("assemblies-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

    if(this.props.configuration?.filterParams){

      this.setState({
          ...this.props.configuration
      })
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // debugger;
    let base = urlParams.get('base');
    let orders = urlParams.get('orders');

    let statusToShow = ['1', '2', '3', '4', '5', '6', '7', '10', '12'];

    if(base) {
      base = base.split(",");
      this.setState({filterParams: {
        ...this.state.filterParams,
        status: ["1", "5", "8", "3,4,10"],
        orderStatus: statusToShow,
        baseTags: base,
        ...this.props.configuration?.filterParams || {}
      }})
    } else {
      let fleet_provider = urlParams.get('fleet_provider');
      if(fleet_provider){
        fleet_provider = fleet_provider.split(",");
        this.setState({filterParams: {
          ...this.state.filterParams,
          status: ["1", "5", "8", "3,4,10"],
          fleet_providers: fleet_provider,
          ...this.props.configuration?.filterParams || {}
        }})
      } else {
        let urlFacilities = urlParams.get('facilities');
        if(urlFacilities) urlFacilities = urlFacilities.split(",")
        this.setState({filterParams: {
          ...this.state.filterParams,
          status: ["1", "5", "8", "3,4,10"],
          from_to_facilities: urlFacilities || [],
          ...this.props.configuration?.filterParams || {}
        }});
      }
      //   console.log("LOADING ORDERS");
      //   // debugger;
      
      if(this.props.tabFilters){
        this.setState({
          filterParams: {
            ...this.state.filterParams,
            ...this.props.tabFilters,
            ...this.props.configuration?.filterParams || {}
          }
        })
      }

    }

    this.props.loadFilterCategories({ company_id: this.props.companyId, filter_type: 2, company_type: this.props.companyType }, {
      onSuccess: (response) => {
        this.setState({
          tripControlCategories: response && response.length ? response : [],
          //columnsData: getDefaultOrder(false, response && response.length ? response : [])
        }, () => { this.loadUserFilters() });
      }
    });

    this.props.getContainerStatus();

    this.props.listVehicles(
      {
        company_id: this.props.companyId
      },
      {
        onSuccess: (vehiclesData) => {
          let newIndex = 9999999999;
          let vehiclesToShow = [];

          for (let i = 0; i < vehiclesData.length; i++) {

            let dataEconomic = vehiclesData[i];
            let vehicles = (dataEconomic.economic_numbers || []).map( (f) => ({
              id: f.id || 0,
              description: f.economic_number || '',
              isDisabled: !f.is_assembled || false,
              disableMessage: 'Camion sin ensamble'
            }));

            if (vehicles.length > 0) {
              vehiclesToShow.push({
                id: newIndex,
                description: `${dataEconomic.title || ''}`,
                items: vehicles
              });
              newIndex = newIndex - 1;
            }

          }

          this.setState({
            vehiclesToShow
          });

          this.loadOrders();
        },

        onError: (error) => {
          this.loadOrders();
        }
      }
    );

  }

  componentWillUnmount() {
    clearTimeout(timer);
    this.props.removeNavbarAction("assemblies-control");
    this.props.setBaseConfig({ ...this.state, resetPaginator: false });
    document.removeEventListener("keydown", this.cancelSearch)
  }

  loadUserFilters = () => {

    this.props.loadUserFilter({ company_id: this.props.companyId, filter_type: 2 }, {
      onSuccess: (response) => {

        this.state.isLoadingCategories = false;

        if (response.filter && response.filter.autorefresh_option) {
          this.state.refreshSelected = response.filter.autorefresh_option;
        }

        if (response.filter && response.filter.columnsHidden && response.filter.columnsHidden.length) {
          this.state.columnsHidden = transformIdArrays(response.filter.columnsHidden);
        }

        if (response.filter && response.filter.columns && response.filter.columns.length && this.state.tripControlCategories.length && this.state.tripControlCategories.length === response.filter.columns.length) {
          let array = reorderArray(this.state.tripControlCategories, response.filter.columns);
          
          this.state.columnsData = getDefaultOrder(true, array);
        } else {
          this.state.columnsData = initialOrder(getDefaultOrder(true, this.state.tripControlCategories));
          this.state.columnsHidden = [18, 19, 20, 21, 22, 23, 24];
        }

        this.setState({}, () => {
          this.setTimer();
        });
      }
    });

  }

  getRealHiddenColumns = () => {
    let realIds = [];

    this.state.columnsHidden.map( item => {

      this.state.columnsData.map( item2 => {
        if (item === item2.id) {
          realIds.push(item2.realId);
        }
      });

    });

    return realIds;
  }

  saveUserFilter = () => {
    let filterToSave = {
      company_id: this.props.companyId,
      type: 2,
      filter: {
        columns: this.state.columnsData.map( item => item.realId),
        columnsHidden: this.getRealHiddenColumns(),
        autorefresh_option: this.state.refreshSelected
      },
      company_user: this.props.userData
    };

    this.props.saveFilter(filterToSave);
  }

  setTimer = () => {

    if (this.state.refreshSelected) {

      let timeToSchedule = 0;

      switch (this.state.refreshSelected) {
        case '1' : timeToSchedule = 60000; break;
        case '2' : timeToSchedule = 180000; break;
        case '3' : timeToSchedule = 300000; break;
        case '4' : timeToSchedule = 600000; break;
      }

      clearTimeout(timer);

      timer = setTimeout(() => {
        this.loadOrders();
      }, timeToSchedule);

    } else {
      clearTimeout(timer);
    }

  }

  handleEmailInput = (e) => {
    if (e) {
      switch (e.target.name) {
        case "inviteTags":
          let mails = e.target.value
            .map((i) => ({
              email: i.email,
              avatar: "hide",
              avatarType: "",
              userId: null,
            }));
          this.setState({ 
            emails: mails, 
            searchStr: e.target.value.map(e => e.email), 
            offset: 1,
            buttonFilterName: "Aplicar"
          }, this.loadOrders);
          break;
        case "deleteTags":
          this.setState({ emails: [].concat(e.target.value), searchStr: e.target.value.map(e => e.email), offset: 1 }, this.loadOrders);
          break;
        default:
      }
    }
  };

  ShipmentsFilters = (
    status = [],
    providers = [],
    vehicles = []
  ) => {
    let statusCondensed = status.filter(s => !s.description.toLowerCase().includes("reservación confirmada"));
    let combined = status.filter(s => s.description.toLowerCase().includes("reservación confirmada")).map(s => s.id);
    statusCondensed.push({id: combined.join(), description: "Reservación confirmada"});
    // debugger;

    let dateTypes = [
      {
        id: "1",
        description: "Carga"
      },
      {
        id: "2",
        description: "Descarga"
      },
      {
        id: "3",
        description: "Programa"
      },
      {
        id: "4",
        description: "Programa y cita de carga"
      },
    ];

    if ((this.state.filterParams?.from_facilities ?? []).length > 0 && (this.state.filterParams?.to_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "1",
          description: "Carga"
        },
        {
          id: "2",
          description: "Descarga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    } else if ((this.state.filterParams?.from_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "1",
          description: "Carga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    } else if ((this.state.filterParams?.to_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "2",
          description: "Descarga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    }

    if ((this.state.filterParams?.from_to_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "1",
          description: "Carga"
        },
        {
          id: "2",
          description: "Descarga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    }

    return [
      {
        title: "Base",
        items: this.props.companyTags.filter((f) =>
          f.type === 3
            ? true
            : false
        ),
        name: "baseTags",
        listType: 1,
      },
      {
        title: "Vehículo",
        items: vehicles,
        name: "vehicle",
        listType: 5,
      },
      {
        title: "Clase",
        items: this.props.transportClass,
        name: "transportClass",
        listType: 5,
      },
      // {
      //   title: "Estatus viaje",
      //   items: statusCondensed,
      //   name: "status",
      //   listType: 5,
      // },
      {
        title: "Estatus orden",
        items: this.props.orderStatus,
        name: "orderStatus",
        listType: 5,
      },
      {
        title: "Estatus equipo",
        items: this.props.containerStatusCatalog,
        name: "containerStatus",
        listType: 5,
      },
      {
        title: "Cliente",
        items: providers,
        name: "fleet_providers",
        listType: 5,
      },
      {
        title: "Origen",
        items:  this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "from_facilities",
        listType: 5,
      },
      {
        title: "Destino",
        items:  this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "to_facilities",
        listType: 5,
      },
      {
        title: "Origen/Destino",
        items:  this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "from_to_facilities",
        listType: 5,
      },
      {
        title: "Fecha",
        name: "appointment",
        listType: 7,
        time: true
      },
      {
        title: "Tipo fecha",
        items: dateTypes,
        name: "appointment_type",
        listType: 5,
      }
    ].filter( item => {
      if ((this.state.filterParams?.from_facilities ?? []).length > 0 || (this.state.filterParams?.to_facilities ?? []).length > 0) {
        return item.title !== 'Origen/Destino';
      } else if ((this.state.filterParams?.from_to_facilities ?? []).length > 0) {
        return !(item.title === 'Origen' || item.title === 'Destino');
      } else {
        return true;
      }
    });
  };

  loadOrders = () => {
    this.props.cancelRequest("COMPANIES.FACILITIES.COMMANDCONTROLLT");
    this.setState({ getTripsCalled: true, isLoadingTrips: true });

    if(this.state.searchStr && this.state.searchStr.length > 0){
      this.props.loadTripsLT(
        {
          company_id: this.props.companyId,
          view: "controls",
          offset: this.state.offset,
          limit: this.state.limit,
          // status: "1,3,4,5",
          status: '', //this.state.filterParams.status,
          fleet_providers: '', //this.state.filterParams.fleet_providers,
          from_facilities: '', //this.state.filterParams.from_facilities,
          to_facilities: '', //this.state.filterParams.to_facilities,
          from_to_facilities: '', //this.state.filterParams.from_to_facilities || [],
          search: this.state.searchStr,
          search_type: this.state.searchType === "1" ? 'folio' : this.state.searchType  === "2" ? 'vehiculo' : 'placas',
          appointment: '', /*this.state.filterParams.appointment[0] 
            ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString())
            : "",*/
          appointment_type: '',//(this.state.filterParams.appointment_type || []).join(",") || "1,2,3",//default todos sin mostrar en interfaz
          orderStatus: '',//this.state.filterParams.orderStatus,
          baseTags: '',//this.state.filterParams.baseTags,
          vehicle: '',//this.state.filterParams.vehicle,
          transport_classes: '',//this.state.filterParams.transportClass || '',
          timezone: getTimeZone(),
          container_status: '',
        },
        { 
          onSuccess: (response) => {

            if (response.count) {
              this.state.ordersCount = response.count;
            }

            this.setState({isLoadingTrips: false});
            if (this.orderItem) {
              this.orderItem.startTimer();
            }
            this.setTimer();
          }, 
          onError: () => {
            this.setState({isLoadingTrips: false});
            if (this.orderItem) {
              this.orderItem.stopTimer();
            }
            this.setTimer();
          }
        }
      );
    } else {

      // loading extra filters

      let etaLoading = '';
      let etaUnloading = '';
      let container_status = (this.state.filterParams.containerStatus || []).join(",");
      let fleet_providers = (this.state.filterParams.fleet_providers || []).join(",");
      let transport_classes = (this.state.filterParams.transportClass || []).join(",");
      let status = (this.state.filterParams.orderStatus || []).join(",");
      let order_tags = '';
      let priority = '';

      if (this.state.order) {
        for (var i = 0; i < this.state.order.length; i++) {
          let filter = this.state.order[i];

          if (filter.idConfig === 7 && filter.ids.length && filter.ids.length > 0) {
            order_tags = (this.state.filterParams.baseTags || []).concat(filter.ids).join(",");
          }

          if (filter.idConfig === 4 && filter.ids.length && filter.ids.length > 0) {
            fleet_providers = (this.state.filterParams.fleet_providers || []).concat(filter.ids).join(",");
          }

          if (filter.idConfig === 14 && filter.ids.length && filter.ids.length > 0) {
            priority = filter.ids.join(",");
          }

          if (filter.idConfig === 13 && filter.ids.length && filter.ids.length > 0) {
            status = filter.ids.join(",");
          }

          if (filter.idConfig === 12 && filter.ids.length && filter.ids.length > 0) {
            transport_classes = (this.state.filterParams.transportClass || []).concat(filter.ids).join(",");
          }

          if (filter.idConfig === 10 && filter.ids.length && filter.ids.length > 0) {
            container_status = (this.state.filterParams.containerStatus || []).concat(filter.ids).join(",");
          }

          if (filter.idConfig === 17 && filter.ids.length && filter.ids.length > 0) {
            let order = filter.ids[0];
            if (order === 1) {
              etaLoading = 'desc'
            } else if (order === 2) {
              etaLoading = 'asc'
            } else if (order === 3) {
              etaLoading = 'withoutDate'
            }
          }

          if (filter.idConfig === 21 && filter.ids.length && filter.ids.length > 0) {
            let order = filter.ids[0];
            if (order === 1) {
              etaUnloading = 'desc'
            } else if (order === 2) {
              etaUnloading = 'asc'
            } else if (order === 3) {
              etaUnloading = 'withoutDate'
            }
          }

        }
      }

      this.props.loadTripsLT(
        {
          priority,
          company_id: this.props.companyId,
          view: "controls",
          offset: this.state.offset,
          limit: this.state.limit,
          // status: "1,3,4,5",
          status,
          fleet_providers,
          from_facilities: this.state.filterParams.from_facilities,
          to_facilities: this.state.filterParams.to_facilities,
          from_to_facilities: this.state.filterParams.from_to_facilities || [],
          search: this.state.searchStr,
          search_type: '',
          appointment: this.state.filterParams.appointment[0] 
            ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString())
            : "",
          appointment_type: (this.state.filterParams.appointment_type || []).join(",") || "1,2,3",//default todos sin mostrar en interfaz
          order_tags,
          baseTags: this.state.filterParams.baseTags,
          vehicle: this.state.filterParams.vehicle,
          transport_classes,
          timezone: getTimeZone(),
          container_status,
          eta_load_sort: etaLoading,
          eta_unload_sort: etaUnloading
        },
        { 
          onSuccess: (response) => {
            
            if (response.count) {
              this.state.ordersCount = response.count;
            }

            this.setState({isLoadingTrips: false}); 
            if (this.orderItem) {
              this.orderItem.startTimer();
            }
            this.setTimer();
          }, 
          onError: () => {
            this.setState({isLoadingTrips: false});
            if (this.orderItem) {
              this.orderItem.stopTimer();
            }
            this.setTimer();
          }
        }
      );
    }
    
  }

  cancelSearch = (e) => {
    if(e.key === "Escape"){
      if(this.state.isLoadingTrips){
        this.props.cancelRequest("COMPANIES.FACILITIES.COMMANDCONTROLLT");
        this.setState({isLoadingTrips: false, offset: this.state.tempOffset});
      }
    }
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = () => {
    if(this.state.searchStr){
      return `${SERVER}/api/v2/companies/${this.props.companyId}/shipment_control/?download=true&view=controls&limit=${this.state.limit}&offset=${this.state.offset}&appointment=&appointment_type=&status=&order_status=&base_tags=&vehicle=&fleet_providers=&from_facilities=&to_facilities=&from_to_facilities=&search=${this.state.searchStr}&transport_classes=&action=&search_type=${this.state.searchType === "1" ? 'folio' : this.state.searchType  === "2" ? 'vehiculo' : 'placas'}&timezone=${getTimeZone()}&container_status=`;
    } else{
      const appointment = (this.state.filterParams.appointment[0] 
        ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString())
        : "");
      return `${SERVER}/api/v2/companies/${this.props.companyId}/shipment_control/?download=true&view=controls&limit=${this.state.limit}&offset=${this.state.offset}&appointment=${appointment}&appointment_type=${(this.state.filterParams.appointment_type || []).join(",") || "1,2,3"}&status=&order_status=${this.state.filterParams.orderStatus}&base_tags=${this.state.filterParams.baseTags}&vehicle=${this.state.filterParams.vehicle}&fleet_providers=${this.state.filterParams.fleet_providers}&from_facilities=${this.state.filterParams.from_facilities}&to_facilities=${this.state.filterParams.to_facilities}&from_to_facilities=${this.state.filterParams.from_to_facilities || []}&search=${this.state.searchStr}&transport_classes=${this.state.filterParams.transportClass || ''}&action=&search_type=&timezone=${getTimeZone()}&container_status=${(this.state.filterParams.containerStatus || []).join(",")}`;
    }
  };

  openDocument = (url) => {
    this.setState({ 
      downloadingFile: true, 
      cancelTokenSource: Axios.CancelToken.source() 
    }, () => {
        Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        headers: this.getToken(),
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Ordenes.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloadingFile: false, 
          cancelTokenSource: null
        })
      });
    });
  };

  render() {
    return(
      <View>
        {
          (this.state.isLoadingTrips || this.state.isLoadingCategories) &&
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }

        <ViewTitle className="margin-title">
          <div style={{flex: "1"}}>
          Control de embarques
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
            <FilterBarCompact
              limit={350}
              limitErrorItem={this.state.currentViewOption === 2 ? "" : this.state.searchType === "1" ? "folios" : "vehículos"}
              clearMultiText={() => {
                this.setState({ emails: [], searchStr: "" });
              }}
              // searchStr={this.state.searchStr}
              searchStr={this.state.emails}
              // searchAction={(e) => {
              //   this.setState(
              //     {
              //       [e.target.name]: e.target.value,
              //     },
              //     () => this.loadTrips()
              //   );
              // }}
              searchPlaceholder={this.state.searchType === "1" ? "Buscar orden ó viaje" : "Buscar vehículo"}
              searchAction={this.handleEmailInput}
              multiText={true}
              content={this.ShipmentsFilters(
                this.props.tripStatuses,
                this.props.companyProviders,
                // this.props.facilities,
                this.state.vehiclesToShow,
              )}
              filterAction={() => {
                // this.loadAssemblies();
                if(this.state.buttonFilterName === "Aplicar"){
                  this.setState(
                    {
                      offset: 1,
                      buttonFilterName: "Restablecer"
                    },
                    () => { this.loadOrders(); }
                  );
                } else {
                  this.setState(
                    {
                      emails: [], 
                      searchStr: '', 
                      filterParams: {
                        baseTags: [],
                        status: [],
                        orderStatus: [],
                        transportClass: [],
                        fleet_providers: [],
                        from_facilities: [],
                        to_facilities: [],
                        from_to_facilities: [],
                        appointment: [],
                        appointment_type: [],
                        vehicle: [],
                      },
                      buttonFilterName: "Aplicar"
                    },
                    () => { this.loadOrders(); }
                  );
                }
              }}
              onChange={(e) => {

                this.state.filterParams = {
                  ...this.state.filterParams,
                  [e.target.name]: e.target.value,
                };

                if (!((this.state.filterParams?.from_facilities ?? []).length > 0 && (this.state.filterParams?.to_facilities ?? []).length > 0)) {

                  if (e.target.name === 'from_facilities') {
                    let newAppointmentType = (this.state.filterParams?.appointment_type ?? []).filter( item => { return `${item}` !== '2'})
                    this.state.filterParams.from_to_facilities = [];
                    this.state.filterParams.appointment_type = newAppointmentType;
                  }
  
                  if (e.target.name === 'to_facilities') {
                    let newAppointmentType = (this.state.filterParams?.appointment_type ?? []).filter( item => { return `${item}` !== '1'})
                    this.state.filterParams.from_to_facilities = [];
                    this.state.filterParams.appointment_type = newAppointmentType;
                  }

                }

                if (e.target.name === "orderStatus") {
                  this.state.filterParams.containerStatus = [];
                }

                if (e.target.name === "containerStatus") {
                  this.state.filterParams.orderStatus = [];
                }

                if ((this.state.filterParams?.from_to_facilities ?? []).length > 0) {
                  this.state.filterParams.from_facilities = [];
                  this.state.filterParams.to_facilities = [];
                }

                this.setState({
                  emails: [], 
                  searchStr: '', 
                  buttonFilterName: "Aplicar"
                });
              }}
              buttonFilterName={this.state.buttonFilterName}
              selectedItems={this.state.filterParams}
            />
            <div style={{display: "flex", transform: `translateY(${this.state.emails.length ? "30" : "10"}px)`}}>
              <RadioInput
                className="filter-radio"
                name="searchType"
                title="Órdenes"
                value="1"
                selected={this.state.searchType}
                onClick={(e) => {this.setState({searchType: e.target.value})}}
              />
              <RadioInput
                className="filter-radio"
                name="searchType"
                title="Vehículos"
                value="2"
                selected={this.state.searchType}
                onClick={(e) => {this.setState({searchType: e.target.value})}}
              />
            </div>
          </div>
        </ViewTitle>
        <TabSelectorRibbon
          onChange={(e) => {this.props.saveFilters(this.state.filterParams); this.props.onChange(e)}}
          bgColor="#212A3C"
          selectedUnderlineColor="#3D77F7"
          openDocument={this.openDocument}
          getUrl={this.getUrl}
          items={[
            { id: "orders", description: "ÓRDENES" },
            { id: "list", description: "VIAJES" },
            { id: "grid", description: "TRÁFICO" },
            { id: "appointments", description: "CITAS" },
          ]}
          activeId={this.props.viewMode}
          addDownloadIcon={true}
          downloadingFile={this.state.downloadingFile}
          disableDownload={this.props.ordersCount > 100}
          disableMessage={this.props.ordersCount > 100 ? 'No se permite descargar más de 100 ordenes.' : ''}
          rightComponent={
            <div 
              style={{display: 'flex', alignItems: 'center', marginRight: 8, cursor: 'pointer'}}
              onClick={() => {
                this.setState({
                  isShowSettingsModal: true
                });
              }}
            >
              <img alt="" src={'/images/settings_icon.png'} style={{ width: 30, height: 30}} data-tip data-for='tooltip-settting'/>
              <ReactTooltip
                id={'tooltip-settting'}
                getContent={() =>
                  'Configurar columnas de tabla'
                }
                delayHide={200}
                place={"left"}
                border={true}
                type={"light"}
              />
            </div>
          }
        />
        <UnassignedOrdersBoard 
          ref={(input) => { this.orderItem = input; }} 
          history={this.props.history}
          companyTags={this.props.companyTags}
          toggleLoadingState={(value) => this.setState({isLoadingTrips: value})}
          urlFacilities={this.state.urlFacilities}
          facilities={this.props.facilities}
          companyId={this.props.companyId}
          ordersLT={this.props.orders}
          ordersCountLT={this.state.ordersCount}
          offset={this.state.offset}
          updateOffset={(offset) => {
            this.state.tempOffset = this.state.offset;

            this.setState({offset}, () => {
              this.loadOrders()
            });
          }}
          updateLimit={
            (limit) => {
              this.setState({limit}, () => {
                this.loadOrders()
              });
            }
          }
          columnsData={this.state.columnsData}
          columnsHidden={this.state.columnsHidden}
          updatePositions={(position) => {
            this.scrollPosition = position;
          }}
          positionToUpdate={this.scrollPosition}
          loadExtraFilters={(newFilters) => {
            this.setState({
              order: newFilters
            }, () => {
              this.loadOrders()
            });
          }}
          loadOrders={() => this.loadOrders()}
        />

        <TripControlColumnsSettingsModal
          open={this.state.isShowSettingsModal}
          refreshSelected={this.state.refreshSelected}
          itemsToHide={this.state.columnsHidden}
          updateItems={(value) => {
            this.setState({
              columnsHidden: value
            }, () => {
              this.saveUserFilter()
            });
          }}
          columns={this.state.columnsData}
          onClose={() => {
            this.setState({
              isShowSettingsModal: false
            })
          }}
          moveCard={ (dragIndex, hoverIndex) => {

            let data = [...this.state.columnsData];
          
            let temp = data[dragIndex];
            data[dragIndex] = data[hoverIndex];
            data[hoverIndex] = temp;

            this.setState({
              columnsData: data
            });

          }}
          dropItems={() => {
            this.saveUserFilter()
          }}
          changeAutorefresh={(refreshSelected) => {
            this.setState({
              refreshSelected
            }, () => {
              this.saveUserFilter();
              this.setTimer();
            });
          }}
        />

      </View>
    )
  }
}


const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const companyData = state.globalCatalog.session.company;

  const tripStatuses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.TRIPSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));
  
  let orderStatus = getEntityItems(
    state,
    "COMPANIES.CATALOGS.ORDERSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));

  if (orderStatus && orderStatus.length && orderStatus.length > 0) {
    orderStatus = orderStatus.filter(status => {
      return ![4,10,12].includes(status.id);
    });
  }

  let transportClass = getEntityItems(
    state,
    "COMPANIES.SHIPMENTS.LT.FILTERS"
  )

  let tempTransportClass = [];

  (transportClass || []).forEach(e => {
    (e.transport_classes_tags || []).forEach(t => {
      if(tempTransportClass.find(toFind => toFind.description === `${e.orbi_id} - ${t.tags.title}`) === undefined){
        const classes = (e.transport_classes_tags || []).filter(tag => tag.tags.title === t.tags.title)
        
        let selectedClasses = []
        classes.forEach(s => {
          (e.transport_classes || []).forEach(classe => {
            if(classe.id === s.transport_class){
              selectedClasses.push(classe)
            }
          })
        })

        tempTransportClass.push(
          { 
            description: `${e.orbi_id} - ${t.tags.title}`,
            id: e.id,
            items: selectedClasses.map((tc) => ({
              id: tc.id,
              description: tc.code,
            })),
          }
        )
      }
    })
  });

  transportClass = tempTransportClass

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK").map(
    (f) => ({
      description: f.fleet_provider.orbi_id || f.fleet_provider.corporation_name,
      id: f.fleet_provider.fleet_provider_id || 0,
    })
  );
    
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");

  let orders = getEntityItems(state, "COMPANIES.FACILITIES.COMMANDCONTROLLT");
  let ordersCount = getEntityCount(state, "COMPANIES.FACILITIES.COMMANDCONTROLLT");
  let containerStatusCatalog = getEntityItems(state, "COMPANIES.CATALOGS.CONTAINERSTATUS").map(c => ({
    id: c.id,
    description: c.event_description
  }));

  return {
    configuration: state.configuration.AssembleShipmentOrdersList,
    companyId,
    companyData,
    tripStatuses,
    orderStatus,
    transportClass,
    companyProviders,
    companyTags,
    orders,
    ordersCount,
    containerStatusCatalog,
    companyType: state.globalCatalog.session.company.type.id,
    userData: state.globalCatalog.session.company?.companyuser?.id || 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));

  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    loadFilterCategories: (params, opt) => dispatch(readEntities("COMPANIES.COMMANDCONTROL.FILTER.CATEGORIES", params, opt)),
    loadUserFilter: (params, opt) => dispatch(readEntity("COMPANIES.COMMANDCONTROL.FILTER", params, opt)),
    saveFilter: (params, opts) => dispatch(createEntity("COMPANIES.COMMANDCONTROL.FILTER", params, opts)),
    getContainerStatus: (params, opt) => dispatch(readEntities("COMPANIES.CATALOGS.CONTAINERSTATUS", params, opt)),
    loadTripsLT: (params, options) => dispatch( readCatalog("COMPANIES.FACILITIES.COMMANDCONTROLLT", params, options)),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadCompanyTags: (params, opts) =>
      dispatch(
        readEntities(
          "COMPANIES.TAGS",
          params,
          opts
        )
      ),
    setBaseConfig: (config) => dispatch(setConfig("AssembleShipmentOrdersList", config)),
    loadOrderStatus: () => dispatch(readEntities("COMPANIES.CATALOGS.ORDERSTATUS")),
    listVehicles: (params, opts) =>
      dispatch(
        readCatalog(
          "COMPANIES.CATALOGS.VEHICLE",
          params,
          opts
        )
      ),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    // loadTransportClass: (params, opt) => 
    //   dispatch(readEntities("COMPANIES.SHIPMENTS.LT.FILTERS", params, opt)),
    // loadFilterFacilities: (params, options) =>
    //   dispatch(
    //     readEntities(
    //       "COMPANIES.TRIPS",
    //       { ...params, action: "get_facilities" },
    //       options
    //     )
    //   ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleShipmentOrdersList);