import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./redux/store";
import { routes } from "./router.jsx";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./styles/theme.scss";
import "./styles/style.scss";

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>{routes}</Provider>
  </DndProvider>,
  document.getElementById("root")
);
