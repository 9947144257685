import React from "react";
import { connect } from "react-redux";
import { createEntity, /* deleteEntity, getEntityItems, */ partialEntity, readEntities } from "../../redux/api";
import { loadCompanyUsers } from "../../redux/reducers/company-reducer";
import CompanyNotificationRow from "./company-notification-row";
// import { groupFacilities } from "../../shared/utils";
// import CompanyAlertRow from "./company-alert-row";

class NotificationListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // openInviteModal: false,
      notifications: []
    };
  }

  componentDidMount() {
    this.props.listNotificationCatalog({
      company_id: this.props.companyId
    }, {
      onSuccess: async result => {
        await asyncForEach(result, async (notification) => {
          this.props.listNotificationUsers({
            company_id: this.props.companyId,
            notification_id: notification.id
          }, {
            onSuccess: r => {
              this.setState({
                notifications: this.state.notifications.concat([{
                  ...notification,
                  roles: r.roles,
                  users: r.users,
                  edit: false
                }])
              })
            }
          })
        });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.notifications.sort((a, b) => a.id - b.id).map(notification => (
            <CompanyNotificationRow 
              notification={notification}
              onNotificationDataChange={this.handleNotificationDataChange}
              users={this.props.companyUsers}
            />
            
          ))
        }
      </React.Fragment>
    );
  }

  handleNotificationDataChange = (e, type, notificationId) => {
    let notifications = [];
    let notificationIndex = 0;
    switch(type) {
      case "active":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId);
        this.props.updateNotification({
          company_id: this.props.companyId,
          notification_id: notificationId,
          is_active: !notifications[notificationIndex].is_active
        }, {
          onSuccess: r => {
            notifications[notificationIndex].is_active = !notifications[notificationIndex].is_active;
            this.setState({notifications});
          }
        })
        break;
      case "user":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId);
        notifications[notificationIndex].users = e.target.value;
        this.setState({notifications});
        break;
      case "edit":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId);
        notifications[notificationIndex].edit = !notifications[notificationIndex].edit;
        this.setState({notifications});
        break;
      case "cancel":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId);
        this.props.listNotificationUsers({
          company_id: this.props.companyId,
          notification_id: notificationId
        }, {
          onSuccess: r => {
            notifications[notificationIndex].users = r.users;
            notifications[notificationIndex].roles = r.roles;
            notifications[notificationIndex].edit = false;
            this.setState({notifications});
          }
        });
        break;
      case "save": 
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId);
        this.props.createNotificationUsers({
          company_id: this.props.companyId,
          notification_id: notificationId,
          users: notifications[notificationIndex].users,
          roles: []
        }, {
          onSuccess: () => {
            notifications[notificationIndex].edit = false;
            notifications[notificationIndex].roles = [];
            this.setState({notifications});
          }
        })
        break;
      default:
    }
  }
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const mapStateToProps = state => {
  // const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  // const facilitiesByTag = groupFacilities(companyFacilities);

  let usersByTag = [
    { id: 9999999999, description: "Sin rol", items: [] },
  ];
  if(state.companyReducer.companyUsers.data && Array.isArray(state.companyReducer.companyUsers.data)){
    (state.companyReducer.companyUsers.data || []).forEach((u) => {
      if(u.role){
        let tagIndex = usersByTag.map((uT) => uT.id).indexOf(u.role);
          if (tagIndex !== -1) {
            usersByTag[tagIndex].items.push({
              id: u.users,
              description: u.username,
            });
          } else {
            let newTag = {
              id: u.role_detail.id,
              description: u.role_detail.description,
              items: [
                {
                  id: u.users,
                  description: u.username,
                },
              ],
            };
            usersByTag.push(newTag);
          }
      } else {
        usersByTag[0].items.push({
          id: u.users,
          description: u.username,
        });
      }
    });
    
    usersByTag = usersByTag.map((tag) => {
      let sortedItems = tag.items.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return { ...tag, items: sortedItems };
    });
    
    let tagless = usersByTag.shift();
    
    usersByTag = usersByTag.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    
    usersByTag.push(tagless);
  
    // return usersByTag;
  }
  
  return {
    // facilitiesByTag,
    companyUsers: usersByTag,
    companyId: state.globalCatalog.session.company.id,
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadCompanyUsers());
  return {
    // loadCompanyFacilities: (params, opts) =>
    //   dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    listNotificationCatalog: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    updateNotification: (params = {}, opts = {}) =>
      dispatch(
        partialEntity(
          "COMPANIES.NOTIFICATIONS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    listNotificationUsers: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS.USERS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createNotificationUsers: (params = {}, opts = {}) =>
      dispatch(
        createEntity(
          "COMPANIES.NOTIFICATIONS.USERS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(NotificationListView);
