import { isArray } from 'lodash';
import moment from 'moment';
import React, { createRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-day-picker/lib/style.css';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import ModalSelectInput from '../../control/shipment/modal-select-input';
import { getEntityItems, partialEntity, readEntity } from '../../redux/api';
import Button from '../controls/button';
import SVGIcon, { icons } from '../svg-icon';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import Loader from '../icons/tat/loader';
import { convertDateStringToInt, getNotAllowedWindows, convertDateToInt, sot_spaces_using } from '../../control/shipment/utils';
import ReactTooltip from 'react-tooltip';
import HeaderTime from './change.date-modal-items/header-time';

const scrollRef = createRef()

const formatDate = (date: Date) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  const tomorrow = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
  date = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes()
  );

  let result =
    date.toDateString() === today.toDateString()
      ? "Hoy, "
      : date.toDateString() === tomorrow.toDateString()
      ? "Mañana, "
      : date.toUTCString().substring(4, 16);

  result = result.concat(
    " ",
    date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    " hrs"
  );
  return result;
};

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

class ChangeDateModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentSchedule: { end: 0 },
      reasonSelect: null,
      hourSelected: '0:00',
      dateSelected: new Date(),
      tagSelected: null,
      spaceSelected: '',
      successAction: false,
      isConflictDates: false,
      maxElementsToShow: 20,
      spaceNotAllowed: false,
      errorByManualDate: false,
      spaces: [],
      time_to_sort: '',
      time_hover: ''
    };

    this.datesScrolled = false;

  }

  resetScrollbar = () => {
    scrollRef.current.scrollTo(0, 0);
  }

  componentDidMount() {
    this.getData();
  }

  updateScroll = () => {
    const { spaces = [] } = this.state;

    if (!this.datesScrolled && spaces.length > 0 && scrollRef.current !== null && this.state.dateSelected.toDateString() === today.toDateString() ) {
    
      var hourToScroll = this.state.dateSelected.getHours();

      scrollRef.current.scrollTo(hourToScroll * 4 * windw_space_width, 0);
      this.datesScrolled = true;

    } else if (!this.datesScrolled && spaces.length > 0 && scrollRef.current !== null) {

      var hourToScroll = 0;

      for (var i = 0; i < spaces.length; i++) {
        const { ranges = [] } = spaces[i];

        for (var j = 0; j < ranges.length; j++) {
          const range = ranges[j];

          if (range.length > 0) {
            let times = range[0].split(':');

            if (range.length === 4) {
              const payload = range[3];

              if (payload.next_day && payload.first_day) {
                times = '00:00:00'.split(':');
              }
            }

            if (times.length > 1) {
              const timeToChange = parseInt(times[0]);

              if (hourToScroll === 0 || timeToChange < hourToScroll) {
                hourToScroll = timeToChange
              }
            }
          }

        }

      }

      scrollRef.current.scrollTo(hourToScroll * 4 * windw_space_width, 0);
      this.datesScrolled = true;

    }
  }

  getData = () => {

    const operation = this.props?.data?.maneuver || '';

    this.props.getSpaces({
      company_id: this.props.companyId,
      facility_id: this.props.facilityId,
      date: moment(this.state.dateSelected).format('YYYY-MM-DD'),
      operation: (operation.toLowerCase() === 'descarga') ? 'unload' : 'load',
      order_id: this.props.orderId,
      maxElementsToShow: 20
      },
      {
        onSuccess: (result) => { 
          let spaces = (result.facility_spaces || []).map( (item, index) => {
            item.space_number = index + 1;

            let manueverTime =  this.props.data.maneuver === 'Carga' ? this.props.info.load_minutes ?? 0 : this.props.info.unload_minutes ?? 0;
            item.ranges = item.ranges.map( range_item => {

              let manuever_time = 0;

              if (range_item.length > 4) {
                (range_item[4] || []).forEach(range => {

                  let transportClass = this.getTransportClass();

                  if (range.classes.includes(transportClass)) {

                    if (operation.toLowerCase() === 'descarga') {
                      manuever_time = range.single_unload > 0 ? range.single_unload : range.doble_unload > 0 ? range.doble_unload : 0
                    } else {
                      manuever_time = range.single_load > 0 ? range.single_load : range.doble_load > 0 ? range.doble_load : 0
                    }
                    
                  }

                });
              }

              range_item[4] = manuever_time;

              return range_item;
            });

            let resultWindows = getNotAllowedWindows(item.dates, item.ranges, manueverTime, true, true);

            item.spaces_not_allowed = resultWindows.result_not_allowed;
            item.spaces_overlapped = resultWindows.result_overlapped;

            return item;
          });

          this.setState({ spaces });
          this.updateScroll(); 
        }
      }
    );

  }

  sortSpaces = () => {
    let spaces = sot_spaces_using(this.state.spaces, this.state.time_to_sort);
    this.setState({ spaces });
  }

  formatNumberToTimePart = (number, hour = false) => {
    let hours = parseInt(number / 100);
    hours = this.limitNumber(hours, 23);
    let minutes = parseInt(number) % 100;
    minutes = this.limitNumber(minutes, 59);
    hours = "00".concat(hours.toString()).substr(-2, 2);
    minutes = "00".concat(minutes.toString()).substr(-2, 2);
    return hour ? hours : minutes;
  };

  limitNumber = (number, upperLimit, lowerLimit = 0) => {
    number = number < lowerLimit ? lowerLimit : number;
    number = number > upperLimit ? upperLimit : number;
    return number;
  };

  handleTimeInput = (e) => {
    let tms = e.target.value.replace(":", "");
    this.handleChange({ target: { name: e.target.name, value: tms } });
  };

  handleChange = (e) => {
    const name = e.target.name || (e.target.attributes["name"] || {}).value || "";
    let currentSchedule = Object.assign({}, this.state.currentSchedule);

    switch (name) {
      case "endHour":
        currentSchedule.end =
          (currentSchedule.end % 100) +
          this.limitNumber(parseInt(e.target.value), 23) * 100;
        break;
      case "endMinute":
        currentSchedule.end =
          parseInt(currentSchedule.end / 100) * 100 +
          this.limitNumber(parseInt(e.target.value), 59);
        break;
    }
    
    this.setState({currentSchedule});

    if (e.target.name === 'endHour' || e.target.name === 'endMinute') {

      let spaces = this.state.spaces.filter( item => `${item.id}` === `${this.state.spaceSelected}`)

      if (spaces.length > 0) {
        let space = spaces[0];

        let dates = space.dates.map( appoinment_item => {
          return [convertDateStringToInt(appoinment_item[0]), convertDateStringToInt(appoinment_item[1])];
        });

        let ranges = space.ranges.map( windows_item => {
          return [convertDateStringToInt(windows_item[0]), convertDateStringToInt(windows_item[1])];
        });

        let isInWindow = false;
        let time_to_validate = convertDateToInt(`${currentSchedule.end}`)

        ranges.forEach( item => {
          if (time_to_validate >= item[0] && time_to_validate <= item[1]) {
            isInWindow = true;
          }
        });

        if (!isInWindow) {
          this.setState({
            errorByManualDate: true,
            spaceNotAllowed: true,
            isConflictDates: false
          });
          return;
        }

        let isInDates = false;

        dates.forEach( item => {
          if (time_to_validate >= item[0] && time_to_validate <= item[1]) {
            isInDates = true;
          }
        });

        if (isInDates) {
          this.setState({
            errorByManualDate: true,
            isConflictDates: true,
            spaceNotAllowed: false
          });
          return;
        }

        let spaceNotAllowedTemp = false;
        let isSpaceNotOverlapped = false;

        space.spaces_not_allowed.forEach( item => {
          if (time_to_validate >= item[0] && time_to_validate <= item[1]) {
            spaceNotAllowedTemp = true;
          }
        });

        space.spaces_overlapped.forEach( item => {
          if (time_to_validate >= item[0] && time_to_validate <= item[1]) {
            isSpaceNotOverlapped = true;
          }
        });

        if (spaceNotAllowedTemp) {
          this.setState({
            errorByManualDate: true,
            spaceNotAllowed: true,
            isConflictDates: false
          });
          return;
        }

        if (isSpaceNotOverlapped) {
          this.setState({
            errorByManualDate: true,
            spaceNotAllowed: false,
            isConflictDates: true
          });
          return;
        }

        this.setState({
          errorByManualDate: false,
          spaceNotAllowed: false,
          isConflictDates: false
        });

      }

    }

  }

  isValidSpace = (hourSpace, minSpace, range, validateSpace) => {

    const startRange = range[0].split(':');
    const endRange = range[1].split(':');

    const tempSpaceHour = parseInt(hourSpace);
    const tempSpaceMin = parseInt(minSpace);
    const tempStartRangeHour = parseInt(startRange[0]);
    const tempStartRangeMins = parseInt(startRange[1]);
    let tempEndRangeHour = parseInt(endRange[0]);
    let tempEndRangeMins = parseInt(endRange[1]);

    if (tempEndRangeHour < tempStartRangeHour) {
      tempEndRangeHour = parseInt(24);
      tempEndRangeMins = parseInt(0);
    }

    const tempSpaceTime = (tempSpaceHour * 60) + tempSpaceMin;
    const tempStartRangeTime = (tempStartRangeHour * 60) + tempStartRangeMins;
    const tempEndRangeTime = (tempEndRangeHour * 60) + tempEndRangeMins;

    if (validateSpace) {
      return (tempSpaceTime >= tempStartRangeTime && tempSpaceTime <= tempEndRangeTime);
    } else {
      return (tempSpaceTime >= tempStartRangeTime && tempSpaceTime < tempEndRangeTime);
    }
  }

  isSelected = (hour, spaceId) => {
    return (`${hour}` === this.state.hourSelected && `${spaceId}` === this.state.spaceSelected);
  }

  getTransportClass = () => { return this.props.info?.transport_class?.code || ''; }

  windowSpace = (number, ranges, dates, spaceId, isSpaceNotAllow, isSpaceNoAvailableDate) => {
    
    const hourWindow = number.split(':');
    var isSpaceAvailable = false;
    var isSpaceNoAvailable = false;
    var isTransportClassFound = false;
    var transportClass = this.getTransportClass();

    for (var i = 0; i < ranges.length; i++) {
      let range = ranges[i];

      if (range.length > 4) {
        let payload = range[3];

        if (payload.next_day && payload.first_day) {
          range[0] = '00:00:00';

          if (range[0] === range[1]) {
            range[1] = '24:00:00';
          }

        } else if (payload.next_day && !payload.first_day) {
          range[1] = '24:00:00'; 
        }

      }

      if (!isSpaceAvailable) {
        isSpaceAvailable = this.isValidSpace(hourWindow[0], hourWindow[1], range, true);
      }

      if (!isTransportClassFound) {
        let classesAvailable = range.length > 2 ? range[2] : [];

        for (var k = 0; k < classesAvailable.length; k++) {

          if (!isTransportClassFound && transportClass === classesAvailable[k]) {
            isTransportClassFound = true;
          }

        }
      }
    }

    for (var j = 0; j < dates.length; j++) {
      const date = dates[j];

      if (!isSpaceNoAvailable) {
        isSpaceNoAvailable = this.isValidSpace(hourWindow[0], hourWindow[1], date, false);
      }
    }

    if (isSpaceNoAvailable && isTransportClassFound) {
      let isSelected = this.isSelected(number, spaceId);

      if (isSelected) {

        return(
          {
            component: 
              <div 
              key={`row-${spaceId}-${number}`}
              style={{width: windw_space_width, minWidth: windw_space_width, height: 30, display:'flex', alignItems: 'center', justifyContent: 'center'}} >
                <div 
                  key={`row-${spaceId}-${number}-detail`}
                  onMouseEnter={() => {
                    if (this.state.spaceNotAllowed) {
                      this.setState({
                        spaceNotAllowed: false
                      });
                    }
                  }}
                  className={isSelected ? 'space-available-selected' : 'space-available'}
                  style={{height: isSelected ? 22 : 20, width: isSelected ? 35 : 33}}
                  onClick={ () => {

                    if (isSpaceNotAllow) { return; }

                    if (`${this.state.hourSelected}` === `${number}` && this.state.spaceSelected === `${spaceId}`) {

                      this.setState({
                        currentSchedule: {end: 0},
                        hourSelected: '0:00',
                        spaceSelected: '',
                        isConflictDates: false,
                        errorByManualDate: false
                      });
      
                    } else {
      
                      const time = number.split(':');
        
                      this.setState({
                        currentSchedule: {end: (parseInt(time[0]) * 100) + parseInt(time[1])},
                        hourSelected: number,
                        spaceSelected: `${spaceId}`,
                        isConflictDates: true,
                        spaceNotAllowed: false,
                        errorByManualDate: false
                      });
                    }
                  }}
                >
                  {`${number}`}
                </div>
              </div>,
            component_visible: true
          }
        )

      } else {

        return(
          {
            component:
            <div 
            key={`row-${spaceId}-${number}`}
            style={{width: windw_space_width, minWidth: windw_space_width, height: 30, display:'flex', alignItems: 'center', justifyContent: 'center'}} >
              <div 
                key={`row-${spaceId}-${number}-detail`}
                onMouseLeave={()=>{
                  if (this.state.spaceNotAllowed) {
                    this.setState({
                      spaceNotAllowed: false
                    });
                  }

                  if (this.state.isConflictDates) {
                    this.setState({
                      isConflictDates: false
                    });
                  }

                }}
                onMouseEnter={() => {
                  if (isSpaceNotAllow) {
                    if (isSpaceNotAllow && !this.state.spaceNotAllowed) {
                      this.setState({
                        spaceNotAllowed: true
                      });
                    } else if (!isSpaceNotAllow && this.state.spaceNotAllowed) {
                      this.setState({
                        spaceNotAllowed: false
                      });
                    }
                  } else {
                    if (!this.state.isConflictDates && !(`${this.state.hourSelected}` === `${number}` && this.state.spaceSelected === `${spaceId}`)) {
                      this.setState({
                        isConflictDates: true
                      });
                    }
                  }

                }}
                className={ 'space-available'}
                style={
                  isSpaceNotAllow ? 
                  { 
                    cursor: 'not-allowed',
                    opacity: 0.2,
                    height: 20, 
                    width: 33,
                    backgroundImage: 'url(/images/disable_background.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left',
                    backgroundSize: 'cover'
                  } :
                  { opacity: 0.2, cursor: 'default', height: 20, width: 33 } 
                }
                onClick={ () => {

                  if (isSpaceNotAllow) { return; }

                  if (`${this.state.hourSelected}` === `${number}` && this.state.spaceSelected === `${spaceId}`) {

                    this.setState({
                      currentSchedule: {end: 0},
                      hourSelected: '0:00',
                      spaceSelected: '',
                      isConflictDates: false,
                      errorByManualDate: false
                    });
    
                  } else {
    
                    const time = number.split(':');
      
                    this.setState({
                      currentSchedule: {end: (parseInt(time[0]) * 100) + parseInt(time[1])},
                      hourSelected: number,
                      spaceSelected: `${spaceId}`,
                      isConflictDates: true,
                      spaceNotAllowed: false,
                      errorByManualDate: false
                    });
                  }
                }}
              >
                {`${number}`}
              </div>
            </div>,
            component_visible: true
          }
        );

      }

    } else if (isSpaceAvailable && isTransportClassFound) {
      let isSelected = this.isSelected(number, spaceId);
      return({
        component: 
          <div 
          key={`row-${spaceId}-${number}`}
          style={{width: windw_space_width, minWidth: windw_space_width , height: 30, display:'flex', alignItems: 'center', justifyContent: 'center'}} >
            <div 
              key={`row-${spaceId}-${number}-detail`}
              onMouseLeave={()=>{

                if (isSpaceNoAvailableDate && this.state.isConflictDates) {
                  this.setState({
                    isConflictDates: false
                  });
                }

                if (this.state.spaceNotAllowed) {
                  this.setState({
                    spaceNotAllowed: false
                  });
                } 
              }}
              onMouseEnter={() => {

                if (isSpaceNoAvailableDate) {
                  this.setState({
                    isConflictDates: true
                  });
                }

                if (isSpaceNotAllow && !this.state.spaceNotAllowed) {
                  this.setState({
                    spaceNotAllowed: true
                  });
                } else if (!isSpaceNotAllow && this.state.spaceNotAllowed) {
                  this.setState({
                    spaceNotAllowed: false
                  });
                }
              }}
              className={isSpaceNotAllow ? 'space-not-allowed' : isSelected ? 'space-available-selected' : 'space-available'}
              style={
                isSpaceNotAllow ?
                {
                  height: isSelected ? 22 : 20, 
                  width: isSelected ? 35 : 33,
                  backgroundImage: 'url(/images/disable_background.png)',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'left',
                  backgroundSize: 'cover'
                } :
                {height: isSelected ? 22 : 20, width: isSelected ? 35 : 33}
              }
              onClick={ () => {

                if (isSpaceNotAllow) { return; }

                if (`${this.state.hourSelected}` === `${number}` && this.state.spaceSelected === `${spaceId}`) {

                  this.setState({
                    currentSchedule: {end: 0},
                    hourSelected: '0:00',
                    spaceSelected: '',
                    isConflictDates: false,
                    errorByManualDate: false
                  });

                } else {

                  const time = number.split(':');

                  this.setState({
                    currentSchedule: {end: (parseInt(time[0]) * 100) + parseInt(time[1])},
                    hourSelected: number,
                    spaceSelected: `${spaceId}`,
                    isConflictDates: false,
                    errorByManualDate: false
                  });

                }
              }}
            >
              {`${number}`}
            </div>
          </div>,
        component_visible: true
      });
    } else {
      return(
        {
          component: <div 
            key={`row-${spaceId}-${number}`}
            style={{width: windw_space_width, minWidth: windw_space_width, height: 30, display:'flex', alignItems: 'center', justifyContent: 'center'}}></div>,
          component_visible: false
        }
      );
    }

  }

  windowsRowHeader = () => {

    return(  
      <div className='table-row' style={{height: 24, minHeight: 24}}>
        <div className='name-content'></div>
        <div style={{width: '100%', display: 'flex', overflow: 'hidden', alignItems: 'center'}} className={'window-scroll'}>
          {
            TIME_ARRAY.map( (value, index) => {
              return (
                <HeaderTime
                  key={`header-time-${index}`}
                  windw_space_width={windw_space_width}
                  value={value}
                  timeSelected={this.state.time_to_sort}
                  didSelect={(value) => this.setState({time_to_sort: value}, () => this.sortSpaces())}
                />
                
                // <div
                //   key={`header-time-${index}`}
                //   style={{
                //     width: windw_space_width, 
                //     minWidth: windw_space_width, 
                //     display: 'flex',
                //     alignItems: 'center',
                //     justifyContent: 'center',
                //     color: 'black',
                //     fontSize: 12,
                //     fontWeight: 'bold',
                //     letterSpacing: 0,
                //   }}
                // >
                //   <div
                //     key={`header-time-detail-${index}`}
                //     onMouseEnter={() => {
                //       this.setState({time_hover: `${value}`});
                //     }}
                //     onMouseLeave={() => {
                //       this.setState({time_hover: ''});
                //     }}
                //     style={{
                //       cursor: 'pointer',
                //       backgroundColor: `${value}` === `${this.state.time_to_sort}` ? 'rgb(61, 119, 247)' : 'transparent',
                //       color: `${value}` === `${this.state.time_to_sort}` ? 'white' : 'black',
                //       fontSize: `${value}` === `${this.state.time_hover}` ? 15 : 12,
                //       borderRadius: '8px',
                //       padding: '5px'
                //     }}
                //     onClick={() => {
                //       this.setState({
                //         time_to_sort: `${value}` === `${this.state.time_to_sort}` ? '' : `${value}`
                //       }, () => {
                //         this.sortSpaces();
                //       })
                //     }}
                //   >
                //     {`${value}`}
                //   </div>
                // </div>
              );
            })
          }
        </div>
      </div>
    );

  }

  windowsRow = (space, index) => {

    const operationToValid = ((this.props?.data?.maneuver || '').toLowerCase() === 'descarga') ? 'unload' : 'load'
    const operationToEvaluate = space?.operation || ''

    if (operationToValid === operationToEvaluate || operationToEvaluate === 'loadunload') {

      var windowsToShow = false;

      const components = TIME_ARRAY.map( (value) => {

        let dateInNumber = convertDateStringToInt(`${value}`);
        let isSpaceNotAllowed = false;
        let isSpaceOverlapped = false;

        space.spaces_not_allowed.forEach( item => {
          if (dateInNumber >= item[0] && dateInNumber <= item[1]) {
            isSpaceNotAllowed = true;
          }
        });

        space.spaces_overlapped.forEach( item => {
          if (dateInNumber >= item[0] && dateInNumber <= item[1]) {
            isSpaceOverlapped = true;
          }
        });

        const { component, component_visible } = this.windowSpace(`${value}`, space.ranges, space.dates, space.id, isSpaceNotAllowed, isSpaceOverlapped);
        if (component_visible) {
          windowsToShow = true;
        }
        return component;
      })

      return(  
        <div className='table-row' key={index}>
          <div className='name-content'>
            {`Espacio ${space.space_number}`}
  
            {
              (space.tag !== null) ? <div className='tag' style={{backgroundColor: space.tag.color}}>{`${space.tag.title}`}</div> : <div/>
            } 
  
          </div>
          <div style={{width: '100%', display: 'flex', overflow: 'hidden', alignItems: 'center'}} className={'window-scroll'}>
            {
              windowsToShow ? components : <div className='no-windows-content'>{'Este espacio no tiene ventana en este dia'}</div>
            }
          </div>
        </div>
      );

    } else {

      return(<div className='no-windows-content'>{'Este espacio no tiene ventana en este dia'}</div>);

    }

  }

  handleScroll = (e) => {
    const scrollTop = e.target.scrollHeight - e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const className = e?.target?.className || '';

    const bottom = (scrollTop > clientHeight - 2) && (scrollTop < clientHeight + 2);

    if (bottom && (this.state.spaces || []).length > this.state.maxElementsToShow && className === 'table-content') { 
      this.setState({maxElementsToShow: this.state.maxElementsToShow + 20}, () => {
        if (scrollRef.current) {
          let currentScroll = scrollRef.current.scrollLeft;
          scrollRef.current.scrollTo(currentScroll === 0 ? currentScroll + 1 : currentScroll - 1, 0);
        }
      });
    }
  }

  getStatusDescription = () => {
    return(
      <div className='status-description'>

        <ReactTooltip
          id={'space-available-tooltip'}
          className='eta-tooltip-content'
        > 
          { <div>{`Disponible`}</div>}
        </ReactTooltip>

        <ReactTooltip
          id={'space-not-allowed-tooltip'}
          className='eta-tooltip-content'
        > 
          { <div>{`No ocupado sin tiempo suficiente para maniobra`}</div>}
        </ReactTooltip>

        <ReactTooltip
          id={'space-available-tooltip-opacity'}
          className='eta-tooltip-content'
        > 
          { <div>{`Ocupado`}</div>}
        </ReactTooltip>

        <ReactTooltip
          id={'space-not-allowed-tooltip-opacity'}
          className='eta-tooltip-content'
        > 
          { <div>{`Ocupado sin tiempo suficiente para maniobra`}</div>}
        </ReactTooltip>


        <div className={'text'}>{'Estatus:'}</div>
        <div 
          className={'space-available'}
          data-tip
          data-for={'space-available-tooltip'}
        > {'01:00'} </div>
        <div 
          className={'space-not-allowed'}
          data-tip
          data-for={'space-not-allowed-tooltip'}
        > {'01:00'} </div>
        <div 
          className={'space-available'} style={{opacity: 0.2}}
          data-tip
          data-for={'space-available-tooltip-opacity'}
        > {'01:00'} </div>
        <div 
          className={'space-not-allowed'} style={{opacity: 0.2}}
          data-tip
          data-for={'space-not-allowed-tooltip-opacity'}
        > {'01:00'} </div>
      </div>
    );
  }

  windowsContent = () => {

    if (this.props.loading) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
          {'Cargando...'}
        </div>
      );
    } else if (this.state.spaces.length === 0) {

      return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
          {'Esta instalación no tiene espacios configurados'}
        </div>
      );

    }

    let spaces_to_show = this.state.spaces.slice(0, this.state.maxElementsToShow) ?? [];
 
    return(
      <div style={{ marginLeft: 23, marginRight: 23}}>

        <div style={{ minHeight: 1, height: 1, backgroundColor: 'rgba(164, 180, 206, 1)', marginTop: 29 }} />

        {
          this.windowsRowHeader() 
        }

        <div className='table-content' onScroll={this.handleScroll}>

          {
            (spaces_to_show !== null && isArray(spaces_to_show)) && spaces_to_show.map( (value, index) => {
              return this.windowsRow(value, index + 1)   
            })
          }

        </div>

        <div style={{
          width: 540,
          minWidth: 540,
          display: 'flex'
        }}>
          
          <div style={{minWidth: '30%', width: '30%'}}/>

          <div
            style={{
              overflowX: 'scroll',
              overflowY: 'hidden',
              height: 15
            }}

            ref={scrollRef}
            onScroll={e => {
              const r = document.getElementsByClassName( 'window-scroll')
              Array.from(r).forEach( a => (a.scrollLeft = scrollRef.current.scrollLeft) )
            }}
          >

            <div style={{width: windw_space_width * TIME_ARRAY.length, minWidth: windw_space_width * TIME_ARRAY.length, height: 1}} />
            
          </div>

        </div>

      </div>
    );
  }

  getDoneButton = () => {

    if (this.props.loadingDate) {
      return 'Cargando...'
    } else {
      return this.props.editDate ? 'Cambiar cita' : 'Guardar cita' ;
    }

  }

  isValidForm = () => {
    return (
      !this.state.errorByManualDate
      && this.state.spaceSelected !== '' 
      && !this.props.loadingDate
      && (!this.props.editDate || this.state.reasonSelect )
    );
  }

  getSpaceTag = () => {
    var tag = null;

    for (var i = 0; i < this.state.spaces.length; i++) {
      if (`${this.state.spaces[i].id}` === `${this.state.spaceSelected}`) {
        tag = this.state.spaces[i].tag;
      }
    }

    return tag;
  }

  getOldDateData = () => {
    var data = null;

    const operation = this.props?.data?.maneuver || '';
    if (operation.toLowerCase() === 'descarga') {
      data = this.props.info?.unload_space || null;
    } else {
      data = this.props.info?.load_space || null;
    }

    return data;
  }

  getTripTag = () => {

    var tag = null;

    for (var i = 0; i < this.props.tripDetails.length; i++) {

      if (`${this.props.tripDetails[i].id}` === `${this.props.orderId}`) {

        if (this.props.tripDetails[i].tags.length > 0) {
          tag = this.props.tripDetails[i].tags[0];
        }

      }

    }

    return tag;
  }

  getSpaceNumber = (spaceId) => {

    for (var i = 0; i < this.state.spaces.length; i++) {
      if (`${spaceId}` === `${this.state.spaces[i]?.id || ''}`) {
        return this.state.spaces[i].space_number
      }
    }

    return 0;
  }

  getContent = () => {

    let { currentSchedule } = this.state;
    let { logo, code, maneuver} = this.props.data;

    return (
      <>
        <div className="dates_content">

        <div className='avatar'>

          <img className='logo' src={logo} alt="" onError={(e)=>{e.target.onerror = null; e.target.src="/images/factory-bckgrnd.png"}}/>

          <div>
            <div className='trip'>{'N° orden: ' + `${code}`}</div>
            <div className='manuever'>{'Maniobra: ' + `${maneuver}`}</div>
          </div>

          {
            this.getTripTag() !== null &&
            <div className='tag' style={{backgroundColor: this.getTripTag()?.color || 'white'}}>{`${this.getTripTag()?.title || ''}`}</div>
          }

        </div>

        <div className='calendar-content'>

          <div style={{width: 310 }}>
            <div className='indications'>{'1. Selecciona la fecha de la nueva cita'}</div>

            <div style={{margin: 10, marginRight: 30}}>
              <ReactDatePicker
                minDate={new Date()}
                locale='es' 
                inline
                selected={this.state.dateSelected}
                onChange={ (value) => {
                  this.setState({
                    dateSelected: value,
                    currentSchedule: {end: 0},
                    hourSelected: '0:00',
                    spaceSelected: '',
                    isConflictDates: false,
                    errorByManualDate: false,
                    reasonSelect: null,
                    time_to_sort: ''
                  }, () => {
                    this.datesScrolled = false;
                    this.getData()
                  });
                }}
              />
            </div>

          </div>

          <div style={{width: 2, minWidth: 2, height: 300, marginRight: 0, display: 'flex', alignItems: 'center'}}>
            <div style={{width: 2, minWidth: 2, height: 200, backgroundColor: 'rgba(164, 180, 206, 1)'}} />
          </div>

          <div style={{width: '100%'}}>

            <div className='indications'>{'2. Selecciona horario y espacio de la nueva cita'}</div>

            <div className='title-wrapper'>
              <div className='windows-title'>{'Tablero de citas'}</div>
              {this.getStatusDescription()}
            </div>
            
            {this.windowsContent()}

            {
              this.state.spaceNotAllowed &&
              <div className='warning'>{'* En este horario no hay tiempo suficiente en la ventana para la maniobra.'}</div>
            }

            {
              this.state.isConflictDates &&
              <div className='warning'>{'* El espacio seleccionado ya se encuentra ocupado. Se te permitirá elegir esta cita bajo tu propia responsabilidad.'}</div>
            }

          </div>

        </div>


        <div className='dates'>

        {
          this.props.editDate && 
          <>
            <div>
              <div className='appointment-title'>
                <SVGIcon
                  name={icons.calendar}
                  fill="#AFC8FF"
                  width="20px"
                  height="20px"
                />
                <div style={{width: 10}}/>
                {'Cita anterior'}
              </div>
              <div style={{display: 'flex'}}>
                <div className='appointment-date'>
                  {formatDate(this.props.date)}
                </div>
              </div>

              {
                (this.getOldDateData() !== null) && <div className='space'>
                {`Espacio ${this.getSpaceNumber(this.getOldDateData()?.space_id || 0)}`}
                <div className='tag' style={{backgroundColor: this.getOldDateData()?.tag?.color || 'white'}}>{`${this.getOldDateData()?.tag?.title || ''}`}</div>
              </div>
              }

            </div>

            <div style={{width: 40}}/>
          </>
        }

        <div>
          <div className='appointment-title'>
            <SVGIcon
              name={icons.calendar}
              fill="#AFC8FF"
              width="20px"
              height="20px"
            />
            <div style={{width: 10}}/>
            {'Cita nueva'}
          </div> 
          <div style={{display: 'flex'}}>
            <div className='appointment-date-active'>
              {`${moment(this.state.dateSelected).format('DD MMMM yyyy')}`}
              <input
                style={{color: this.state.spaceSelected === '' ? 'rgb(209, 209, 209)' : 'black'}}
                disabled={this.state.spaceSelected === ''}
                name="endHour"
                type="number"
                value={this.formatNumberToTimePart(
                  currentSchedule.end,
                  true
                )}
                onChange={this.handleTimeInput}
              />
              :
              <input
                style={{color: this.state.spaceSelected === '' ? 'rgb(209, 209, 209)' : 'black'}}
                disabled={this.state.spaceSelected === ''}
                name="endMinute"
                type="number"
                value={this.formatNumberToTimePart(currentSchedule.end)}
                onChange={this.handleTimeInput}
              />
            </div>
          </div>
          
          {
            this.state.spaceSelected !== '' &&
            <div className='space'>
              {`Espacio ${this.getSpaceNumber(this.state.spaceSelected)}`}
              <div className='tag' style={{backgroundColor: this.getSpaceTag()?.color || 'white'}}>{this.getSpaceTag()?.title}</div>
            </div>
          }
        </div>

        <div style={{width: 60}}/>

        {
          this.props.editDate && 
          <div style={{width: 350}}>
            <ModalSelectInput
              menuPlacement="top"
              value={this.state.reasonSelect}
              placeholder={this.reasonTitle()} 
              isSearchable={false}
              name="reasonSelect"
              options={this.props.reasons}
              onChange={ (value) => {
                this.setState({reasonSelect: value});
              }}
            />
          </div>
        }

        </div>


        </div>

        <div className='content-buttons'>

        <Button
          text={'Cancelar'}
          type={'primary outline dark'}
          onClick={() => {this.props.onClose()}}
        />

        <Button
          disabled={!this.isValidForm()}
          text={this.showLoaderInButton() ? <Loader/> : this.getDoneButton()}
          type={'primary'}
          onClick={() => {

            var minString = '0';
            var hourString = '0';

            if (this.state.currentSchedule.end !== null && this.state.currentSchedule.end !== 0) {

              if (`${this.state.currentSchedule.end}`.length === 3) {
                minString = `${this.state.currentSchedule.end}`.substring(1, 3);
                hourString = `${this.state.currentSchedule.end}`.substring(0, 1);
              } else if (`${this.state.currentSchedule.end}`.length === 2) {
                minString = `${this.state.currentSchedule.end}`.substring(0, 2);
              } else if (`${this.state.currentSchedule.end}`.length === 1) {
                minString = `${this.state.currentSchedule.end}`.substring(0, 1);
              } else {
                minString = `${this.state.currentSchedule.end}`.substring(2, 4);
                hourString = `${this.state.currentSchedule.end}`.substring(0, 2);
              }

            }

            const newDate = setHours(setMinutes(this.state.dateSelected,  parseInt(minString)),  parseInt(hourString));

            const params = {
              company_id: this.props.companyId,
              id: this.props.orderId,
              old_date: null,
              new_date: newDate.toISOString(),
              old_space: null,
              new_space: this.state.spaceSelected,
              action: "single_replace",
              submotive: this.state.reasonSelect !== null ? `${this.state.reasonSelect?.value}` : null,
              replaced_order: null
            };

            const operation = this.props?.data?.maneuver || '';
            params.view = (operation.toLowerCase() === 'descarga') ? 'unload' : 'load'

            this.props.setAppointmentDate(params, {
              onSuccess: () => {

                this.setState({
                  successAction: true
                }, () => { 
                  this.props.loadTrips(); 
                })
              },
            });

          }}
        />

        </div>

      </>
    );

  }

  showLoaderInButton = () => {
    if (this.props.editDate) {
      return this.props.isLoadingReasons;
    } else {
      return false;
    }
  }

  reasonTitle = () => {
    if (this.props.editDate) {
      if (this.props.isLoadingReasons) {
        return 'Cargando';
      } else if ((this.props.reasons || []).length === 0) {
        return 'No hay motivos para seleccionar';
      } else {
        return 'Seleccione un motivo';  
      }
    } else {
      return 'Seleccione un motivo';
    }
  }

  getSuccesContent = () => {
    return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{height: 20}}/>
      { this.props.editDate ? 'Se cambió correctamente la cita' : 'Se asignó correctamente la cita'}
      <div style={{height: 40}}/>
      <Button
        text={'Aceptar'}
        type={'primary'}
        onClick={() => {
          this.props.onClose();
        }}
      />
      <div style={{height: 20}}/>
    </div>
  }

  render() {

    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog change-date-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">{ this.props.editDate ? 'Cambio de cita' : 'Asignar cita'}</div>
        {
          !this.state.successAction && <div className="subTitle">{'Esta acción cancela la cita anterior y re asignará la nueva cita al conductor del camión.'}</div>
        }
        <div className="close" onClick={() => {this.props.onClose()}}></div>

        {
          this.state.successAction ?
          this.getSuccesContent() : this.getContent()
        }

      </Modal>
    );
  }

}

const mapStateToProps = (state) => {

  const loading = state.api["COMPANIES.FACILITIES.SPACES.DATA"]?.status?.isFetching || false;
  const loadingDate = state.api["COMPANIES.ORDERS"]?.status?.isFetching || false;
  const data = getEntityItems(state, "COMPANIES.FACILITIES.SPACES.DATA");

  return {
    loading,
    loadingDate,
    info: data?.order || null,
    companyId: state.globalCatalog?.session?.company?.id || 0,
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    getSpaces: (params, opt) => dispatch(readEntity("COMPANIES.FACILITIES.SPACES.DATA", params, opt)),
    setAppointmentDate: (params, opts) => dispatch(partialEntity("COMPANIES.ORDERS", params, opts)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeDateModal);

const windw_space_width = 55;

const TIME_ARRAY = [
  '00:00', '00:05', '00:10', '00:15', '00:20', '00:25', '00:30', '00:35', '00:40', '00:45', '00:50', '00:55', 
  '01:00', '01:05', '01:10', '01:15', '01:20', '01:25', '01:30', '01:35', '01:40', '01:45', '01:50', '01:55',
  '02:00', '02:05', '02:10', '02:15', '02:20', '02:25', '02:30', '02:35', '02:40', '02:45', '02:50', '02:55',
  '03:00', '03:05', '03:10', '03:15', '03:20', '03:25', '03:30', '03:35', '03:40', '03:45', '03:50', '03:55',
  '04:00', '04:05', '04:10', '04:15', '04:20', '04:25', '04:30', '04:35', '04:40', '04:45', '04:50', '04:55',
  '05:00', '05:05', '05:10', '05:15', '05:20', '05:25', '05:30', '05:35', '05:40', '05:45', '05:50', '05:55',
  '06:00', '06:05', '06:10', '06:15', '06:20', '06:25', '06:30', '06:35', '06:40', '06:45', '06:50', '06:55',
  '07:00', '07:05', '07:10', '07:15', '07:20', '07:25', '07:30', '07:35', '07:40', '07:45', '07:50', '07:55',
  '08:00', '08:05', '08:10', '08:15', '08:20', '08:25', '08:30', '08:35', '08:40', '08:45', '08:50', '08:55',
  '09:00', '09:05', '09:10', '09:15', '09:20', '09:25', '09:30', '09:35', '09:40', '09:45', '09:50', '09:55',
  '10:00', '10:05', '10:10', '10:15', '10:20', '10:25', '10:30', '10:35', '10:40', '10:45', '10:50', '10:55',
  '11:00', '11:05', '11:10', '11:15', '11:20', '11:25', '11:30', '11:35', '11:40', '11:45', '11:50', '11:55',
  '12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55',
  '13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55',
  '14:00', '14:05', '14:10', '14:15', '14:20', '14:25', '14:30', '14:35', '14:40', '14:45', '14:50', '14:55',
  '15:00', '15:05', '15:10', '15:15', '15:20', '15:25', '15:30', '15:35', '15:40', '15:45', '15:50', '15:55',
  '16:00', '16:05', '16:10', '16:15', '16:20', '16:25', '16:30', '16:35', '16:40', '16:45', '16:50', '16:55',
  '17:00', '17:05', '17:10', '17:15', '17:20', '17:25', '17:30', '17:35', '17:40', '17:45', '17:50', '17:55',
  '18:00', '18:05', '18:10', '18:15', '18:20', '18:25', '18:30', '18:35', '18:40', '18:45', '18:50', '18:55',
  '19:00', '19:05', '19:10', '19:15', '19:20', '19:25', '19:30', '19:35', '19:40', '19:45', '19:50', '19:55',
  '20:00', '20:05', '20:10', '20:15', '20:20', '20:25', '20:30', '20:35', '20:40', '20:45', '20:50', '20:55',
  '21:00', '21:05', '21:10', '21:15', '21:20', '21:25', '21:30', '21:35', '21:40', '21:45', '21:50', '21:55',
  '22:00', '22:05', '22:10', '22:15', '22:20', '22:25', '22:30', '22:35', '22:40', '22:45', '22:50', '22:55',
  '23:00', '23:05', '23:10', '23:15', '23:20', '23:25', '23:30', '23:35', '23:40', '23:45', '23:50', '23:55'
];