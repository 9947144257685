import React from 'react';
import Button from '../../shared/controls/button';
import SelectInputStyled from '../../shared/controls/select-input-styled';
import { connect } from 'react-redux';
import { getEntityItems, readEntities } from '../../redux/api';
import { cloneDeep } from 'lodash';
import TagsContainer from '../../shared/component/tags-container';
import InputSearch from '../../shared/controls/input-search';
import Checkbox from '../../shared/controls/checkbox';
import { normalizeStr } from '../../shared/utils';
import Modal from "react-modal";
import ItemSelector from './item-selector';
import Loader from '../../shared/icons/tat/loader';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import CheckCircleIcon from '../../shared/icons/check-circle-icon';
import CrossCircleIcon from '../../shared/icons/cross-circle-icon';

class TaxAddressRow extends React.Component {
  state={
    edit: false,
    facilities: [],
    originalFacilities: [],
    vehicles: [],
    originalVehicles: [],
    originalVehiclesIds: [],
    showEditFacilitiesModal: false,
    showEditVehiclesModal: false,
    searchStr: '',
    loading: false
  }

  componentDidMount() {
    if(this.props.companyType === 2){
      this.props.loadFacilitiesList({
        company_id: this.props.companyId,
        tax_info_id: this.props.address.id
      }, {
        onSuccess: (r) => {
          if(r.facilities.length){
            let facilities = r.facilities.map(f => f.id.toString());
            this.setState({facilities, originalFacilities: r.facilities});
          }
        }
      })
    } else {
      // if(this.props.address.type === 3 || this.props.address.type === 4){
        this.props.loadVehiclesList({
          company_id: this.props.companyId,
          tax_info_id: this.props.address.id
        }, {
          onSuccess: (r) => {
            if(r.vehicles.length){
              let vehicles = r.vehicles.map(f => f.fleet_data.toString());
              this.setState({vehicles, originalVehiclesIds: [...vehicles], originalVehicles: r.vehicles});
            }
          }
        })
      // }
    }
  }

  onFacilityChange = (e) => {
    this.setState({facilities: e.target.value});
  } 

  onVehicleChange = (e) => {
    this.setState({vehicles: e.target.value});
  }

  cancelChanges = () => {
    this.setState({
      edit: false,
      facilities: [...this.state.originalFacilities],
      vehicles: [...this.state.originalVehicles]
    })
  }

  saveFacilities = () => {
    this.setState({loading: true})
    // let original = this.state.originalFacilities.map(f => f.id.toString());
    // let addFacilities = this.state.facilities.filter(f => !original.includes(f));
    // let removeFacilities = this.state.originalFacilities.filter(f => !this.state.facilities.includes(f.id.toString()));
    // if(!addFacilities.length && !removeFacilities.length){
    //   this.setState({loading: false, edit: false, showEditFacilitiesModal: false})
    // } else {
      // if(addFacilities.length){
        // this.props.saveFacilities(addFacilities, removeFacilities, () => {this.setState({loading: false, edit: false, showEditFacilitiesModal: false})});
        this.props.saveFacilities(this.state.facilities, () => {this.setState({loading: false, edit: false, showEditFacilitiesModal: false})});
      // } 
      // if(removeFacilities.length){
      //   this.props.removeFacilities(removeFacilities, () => {this.setState({edit: false})});
      // }
    // }
    
  }

  saveVehicles = () => {
    this.setState({loading: true})
    // let original = this.state.originalVehicles.map(f => f.fleet_data.toString());
    // let addVehicles = this.state.vehicles.filter(f => !original.includes(f));
    // let removeVehicles = this.state.originalVehicles.filter(f => !this.state.vehicles.includes(f.fleet_data.toString()));
    // if(!addVehicles.length && !removeVehicles.length){
    //   this.setState({loading: false, edit: false, showEditVehiclesModal: false})
    // } else {
        // this.props.saveVehicles(addVehicles, removeVehicles, () => {
        this.props.saveVehicles(this.state.vehicles.map(v => Number(v)), () => {
          this.setState({loading: false, edit: false, showEditVehiclesModal: false});
          this.props.loadVehiclesList({
            company_id: this.props.companyId,
            tax_info_id: this.props.address.id
          }, {
            onSuccess: (r) => {
              if(r.vehicles.length){
                let vehicles = r.vehicles.map(f => f.fleet_data.toString());
                this.setState({vehicles, originalVehiclesIds: [...vehicles], originalVehicles: r.vehicles});
              } else {
                this.setState({vehicles: [], originalVehiclesIds: [], originalVehicles: []});
              }
            }
          })
        });
    // }
  }

  getTypeDescription = () => {
    let type = this.props.address?.type || 0
    switch(type){
      case 3:
        return ' (Arrendatario)';
      case 4: 
        return ' (Propietario)';
      default:
        return '';
    }
  }

  getFacilities = () => {
    let facilities = this.props.facilities.map(f => f.items).flat();
    // let selectedFacilities = facilities.filter(f => this.state.facilities.includes(f.id.toString()));
    // selectedFacilities = [...new Set(selectedFacilities)];
    // debugger;
    let selectedFacilities = this.state.facilities.map(fId => (facilities.find(sF => sF.id.toString() === fId.toString()) || {}).description);
    return selectedFacilities;
  }
  getVehicles = () => {
    let vehicles = this.props.vehicles.map(f => f.items).flat();
    // let selectedFacilities = vehicles.filter(f => this.state.vehicles.includes(f.id.toString()));
    // selectedFacilities = [...new Set(selectedFacilities)];
    // debugger;
    let selectedFacilities = this.state.vehicles.map(fId => (vehicles.find(sF => sF.id.toString() === fId.toString()) || {}).description);
    return selectedFacilities;
  }

  maxFacilities = () => {
    let facilities = this.props.facilities.map(f => f.items).flat().map(f => f.id);
    // let selectedFacilities = facilities.filter(f => this.state.facilities.includes(f.id.toString()));
    facilities = [...new Set(facilities)];
    // debugger;
    return facilities.length;
  }
  maxVehicles = () => {
    let vehicles = this.props.vehicles.map(f => f.items).flat().map(f => f.id);
    // let selectedFacilities = vehicles.filter(f => this.state.vehicles.includes(f.id.toString()));
    vehicles = [...new Set(vehicles)];

    vehicles = vehicles.filter(v => (!v.is_assembled || (this.state.originalVehiclesIds || []).includes(v.id.toString())))

    return vehicles.length;
  }

  handleFacilitySearch = (name, value) => {
    this.setState({ searchStr: value });
  };
  selectAllFacilities = (e) => {
    let st = [];
    if (e.target.checked) {
      this.props.facilities.forEach((fe) => {
        if ((fe.items || []).length > 0) {
          fe.items.forEach((ch) => {
            if (!st.includes(ch.id)) st.push(ch.id);
          });
        } else {
          st.push(fe.id);
        }
      });
    }
    this.setState({ facilities: st });
  };

  handleFilterListSearch = (items, field, value = "") => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field]).includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    "description",
                    this.state.searchStr
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field]).includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };
  handleSelectFacility = (e, items) => {

    let st = this.state.facilities.map((m) => m.toString()) || [];
    if ((items || {}).length > 0) {
      items.forEach((item) => {
        let val = item.id.toString();
        st = st.filter((a) => a !== val);
        if (e.target.checked) {
          st = st.concat(val);
        }
      });
    } else {
      if (st.includes(e.target.value.toString())) {
        st = st.filter((a) => a.toString() !== e.target.value.toString());
      } else {
        st = st.concat(e.target.value.toString());
      }
    }
    this.setState({ facilities: st });
  };

  handleVehicleSearch = (name, value) => {
    this.setState({ searchStr: value });
  };
  selectAllVehicles = (e) => {
    let st = [];
    if (e.target.checked) {
      this.props.vehicles.forEach((fe) => {
        if ((fe.items || []).length > 0) {
          fe.items.forEach((ch) => {
            if (!st.includes(ch.id) && (!ch.is_assembled || (this.state.originalVehiclesIds || []).includes(ch.id.toString()))) {
              st.push(ch.id)
            }
            // if (!st.includes(ch.id)) st.push(ch.id);
          });
        } else {
          // st.push(fe.id);
        }
      });
    }
    this.setState({ vehicles: st });
  };

  // handleFilterListSearch = (items, field, value = "") => {
  //   return value.length > 0
  //     ? items
  //         .map((i) =>
  //           (i.items || []).length &&
  //           !normalizeStr(i[field]).includes(normalizeStr(value))
  //             ? {
  //                 ...i,
  //                 items: this.handleFilterListSearch(
  //                   i.items,
  //                   "description",
  //                   this.state.searchStr
  //                 ),
  //               }
  //             : i
  //         )
  //         .filter(
  //           (i) =>
  //             normalizeStr(i[field]).includes(normalizeStr(value)) ||
  //             (i.items || []).length
  //         )
  //     : items;
  // };
  handleSelectVehicle = (e, items) => {
// debugger;
    let st = this.state.vehicles.map((m) => m.toString()) || [];
    if ((items || []).length > 0) {
      items.forEach((item) => {
        let val = item.id.toString();
        st = st.filter((a) => a !== val);
        if (e.target.checked && (!item.is_assembled || (this.state.originalVehiclesIds || []).includes(val))) {
          st = st.concat(val);
        }
      });
    } else {
      if (st.includes(e.target.value.toString())) {
        st = st.filter((a) => a.toString() !== e.target.value.toString());
      } else {
        st = st.concat(e.target.value.toString());
      }
    }
    this.setState({ vehicles: st });
  };

  render () {
    return (
      <div className="address-row">
        <div className="row title" style={{lineHeight: "normal"}}>
          {this.props.address?.data?.legal_company_name} - {this.props.address?.data?.fiscal_number} {this.getTypeDescription()}
          <div className="icon-container">
            <img
              alt=""
              src="/images/edit-transparent.svg"
              className="edit-image"
              style={{cursor: "pointer", marginLeft: "10px"}}
              // onClick={this.props.onClick}
              onClick={() => {this.props.history.push(`/company/billingaddress/${this.props.address.id}`)}}
            />
            <img
              alt=""
              src="/images/bin.png"
              style={{ cursor: "pointer"}}
              onClick={() => {this.setState({showDeleteModal: true});}}
            />
          </div>
        </div>
        <div className="row" style={{marginBottom: "20px", fontWeight: "bold"}}>
          Archivo:
          {
            (this.props.address?.type || 0) === 3 || (this.props.address?.type || 0) === 4
            ? <div style={{marginLeft: "10px"}}>No aplica</div>
            : <>
                <div className="icon-container" style={{marginLeft: "10px"}}>
                  {
                    this.props.address?.data?.zip_file
                    ? <CheckCircleIcon />
                    : <CrossCircleIcon />
                  } 
                  .key
                </div>
                <div className="icon-container">
                  {
                    this.props.address?.data?.zip_file
                    ? <CheckCircleIcon />
                    : <CrossCircleIcon />
                  } 
                  .cer
                </div>
              </>
          }
        </div>
        <div className="row">
          {this.props.address?.data?.street} {this.props.address?.data?.exterior_number} {this.props.address?.data?.interior_number}
        </div>
        <div className="row">
          {this.props.address?.data?.zip_code?.cp} 
          {' '}{this.props.address?.data?.suburb?.nombre /*estado === */} 
          {' '}{this.props.address?.data?.city?.descripcion}, 
          {' '}{this.props.address?.data?.state?.descripcion}, 
          {' '}{this.props.address?.data?.country?.descripcion}
        </div>
        <div className="row">
          Correo: {this.props.address?.data?.email} Tel: {this.props.address?.data?.phone_number}
        </div>
        {
          this.props.companyType === 2 &&
            <div className="row" style={{alignItems: "baseline"}}>
              <ItemSelector 
                title="Instalaciones:"
                name="instalaciones"
                tags={[]}
                labels={this.getFacilities()}
                onClick={() => {this.setState({showEditFacilitiesModal: true})}}
              />
              {/* <SelectInputStyled
                selectedItems={this.state.facilities.map(u => u.toString())}
                items={this.props.facilities}
                onChange={(e) => this.onFacilityChange(e)}
                className={""}
                name={"selectedFacility"}
                label={"Instalaciones"}
                multiple={true}
                disabled={!this.state.edit}
              />
              <div
                className="edit-btn"
                onClick={() => {this.setState({edit: !this.state.edit})}}
              >
                <img alt="" src="/images/edit-transparent.svg" />
              </div> */}
            </div>
        }
        {
          this.props.companyType === 1 &&
            <div className="row" style={{alignItems: "baseline"}}>
              <ItemSelector 
                title="Vehículos:"
                name="vehicles"
                tags={[]}
                labels={this.getVehicles()}
                onClick={() => {this.setState({showEditVehiclesModal: true})}}
              />
              {/* <SelectInputStyled
                selectedItems={this.state.vehicles.map(u => u.toString())}
                items={this.props.vehicles}
                onChange={(e) => this.onVehicleChange(e)}
                className={""}
                name={"selectedVehicle"}
                label={"Vehículos"}
                multiple={true}
                disabled={!this.state.edit}
              /> */}
              {/* <div
                className="edit-btn"
                onClick={() => {this.setState({edit: !this.state.edit})}}
              >
                <img alt="" src="/images/edit-transparent.svg" />
              </div> */}
            </div>
        }
        {
          this.state.edit &&
          <div className="row actions">
            <Button
              text="Cancelar"
              type="secondary small"
              onClick={this.cancelChanges}
            />
            <Button
              text="Guardar"
              type="primary small"
              onClick={this.props.companyType === 2 ? this.saveFacilities : this.saveVehicles}
            />
          </div>
        }
        <Modal
          isOpen={this.state.showEditVehiclesModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Vehículos {this.props.address?.data?.legal_company_name} - {this.props.address?.data?.fiscal_number}</div>
          <div
            className="close"
            onClick={() => {this.setState({showEditVehiclesModal: false, searchStr: '', vehicles: [...this.state.originalVehiclesIds]})}}
          ></div>
          <div className="message">
            <div className="facilities-options-container">
              <div className="search-container">
                <InputSearch
                  type="text"
                  label="Buscar vehículos"
                  name="usersFacilitySearch"
                  className="full"
                  onChange={this.handleVehicleSearch}
                />
                <img src="/images/search.svg" alt="" className="icono" />
              </div>
              <div className="facilities-list-container">
                <Checkbox
                  onChange={(e) => this.selectAllVehicles(e)}
                  item={{
                    id: 0,
                    description: "Todos",
                    active:
                      this.state.vehicles.length ===
                      this.maxVehicles(),
                  }}
                  name="facilities"
                  className="bold"
                />
                <div className="separator" />
                <div className="items checks">
                  {
                    // this.props.facilities
                    // .filter(f =>
                    //   normalizeStr(f[this.state.displayFacilityBy]).includes(
                    //     normalizeStr(this.state.searchStr)
                    //   )
                    // )
                    this.handleFilterListSearch(
                      this.props.vehicles || [],
                      "description",
                      this.state.searchStr
                    ).map((i) => {
                      let l = [];
                      l.push(
                        <Checkbox
                          // key={counter++}
                          onChange={(e) =>
                            this.handleSelectVehicle(e, i.items)
                          }
                          item={{
                            id: i.id,
                            description: i.description,
                            active: (this.state.vehicles || []).find(
                              (si) => si.toString() === i.id.toString()
                            )
                              ? true
                              : false,
                          }}
                          name="facilities"
                          className={(i.items || []).length ? "bold" : ""}
                        />
                      );
                      if ((i.items || []).length) {
                        i.items.forEach((ci) =>
                          l.push(
                            <Checkbox
                              // key={counter++}
                              className={"sub-item"}
                              onChange={(e) => this.handleSelectVehicle(e)}
                              disabled={ci.is_assembled && !(this.state.originalVehiclesIds || []).includes(ci.id.toString())}
                              disableMessage={'Ya está ligado a un domiclio.'}
                              item={{
                                id: ci.id,
                                description: ci.description,
                                active: (this.state.vehicles || []).find(
                                  (si) => si.toString() === ci.id.toString()
                                )
                                  ? true
                                  : false,
                              }}
                              name="facilities"
                            />
                          )
                        );
                        l.push(<div className="separator" />);
                        let full = true;
                        i.items.forEach((fe) =>
                          (this.state.vehicles || []).find(
                            (si) => si.toString() === fe.id.toString()
                          ) && full
                            ? (full = true)
                            : (full = false)
                        );
                        l[0].props.item.active = full;
                      }
                      return l;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <Button
              disabled={this.state.loading}
              text={this.state.loading ? <Loader /> : "Guardar"}
              type="primary"
              onClick={this.saveVehicles}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showEditFacilitiesModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Instalaciones {this.props.address?.data?.legal_company_name} - {this.props.address?.data?.fiscal_number}</div>
          <div
            className="close"
            onClick={() => {this.setState({showEditFacilitiesModal: false, searchStr: '', facilities: this.state.originalFacilities.map(f => f.id.toString())})}}
          ></div>
          <div className="message">
            <div className="facilities-options-container">
              <div className="search-container">
                <InputSearch
                  type="text"
                  label="Buscar instalaciones"
                  name="usersFacilitySearch"
                  className="full"
                  onChange={this.handleFacilitySearch}
                />
                <img src="/images/search.svg" alt="" className="icono" />
              </div>
              <div className="facilities-list-container">
                <Checkbox
                  onChange={(e) => this.selectAllFacilities(e)}
                  item={{
                    id: 0,
                    description: "Todos",
                    active:
                      this.state.facilities.length ===
                      this.maxFacilities(),
                  }}
                  name="facilities"
                  className="bold"
                />
                <div className="separator" />
                <div className="items checks">
                  {
                    // this.props.facilities
                    // .filter(f =>
                    //   normalizeStr(f[this.state.displayFacilityBy]).includes(
                    //     normalizeStr(this.state.searchStr)
                    //   )
                    // )
                    this.handleFilterListSearch(
                      this.props.facilities || [],
                      "description",
                      this.state.searchStr
                    ).map((i) => {
                      let l = [];
                      l.push(
                        <Checkbox
                          // key={counter++}
                          onChange={(e) =>
                            this.handleSelectFacility(e, i.items)
                          }
                          item={{
                            id: i.id,
                            description: i.description,
                            active: (this.state.facilities || []).find(
                              (si) => si.toString() === i.id.toString()
                            )
                              ? true
                              : false,
                          }}
                          name="facilities"
                          className={(i.items || []).length ? "bold" : ""}
                        />
                      );
                      if ((i.items || []).length) {
                        i.items.forEach((ci) =>
                          l.push(
                            <Checkbox
                              // key={counter++}
                              className={"sub-item"}
                              onChange={(e) => this.handleSelectFacility(e)}
                              item={{
                                id: ci.id,
                                description: ci.description,
                                active: (this.state.facilities || []).find(
                                  (si) => si.toString() === ci.id.toString()
                                )
                                  ? true
                                  : false,
                              }}
                              name="facilities"
                            />
                          )
                        );
                        l.push(<div className="separator" />);
                        let full = true;
                        i.items.forEach((fe) =>
                          (this.state.facilities || []).find(
                            (si) => si.toString() === fe.id.toString()
                          ) && full
                            ? (full = true)
                            : (full = false)
                        );
                        l[0].props.item.active = full;
                      }
                      return l;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <Button
              disabled={this.state.loading}
              text={this.state.loading ? <Loader /> : "Guardar"}
              type="primary"
              onClick={this.saveFacilities}
            />
          </div>
        </Modal>
        
        <ConfirmDialog
          closeAction={() => {this.setState({showDeleteModal: false})}}
          open={this.state.showDeleteModal}
          title={'Eliminar'}
          message={'¿Seguro que deseas eliminar este domicilio fiscal?'}
          acceptText={'Confirmar'}
          acceptAction={() => this.props.deleteAddress(this.props.address.id, () => { this.setState({showDeleteModal: false}) })}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          // disabledAccept={!this.state.palletName}
          // contentObject={}
        />
      </div> 
    )
  }
}

// export default TaxAddressRow;
const mapStateToProps = (state) => {
  const selectedFacilities = (getEntityItems(state, "COMPANIES.SAT.ADDRESS.FACILITY")["facilities"] || []).map(f => f.id);
  return {
    companyId: state.globalCatalog.session.company.id,
    selectedFacilities,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFacilitiesList: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.FACILITY", params, opt)
      );
    },
    loadVehiclesList: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.VEHICLE", params, opt)
      );
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxAddressRow);