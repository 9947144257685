import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CatalogRow extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    // onSelect: PropTypes.func,
    // selectionMode: PropTypes.bool,
    isSelectable: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isHeightAuto: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    defaultClass: '',
    onClick: () => { console.log('Not implemented yet!'); },
    selected: false,
    isHeightAuto: false
  }

  handleClick = (e) => {
    this.props.onClick({ target: { name: this.props.id, id: e.target.id, value: !this.props.selected } });
  }

  render() {
    const { isSelectable = true } = this.props;

    if (isSelectable) {
      return (
        <div key={this.props.id} className={''.concat('catalog-row', ' ', this.props.defaultClass, ' ', this.props.className)} onClick={this.handleClick} style={ this.props.isHeightAuto ? {height: 'auto'} : {}}>
          <div className='overlay'>
            <div className="checkbox"></div>
          </div>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div key={this.props.id} className={''.concat('catalog-row', ' ', this.props.defaultClass, ' ', this.props.className)} style={ this.props.isHeightAuto ? {height: 'auto'} : {}}>
          <div className='overlay'></div>
          {this.props.children}
        </div>
      );
    }

  }
}

export default CatalogRow;