import React from "react";
import { PurchaseContainer } from "./style";
import Button from "../../../shared/controls/button";

class PaymentsPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { credits } = this.props.data;
    return (
      <PurchaseContainer>
        <div>SALDO AL DIA DE HOY</div>
        <div className="info">
          <img src="/images/orbicoin.png" alt=""/>
          <div className="qty">
            <span className="money">{credits}</span>
            <span>ORBIBUCKS</span>
          </div>
          <Button 
            text="Comprar saldo" 
            type="primary" 
            onClick={() => {}} 
            disabled={true}
          />
        </div>
      </PurchaseContainer>
    );
  }
}

export default PaymentsPurchase;
