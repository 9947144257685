import React from "react";
import PropTypes from "prop-types";
// import ReactTooltip from "react-tooltip";
import ValidatePerms from "./validate-perms";
import SubStringText from "./substring-text";
import { isArray } from "lodash";

/* Types of profiles

    USER-PROFILE:
        name: String
        userType: String
        alias: String
        mainImage: String
        isActive: Bool
        clickEditBtn: Function

    COMPANY-PROFILE:
        name: String
        alias: String
        mainImage: String
        address: String,
        email: String,
        clickEditBtn: Function

    FACILITY-PROFILE:
        name: String
        alias: String
        mainImage: String
        isValid: Bool,
        address: String,
        email: String,
        clickEditBtn: Function,
        adminNumber: String
        warehouseNumber: Strins,
        tags: [String]

    FLOTILLA-VEHICLE:
        name: String
        alias: String
        mainImage: String
        secondImage: String
        isValid: Bool,
        clickEditBtn: Function,
        serie: String,
        motor: String,
        owner: String

    FLOTILLA-EQUIPMENT:
        name: String
        alias: String
        mainImage: String
        secondImage: String
        isValid: Bool,
        serie: String,
        motor: String,
        owner: String

    FLOTILLA-OPERATOR:
        name: String
        alias: String
        mainImage: String
        secondImage: String
        isValid: Bool,
        ine: String,
        rControl: String,
        workWith: String

    Esta información debe estar dentro de un prop con el nomber de 'data' y el tipo de perfil 'type'.

*/

class ProfileInformation extends React.Component {
  renderEditInfo = (type) => {
    const {
      data: {
        id = "",
        userType = "",
        facilityID = "",
        clickEditBtn = () => {},
        showEditUserType = false,
        clickEditUserType = () => {}
      } = {},
      companyUsers = [],
      color = "#05c985",
    } = this.props;

    const storage = JSON.parse(
      window.sessionStorage.getItem("orbinetwork_session")
    );
    // const company = storage.company
    const user = storage.user;

    const currentUserData = ( companyUsers !== null && isArray(companyUsers) ) ? companyUsers.find( (value) => {
      return `${value.users}` === `${user.id}`
    }) : null;

    switch (type) {
      case "USER-PROFILE":
        return (
          <div className="profile-information edit-info">
            <div
              className="profile-information edit-info edit-user"
              style={{ color, justifyContent: 'flex-start' }}
            >
              {userType}
              {
                (String(id) !== String(user.id) && showEditUserType && currentUserData !== null && currentUserData !== undefined && ( `${currentUserData.users_type}` === '5' || `${currentUserData.users_type}` === '6' )) && (
                  <div
                    style={{width: 20, height: 20, cursor:'pointer'}}
                    onClick={clickEditUserType}
                  >
                    <img alt="" src="/images/edit-transparent.svg" />
                  </div>
                )
              }
            </div>

            {String(id) === String(user.id) && (
              <div
                className="profile-information edit-info edit-btn"
                onClick={clickEditBtn}
              >
                <img alt="" src="/images/edit-transparent.svg" />
              </div>
            )}
          </div>
        );

      case "FLOTILLA-VEHICLE":
      case "COMPANY-PROFILE":
        return (
          <div className="profile-information edit-info">
            <div />
            <ValidatePerms
              perms={[
                {
                  id: "10",
                  perm: "u",
                },
              ]}
            >
              <div
                className="profile-information edit-info edit-btn"
                onClick={clickEditBtn}
              >
                <img alt="" src="/images/edit-transparent.svg" />
              </div>
            </ValidatePerms>
          </div>
        );

      case "FACILITY-PROFILE":
        return (
          <div className="profile-information edit-info">
            <div className="profile-information edit-info edit-id">
              {facilityID}
            </div>

            <div
              className="profile-information edit-info edit-btn"
              onClick={clickEditBtn}
            >
              <img alt="" src="/images/edit-transparent.svg" />
            </div>
          </div>
        );

      default:
        return <div style={{ height: "55px" }} />;
    }
  };

  // getEmails = (emails) => {
  //   let text = "";

  //   String(emails)
  //     .split(",")
  //     .forEach((e) => {
  //       text += `${e}</br>`;
  //     });

  //   return text;
  // };

  renderLocationSection = (type) => {
    const {
      data: {
        accountStatus = "",
        lastLogin = "",
        address = "",
        email = "",
        webPage = "",
        adminNumber = "",
        warehouseNumber = "",
        ine = "",
        serie = "",
        rControl = "",
        motor = "",
        workWith = "",
        owner = "",
        tags = [],
      } = {},
    } = this.props;

    switch (type) {
      case "USER-PROFILE":
        return (
          <div className="profile-information sesion-container">
            <div className="last-sesion">Última sesión: {lastLogin}</div>
            <div className="connection-status">
              <img
                alt=""
                src={"/images/white-check.svg"}
                className="icon-active"
              />
              {/* {isActive ? 'Activo' : 'Inactivo'} */}
              {accountStatus}
            </div>
          </div>
        );

      case "COMPANY-PROFILE":
        return (
          <div className="profile-information location-container">
            <div className="address">
              <div style={{ margin: "30px" }}>
                <img
                  alt=""
                  src="/images/location-pin.svg"
                  style={{ marginRight: "20px" }}
                />
                {address}
              </div>
            </div>

            <div className="email">{webPage}</div>

            {/* <div
              className="icon"
              data-tip={this.getEmails(email)}
              data-for="orbi-tooltip"
            >
              @
            </div>

            <ReactTooltip
              id={"orbi-tooltip"}
              place="right"
              type="light"
              effect="solid"
              style={{ maxWidth: "380px", fontSize: "16px" }}
              html={true}
            /> */}
          </div>
        );

      case "FACILITY-PROFILE":
        return (
          <div>
            <div className="profile-information address-section">
              <div className="location">
                <div className="icon">
                  <img alt="" src="/images/location-pin.svg" />
                </div>
                {address}
              </div>

              <div className="tags-container">
                <div className="title">Etiquetas:</div>

                <div className="tags">
                  {tags.map((tag, index) => (
                    <div className="tags tag" key={index}>
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="profile-information facility-section">
              <div className="email">{email}</div>

              <div className="users-container">
                <img alt="" src="/images/user-icon-blue.svg" className="icon" />
                <div>{adminNumber}</div>
                <img
                  alt=""
                  src="/images/user-icon-golden.svg"
                  className="icon"
                />
                <div>{warehouseNumber}</div>
              </div>
            </div>
          </div>
        );

      case "FLOTILLA-OPERATOR":
      case "FLOTILLA-EQUIPMENT":
      case "FLOTILLA-VEHICLE":
        return (
          <div className="profile-information address-section">
            <div className="section">
              <div className="title">
                {type === "FLOTILLA-OPERATOR" ? "INE" : "Número de serie:"}
              </div>
              <div className="value">
                {type === "FLOTILLA-OPERATOR" ? ine : serie}
              </div>
            </div>

            <div className="section">
              <div className="title">
                {type === "FLOTILLA-OPERATOR"
                  ? "R Control"
                  : "Número de motor:"}
              </div>
              <div className="value">
                {type === "FLOTILLA-OPERATOR" ? rControl : motor}
              </div>
            </div>

            <div className="section">
              <div className="title">
                {type === "FLOTILLA-OPERATOR"
                  ? "Trabaja para:"
                  : "Dueño transportista:"}
              </div>
              <div className="value">
                {type === "FLOTILLA-OPERATOR" ? workWith : owner}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  renderImageProfile = (type) => {
    const { data: { mainImage = "", secondImage = "" } = {} } = this.props;

    switch (type) {
      case "USER-PROFILE":
        return (
          <div className="profile-image-container">
            <img alt="" src={mainImage} className="profile-image" />
            <div className="profile-indicator" />
          </div>
        );

      case "FACILITY-PROFILE":
      case "COMPANY-PROFILE":
        return (
          <div className="profile-image-container">
            <img alt="" src={mainImage} className="profile-image" />
          </div>
        );

      case "FLOTILLA-EQUIPMENT":
      case "FLOTILLA-OPERATOR":
      case "FLOTILLA-VEHICLE":
        return (
          <div className="profile-image-container">
            <img alt="" src={mainImage} className="profile-image" />
            <img alt="" src={secondImage} className="secondary-image" />
          </div>
        );

      default:
        return null;
    }
  };

  renderName = (type) => {
    const {
      data: {
        name = "",
        alias = "",
        isValid = false,
        birthDate = "",
        cel = "",
      } = {},
    } = this.props;

    switch (type) {
      case "FLOTILLA-EQUIPMENT":
      case "FLOTILLA-VEHICLE":
        return (
          <div>
            <SubStringText
              label={name}
              labelTag={"div"}
              labelSize={20}
              labelClass={"profile-information name"}
              tooltipClass={"tooltip-class-name"}
            />
            {/* <div className="profile-information name">{name}</div> */}
            <div className="profile-information alias">{alias}</div>

            <div
              className="profile-information validation-section"
              style={!isValid ? { color: "red" } : {}}
            >
              <img alt="" src={"/images/check.svg"} className="icon" />
              {isValid ? "Validado" : "Rechazado"}
            </div>
          </div>
        );

      case "FLOTILLA-OPERATOR":
        return (
          <div>
            <SubStringText
              label={name}
              labelTag={"div"}
              labelSize={20}
              labelClass={"profile-information name"}
              tooltipClass={"tooltip-class-name"}
            />
            {/* <div className="profile-information name">{name}</div> */}
            <div className="profile-information birthDate">{birthDate}</div>
            <div className="profile-information cel">{cel}</div>

            <div
              className="profile-information validation-section"
              style={!isValid ? { color: "red" } : {}}
            >
              <img alt="" src={"/images/check.svg"} className="icon" />
              {isValid ? "Validado" : "Rechazado"}
            </div>
          </div>
        );

      default:
        return (
          <div>
            <SubStringText
              label={name}
              labelTag={"div"}
              labelSize={20}
              labelClass={"profile-information name"}
              tooltipClass={"tooltip-class-name"}
            />
            {/* <div className="profile-information name">{name}</div> */}
            <div className="profile-information alias">{alias}</div>
          </div>
        );
    }
  };

  render() {
    const { type = "USER-PROFILE", color = "#05c985" } = this.props;

    return (
      <div className="profile-information">
        {this.renderImageProfile(type)}

        <div
          className="profile-information container"
          style={
            type === "USER-PROFILE" ? { borderTop: `3px solid ${color}` } : {}
          }
        >
          {this.renderEditInfo(type)}

          {this.renderName(type)}

          {this.renderLocationSection(type)}
        </div>
      </div>
    );
  }
}

ProfileInformation.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default ProfileInformation;
