//@flow
import * as React from "react";
import Caret from "../../../shared/component/caret";
import type { RouterHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../shared/controls/button";
import { ActionsTricolon } from "../../../shared/controls/actions-tricolon";
import * as Types from "../types";
import RowD from "../../../shared/component/row-d";
import UnassignedShipmentDetailTable from "./unassigned-shipment-detail-table";
import Tag from "../../../shared/controls/tag";
import ExceedPlus from "../../../shared/controls/exceed-plus";
// import ReactTooltip from "react-tooltip";
// import { listCompanyUsersFacilities } from "../../../redux/reducers/company-reducer";
import CopyIcon from '../../../shared/icons/copy-icon';

type Props = {
  ...Types.UnassignedShipmentRowType,
  history: RouterHistory,
};

type State = {
  collapsed: boolean,
};

class UnassignedShipmentRow extends React.Component<Props, State> {
  state = {
    collapsed: true,
    isHovered: false
  };

  componentDidUpdate(prevProps) {
    if(prevProps.collapsed !== this.props.collapsed){
      this.setState({collapsed: this.props.collapsed});
    }
  }

  render() {
    const primaryAction = this.props
      .actions()
      .find((a) => a.type === "primary");
    const secondaryAction = this.props
      .actions()
      .find((a) => a.type === "secondary");
    const extraActions = this.props.actions().filter((a) => a.type === "extra");
    const detailActions = this.props
      .actions()
      .filter((a) => a.type.includes("detail"));

    const missingAppointment = this.props.details.some(d => 
      (d.origin.facilityId.toString() === this.props.currentFacilityId &&
        !d.origin.date) /* ||
      (d.destination.facilityId.toString() === this.props.currentFacilityId &&
        !d.destination.date) */
    )
      
    return (
      <React.Fragment>
        {/* <ReactTooltip
          id={this.props.id.toString()}
        >{`viaje ${this.props.id}`}</ReactTooltip> */}
        <RowD {...this.props}>
          <CaretContainer
            onClick={() => this.setState({ collapsed: !this.state.collapsed })}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <Caret up={this.state.collapsed} fill="#AFC8FF" isHovered={this.state.isHovered} />
          </CaretContainer>
          <CompanyAvatar
          // data-place="right"
          // data-tip
          // data-for={this.props.id.toString()}
          >
            <img
              src={this.props.companyLogo || "/images/interrogacion.svg"}
              alt=""
            />
            <Text>{this.props.companyName}</Text>
          </CompanyAvatar>
          <Name>{this.props.mainFacility.map(f => {
            return <>{f}</>
          })}</Name>
          {
            <div style={{display: 'flex', alignItems: 'center', margin: 'auto'}}>
              <Text
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.history.push(
                    `/company/controls/shipment/orderdetail/${this.props.id}?facilityId=${this.props.facilityId}`
                  )
                }
              >
                {this.props.tripNumber}
              </Text>
              {
                this.props.tripNumber !== "" ?
                <CopyIcon className="copy-icon-style" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(this.props.tripNumber);}}/> : ""
              }
            </div>
          }
          <Text>{this.props.tickets}</Text>
          <Text>{this.props.class}</Text>
          <TagsContainer name={this.props.id}>
            {this.props.tags.map((t, index) => (
              <Tag {...t} key={index} />
            ))}
          </TagsContainer>
          <Text>{this.props.status}</Text>
          <Text>{this.props.assemble}</Text>
          <ActionsContainer>
            {secondaryAction && (
              <Button
                onClick={secondaryAction.action}
                type={`${secondaryAction.type} small`}
                text={secondaryAction.description}
                name="secondary"
              />
            )}
            {primaryAction && (
              <Button
                onClick={primaryAction.action}
                type={`${primaryAction.type} small`}
                text={primaryAction.description}
                name="primary"
              />
            )}
            {
              missingAppointment && (this.props.status !== "Cancelado" && this.props.status !== "Finalizado sin datos") && "Asignar Cita"
            }
            {extraActions && extraActions.length ? <ActionsTricolon actions={extraActions} tooltipPlacement={"left"}/> : ''}
          </ActionsContainer>
        </RowD>
        {!this.state.collapsed && ( 
          <UnassignedShipmentDetailTable
            history={this.props.history}
            facilityId={this.props.facilityId}
            orderId={this.props.id}
            details={this.props.details}
            actions={detailActions}
            currentFacilityId={this.props.currentFacilityId}
            setOrderDetail={orderToSelect => {
              if (this.props.setOrderDetail) {
                this.props.setOrderDetail(orderToSelect)
              }
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UnassignedShipmentRow;

const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: row;
  & img {
    width: 48px;
    min-height: 48px;
    border-radius: 24px;
    margin-right: 6px;
  }
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

const Name = styled.div`
  align-self: center;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 135px;
`;

const Text = styled.div`
  align-self: center;
  width: 100%;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const TagsContainer = styled(ExceedPlus)`
  justify-content: center;
  width: 100%;
`;
