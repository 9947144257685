import React from "react";
import { connect } from "react-redux";
import { getEntityItems, readEntities, readEntity } from "../../redux/api";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import MenuAlertsDetailsCards from "./details-cards";
import { AlertsNoFound } from "./styled";

const TODAY = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const YESTERDAY = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  (new Date().getDate()) - 1 
);

class MenuAlertsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},

      showReadAlertModal: false,
      showUnreadAlertModal: false,
      showDeleteAlertModal: false,
      showUnsubscribeAlertModal: false,

      selectedAlertId: 0
    };
  }

  componentDidMount() {
    this.loadAlerts();
  }

  componentDidUpdate(prevProps) {
    const { currentOpc } = this.props;
    if (prevProps.currentOpc !== currentOpc) {
      if (currentOpc !== "alerts") {
        this.setState({
          cards: {
            today: [],
            yesterday: [],
            daysago: [
              // {
              //   id: 1,
              //   type: 4,
              //   title: "Orden de Compra",
              //   time: "22:17",
              //   description:
              //     "El cliente 335409223 creo un nuevo pedido para la planta toluca…",
              // },
            ],
          },
        });
      }
    }
  }

  loadAlerts = () => {
    let action = "read_notifications"
    this.props.loadAlertDetail(
      this.props.companyId,
      this.props.userData,
      action
    );
  }

  //Actions
  markRead = () => {
    this.props.attendAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId,
      attended: true
    }, {
      onSuccess: () => {
        this.setState({showReadAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  markUnread = () => {
    this.props.attendAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId,
      attended: false
    }, {
      onSuccess: () => {
        this.setState({showUnreadAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  deleteAlert = () => {
    this.props.deleteAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId
    }, {
      onSuccess: () => {
        this.setState({showDeleteAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  unsubscribeAlert = () => {
    this.props.unsubscribeAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId
    }, {
      onSuccess: () => {
        this.setState({showUnsubscribeAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  render() {
    let today = []; 
    let yesterday = [];
    let daysago = [];
    if(this.props.currentOpc === "alerts"){
      today = this.props.alert_details.filter(aD => {
        let currentDate = new Date(aD.created);
        return currentDate.getTime() >= TODAY.getTime()
      }).map(aD => {
        let alertDate = new Date(aD.created);
        return {
        id: aD.id,
        type: 1,
        title: "Alerta",
        time: ''.concat(alertDate.getHours(), ':', alertDate.getMinutes()),
        description: aD.message,
        level: aD.level,
        object: aD.object,
        facility: aD.facility,
        alert: aD.alert,
        notification: aD.notification,
        alert_date: alertDate,
        attended: aD.attended,
        actions: [
          {
            description: aD.attended ? "Marcar como no leída" : "Marcar como leída",
            action: () => {this.setState({[aD.attended ? "showUnreadAlertModal" : "showReadAlertModal"]: true, selectedAlertId: aD.id})}
          },
          {
            description: "Eliminar alerta",
            action: () => {this.setState({showDeleteAlertModal: true, selectedAlertId: aD.id})}
          },
          // {
          //   description: "De-suscribir de la alerta",
          //   action: () => {this.setState({showUnsubscribeAlertModal: true, selectedAlertId: aD.id})}
          // }
        ]
      }});
      yesterday = this.props.alert_details.filter(aD => {
        let currentDate = new Date(aD.created);
        return currentDate.getTime() >= YESTERDAY.getTime() && currentDate.getTime() < TODAY.getTime()
      }).map(aD => {
        let alertDate = new Date(aD.created);
        return {
        id: aD.id,
        type: 1,
        title: "Alerta",
        time: ''.concat(alertDate.getHours(), ':', alertDate.getMinutes()),
        description: aD.message,
        level: aD.level,
        object: aD.object,
        facility: aD.facility,
        alert: aD.alert,
        notification: aD.notification,
        alert_date: alertDate,
        attended: aD.attended,
        actions: [
          {
            description: aD.attended ? "Marcar como no leída" : "Marcar como leída",
            action: () => {this.setState({[aD.attended ? "showUnreadAlertModal" : "showReadAlertModal"]: true, selectedAlertId: aD.id})}
          },
          {
            description: "Eliminar alerta",
            action: () => {this.setState({showDeleteAlertModal: true, selectedAlertId: aD.id})}
          },
          // {
          //   description: "De-suscribir de la alerta",
          //   action: () => {this.setState({showUnsubscribeAlertModal: true, selectedAlertId: aD.id})}
          // }
        ]

      }});
      daysago = this.props.alert_details.filter(aD => {
        let currentDate = new Date(aD.created);
        return currentDate.getTime() < YESTERDAY.getTime()
      }).map(aD => {
        let alertDate = new Date(aD.created);
        return {
        id: aD.id,
        type: 1,
        title: "Alerta",
        time: ''.concat(alertDate.getHours(), ':', alertDate.getMinutes()),
        description: aD.message,
        level: aD.level,
        object: aD.object,
        facility: aD.facility,
        alert: aD.alert,
        notification: aD.notification,
        alert_date: alertDate,
        attended: aD.attended,
        actions: [
          {
            description: aD.attended ? "Marcar como no leída" : "Marcar como leída",
            action: () => {this.setState({[aD.attended ? "showUnreadAlertModal" : "showReadAlertModal"]: true, selectedAlertId: aD.id})}
          },
          {
            description: "Eliminar alerta",
            action: () => {this.setState({showDeleteAlertModal: true, selectedAlertId: aD.id})}
          },
          // {
          //   description: "De-suscribir de la alerta",
          //   action: () => {this.setState({showUnsubscribeAlertModal: true, selectedAlertId: aD.id})}
          // }
        ]
      }});
    } else {
      today = this.state.cards.today;
      yesterday = this.state.cards.yesterday;
      daysago = this.state.cards.daysago;
    }
    
    
    return (
      <React.Fragment>
        <div style={{fontWeight: "bold"}}>Hoy</div>
        {today && today.length ? (
          (today || []).map((card) => {
            return <MenuAlertsDetailsCards history={this.props.history} card={card} key={card.id} />;
          })
        ) : (
          <AlertsNoFound>No Existen alertas</AlertsNoFound>
        )}
        <div style={{fontWeight: "bold"}}>Ayer</div>
        {yesterday && yesterday.length ? (
          (yesterday || []).map((card) => {
            return <MenuAlertsDetailsCards history={this.props.history} card={card} key={card.id} />;
          })
        ) : (
          <AlertsNoFound>No Existen alertas</AlertsNoFound>
        )}
        <div style={{fontWeight: "bold"}}>Más antiguas</div>
        {daysago && daysago.length ? (
          (daysago || []).map((card) => {
            return <MenuAlertsDetailsCards history={this.props.history} card={card} key={card.id} />;
          })
        ) : (
          <AlertsNoFound>No Existen alertas</AlertsNoFound>
        )}
        <ConfirmDialog
          open={this.state.showReadAlertModal}
          title="Marcar como leída"
          message={"¿Estás seguro que deseas marcar esta alerta como leída?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showReadAlertModal: false })}
          acceptAction={this.markRead}
        />
        <ConfirmDialog
          open={this.state.showUnreadAlertModal}
          title="Marcar como no leída"
          message={"¿Estás seguro que deseas marcar esta alerta como no leída?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showUnreadAlertModal: false })}
          acceptAction={this.markUnread}
        />
        <ConfirmDialog
          open={this.state.showDeleteAlertModal}
          title="Eliminar alerta"
          message={"¿Estás seguro que deseas eliminar esta alerta?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showDeleteAlertModal: false })}
          acceptAction={this.deleteAlert}
        />
        <ConfirmDialog
          open={this.state.showUnsubscribeAlertModal}
          title="De-suscribirse de la alerta"
          message={"¿Estás seguro que deseas de-suscribirte de esta alerta?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showUnsubscribeAlertModal: false })}
          acceptAction={this.unsubscribeAlert}
        />
      </React.Fragment>
      // <></>
    );
  }
}


const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  let alert_details = getEntityItems(state, "COMPANIES.CATALOGS.ALERTCOUNTER.DETAIL");
  return {
    companyId,
    userData: state.globalCatalog.session.company?.companyuser?.id || 0,
    alert_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    loadAlertDetail: (company_id,user_company,action) =>{
      dispatch(
        readEntities("COMPANIES.CATALOGS.ALERTCOUNTER.DETAIL", { company_id, user_company,action })
      );
    },
    attendAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.ATTENDED", params, opt)
      );
    },
    deleteAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.DELETED", params, opt)
      );
    },
    unsubscribeAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.UNSUBSCRIBED", params, opt)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuAlertsDetails);