import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';
import InputTagger from '../controls/inputTagger';
import Resume from '../component/resume';
import Checkbox from '../controls/checkbox';

class AddEmailsModal extends React.Component {

  handleInput = (e) => {
    if (e)
      this.props.acceptAction(e);
  }
  onClose = () => {
    if (this.props.closeAction) {
      this.props.closeAction();
    }
  }

  sendButtonSettings = {
    text: 'Enviar Invitación',
    onClick: (e) => {
      this.props.inviteDone ?
        this.onClose() :
        this.handleInput({ target: { name: 'sendInvitations', value: '' } })
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog emails-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        {/* <div className="title">{this.props.isSent === true ? '¡Listo!' : this.props.title}</div> */}
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">
          {this.props.inviteDone === false ?
            <InputTagger
              placeholder='Ingresa el o los correos separados por "enter"'
              facilityId={this.props.facilityId}
              tags={this.props.tags}
              onChange={this.handleInput}
            />
            :
            <Resume tags={this.props.tags} />
          }
        </div>
        {!this.props.inviteDone ?
          <div className='admins'>
            <Checkbox
              className=''
              onChange={this.handleInput}
              item={{
                description: 'Invitar a los Administradores',
                active: this.props.inviteAdmins,
                id: 0
              }}
              name='admins'
            />
          </div>
          : <div> </div>
        }
        <div className="actions">
          {
            this.props.inviteDone ?
              <Button text='Finalizar' />
              :
              <Button name='sendInvitations' settings={this.sendButtonSettings} />
          }
        </div>
      </Modal>
    )
  }
}

export default AddEmailsModal;