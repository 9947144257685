import React from "react";
// import FilterSearchComponent from "../../shared/component/filter/search";
import TagCategoryContainer from "../../shared/component/tag-category-container";
import EditTagsModal from "../../shared/dialog/edit-tags-modal";
import {
  loadTags,
  createTag,
  updateTag,
  deleteTag,
} from "../../redux/reducers/tags.reducer";
import { getSessionCompanyData } from "../../redux/reducers/company-reducer";
import { connect } from "react-redux";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import Tag from "../../shared/controls/tag";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
import TextInput from "../../shared/controls/text-input";
import { normalizeStr } from "../../shared/utils";
import ValidatePerms from "../../shared/component/validate-perms";
import { SaveItemCounter } from "../../redux/api/save-item-counter";

class TagsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditModal: false,
      openDeleteModal: false,
      selectedTags: [],
      selectedCategory: 0,
      selectionModeOn: false,
      searchText: "",
      tagDeletionSummary: [],
    };
  }

  componentDidMount() {
    this.props.getCompanyData();
    this.props.reloadTags();
  }

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value || "" });
  };

  handleTagClick = (e) => {
    this.setState({
      selectedTags: [e.target.id],
      selectedCategory: e.target.tag.type,
    });
    this.showEditModal();
  };
  handleCreateClick = (categoryId) => {
    this.setState({ selectedTags: [], selectedCategory: categoryId });
    this.showEditModal();
  };
  selectTag = (e) => {
    let selectedTags = [...this.state.selectedTags];
    if (selectedTags.includes(e.target.id)) {
      selectedTags.splice(selectedTags.indexOf(e.target.id), 1);
    } else {
      selectedTags.push(e.target.id);
    }
    this.setState({ selectedTags: selectedTags });
  };
  selectAllTags = () => {
    let categoryTags = this.props.tagList.filter(
      (t) => t.type === this.state.selectedCategory
    );
    if (this.state.selectedTags.length < categoryTags.length) {
      this.setState({ selectedTags: categoryTags.map((tag) => tag.id) });
    } else {
      this.setState({ selectedTags: [] });
    }
  };
  showDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };
  closeDeleteModal = (summary: boolean = false) => {
    if (summary) {
      this.setState({ tagDeletionSummary: [] });
    } else {
      this.setState({ openDeleteModal: false, selectedTags: [] });
      this.props.reloadTags();
    }
  };
  showEditModal = () => {
    this.setState({ openEditModal: true });
  };
  closeEditModal = () => {
    this.setState({ openEditModal: false });
  };
  onAccept = (newParams, oldParams) => {
    if (this.state.selectedCategory !== "roles") {
      //Tags
      let tagParams = {
        title: newParams.title,
        color: newParams.color,
        type: this.state.selectedCategory,
        company: this.props.companyId,
      };
      if (this.state.selectedTags.length) {
        //edit tags
        if (this.state.selectedTags.length > 1) {
          // multiple, keep same names new color
          oldParams.forEach((tag) => {
            tagParams.tag_id = tag.id;
            tagParams.title = tag.title;
            this.saveTagChanges(tagParams);
          });
        } else {
          // single, new name & color
          tagParams.tag_id = this.state.selectedTags[0];
          this.saveTagChanges(tagParams);
        }
      } else {
        //create tag
        this.createTag(tagParams);
      }
    } else {
      //Roles
      let tagParams = {
        description: newParams.title,
        color: newParams.color,
        company_id: this.props.companyId,
      };
      let tagPermissions = newParams.permissions;
      if (this.state.selectedTags.length) {
        if (this.state.selectedTags.length > 1) {
          oldParams.forEach((rol) => {
            tagParams.rol_id = rol.id;
            tagParams.description = rol.description;
            this.saveRolChanges(tagParams);
          });
        } else {
          tagParams.rol_id = this.state.selectedTags[0];
          tagPermissions.forEach((permission) => {
            //company_id, rol_id, permission_id, p_create, p_read, p_update, p_delete
            this.props
              .updateRolPermissions({
                company_id: this.props.companyId,
                rol_id: this.state.selectedTags[0],
                permission_id: permission.id,
                p_create: permission.p_create,
                p_read: permission.p_read,
                p_update: permission.p_update,
                p_delete: permission.p_delete,
              })
              .then(() => this.props.reloadRoles());
          });
          this.saveRolChanges(tagParams);
        }
      } else {
        this.props.createRol(tagParams).then((result) => {
          this.props
            .getCompanyRolPermissions(result.id)
            .then((permissionsData) => {
              if (permissionsData) {
                tagPermissions.forEach((tP) => {
                  this.props
                    .updateRolPermissions({
                      company_id: this.props.companyId,
                      rol_id: result.id,
                      permission_id: permissionsData.find(
                        (pD) => pD.module_permission === tP.id
                      ).id,
                      p_create: tP.p_create,
                      p_read: tP.p_read,
                      p_update: tP.p_update,
                      p_delete: tP.p_delete,
                    })
                    .then(() => this.props.reloadRoles());
                });
              }
            });
          this.props.reloadRoles();
        });
      }
    }
  };
  createTag = (tagParams) => {
    this.props.createTag(tagParams).then(() => this.props.reloadTags());
    this.setState({ openEditModal: false });
  };
  saveTagChanges = (tagParams) => {
    this.props.updateTag(tagParams).then(() => this.props.reloadTags());
    this.closeEditModal();
  };
  deleteTags = () => {
    let c = new SaveItemCounter(this.closeDeleteModal);
    this.setState({ tagDeletionSummary: [] });

    this.state.selectedTags.forEach((tagId) => {
      c.add();
      this.props.deleteTag(tagId).then((r) => {
        c.done();
        if (r.error) {
          r.error.then((l) =>
            this.setState({
              tagDeletionSummary: this.state.tagDeletionSummary.concat(tagId),
            })
          );
        }
      });

      // this.props.deleteTag(tagId).then(() => this.props.reloadTags());
    });
    // this.closeDeleteModal();
  };
  toggleSelectionMode = (categoryId) => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedCategory: categoryId,
      selectedTags: [],
    });
  };

  getTitleTab = (title) => {
    if (this.props.companyType === 1) {
      switch (title) {
        case "Flotilla":
          return "Bases";

        default:
          return title;
      }
    }
    if(title === "Operaciones")
      return "Ordenes"
    return title;
  };

  render() {
    return (
      <ValidatePerms perms={this.props.permsRead}>
        <div className="tags-tab">
          <div className="header">
            <div className="sorts"></div>
            <div className="search">
              <TextInput
                className="search-input"
                value={this.state.searchText}
                label="Buscar"
                name="search"
                onChange={this.handleSearch}
                suffix={{
                  name: "",
                  element: (
                    <img src="/images/search.svg" alt="" className="icono" />
                  ),
                }}
              />
            </div>
          </div>
          {this.props.tagCategories
            .filter(
              (tC) =>
                (this.props.companyInfo.type === 1 &&
                  tC.description === "Flotilla") ||
                (this.props.companyInfo.type === 2 &&
                  tC.description !== "Roles" &&
                  tC.description !== "Flotilla" &&
                  tC.description !== "Ordenes")
            )
            .map((category) => {
              // if(this.props.companyInfo && this.props.companyInfo.type === 2){
              return (
                <TagCategoryContainer
                  key={category.id}
                  id={category.id}
                  title={this.getTitleTab(category.description)}
                  tags={this.props.tagList.filter(
                    (t) =>
                      t.type === category.id &&
                      normalizeStr(t.title).includes(
                        normalizeStr(this.state.searchText)
                      )
                  )}
                  selectedTags={this.state.selectedTags}
                  selectedCategory={this.state.selectedCategory}
                  handleTagClick={this.handleTagClick}
                  handleCreateClick={this.handleCreateClick}
                  selectTag={this.selectTag}
                  selectionModeOn={
                    this.state.selectionModeOn &&
                    this.state.selectedCategory === category.id
                  }
                  toggleSelectionMode={this.toggleSelectionMode}
                  // {...this.props}
                  evalTabs={this.props.evalTabs}
                  permsTabs={this.props.permsTabs}
                  permsAdd={this.props.permsAdd}
                  permsUpdate={this.props.permsUpdate}
                  validateObjPerms={this.props.validateObjPerms}
                />
              ); /*  : (
              ""
              ); */
              // } else {

              // }
              // return
            })}
          <EditTagsModal
            open={this.state.openEditModal}
            tags={this.props.tagList.filter((t) =>
              this.state.selectedTags.includes(t.id)
            )}
            permissions={[]}
            category={this.state.selectedCategory}
            acceptAction={this.onAccept}
            closeAction={this.closeEditModal}
            tagsToValidate={this.state.selectedCategory === 3 ? this.props.tagList : []}
          /> . 
          <ConfirmDialog
            open={this.state.openDeleteModal}
            title="Eliminar etiquetas"
            message={"¿Estás seguro que deseas eliminar estas etiquetas?"}
            contentObject={
              <div>
                {this.props.tagList
                  .filter((t) => this.state.selectedTags.includes(t.id))
                  .map((tag) => (
                    <Tag
                      key={tag.id}
                      id={tag.id}
                      title={tag.title}
                      color={tag.color}
                    />
                  ))}
              </div>
            }
            acceptText="Eliminar"
            class="delete-tags"
            showCancel={true}
            cancelText="Cancelar"
            cancelButtonType="secondary"
            closeAction={this.closeDeleteModal}
            acceptAction={this.deleteTags}
          />
          <ConfirmDialog
            open={this.state.tagDeletionSummary.length > 0}
            title="Eliminar etiquetas"
            message={
              "Las siguientes etiquetas están asignadas y no pueden eliminarse"
            }
            contentObject={
              <div>
                {this.props.tagList
                  .filter((t) => this.state.tagDeletionSummary.includes(t.id))
                  .map((tag) => (
                    <Tag
                      key={tag.id}
                      id={tag.id}
                      title={tag.title}
                      color={tag.color}
                    />
                  ))}
              </div>
            }
            acceptText="Cerrar"
            class="delete-tags"
            // showCancel={true}
            // cancelText="Cancelar"
            cancelButtonType="secondary"
            closeAction={() => this.closeDeleteModal(true)}
            acceptAction={() => this.closeDeleteModal(true)}
          />

          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedTags.length}
            selectAll={this.selectAllTags}
            actions={[
              this.props.validateObjPerms(
                [
                  {
                    id: "14",
                    perm: "u",
                  },
                ],
                {
                  description: "Editar etiquetas",
                  f: this.showEditModal,
                }
              ),
              this.props.validateObjPerms(
                [
                  {
                    id: "14",
                    perm: "d",
                  },
                ],
                {
                  description: "Eliminar",
                  f: this.showDeleteModal,
                }
              ),
            ]}
          />
        </div>
      </ValidatePerms>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    companyInfo: state.companyReducer.company.data,
    tagCategories: Array.isArray(state.tags.tagTypes.data)
      ? state.tags.tagTypes.data
      : [],
    tagList: Array.isArray(state.tags.tags) ? state.tags.tags : [],
    companyType: state.globalCatalog.session.company.type.id,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  // dispatch(loadTags(ownProps.companyId));
  return {
    getCompanyData: () => dispatch(getSessionCompanyData()),
    reloadTags: () => dispatch(loadTags(ownProps.companyId)),
    createTag: (tag) => dispatch(createTag(tag)),
    updateTag: (tag) => dispatch(updateTag(tag)),
    deleteTag: (tagId) => dispatch(deleteTag(tagId)),
    validateObjPerms: (perms, obj, evalPerm) =>
      validateObjPerms(perms, obj, evalPerm),
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(TagsTab);

TagsTab.defaultProps = {
  tagCategories: [],
  tagList: [],
};
