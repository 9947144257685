import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getEntityItems, readEntities, readEntity } from '../../redux/api';
import { getUrlsEnv } from '../../redux/api/api-urls';
import { loadSession } from '../../redux/reducers/global-catalog.reducer';
import CatalogRow from '../../shared/component/catalog-row';
import CatalogSelectionFooter from '../../shared/component/catalog-selection-footer';
import FilterBarCompact from '../../shared/component/filter/filter-bar-compact';
import { FILTER_LIST_TYPES } from '../../shared/component/filter/filter-bar-content';
import Panel from '../../shared/component/panel';
import Selector from '../../shared/component/selector';
import { ActionsTricolon } from '../../shared/controls/actions-tricolon';
import Button from '../../shared/controls/button';
import PaginatorControlled from '../../shared/controls/paginator-controlled';
import TabSelectorRibbon from '../../shared/controls/tab-selector-ribbon';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import FillIcon from '../../shared/icons/alerts/fill';
import { View, ViewTitle } from '../../shared/styled/view';

const TABS = [
  // {
  //   id: "1",
  //   description: "NOTIFICACIONES"
  // },
  {
    id: "2",
    description: "ALERTAS"
  }
]

let typingTimer;

class AlertDashboardView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedTab: "2",

      selectedAlertsIds: [],
      selectionModeOn: false,

      buttonFilterName: "Aplicar",

      textSearch: "",
      filter_level: [],
      filter_status: [],
      filter_read_status: [],
      filter_date: [],
      filter_type: [],
      offset: 1,
      limit: 10,

      showReadAlertModal: false,
      showUnreadAlertModal: false,
      showDeleteAlertModal: false,
      showCloseAlertModal: false
    }
  }

  componentDidMount() {
    this.props.listAlertCatalog({company_id: this.props.companyId});
    this.props.loadAlertStatusCatalog({company_id: this.props.companyId});
    this.props.loadAlertTypes({company_id: this.props.companyId});

    this.loadAlerts();
  }

  switchTab = (e) => {
    let val = e.target.attributes["name"].value;
    this.setState({selectedTab: val}, () => {
      // if(val === "2" && this.state.routePoints.length){
      //   let lats = this.state.routePoints.map(p => p.lat);
      //   let lngs = this.state.routePoints.map(p => p.lng);
      //   this.mapController.setCenterMapMultiple(
      //     Math.min(...lats),
      //     Math.min(...lngs),
      //     Math.max(...lats),
      //     Math.max(...lngs)
      //   )
      // }
    });
  }

  loadAlerts = () => {
    this.props.loadAlerts({
      company_id: this.props.companyId,
      user_company: this.props.userData,
      limit: this.state.limit,
      offset: this.state.offset,
      // date: this.state.filter_date,
      date: this.state.filter_date[0] 
      ? "".concat(this.state.filter_date[0].toISOString(), ",", this.state.filter_date[1] ? this.state.filter_date[1].toISOString() : this.state.filter_date[0].toISOString())
      : "",
      status: this.state.filter_status,
      level: this.state.filter_level,
      attended: this.state.filter_read_status,
      search: this.state.textSearch,
      type: this.state.filter_type
    })
  }

  //SELECTOR
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedAlertsIds: [],
    });
  };

  handleSelectUser = (e) => {
      let selectedAlertsIds = [...this.state.selectedAlertsIds];
      if (e.target.value) {
        selectedAlertsIds.push(e.target.name);
      } else {
        selectedAlertsIds.splice(
          selectedAlertsIds.findIndex((f) => f === e.target.name),
          1
        );
      }
      this.setState({ selectedAlertsIds: selectedAlertsIds });
    
  };

  selectAllItems = () => {
    if (
      this.props.alerts.length === this.state.selectedAlertsIds.length
    ) {
      this.setState({ selectedAlertsIds: [] });
    } else {
      this.setState({ selectedAlertsIds: this.props.alerts.map(a => a.id.toString()) });
    }
  };

  //FILTERS
  setSearchStr = (e) => {
    this.setState({ 
      textSearch: e.target.value || "",  
      offset: 1
    });
    clearTimeout(typingTimer);
    typingTimer = setTimeout(this.loadAlerts, 1000);
  };

  manageFilter = (e) => {
    this.setState({[e.target.name]: e.target.value, buttonFilterName: "Aplicar"});
  }
  
  getFilterContent = () => {
    return [
      {
        title: "Nivel",
        items: [
          {
            id: 1,
            description: '1'
          },
          {
            id: 2,
            description: '2'
          },
          {
            id: 3,
            description: '3'
          },
          {
            id: 4,
            description: '4'
          },
          {
            id: 5,
            description: '5'
          }
        ],
        name: "filter_level",
        listType: FILTER_LIST_TYPES.checkListAll,
      },
      {
        title: "Estatus",
        items: this.props.alertsStatus,
        name: "filter_status",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Leído",
        items: [
          {
            id: 1,
            description: "Leído"
          },
          {
            id: 2,
            description: "No leído"
          }
        ],
        name: "filter_read_status",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Fecha",
        name: "filter_date",
        listType: FILTER_LIST_TYPES.dateRange,
      },
      {
        title: "Tipo",
        items: this.props.alertsTypes,
        name: "filter_type",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      }
    ];
  };

  //ROWS
  getRow = (rowItem) => {
    let statusDescription = ((this.props.alertsStatus || []).find(aS => aS.id === rowItem.status) || {description: "-"}).description;
    let isRead = rowItem.attended ? true : false;
    let isClosed = statusDescription.toLowerCase() === "cerrado";
    let isInProgress = statusDescription.toLowerCase() === "en atención";

    let actions = [
      {
        description: "Ir a configuración de alertas",
        action: () => {this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=alertas'))}
      }
    ];
    if(isRead){
      actions.push({
        description: "Marcar como no leído",
        action: () => {this.setState({showUnreadAlertModal: true, selectedAlertsIds: [rowItem.id]})}
      })
    } else {
      actions.push({
        description: "Marcar como leído",
        action: () => {this.setState({showReadAlertModal: true, selectedAlertsIds: [rowItem.id]})}
      })
    }
    return (
      <div className="row-content">
        <div style={{width: "1%", height: "100%", backgroundColor: isRead ? "#3868F500" : "#3868F5", position: "absolute", top: "0", left: "0"}}>

        </div>
        <div style={{width: "5%"}}>
          <FillIcon width="47px" height="47px" viewBox="10 0 48 45" fill={rowItem.level < 3 ? "#FFE358" : rowItem.level < 5 ? "#FF9145" : rowItem.level === 5 ? "#FF0000" : null}/>
        </div>
        <div style={{width: "44%", flexDirection: "column", alignItems: "flex-start"}}>
          <div style={{fontWeight: isRead ? "normal" : "bold"}}>
            {((this.props.alertCatalog || []).find(aC => aC.id === rowItem.alert) || {name: "Alerta"}).name}
          </div>
          <div style={{fontWeight: "normal"}}>
            {rowItem.message}
          </div>
        </div>
        <div style={{width: "15%"}}>
          {new Date(rowItem.created).toLocaleString("en-GB", {dateStyle: "short", timeStyle: "short"})}
        </div>
        <div style={{width: "15%", color: "#86C6FF"}}>
          {
            isInProgress 
            ? <span data-for={`actions-${rowItem.id}`} data-tip >{statusDescription}</span> 
            : statusDescription
          }
          {
            isInProgress && 
            <ReactTooltip
              id={`actions-${rowItem.id}`}
              clickable={false}
              effect="solid"
              place={"top"}
              type="light"
              delayHide={200}
              data-class={null}
              className={null}
            >
              {
                (rowItem.last_attendance || []).map(user => {
                  let userInfo = {};
                  userInfo.name = user.user || '-';
                  if(user.profile_image){
                    userInfo.src = getUrlsEnv().files.concat(user.profile_image);
                    userInfo.width = "35px";
                  } else {
                    userInfo.src = "/images/menu/user-no-circle.svg";
                    userInfo.width = "29px";
                  }
                  return(<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px", fontSize: "13px"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <div>
                        <img
                          // className={user.className || ""}
                          src={userInfo.src}
                          alt=""
                          width={userInfo.width}
                          style={{borderRadius: "50%"}}
                        />
                      </div>
                      <div style={{fontWeight: "bold", margin: "0 5px"}}>
                        <span style={{color: "#2E9FFF"}}>{userInfo.name}</span>
                        {" "}
                        está en la conversación
                      </div>
                    </div>
                    <div style={{color: "#00000040"}}>Hace: {user.elapsed_time}</div>
                  </div>)
                })
              }
            </ReactTooltip>
          }
        </div>
        <div style={{width: "15%"}}>
          {
            isClosed 
            ? ""
            : <Button 
                type="primary"
                text={"Cerrar alerta"}
                onClick={() => {this.setState({showCloseAlertModal: true, selectedAlertsIds: [rowItem.id]})}}
              />
          }
        </div>
        <div style={{width: "5%"}}>
          <ActionsTricolon
            tooltipPlacement={"left"}
            actions={actions}
          />
        </div>
      </div>
    )
  }

  //Actions
  markRead = () => {
    this.props.attendAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertsIds,
      attended: true
    }, {
      onSuccess: () => {
        this.setState({showReadAlertModal: false, selectionModeOn: false, selectedAlertsIds: []});
        this.loadAlerts();
      }
    })
  }

  markUnread = () => {
    this.props.attendAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertsIds,
      attended: false
    }, {
      onSuccess: () => {
        this.setState({showUnreadAlertModal: false, selectionModeOn: false, selectedAlertsIds: []});
        this.loadAlerts();
      }
    })
  }

  deleteAlert = () => {
    this.props.deleteAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertsIds
    }, {
      onSuccess: () => {
        this.setState({showDeleteAlertModal: false, selectionModeOn: false, selectedAlertsIds: []});
        this.loadAlerts();
      }
    })
  }

  closeAlert = () => {
    this.props.closeAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertsIds
    }, {
      onSuccess: () => {
        this.setState({showCloseAlertModal: false, selectedAlertsIds: []});
        this.loadAlerts();
      }
    })
  }

  redirect = (alert) => {
    let url = this.getUrl(alert.object, alert.facility, alert.alert);
    if (url){
      this.props.history.push(url);
      document.location.reload(true);
    }
  }

  getUrl = (alertObject, facilityId, alertType) => {
    let url = null

    if (alertType && alertType===6 && facilityId && alertObject.search){
      let date = new Date(alertObject.date)
      // Alerta de retraso a cita (carga o descarga) 
      url = `/company/controls/shipment/detail/${facilityId}/?search=${alertObject.search}&date=${
        ''.concat(date.getFullYear(), '-',("0" + (date.getMonth() + 1)).slice(-2),'-', ("0" + date.getDate()).slice(-2))}`
    } else if (facilityId && alertObject.search){
      url = `/company/controls/shipment/detail/${facilityId}/?search=${alertObject.search}`
    } else {
      const { type = '', search = '' } = alertObject;
      if (type === 'assembly') {
        url = `/company/controls/gps/?search=${search}`
      }
    }
    return url
  }

  render(){
    return (
      <View>
        <ViewTitle>
          Panel de alertas y notificaciones
          <FilterBarCompact
            filtered={false}
            searchAction={this.setSearchStr}
            searchStr={this.state.textSearch}
            content={this.getFilterContent()}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState({ buttonFilterName: "Restablecer" });
              } else {
                this.setState({
                  filter_level: [],
                  filter_status: [],
                  filter_read_status: [],
                  filter_date: [],
                  filter_type: [],
                  buttonFilterName: "Aplicar"
                });
              }

              this.loadAlerts();
            }}
            selectedItems={{
              filter_level: this.state.filter_level,
              filter_status: this.state.filter_status,
              filter_read_status: this.state.filter_read_status,
              filter_date: this.state.filter_date,
              filter_type: this.state.filter_type
            }}
            buttonFilterName={this.state.buttonFilterName}
            onChange={this.manageFilter}
          />
        </ViewTitle>
        <div>
          <Panel className="alerts-panel">
            <div style={{width: "100%", backgroundColor: "#1A202E", borderRadius: "12px 12px 0 0", padding: "0 12px", display: "flex"}}>
              <div>
                <TabSelectorRibbon 
                  activeId={this.state.selectedTab}
                  items={TABS}
                  onChange={this.switchTab}
                />
              </div>
            </div>
            <Selector
              selectedItemClass="selected"
              selectionModeClass="selection"
              selectedItems={this.state.selectedAlertsIds}
              onSelect={this.handleSelectUser}
              selectionModeOn={this.state.selectionModeOn}
              toggleSelectionMode={this.toggleSelectMode}
            >
              {
                (this.props.isLoadingAlerts) ? 
                <div style={{color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200}}>
                  Cargando...
                </div> 
                :
                (this.state.selectedTab === "2" ? this.props.alerts : []).map(alert => (
                  <CatalogRow
                    key={alert.id.toString()}
                    defaultClass={"alert-row "}
                    id={alert.id.toString()}
                    selected={this.state.selectedAlertsIds.includes(alert.id.toString())}
                    onClick={() => {this.redirect(alert)}}
                  >
                    {this.getRow(alert)}
                  </CatalogRow>
                ))
              }
            </Selector>
            <PaginatorControlled
              itemCount={this.props.alertsCount}
              onChange={(e) =>
                this.setState({ [e.target.name]: e.target.value }, () =>
                  this.loadAlerts()
                )
              }
              limit={this.state.limit}
              offset={this.state.offset}
            />
          </Panel>
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedAlertsIds.length}
            selectAll={this.selectAllItems}
            actions={[
              {
                description: 'Marcar como no leídos',
                f: () => {this.setState({showUnreadAlertModal: true})}
              },
              {
                description: 'Marcar como leídos',
                f: () => {this.setState({showReadAlertModal: true})}
              }, 
              {
                description: "Eliminar",
                f: () => {this.setState({showDeleteAlertModal: true})},
              },
            ]}
          />
        </div>
        <ConfirmDialog
          open={this.state.showReadAlertModal}
          title="Marcar como leída"
          message={"¿Estás seguro que deseas marcar esta(s) alerta(s) como leída(s)?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showReadAlertModal: false })}
          acceptAction={this.markRead}
        />
        <ConfirmDialog
          open={this.state.showUnreadAlertModal}
          title="Marcar como no leída"
          message={"¿Estás seguro que deseas marcar esta(s) alerta(s) como no leída(s)?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showUnreadAlertModal: false })}
          acceptAction={this.markUnread}
        />
        <ConfirmDialog
          open={this.state.showDeleteAlertModal}
          title="Eliminar alerta"
          message={"¿Estás seguro que deseas eliminar estas alertas?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showDeleteAlertModal: false })}
          acceptAction={this.deleteAlert}
        />
        <ConfirmDialog
          open={this.state.showCloseAlertModal}
          title="Cerrar alerta"
          message={"¿Estás seguro que deseas marcar esta alerta como atendida?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showCloseAlertModal: false })}
          acceptAction={this.closeAlert}
        />
      </View>
    )
  }
}


const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const alertsCount = getEntityItems(state, "COMPANIES.ALERTS.NOTIFICATIONCENTER").count || 0;
  const alerts = getEntityItems(state, "COMPANIES.ALERTS.NOTIFICATIONCENTER").results || [];
  const alertsStatus = getEntityItems(state, "COMPANIES.CATALOGS.ALERTSTATUS");
  const alertCatalog = getEntityItems(state, "COMPANIES.ALERTS.CATALOG");
  const alertsTypesData = getEntityItems(state, "COMPANIES.CATALOGS.ALERTTYPES");
  const isLoadingAlerts = state?.api['COMPANIES.ALERTS.NOTIFICATIONCENTER']?.status?.isFetching || false;
  return {
    companyId,
    company_name: state.globalCatalog.session.company?.orbi_id,
    userData: state.globalCatalog.session.company?.companyuser?.id || 0,
    alertsStatus,
    alertCatalog,
    alertsCount,
    alerts,
    alertsTypes: alertsTypesData.map( (value) => {
      const { id, name } = value;
      return { id, description: name }
    }),
    isLoadingAlerts
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    loadAlertStatusCatalog: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.CATALOGS.ALERTSTATUS", params, opt) 
      );
    },
    loadAlertTypes: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.CATALOGS.ALERTTYPES", params, opt) 
      );
    },
    // loadAlertDetail: (params, opt) =>{
    //   dispatch(
    //     readEntities("COMPANIES.CATALOGS.ALERTCOUNTER.DETAIL", params, opt)
    //   );
    // },
    loadAlerts: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.ALERTS.NOTIFICATIONCENTER", params, opt)
      );
    },
    listAlertCatalog: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.ALERTS.CATALOG",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    attendAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.ATTENDED", params, opt)
      );
    },
    deleteAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.DELETED", params, opt)
      );
    },
    closeAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.CLOSED", params, opt)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDashboardView);