import React from "react";
import { connect } from "react-redux";
import { postResendEmailValidation } from "./../redux/reducers/home.reducer";
import Button from "../shared/controls/button";
import {
  loadSession,
  getRequest,
  downloadPermissionFile,
} from "../redux/reducers/global-catalog.reducer";
import { withRouter } from "react-router-dom";
import ChangeEmailDialog from "../shared/dialog/change-email-modal";
import ValidationEmailDialog from "../shared/dialog/validation_email_modal";
import { getUrlsEnv } from "../shared/backend-api";
import {
  refreshSession,
  setSessionCompany,
  setSessionUser,
  setSessionCompanyList,
} from "../redux/reducers/global-catalog.reducer";
import HomeDashboard from "./home/home-dashboard";
import HelperFilesModal from '../shared/component/helper_files_modal';

// TODO: mover esta vista a la carpeta de register
// TODO: obtener datos de session desde el reducer
// TODO: quitar el metodo componentwillreceiveprops, esta obsoleto

let filesPath = "";

class Home extends React.Component {
  constructor() {
    super();

    filesPath = getUrlsEnv().files;

    this.state = {
      showPopUp: false,
      showEmailPopUp: false,
      showInvitationModal: false,
      userData: (JSON.parse(sessionStorage.getItem("orbinetwork_session"))) ? JSON.parse(sessionStorage.getItem("orbinetwork_session")).user : {},
      companyData: (JSON.parse(sessionStorage.getItem("orbinetwork_session"))) ? JSON.parse(sessionStorage.getItem("orbinetwork_session"))
        .company : {},
      session: (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
        .access,
    };
  }

  componentDidMount() {
    this.props.getRequestCompany(this.state.session).then((response) => {
      const {
        companyData: { id = -1 },
      } = this.state;

      if (id === 0) {
        if (this.props.requestCompany.length === 0) {
          this.props.refreshSession(this.props.refreshToken).then((result) => {
            if (result) {
              if (
                Object.values(result.company).length ||
                !result.verified_email
              ) {
                result.company = result.company[0];
                this.props.setSessionCompany(result.company);
                this.props.setSessionUser(result.user);
                this.props.setSessionCompaniesList([]);
              } else {
                window.location.href = "/register/account/";
              }
            }
          });
        }
      } else if (
        String(this.state.userData.first_name) === "" &&
        String(this.state.userData.last_name) === ""
      ) {
        // window.location.href = "/register/account/";
      }
    });
  }

  actionCloseDialog = () => {
    this.setState({
      showPopUp: false,
      showEmailPopUp: false,
      showInvitationModal: false,
    });
  };

  getVerifiedStatus = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div className="point" />
          <div className="welcome-content">
            Recuerda que para tener acceso a Orbinetwork debes confirmar tu
            correo:
          </div>
        </div>

        <div className="welcome-content" style={{ marginLeft: "25px" }}>
          Valida el correo que enviamos a:
          <div className="mail">{this.props.email}</div>{" "}
        </div>

        <div className="container">
          <Button
            settings={{
              type: "btn outline primary white",
              text: "Este no es mi correo",
              onClick: () =>
                this.setState({ showEmailPopUp: true, showPopUp: false }),
            }}
            className="mail-button"
          />

          <Button
            disabled={this.props.isFetching}
            settings={{
              type: "btn primary",
              text: "Reenviar correo",
              onClick: () => {
                this.props.resendEmail().then((response) => {
                  this.setState({ showEmailPopUp: false, showPopUp: true });
                });
              },
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  getLinkStatus = () => {
    const {
      companyData: { id = -1 },
    } = this.state;

    if (id === -1) {
      return null;
    }

    if (id === 0 && this.props.requestCompany.length > 0) {
      const corporationName = this.props.requestCompany[0].company
        .corporation_name;
      const logo = this.props.requestCompany[0].company.logo;

      return (
        <React.Fragment>
          <div style={{ display: "flex" }}>
            <div className="point" />
            <div className="welcome-content">
              La autorización del administrador de la empresa:
            </div>
          </div>

          <div className="container">
            <Button
              settings={{
                type: "btn company",
                text: `${corporationName}`,
                prefix: {
                  element: (
                    <img
                      alt=""
                      src={`${filesPath + logo}`}
                      className="img"
                      onError={(e) => {
                        e.target.src = "/images/factory-bckgrnd.png";
                      }}
                    />
                  ),
                },
                onClick: () => {},
              }}
              className="company-button"
            />
          </div>
        </React.Fragment>
      );
    } else return null;
  };

  getCompanyLogo = () => {
    if (this.state.companyData.logo) {
      return (getUrlsEnv().files.concat(this.state.companyData.logo));
    } else if (this.state.companyData.type) {
      return "/".concat(this.state.companyData.type.avatar_image);
    } else {
      return "/images/logo-mini-menu-white.svg";
    }
  };

  renderContent = () => {
    const {
      companyData: { id = -1 },
    } = this.state;
    return (
      <div className="home">
        <div
          style={{
            paddingTop: 15,
            display: 'flex',
            marginRight: 60,
            flexDirection: 'row-reverse'
          }}
        >
          <HelperFilesModal
            files={[]}
          />
        </div>
        <div style={{position: "relative"}}>
          <img alt="" className="logo" src="/images/logo-white.svg" />
          {
            (this.props.verificatedEmail && id !== 0)
            ? <img  
                className="company-logo" 
                alt="" 
                src={this.getCompanyLogo()}  
                onClick={() =>
                  this.props.history.push(
                  `/company/profile/${this.state.companyData.orbi_id}`
                )}
              />
            : ""
          }
        </div>
        {!this.props.verificatedEmail || id === 0 ? (
          <React.Fragment>
            <div className="welcome-title">Estás a punto de comenzar...</div>
            <div className="welcome-content">
              Solo es necesario:
              <div className="decoration">
                <img
                  alt=""
                  src={
                    this.props.currentCompanyId
                      ? "/images/home-validate-email.png"
                      : "/images/home-validate-company.png"
                  }
                ></img>
              </div>
            </div>
            {!this.props.verificatedEmail && this.getVerifiedStatus()}
            {this.getLinkStatus()}
          </React.Fragment>
        ) : (
          this.props.welcome ? (
            <React.Fragment>
              <div className="welcome-title">¡Bienvenido!</div>
              <div className="welcome-content">
                Tu cuenta quedo exitosamente validada, ya puedes acceder a
                Orbinetwork
                <div className="decoration">
                  <img alt="" src={"/images/home-validate-company.png"}></img>
                </div>
              </div>

              {/* <button
                style={{ margin: "40px 40px 40px 0px" }}
                className="btn primary"
                onClick={() => {
                  this.props.history.push(`/user/data`);
                }}
              >
                Ir a mi cuenta
              </button> */}
            </React.Fragment>
          )
          : (
              <HomeDashboard history={this.props.history}/>
          )
        )}
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderContent()}

        <ValidationEmailDialog
          open={this.state.showPopUp || this.state.showInvitationModal}
          title={
            this.state.showInvitationModal
              ? "Enviado !"
              : "¡Correo de validación!"
          }
          message={
            this.state.showInvitationModal
              ? "Hemos enviado una solicitud"
              : "Se envió correo de validación, verifica tu correo"
          }
          acceptText="Continuar"
          acceptAction={() => {
            this.setState({
              showEmailPopUp: false,
              showPopUp: false,
              showInvitationModal: false,
            });
          }}
          closeAction={() => {
            this.setState({
              showEmailPopUp: false,
              showPopUp: false,
              showInvitationModal: false,
            });
          }}
        />

        <ChangeEmailDialog
          open={this.state.showEmailPopUp}
          title="¡Cambiar correo!"
          message="Introduzca su nuevo correo"
          acceptText="Guardar"
          error={false}
          acceptAction={() => {
            this.setState({
              showEmailPopUp: false,
              showPopUp: false,
              showInvitationModal: false,
            });
          }}
          closeAction={() => {
            this.setState({
              showEmailPopUp: false,
              showPopUp: false,
              showInvitationModal: false,
            });
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    home: { error, data, isFetching },
  } = state;
  return {
    requestCompany: Array.isArray(state.globalCatalog.requestCompany)
      ? state.globalCatalog.requestCompany
      : [],
    error,
    data,
    isFetching,
    verificatedEmail: (state.globalCatalog.session && state.globalCatalog.session.verified_email) || false,
    currentCompanyId: null, //state.globalCatalog.session.company.id || null,
    session: state.globalCatalog.session,
    welcome: (state.globalCatalog.session && state.globalCatalog.session.user && state.globalCatalog.session.user.welcome) || false,
    email: (state.globalCatalog.session && state.globalCatalog.session.user && state.globalCatalog.session.user.email) || "",
    isFetchingEmail: state.registerAccount.isFetchingEmail,
    isSentInvitation: state.registerAccount.emailSent,
    refreshToken:
      state.globalCatalog.session && state.globalCatalog.session.refresh,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(downloadPermissionFile());

  return {
    getRequestCompany: (session) => dispatch(getRequest(session)),
    resendEmail: () => dispatch(postResendEmailValidation()),
    refreshSession: (token) => dispatch(refreshSession(token)),
    setSessionCompany: (company) => dispatch(setSessionCompany(company)),
    setSessionUser: () => dispatch(setSessionUser({ welcome: true })),
    setSessionCompaniesList: (companiesList) =>
      dispatch(setSessionCompanyList(companiesList)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
