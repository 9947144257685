import React from "react";
import { connect } from "react-redux";
import {
  loadSession,
  loadNetworkStatusCatalog,
  loadCompanyTypesCatalog,
  validateObjPerms,
} from "../../redux/reducers/global-catalog.reducer";
import { readEntities, getEntityItems } from '../../redux/api';
import {
  listNetworkReceivedInvitations,
  listNetworkSentInvitations,
  listNetworkCompanies,
  listNetworkApprovedInvitations,
  rejectNetworkRequest,
  acceptNetworkRequest,
  resendNetworkRequest,
  deleteNetworkRequest,
  sendNetworkRequest,
  inviteNewCompanyByEmail,
  updateNetworkContact,
} from "../../redux/reducers/company-reducer";
import CatalogHeader from "../../shared/component/catalog-header";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import CatalogRow from "../../shared/component/catalog-row";
import Button from "../../shared/controls/button";
import { getUrlsEnv } from "../../shared/backend-api";
import ReactTooltip from "react-tooltip";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import Modal from "react-modal";
import InputSearch from "../../shared/controls/input-search";
import InputTagger from "../../shared/controls/inputTagger";
import { getUserByEmail } from "../../redux/reducers/user-reducer";
import TextInput from "../../shared/controls/text-input";
import ValidatePerms from "../../shared/component/validate-perms";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import styled from 'styled-components';
import moment from 'moment';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from '../../shared/component/helper_files_modal';

const companiesStatus = [
  { id: 1, description: "Mis contactos" },
  { id: 2, description: "Solicitudes recibidas" },
  { id: 3, description: "Solicitudes enviadas" },
  { id: 4, description: "Recomendaciones enviadas" },
];

class NetworkCatalogView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchCompanyModal: false,
      showCancelInvitationModal: false,
      showDeleteInvitationModal: false,
      showRejectInvitationModal: false,
      showAcceptInvitationModal: false,
      showResendInvitationModal: false,
      showRenameCompanyModal: false,
      showInviteCompanyModal: false,
      showResultModal: false,
      resultModalText: "",
      resultModalTitle: "",
      selectedCompanyId: 0,
      searchStr: "",
      // resetPaginator: false,
      limit: 10,
      offset: 1,
      emails: [],
      searchOnStr: "",
      companiesStatusItem: companiesStatus[0],

      selectedContactName: "",
      selectedContactRelationshipId: 0,
      selectedContactOrbiId: 0,
      selectedContactCode: "",
      selectedContactAlias: "",
      selectedContactAvatar: null,
      selectedContactParamType: "",
      selectedCompanyType: "",
      resume: {
        approved_request: 0, 
        received_request: 0,
        sent_request: 0,
        sent_recommendation: 0
      }
    };

    this.openedLink = false;
  }

  componentDidMount() {
    this.setState({ ...this.props.configuration.base });

    this.props.loadResume({
      company_id: this.props.ownCompanyId
    }, { alias: 'resume' });

    this.props.setNavbarAction("network-catalog", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }
  componentWillUnmount() {
    let config = this.state;

    if (!this.openedLink) {
      config.searchOnStr = '';
    }

    this.props.setBaseConfig(config);
    this.openedLink = false;

    this.props.removeNavbarAction("network-catalog");
  }

  headerActionButtonSettings = {
    text: "Buscar Empresa",
    onClick: () => this.showSearchCompanyModal(true),
  };

  actionButtonInvite = {
    type: 'btn outline primary fit-width',
    text: 
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 10
        }}
      > 
        <img
          alt=""
          src="/images/invite_company.png"
          style={{width: 20, height: 20, marginRight: 10, filter: 'invert(1)'}}
        />
        {"Recomendar a un amigo"}
      </div>,
    onClick: () => this.showInviteCompanyModal(true),
  };

  showSearchCompanyModal = (show) => {
    if (!show) {
      this.reloadViewItems();
    }
    this.setState({ showSearchCompanyModal: show, searchStr: "" });
  };

  showCancelInvitationModal = (show) => {
    this.setState({ showCancelInvitationModal: show });
  };

  showDeleteInvitationModal = (show) => {
    this.setState({ showDeleteInvitationModal: show });
  };

  showRejectInvitationModal = (show) => {
    this.setState({ showRejectInvitationModal: show });
  };

  showAcceptInvitationModal = (show) => {
    this.setState({ showAcceptInvitationModal: show });
  };

  showResendInvitationModal = (show) => {
    this.setState({ showResendInvitationModal: show });
  };

  showRenameCompanyModal = (show) => {
    this.setState({ showRenameCompanyModal: show });
  };

  showInviteCompanyModal = (show) => {
    this.setState({ showInviteCompanyModal: show, emails: [] });
  };

  showResultModal = (show) => {
    if (!show) {
      this.reloadViewItems();
    }
    this.setState({ showResultModal: show });
  };

  switchCompanyStatusType = (e) => {
    if (e.target.attributes["name"]) {
      this.state.searchOnStr = '';
      let ftId = e.target.attributes["name"].value;
      let type = companiesStatus.find((ft) => ft.id.toString() === ftId);
      this.props.setConfig(this.state.companiesStatusItem.id, {
        ...this.state,
      });

      this.props.setBaseConfig(this.props.configuration[ftId]);

      this.setState(
        {
          ...this.props.configuration[ftId],
          companiesStatusItem: type,
        },
        () => {
          switch (ftId) {
            case "1":
              this.props.reloadApprovedInvitations(
                this.state.limit,
                this.state.offset,
                this.state.searchOnStr
              );
              break;
            case "2":
              this.props.reloadReceivedInvitations(
                this.state.limit,
                this.state.offset,
                this.state.searchOnStr
              );
              break;
            case "3":
              this.props.reloadSentInvitations(
                this.state.limit,
                this.state.offset,
                this.state.searchOnStr
              );
              break;
            case "4":
              this.props.reloadRecommendations({
                company_id: this.props.ownCompanyId,
                limit: this.state.limit,
                offset: this.state.offset,
                search: this.state.searchOnStr
              }, { alias: 'recommendations' });
              break;
            default:
              break;
          }
        }
      );
    }
  };

  handleCancelInvitation = (invitation_id) => {
    ReactTooltip.hide();
    this.setState({
      showCancelInvitationModal: true,
      selectedCompanyId: invitation_id,
    });
  };

  handleDeleteRelationship = (invitation) => {
    ReactTooltip.hide();
    this.setState({
      showDeleteInvitationModal: true,
      selectedCompanyId: invitation.id,
      selectedCompanyType: (
        this.props.companyTypesCatalog.find(
          (cT) => cT.id === invitation.type
        ) || {}
      ).description,
      selectedContactName: invitation.name,
      selectedContactAvatar: invitation.avatar,
    });
  };

  deleteInvitation = () => {
    this.props.deleteRequest(this.state.selectedCompanyId).then((response) => {
      if (response) {
        this.setState({
          showDeleteInvitationModal: false,
          showResultModal: true,
          resultModalTitle: "¡Listo!",
          resultModalText: "La relación ha sido desactivada.",
        });
        this.reloadViewItems();
      } else {
        this.setState({
          showDeleteInvitationModal: false,
          showResultModal: true,
          resultModalTitle: "¡Ups!",
          resultModalText:
            "Parece que algo salió mal, intentalo de nuevo más tarde.",
        });
      }
    });
  };

  cancelInvitation = () => {
    // debugger;
    this.props.deleteRequest(this.state.selectedCompanyId).then((response) => {
      this.setState({
        showCancelInvitationModal: false,
        showResultModal: true,
        resultModalTitle: "¡Listo!",
        resultModalText: "La solicitud ha sido cancelada.",
      });
      this.reloadViewItems();
    });
  };

  handleRejectInvitation = (invitation_id) => {
    this.setState({
      showRejectInvitationModal: true,
      selectedCompanyId: invitation_id,
    });
  };

  rejectInvitation = () => {
    this.props.rejectRequest(this.state.selectedCompanyId).then((response) => {
      this.setState({
        showRejectInvitationModal: false,
        showResultModal: true,
        resultModalTitle: "¡Listo!",
        resultModalText: "La solicitud ha sido rechazada.",
      });
      this.reloadViewItems();
    });
  };

  handleAcceptInvitation = (invitation_id) => {
    this.setState({
      showAcceptInvitationModal: true,
      selectedCompanyId: invitation_id,
    });
  };

  acceptInvitation = () => {
    this.props.acceptRequest(this.state.selectedCompanyId).then((response) => {
      this.setState({
        showAcceptInvitationModal: false,
        showResultModal: true,
        resultModalTitle: "¡Listo!",
        resultModalText: "La solicitud ha sido aceptada.",
      });
      this.reloadViewItems();
    });
  };

  handleResendInvitation = (invitation_id) => {
    this.setState({
      showResendInvitationModal: true,
      selectedCompanyId: invitation_id,
    });
  };

  handleRenameContact = (company) => {
    // debugger
    this.setState({
      selectedContactName: company.name,
      selectedContactRelationshipId: company.id,
      selectedContactOrbiId: company.orbi_id,
      selectedContactCode: company.code,
      selectedContactAlias: company.alias,
      selectedContactAvatar: company.avatar,
      selectedContactParamType: company.param,
    });
    this.showRenameCompanyModal(true);
  };

  handleEditContact = () => {
    let params = {};
    params[
      this.state.selectedContactParamType.concat("_code")
    ] = this.state.selectedContactCode;
    params[
      this.state.selectedContactParamType.concat("_alias")
    ] = this.state.selectedContactAlias;
    params.network_id = this.state.selectedContactRelationshipId;
    this.props.updateNetworkContact(params).then((result) => {

      if (result.errors && result.errors.length > 0) {

        const errorMessage = result.errors.join(', ');

        this.setState({
          showResultModal: true,
          resultModalText: errorMessage,
          resultModalTitle: "¡Error!",
        });

      } else {
        this.setState({
          showResultModal: true,
          showRenameCompanyModal: false,
          resultModalText: "Los cambios se han guardado con éxito.",
          resultModalTitle: "¡Listo!",
          selectedContactName: "",
          selectedContactRelationshipId: 0,
          selectedContactOrbiId: 0,
          selectedContactCode: "",
          selectedContactAlias: "",
          selectedContactAvatar: null,
          selectedContactParamType: "",
        });
      }

    });
  };

  handleTextInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  resendInvitation = () => {
    this.props.resendRequest(this.state.selectedCompanyId).then((response) => {
      this.setState({
        showResendInvitationModal: false,
        showResultModal: true,
        resultModalTitle: "¡Listo!",
        resultModalText: "La solicitud ha sido re-enviada.",
      });
      this.reloadViewItems();
    });
  };

  sendInvitation = (company) => {
    if (company.network_status.status === 2) {
      //2 - Resend
      this.props
        .resendRequest(company.network_status.request_id)
        .then((response) => {
          this.setState({
            showResendInvitationModal: false,
            showResultModal: true,
            resultModalTitle: "¡Listo!",
            resultModalText: "La solicitud ha sido re-enviada.",
          });
          this.reloadViewItems();
        });
    } else {
      // 6- Send
      this.props.sendRequest(company.id).then((result) => {
        this.props.getNetworkCompanies(this.state.searchStr);
      });
    }
  };

  setSearchStr = (name, value) => {
    this.setState({ searchStr: value });
    if (value && value.length > 2) this.props.getNetworkCompanies(value);
  };

  handleEmailInput = (e) => {
    if (e) {
      switch (e.target.name) {
        case "inviteTags":
          e.target.value
            .filter((it) => !it.processed)
            .forEach((i) => this.onEmail(i.email));
          break;
        case "deleteTags":
          this.setState({ emails: [].concat(e.target.value) });
          break;
        default:
      }
    }
  };

  onEmail = (email) => {
    this.props.findUserByEmail(email).then((user) => {
      if (user.type === "USERS_GET_BY_EMAIL_SUCCESS") {
        let tag = {
          email: email,
          avatar: "/images/mail.svg",
          avatarType: "contained",
          userId: null,
          processed: true,
        };
        if (user.response.length > 0) {
          tag.avatar = user.response[0].profile_image
            ? getUrlsEnv().files.concat(user.response[0].profile_image)
            : "/images/user-white.svg";
          tag.avatarType = user.response[0].profile_image
            ? "full"
            : "contained";
          tag.userId = user.response[0].id;
        }
        this.setState({ emails: this.state.emails.concat(tag) });
      }
    });
  };

  inviteNonUser = () => {
    this.state.emails.forEach((email) => {
      this.props.inviteNewCompanyByEmail(email.email);
    });
    this.setState({
      showInviteCompanyModal: false,
      showResultModal: true,
      resultModalTitle: "¡Listo!",
      resultModalText: "Se han enviado las invitaciones.",
    });
  };

  getAvatarUrl = (partialUrl, companyType) => {
    if (partialUrl) {
      return getUrlsEnv().files.concat(partialUrl);
    } else if (companyType === 2 || companyType === "Dueño de Carga") {
      // return '/images/modal-factory-blue-icon.svg'
      return "/images/factory-bckgrnd.png";
    } else {
      return "/images/truck-bckgrnd.png";
    }
  };

  getInvitationAvatarUrl = (partialUrl, companyType) => {
    if (partialUrl) {
      return getUrlsEnv().files.concat(partialUrl);
    } else if (companyType === 2 || companyType === "Dueño de Carga") {
      // return '/images/modal-factory-blue-icon.svg'
      return "/images/user-icon-blue.svg";
    } else {
      return "/images/user-icon-blue.svg";
    }
  };

  // handlePaginator = (limit, offset) => {
  //   // offset = Number(offset) - 1;
  //   this.reloadViewItems(limit, offset, false);
  // };

  reloadViewItems = () => {
    // debugger;
    this.props.loadResume({
      company_id: this.props.ownCompanyId
    }, { alias: 'resume' })
    switch (this.state.companiesStatusItem.id) {
      case 1:
        this.props.reloadApprovedInvitations(
          this.state.limit,
          this.state.offset,
          this.state.searchOnStr
        );
        break;
      case 2:
        this.props.reloadReceivedInvitations(
          this.state.limit,
          this.state.offset,
          this.state.searchOnStr
        );
        break;
      case 3:
        this.props.reloadSentInvitations(
          this.state.limit,
          this.state.offset,
          this.state.searchOnStr
        );
        break;
      case 4:
        this.props.reloadRecommendations({
          company_id: this.props.ownCompanyId,
          limit: this.state.limit,
          offset: this.state.offset,
          search: this.state.searchOnStr
        }, { alias: 'recommendations' });
      break;
      default:
        break;
    }
  };

  getMaxElements = () => {
    switch (this.state.companiesStatusItem.id) {
      case 1:
        return this.props.approvedRequestsCount;
      case 2:
        return this.props.receivedRequestsCount;
      case 3:
        return this.props.sentRequestsCount;
      case 4:
        return this.props.recommendationsCount;
      default:
        return 0;
    }
  };

  getRow = (company) => {
    switch (this.state.companiesStatusItem.id) {
      case 4:
        return (
          <div className="row-content" style={{height: 'auto'}}>
            <div className="logo">
              <div
                className={"avatar "}
                style={{
                  backgroundPosition: 'initial',
                  backgroundImage: "url(".concat(
                    this.getInvitationAvatarUrl(company.profile_image, company.type),
                    ")"
                  ),
                }}
              ></div>
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              {company.orbi_user ?? ""}
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              <BigNames
                data-tip
                data-for={"recommendated-email-".concat(company.id)}
              >
                {company.recommendated_email ?? ""}
              </BigNames>

              <ReactTooltip
                id={"recommendated-email-".concat(company.id)}
                place="top"
                type="light"
                effect="solid"
                event="mouseover"
                eventOff="mouseleave"
              > {company.recommendated_email ?? ""} </ReactTooltip>

            </div>
            <div className="invitations">
              { (company.is_orbi_user ?? false) ? "usuario existente" : "usuario no existe"}
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              {
                (company.companies ?? []).map( (item, index) => {

                  return <>
                    <BigNames
                      data-tip
                      data-for={"company-id-".concat(company.id).concat('-').concat(index)}
                    >
                      {item.company_orbi_id ?? "ninguna"}
                    </BigNames>

                    {
                      (item.company_orbi_id ?? "ninguna") !==  "ninguna" ?
                      <ReactTooltip
                        id={"company-id-".concat(company.id).concat('-').concat(index)}
                        place="top"
                        type="light"
                        effect="solid"
                        event="mouseover"
                        eventOff="mouseleave"
                      > {item.company_orbi_id ?? "ninguna"} </ReactTooltip> : <div/>
                    }

                  </>
                })
              }
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              {
                (company.companies ?? []).map( item => {
                  return <BigNames>{item.type ?? "-"}</BigNames>
                })
              }
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              {
                (company.companies ?? []).map( item => {

                  let dateToReturn = item.created ?? "-";
                  
                  if (dateToReturn !== "-") {
                    dateToReturn = moment(dateToReturn).format('DD MMM YYYY');
                  }

                  return <BigNames>{dateToReturn}</BigNames>
                })
              }
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              {
                (company.companies ?? []).map( item => {
                  let dateToReturn = item.recommendation_sent ?? "-";
                  
                  if (dateToReturn !== "-") {
                    dateToReturn = moment(dateToReturn).format('DD MMM YYYY');
                  }

                  return <BigNames>{dateToReturn}</BigNames>
                })
              }
            </div>
            <div className="invitations" style={{display: 'grid'}}>
              {
                (company.companies ?? []).map( item => {
                  let dateToReturn = item.free_trips ?? "-";
                  
                  if (dateToReturn !== "-") {
                    dateToReturn = moment(dateToReturn).format('DD MMM YYYY');
                  }

                  return <BigNames>{dateToReturn}</BigNames>
                })
              }
            </div>
            <div className="invitations" style={{display: 'grid'}}>

              {
                (company.companies ?? []).map( item => {

                  let is_in_network_companies = item.is_in_network_companies ?? false;

                  let companyOrbiId = item.company_orbi_id ?? "";

                  if (companyOrbiId === "") {

                    let emailToSend = company.recommendated_email ?? "";

                    if (emailToSend === "") { return "-" }

                    return <LinkText
                      onClick={ ()=> {
                        this.props
                        .inviteNewCompanyByEmail(emailToSend)
                        .then((response) => {
                          this.setState({ 
                            showResultModal: true,
                            resultModalTitle: "¡Listo!",
                            resultModalText: "La recomendación ha sido re-enviada.",
                          });
                          this.reloadViewItems();
                        });
                      }}
                    >
                      {"Reenviar recomendación"}
                    </LinkText>
                  }

                  if (is_in_network_companies) {
                    return <div>{"Conectado"}</div>
                  } else {

                    let companyIdToSend = item.company_id ?? -1;

                    if (companyIdToSend === -1) {
                      return "-"
                    }

                    return <LinkText
                      onClick={ ()=> {
                        
                        this.props.sendRequest(companyIdToSend).then((result) => {
                          this.props.getNetworkCompanies(this.state.searchStr);

                          this.setState({ 
                            showResultModal: true,
                            resultModalTitle: "¡Listo!",
                            resultModalText: "Invitación enviada",
                          });
                        });
                      }}
                    >
                      {"Enviar solicitud"}
                    </LinkText>
                  }
                })
              }
            </div>
          </div>
        );
      case 1: //SOlicitudes aprobadas
        return (
          <div className="row-content">
            <div className="logo">
              <div
                className={"avatar "}
                style={{
                  backgroundImage: "url(".concat(
                    this.getAvatarUrl(company.avatar, company.type),
                    ")"
                  ),
                }}
              ></div>
            </div>
            <div className="credentials">
              <div className="principal">{company.name}</div>
              <div className="alternative">
                <div className="alias">{company.alias || "-"}</div>
                <div className="code">{company.code || "-"}</div>
              </div>
            </div>
            <div className="orbi-id">{company.orbi_id}</div>
            <div className="partners-since">{company.partners_since}</div>
            <div className="company-type">
              {
                (
                  this.props.companyTypesCatalog.find(
                    (cT) => cT.id === company.type
                  ) || {}
                ).description
              }
            </div>
            <ValidatePerms
              perms={[
                {
                  id: "39",
                  perm: "u",
                },
                {
                  id: "39",
                  perm: "d",
                },
              ]}
              eval={"or"}
            >
              <div
                className="actions"
                // onClick={() => {debugger; ReactTooltip.hide()}}
                data-tip
                data-for={"tooltip-".concat(company.id)}
              >
                <div className="ellipsis">
                  <b></b>
                </div>
              </div>
              <ReactTooltip
                id={"tooltip-".concat(company.id)}
                clickable={true}
                event="click"
                globalEventOff="click"
                delayHide={200}
                delayShow={200}
                delayUpdate={200}
                place={"left"}
                border={true}
                type={"light"}
                isCapture={true}
              >
                {
                  `${this.props.companyType}` !== '1' ? 
                  <ValidatePerms  
                    perms={[
                      {
                        id: "39",
                        perm: "d",
                      },
                    ]}
                  >
                    <div
                      className="tooltip-option"
                      onClick={() => {
                        this.handleDeleteRelationship(company);
                      }}
                    >
                      {"Inactivar relación"}
                    </div>
                  </ValidatePerms>
                  :<div/>
                }

                <ValidatePerms
                  perms={[
                    {
                      id: "39",
                      perm: "u",
                    },
                  ]}
                >
                  <div
                    className="tooltip-option"
                    onClick={() => this.handleRenameContact(company)}
                  >
                    Editar alias
                  </div>
                </ValidatePerms>
              </ReactTooltip>
            </ValidatePerms>
          </div>
        );
      case 2: //Solicitudes recibidas
        return (
          <div className="row-content">
            <div className="logo">
              <div
                className={
                  "avatar " /* .concat(company.avatar ? '' : 'default') */
                }
                style={{
                  backgroundImage: "url(".concat(
                    this.getAvatarUrl(company.avatar, company.type),
                    ")"
                  ),
                }}
              ></div>
            </div>
            <div className="credentials">
              <div className="principal">{company.name}</div>
            </div>
            <div className="orbi-id">{company.orbi_id}</div>
            <div className="company-type">
              {
                (
                  this.props.companyTypesCatalog.find(
                    (cT) => cT.id === company.type
                  ) || {}
                ).description
              }
            </div>
            <div className="status">
              <ValidatePerms
                perms={[
                  {
                    id: "39",
                    perm: "d",
                  },
                ]}
              >
                <Button
                  text="Rechazar"
                  type="primary outline"
                  onClick={() => this.handleRejectInvitation(company.id)}
                />
              </ValidatePerms>
              <ValidatePerms
                perms={[
                  {
                    id: "39",
                    perm: "c",
                  },
                ]}
              >
                <Button
                  text="Aceptar"
                  type="primary"
                  onClick={() => this.handleAcceptInvitation(company.id)}
                />
              </ValidatePerms>
            </div>
          </div>
        );
      case 3: //Solicitudes enviadas
        return (
          <div className="row-content">
            <div className="logo">
              <div
                className={
                  "avatar " /* .concat(company.avatar ? '' : 'default') */
                }
                style={{
                  backgroundImage: "url(".concat(
                    this.getAvatarUrl(company.avatar, company.type),
                    ")"
                  ),
                }}
              ></div>
            </div>
            <div className="credentials">
              <div className="principal">{company.name}</div>
            </div>
            <div className="orbi-id">{company.orbi_id}</div>
            <div className="company-type">
              {
                (
                  this.props.companyTypesCatalog.find(
                    (cT) => cT.id === company.type
                  ) || {}
                ).description
              }
            </div>
            <div className="status">
              {
                (
                  this.props.networkStatusCatalog.find(
                    (cT) => cT.id === company.status
                  ) || {}
                ).description
              }
            </div>
            <ValidatePerms
              perms={[
                {
                  id: "39",
                  perm: "d",
                },
              ]}
            >
              <div
                className="actions"
                data-tip
                data-for={"tooltip-".concat(company.id)}
              >
                <div className="ellipsis">
                  <b></b>
                </div>
              </div>
              <ReactTooltip
                id={"tooltip-".concat(company.id)}
                clickable={true}
                event="click"
                globalEventOff="click"
                delayHide={200}
                delayShow={200}
                delayUpdate={200}
                place={"left"}
                border={true}
                type={"light"}
                isCapture={true}
              >
                <div
                  className="tooltip-option"
                  onClick={() => {
                    (
                      this.props.networkStatusCatalog.find(
                        (cT) => cT.id === company.status
                      ) || {}
                    ).description === "Pendiente"
                      ? this.handleCancelInvitation(company.id)
                      : this.handleResendInvitation(company.id);
                  }}
                >
                  {(
                    this.props.networkStatusCatalog.find(
                      (cT) => cT.id === company.status
                    ) || {}
                  ).description === "Pendiente"
                    ? "Cancelar invitación"
                    : "Re-enviar invitación"}
                </div>
              </ReactTooltip>
            </ValidatePerms>
          </div>
        );
      default:
        return <></>;
    }
  };

  getCompaniesData = () => {
    let companies = [];
    switch (this.state.companiesStatusItem.id) {
      case 1:
        companies = this.props.approvedRequests;
        return companies.map((invitation) => {
          let friendCompany =
            invitation.from_companies.id === this.props.ownCompanyId
              ? invitation.to_companies
              : invitation.from_companies;
          let param =
            invitation.from_companies.id === this.props.ownCompanyId
              ? "to_companies"
              : "from_companies";
          return {
            id: invitation.id,
            avatar: friendCompany.logo,
            name: friendCompany.corporation_name,
            orbi_id: friendCompany.orbi_id,
            type: friendCompany.type,
            partners_since: invitation.accepted_date,
            code: invitation[param.concat("_code")],
            alias: invitation[param.concat("_alias")],
            param: param,
          };
        });
      case 2:
        companies = this.props.receivedRequests;
        return companies.map((invitation) => ({
          id: invitation.id,
          avatar: invitation.from_companies.logo,
          name: invitation.from_companies.corporation_name,
          orbi_id: invitation.from_companies.orbi_id,
          type: invitation.from_companies.type,
        }));
      case 3:
        companies = this.props.sentRequests;
        return companies.map((invitation) => ({
          id: invitation.id,
          avatar: invitation.to_companies.logo,
          name: invitation.to_companies.corporation_name,
          orbi_id: invitation.to_companies.orbi_id,
          type: invitation.to_companies.type,
          status: invitation.network_status,
        }));
      case 4: return this.props.recommendations;
      default:
        return [];
    }
  };

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  manageFilterSearch = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "searchStr":
        this.setState({ searchOnStr: value, offset: 1 }, this.reloadViewItems);
        break;
      case "doSearch":
        this.setState({ offset: 1 }, this.reloadViewItems);
        break;
      default:
    }
  };

  buildHeader = () =>  {
    if (this.state.companiesStatusItem.id === 4) {
      return(
        <>
          <div className="logo"></div>
          <div className="invitations">Recomendación enviada por</div>
          <div className="invitations">Correo electrónico</div>
          <div className="invitations">¿Tiene cuenta en Orbinetwork?</div>
          <div className="invitations">Empresa en Orbinetwork a las que pertenece</div>
          <div className="invitations">Tipo de cuenta</div>
          <div className="invitations">Empresa creadas en Orbinetwork desde</div>
          <div className="invitations">Recomendación enviada</div>
          <div className="invitations">Viajes gratis hasta</div>
          <div className="invitations">Estatus de relación</div>
        </>
      );
    } else {
      return(
        <>
          <div className="logo"></div>
          <div className="credentials">Datos</div>
          <div className="orbi-id">Orbi ID</div>
          {this.state.companiesStatusItem.id === 1 ? (
            <div className="joined-since">Unidos desde</div>
          ) : (
            ""
          )}
          <div className="company-type">Tipo de empresa</div>
          {this.state.companiesStatusItem.id !== 1 ? (
            <div className="status">Estatus</div>
          ) : (
            ""
          )}
          <div className="actions"></div>
        </>
      );
    }
  }

  render() {
    return (
      <div className="catalog">
        <ValidatePerms
          perms={[
            {
              id: "39",
              perm: "c",
            },
          ]}
          optionalElement={
            <CatalogHeader title={"Mi red"} showViewMode={false} />
          }
        >
          <CatalogHeader
            title={"Mi red"}
            actionButtons={[this.actionButtonInvite, this.headerActionButtonSettings]}
            showViewMode={false}
          ></CatalogHeader>
        </ValidatePerms>
        <ValidatePerms
          perms={[
            {
              id: "39",
              perm: "r",
            },
          ]}
        >
          <TabSelectorRibbon
            items={companiesStatus}
            badge={true}
            badges={this.props.resume}
            onChange={this.switchCompanyStatusType}
            activeId={this.state.companiesStatusItem.id}
          />
          <div
            style={{
              margin: "0 2% 0 auto",
              display: "flex",
              justifyContent: "flex-end",
              height: "40px",
            }}
          >
            <FilterBarCompact
              searchOnly={true}
              searchStr={this.state.searchOnStr}
              searchAction={this.manageFilterSearch}
            />
          </div>
          <div className="content ">
            <div className="catalog-row-header network-row-header">
              { this.buildHeader() }
            </div>
            {this.getCompaniesData().map((company) => (
              <CatalogRow
                key={company.id}
                defaultClass={"company-row "}
                id={company.id}
                isHeightAuto={this.state.companiesStatusItem.id === 4}
                onClick={() => {}}
              >
                {this.getRow(company)}
              </CatalogRow>
            ))}
            <PaginatorControlled
              itemCount={this.getMaxElements()}
              onChange={(e) =>
                this.setState(
                  { [e.target.name]: e.target.value },
                  this.reloadViewItems
                )
              }
              limit={this.state.limit}
              offset={this.state.offset}
            />
          </div>
        </ValidatePerms>
        <ConfirmDialog
          open={this.state.showCancelInvitationModal}
          title="¿Cancelar la solicitud?"
          message="¿Estás seguro que deseas cancelar la solicitud?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showCancelInvitationModal(false)}
          acceptAction={() => this.cancelInvitation()}
        />
        <ConfirmDialog
          open={this.state.showDeleteInvitationModal}
          title="¿Desactivar la relación?"
          message="Recuerda que al desactivar esta empresa los registros generados no se perderán, pero ya no tendrás esta relación ¿estás seguro que deseas desactivarla?"
          acceptText="Continuar"
          class="end-relationship-message"
          contentObject={
            <div className="credentials">
              <div className="logo">
                <div
                  className={
                    "avatar " /* .concat(this.state.selectedContactAvatar ? '' : 'default') */
                  }
                  style={{
                    backgroundImage: "url(".concat(
                      this.getAvatarUrl(
                        this.state.selectedContactAvatar,
                        this.selectedCompanyType
                      ),
                      ")"
                    ),
                  }}
                ></div>
              </div>
              <div className="">
                <div className="name">{this.state.selectedContactName}</div>
                <div className="orbi-id">{this.state.selectedCompanyType}</div>
              </div>
            </div>
          }
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showDeleteInvitationModal(false)}
          acceptAction={() => this.deleteInvitation()}
        />
        <ConfirmDialog
          open={this.state.showRejectInvitationModal}
          title="¿Rechazar la solicitud?"
          message="¿Estás seguro que deseas rechazar la solicitud?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showRejectInvitationModal(false)}
          acceptAction={() => this.rejectInvitation()}
        />
        <ConfirmDialog
          open={this.state.showAcceptInvitationModal}
          title="¿Aceptar la solicitud?"
          message="¿Estás seguro que deseas aceptar la solicitud?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showAcceptInvitationModal(false)}
          acceptAction={() => this.acceptInvitation()}
        />
        <ConfirmDialog
          open={this.state.showResendInvitationModal}
          title="¿Re-enviar la solicitud?"
          message="¿Estás seguro que deseas re-enviar la solicitud?"
          acceptText="Continuar"
          class="result-message"
          showCancel={true}
          cancelText="Regresar"
          cancelButtonType="secondary"
          closeAction={() => this.showResendInvitationModal(false)}
          acceptAction={() => this.resendInvitation()}
        />
        <ConfirmDialog
          open={this.state.showInviteCompanyModal}
          title="Recomendar a un amigo"
          message="Ingresa los correos electrónicos de tus clientes que deseas que conozcan y se únan a Orbinetwork"
          contentObject={
            <div className="input-tagger-container">
              <InputTagger
                placeholder='Ingresa los correos electrónicos separados por espacio y presiona “enter” para habilitar el botón de aceptar.'
                tags={this.state.emails}
                onChange={this.handleEmailInput}
              />
            </div>
          }
          acceptText="Recomendar"
          class="invite-message "
          closeAction={() => this.showInviteCompanyModal(false)}
          acceptAction={() => this.inviteNonUser()}
          disabledAccept={this.state.emails.length === 0}
        />
        <Modal
          isOpen={this.state.showRenameCompanyModal}
          portalClassName="dialog emails-modal edit-network-contact"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Editar contacto</div>
          <div
            className="close"
            onClick={() => this.showRenameCompanyModal(false)}
          ></div>
          <div className="message">
            <div className="credentials">
              <div className="logo">
                <div
                  className={
                    "avatar " /* .concat(this.state.selectedContactAvatar ? '' : 'default') */
                  }
                  style={{
                    backgroundImage: "url(".concat(
                      this.getAvatarUrl(
                        this.state.selectedContactAvatar,
                        this.state.selectedCompanyType
                      ),
                      ")"
                    ),
                  }}
                ></div>
              </div>
              <div className="">
                <div className="name">{this.state.selectedContactName}</div>
                <div className="orbi-id">
                  Orbi ID: {this.state.selectedContactOrbiId}
                </div>
              </div>
            </div>
            <div className="alias">
              <TextInput
                value={this.state.selectedContactAlias}
                label="Alias"
                name="selectedContactAlias"
                onChange={this.handleTextInput}
              />
            </div>
            <div className="code">
              <TextInput
                value={this.state.selectedContactCode}
                label="Código"
                name="selectedContactCode"
                onChange={this.handleTextInput}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="secondary"
              onClick={() => this.showRenameCompanyModal(false)}
            />
            <Button
              text="Guardar"
              type="primary"
              onClick={this.handleEditContact}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showSearchCompanyModal}
          portalClassName="dialog emails-modal invite-network"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Agregar a mi red</div>
          <div
            className="close"
            onClick={() => this.showSearchCompanyModal(false)}
          ></div>
          <div className="message">
            <div className="search">
              <InputSearch
                type="text"
                label="Buscar por Orbi ID o razón social"
                name="networkCompaniesSearch"
                className=""
                value={this.state.searchStr}
                onChange={this.setSearchStr}
              />
              <img src="/images/search.svg" alt="" className="icono" />
            </div>
            {this.state.searchStr.length > 2 ? (
              <>
                <p className="indicator-text center">
                  {this.props.networkCompanies.length} resultado(s)
                  encontrado(s)
                </p>
                <div className="company-results">
                  {this.props.networkCompanies.map((company) => {
                    return (
                      <div key={company.id} className="company-row">
                        <div className="logo">
                          <div
                            className={
                              "avatar " /* .concat(company.logo ? '' : 'default') */
                            }
                            style={{
                              backgroundImage: "url(".concat(
                                this.getAvatarUrl(company.logo, company.type),
                                ")"
                              ),
                            }}
                          ></div>
                        </div>
                        <div className="credentials">
                          <div className="name">
                            {company.corporation_name} - {company.orbi_id}
                          </div>
                          <div className="type">{company.type}</div>
                        </div>
                        <div className="action">
                          {company.network_status.status === 2 ||
                          company.network_status.status === 6 ? (
                            <Button
                              text={company.network_status.description}
                              type="primary"
                              onClick={() => {
                                this.sendInvitation(company);
                              }}
                            />
                          ) : (
                            <div className="network_status">
                              {company.network_status.description}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="indicator-text">
                  Si no encuentras la empresa buscada, envía una invitación para
                  que se registre.
                </p>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="actions">
            {/* <Button
              text="Invitar empresa"
              type="primary outline"
              onClick={() =>
                this.setState({
                  showSearchCompanyModal: false,
                  showInviteCompanyModal: true,
                })
              }
            /> */}
            {/* <Button text="Buscar" type="primary" onClick={() => {this.setState({showSearchResults: true})}} /> */}
          </div>
        </Modal>
        <ConfirmDialog
          open={this.state.showResultModal}
          title={this.state.resultModalTitle}
          message={this.state.resultModalText}
          acceptText="Aceptar"
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const configuration = {
    base: state.configuration.NetworkCatalog,
    1: state.configuration.NetworkCatalog1,
    2: state.configuration.NetworkCatalog2,
    3: state.configuration.NetworkCatalog3,
  };
  const recommendations = state.api["recommendations"]?.items?.results ?? [];
  const recommendationsCount = state.api["recommendations"]?.items?.count ?? 0;

  const resume = getEntityItems(state,"resume");
  return {
    companyType: state.globalCatalog.session.company.type.id,
    configuration,
    sentRequests:
      (state.companyReducer.networkSentInvitations.data &&
        state.companyReducer.networkSentInvitations.data.results) ||
      [],
    sentRequestsCount:
      (state.companyReducer.networkSentInvitations.data &&
        state.companyReducer.networkSentInvitations.data.count) ||
      0,
    recommendations,
    recommendationsCount,
    receivedRequests:
      (state.companyReducer.networkReceivedInvitations.data &&
        state.companyReducer.networkReceivedInvitations.data.results) ||
      [],
    receivedRequestsCount:
      (state.companyReducer.networkReceivedInvitations.data &&
        state.companyReducer.networkReceivedInvitations.data.count) ||
      0,
    approvedRequests:
      (state.companyReducer.networkApprovedInvitations.data &&
        state.companyReducer.networkApprovedInvitations.data.results) ||
      [],
    approvedRequestsCount:
      (state.companyReducer.networkApprovedInvitations.data &&
        state.companyReducer.networkApprovedInvitations.data.count) ||
      0,
    networkStatusCatalog: state.globalCatalog.networkStatus.data,
    companyTypesCatalog: state.globalCatalog.companyTypes.data,
    ownCompanyId: state.globalCatalog.session.company.id,
    networkCompanies: state.companyReducer.networkCompanies.data,
    resume
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadNetworkStatusCatalog());
  dispatch(loadCompanyTypesCatalog());
  // dispatch(listNetworkReceivedInvitations());
  // dispatch(listNetworkSentInvitations());
  // dispatch(listNetworkApprovedInvitations(10, 1));
  return {
    setConfig: (view, config) =>
      dispatch(setConfig("NetworkCatalog".concat(view), config)),
    setBaseConfig: (config) => dispatch(setConfig("NetworkCatalog", config)),

    loadResume: (
        params: { company_id: number },
        opts: ApiOptions
      ) =>
      dispatch(
        readEntities("COMPANIES.NETWORK", { ...params, view: "resume" }, opts)
      ),

    getNetworkCompanies: (searchStr) =>
      dispatch(listNetworkCompanies(searchStr)),
    reloadReceivedInvitations: (limit, offset, searchOnStr) =>
      dispatch(listNetworkReceivedInvitations(limit, offset, searchOnStr)),
    reloadSentInvitations: (limit, offset, searchOnStr) =>
      dispatch(listNetworkSentInvitations(limit, offset, searchOnStr)),
    reloadApprovedInvitations: (limit, offset, searchOnStr) =>
      dispatch(listNetworkApprovedInvitations(limit, offset, searchOnStr)),
    rejectRequest: (request_id) => dispatch(rejectNetworkRequest(request_id)),
    acceptRequest: (request_id) => dispatch(acceptNetworkRequest(request_id)),
    resendRequest: (request_id) => dispatch(resendNetworkRequest(request_id)),
    deleteRequest: (request_id) => dispatch(deleteNetworkRequest(request_id)),
    sendRequest: (to_company_id) => dispatch(sendNetworkRequest(to_company_id)),
    findUserByEmail: (email) => dispatch(getUserByEmail(email, "", "")),
    inviteNewCompanyByEmail: (email) =>
      dispatch(inviteNewCompanyByEmail(email)),
    updateNetworkContact: (params) => dispatch(updateNetworkContact(params)),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    reloadRecommendations: (params, opt) => dispatch(readEntities("COMPANIES.NETWORK.RECOMMENDATIONS", params, opt)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(NetworkCatalogView);

NetworkCatalogView.defaultProps = {
  networkCompanies: [],
};

export const LinkText = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

export const BigNames = styled.div`
  display: block;
  margin-right: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  margin-top: 4px;
  margin-bottom: 4px;
`