import React from "react";
import Button from "../../shared/controls/button";
import PrevSwitch from "../../shared/controls/prev-switch";
import SelectInputStyled from "../../shared/controls/select-input-styled";
// import TextInput from "../../shared/controls/text-input";
// import AlertLevel from "./alert-level-config";
// import PrevSwitch from "../controls/prev-switch";

class CompanyNotificationRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }
  manageCollapse = e => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className={"alert-row ".concat(this.props.className)}>
        <div className="alert-title">
          {this.props.notification.description}
          <div>
            <PrevSwitch
              name="active"
              checked={this.props.notification.is_active}
              handleClick={e =>
                this.props.onNotificationDataChange({
                  target: {
                    name: "active",
                    value: this.props.notification.id,
                    checked: e
                  }
                }, "active", this.props.notification.id)
              }
            />
            <div
              className={"collapser".concat(
                this.state.collapsed ? " collapsed" : ""
              )}
              onClick={this.manageCollapse}
            >
              <img alt="" src="/images/select-arrow.svg" />
            </div>
          </div>
          
        </div>
        <div
          className={"notification-content".concat(
            this.state.collapsed ? " collapsed" : "",
            this.props.notification.edit ? "" : " blocked"
          )}
        >
          <div
            className="edit-btn"
            onClick={(e) => this.props.onNotificationDataChange(e, "edit", this.props.notification.id)}
          >
            <img alt="" src="/images/edit-transparent.svg" />
          </div>
          <div className="notification-users">
            <SelectInputStyled
              selectedItems={this.props.notification.users.map(u => u.toString())}
              items={this.props.users}
              onChange={(e) => this.props.onNotificationDataChange(e, "user", this.props.notification.id)}
              className={""}
              name={"selectedUsers"}
              label={"Selecciona los usuarios que recibirán esta notificación"}
              multiple={true}
              disabled={!this.props.notification.edit}
            />
          </div>
          {
            this.props.notification.edit &&
            <div className="action-buttons">
              <Button 
                text={"Cancelar"}
                type={"primary outline"}
                className={"cancel-changes-button"}
                onClick={(e) => {
                  this.props.onNotificationDataChange(e, "cancel", this.props.notification.id)
                }}
              />
              <Button 
                text={"Guardar"}
                type={"primary"}
                className={"save-changes-button"}
                onClick={(e) => {
                  this.props.onNotificationDataChange(e, "save", this.props.notification.id)
                }}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default CompanyNotificationRow;