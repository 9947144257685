import * as mqtt from "react-paho-mqtt";
import store from "../redux/store";
import {
  SOCKET,
  downloadPermissionFile
} from "../redux/reducers/global-catalog.reducer";
import { getUrlsEnv } from "./backend-api";
let  callback = null;

export default class SocketClient {
  //   constructor() {
  //     let currentSubscription = "";
  //     let c = null;
  //   }

  currentSubscription = "";
  c = null;

  _options = {
    useSSL: true,
    onSuccess: () => this.onConnect(this.currentSubscription, this.c),
    onFailure: this.onFailure
  };

  onConnect = (subscription, connect) => {
    connect.subscribe(subscription, {});
  };

  onFailure() {}

  init() {
    let c = mqtt.connect(
      getUrlsEnv().mqtt,
      Number(8765),
      "client-" + Math.floor(Math.random() * 1000000.0),
      this.onConnectionLost,
      this.onMessageArrived
    );
    store.dispatch(SOCKET.SET.ACTION(c));
    return c;
  }

  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost: " + responseObject.errorMessage);
    }
  }

  onMessageArrived(message) {
    let response = JSON.parse(message.payloadString);
    switch (response.type) {
      case 1:
        store.dispatch(downloadPermissionFile());
        // window.location.reload();
        break;
      case 2:
        sessionStorage.clear();
        window.location.href = "/login.html";
        break;
      case 3:
        window.location.href = "/company/select";
        break;

      case 5:
          // console.log("Alertas")
          // console.log(response.type)
          // console.log(response.data)
          if (callback)
          callback(response)
          break
      default:
        break;
    }
  }

  validClient(subscription) {
    let client = store.getState().globalCatalog.socket.client;
    if (Object.values(client).length === 0) {
      this.c = this.init();
    } else {
      this.c = client;
    }
    this.currentSubscription = subscription;
    if (!this.c.isConnected()) {
      this.c.connect(this._options);
    } else {
      this.c.disconnect();
      this.c.connect(this._options);
    }
  }

  connect(subscription,callback_1) {
    // this.currentSubscription = subscription;
    callback = callback_1;
    this.validClient(subscription);
  }
}
