import React from "react";
import { BuyRow } from "./style";
import Button from "../../../shared/controls/button";

class PaymentsBuyRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: 0,
    };
  }

  getQty = (item) => {
    if (item.editable) {
      return (
        <input
          name="editable"
          type="number"
          value={item.qty}
          onChange={(e) => {
            this.props.changeEditable(e);
          }}
          onBlur={(e) => {
            this.validEditable(e);
          }}
        />
      );
    }
    return item.qty;
  };

  getTotal = (item) => {
    return item.qty * item.price;
  };

  validEditable = (e) => {
    let data = {
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    };
    if (parseInt(e.target.value) < 1000) {
      this.setState({ [e.target.name]: 1000 });
      data.target.value = 1000;
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.props.changeEditable(data);
  };

  getCredits = (item) => {
    if (item.editable) {
      return parseInt(this.state.editable);
    }
    return item.qty;
  };

  render() {
    const { data } = this.props;
    return data.map((item) => {
      return (
        <BuyRow>
          <div className="left">
            <div className="title">{item.title} Orbibucks</div>
            <div className="subtitle">Precio Unitario ${item.price} usd</div>
          </div>
          <div className="right">
            <div className="title">
              <div>QTY</div>
              <div>P.U</div>
              <div>Total</div>
              <div></div>
            </div>
            <div className="details">
              <div>{this.getQty(item)}</div>
              <div>$ {item.price}</div>
              <div>${this.getTotal(item)} USD</div>
              <div>
                <Button
                  text="Pagar"
                  type="primary"
                  onClick={() => console.log(this.getCredits(item))}
                />
              </div>
            </div>
          </div>
        </BuyRow>
      );
    });
  }
}

export default PaymentsBuyRow;
